//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DescriptionFoldingComponent",
  components: {
    Icon24FillArrowTop: () => import("@/assets/icons/Icon24FillArrowTop.svg?inline"),
    Icon24FillArrowBottom: () => import("@/assets/icons/Icon24FillArrowBottom.svg?inline"),
  },
  props: {
    description: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      fold: false
    };
  },
  computed: {
    styles() {
      return {
        "description-folding-component__wrapper--full-height": this.fold,
        "description-folding-component__wrapper--min-height": !this.fold,
        "description-folding-component__wrapper--gradient": !this.fold && this.showButton,
      };
    },
    show() {
      return this?.description.length > 0;
    },
    showButton() {
      return this?.description.length > 600;
    }
  },
  methods: {
    showHide() {
      this.fold = !this.fold;
    }
  }
};
