export const state = () => ({
  toasters: [],
  opened: false,
  params: null,
})

export const mutations = {
  setToasters: (state, toaster) => {
    state.toasters = [...state.toasters, toaster]
  },
  open: (state, { params }) => {
    state.opened = true
    state.params = params
  },
  close: (state) => {
    state.opened = false
    state.params = null
    state.toasters = []
  },
  setDeleteToaster: (state, id) => {
    state.toasters = state.toasters.filter(item => item.id !== id)
  }
}

export const actions = {}

export const getters = {
  toasters: state => state.toasters,
  opened: state => state.opened,
  params: state => state.params,
}
