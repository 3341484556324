//
//
//
//
//
//
//

export default {
  name: "EmptyButton",
  props: {
    width: {
      type: String,
      default: () => '35%'
    }
  },
  computed: {
    style() {
      return {
        width: this.width
      }
    }
  }
}
