export default async function ({
                                 $config,
                                 store,
                                 app
                               }) {
  await store.dispatch('pages/excursions/agencies', {
    params: {
      city_id: $config.singleCity ? store.getters['cities/default']?.id : null,
      count: 100500,
      lang: app.i18n.locale,
      has_excursions: true,
    }
  })
}
