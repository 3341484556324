export default {
  accessible_environment: false,
  agency_id: null,
  annotation: null,
  city_id: null,
  days: null,
  description: null,
  disabled: false,
  distance_int: null,
  duration_int: null,
  edit: false,
  entity: null,
  errors: null,
  hours: null,
  id: null,
  images: [],
  kilometers: null,
  level_id: null,
  season_id: null,
  meters: null,
  minutes: null,
  pending: false,
  places: [],
  category_id: null,
  title: null,
  tourism_type_id: null,
  additions: []
}
