import {Plugin} from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$login inside Vue components
  interface Vue {
    $login(url: string): void
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$login inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $login(url: string): void
  }

  // nuxtContext.$login
  interface Context {
    $login(url: string): void
  }
}

declare module 'vuex/types/index' {
  // this.$login inside Vuex stores
  interface Store<S> {
    $login(url: string): void
  }
}

const pluginLogin: Plugin = ({app, store, route, $config}, inject) => {
  inject('login', (url = ''): void => {
    const host = store.state.host
    const locale = app.$defaultLocale() === app.$currentLocale() ? '' : app.$prefixLocale()
    if (url && url.length > 0) {
      window.location.href = `${host}/login?latest_url=${url}`
    } else {
      const latestUrl = host + app.context.route.fullPath
      window.location.href = `${host}/login?latest_url=${latestUrl}`
    }
  })
}

export default pluginLogin
