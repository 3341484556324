export default async function ({ store, app }) {
  // const tourism_types = store.getters['tourism-types/tourism_types']
  // if (tourism_types.length === 0) {
  await store.dispatch('tourism-types/fetch', {
    params: {
      count: 100500,
      lang: app.i18n.locale
    }
  })
  // }
}
