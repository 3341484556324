import Vue from 'vue'
import Router from 'vue-router'
import routes from '@/configs/router.config.ts'

Vue.use(Router)

export async function createRouter() {
  return new Router({
    mode: 'history',
    routes: await routes(),
    scrollBehavior(to) {
      if (!process.server && !to.hash) {
        window.scrollTo(0, 0)
      }
    }
  })
}
