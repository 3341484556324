//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Disclaimer",
  components: {
    Icon24FillClose: () => import("@/assets/icons/Icon24FillClose.svg?inline")
  },
  data() {
    return {
      show: false,
      text: ''
    };
  },
  mounted() {
    this.$axios.$get(`/api/disclaimers?lang=${this.$i18n.locale}`)
      .then((resolve) => {
        this.text = resolve?.data[0]?.text;
        this.show = resolve?.data[0]?.active;
      })
      .catch(() => null);
  },
  methods: {
    close() {
      this.show = false;
    }
  }
};
