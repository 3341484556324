const middleware = {}

middleware['account-agency-event-validation'] = require('../middleware/account-agency-event-validation.js')
middleware['account-agency-event-validation'] = middleware['account-agency-event-validation'].default || middleware['account-agency-event-validation']

middleware['account-agency-excursion-validation'] = require('../middleware/account-agency-excursion-validation.js')
middleware['account-agency-excursion-validation'] = middleware['account-agency-excursion-validation'].default || middleware['account-agency-excursion-validation']

middleware['account-agency-orders-validation'] = require('../middleware/account-agency-orders-validation.js')
middleware['account-agency-orders-validation'] = middleware['account-agency-orders-validation'].default || middleware['account-agency-orders-validation']

middleware['account-agency-places-validation'] = require('../middleware/account-agency-places-validation.js')
middleware['account-agency-places-validation'] = middleware['account-agency-places-validation'].default || middleware['account-agency-places-validation']

middleware['account-agency-profile-validation'] = require('../middleware/account-agency-profile-validation.js')
middleware['account-agency-profile-validation'] = middleware['account-agency-profile-validation'].default || middleware['account-agency-profile-validation']

middleware['account-agency-widget'] = require('../middleware/account-agency-widget.js')
middleware['account-agency-widget'] = middleware['account-agency-widget'].default || middleware['account-agency-widget']

middleware['account-my-orders'] = require('../middleware/account-my-orders.js')
middleware['account-my-orders'] = middleware['account-my-orders'].default || middleware['account-my-orders']

middleware['age'] = require('../middleware/age.js')
middleware['age'] = middleware['age'].default || middleware['age']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['categories'] = require('../middleware/categories.js')
middleware['categories'] = middleware['categories'].default || middleware['categories']

middleware['cities'] = require('../middleware/cities.js')
middleware['cities'] = middleware['cities'].default || middleware['cities']

middleware['gallery'] = require('../middleware/gallery.js')
middleware['gallery'] = middleware['gallery'].default || middleware['gallery']

middleware['host'] = require('../middleware/host.js')
middleware['host'] = middleware['host'].default || middleware['host']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['levels'] = require('../middleware/levels.js')
middleware['levels'] = middleware['levels'].default || middleware['levels']

middleware['locale'] = require('../middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['login'] = require('../middleware/login.js')
middleware['login'] = middleware['login'].default || middleware['login']

middleware['menu'] = require('../middleware/menu.js')
middleware['menu'] = middleware['menu'].default || middleware['menu']

middleware['page-places'] = require('../middleware/page-places.js')
middleware['page-places'] = middleware['page-places'].default || middleware['page-places']

middleware['page-search'] = require('../middleware/page-search.js')
middleware['page-search'] = middleware['page-search'].default || middleware['page-search']

middleware['pages/articles'] = require('../middleware/pages/articles.js')
middleware['pages/articles'] = middleware['pages/articles'].default || middleware['pages/articles']

middleware['pages/category-collections/has-events'] = require('../middleware/pages/category-collections/has-events.js')
middleware['pages/category-collections/has-events'] = middleware['pages/category-collections/has-events'].default || middleware['pages/category-collections/has-events']

middleware['pages/category-collections/index'] = require('../middleware/pages/category-collections/index.js')
middleware['pages/category-collections/index'] = middleware['pages/category-collections/index'].default || middleware['pages/category-collections/index']

middleware['pages/event'] = require('../middleware/pages/event.js')
middleware['pages/event'] = middleware['pages/event'].default || middleware['pages/event']

middleware['pages/excursion'] = require('../middleware/pages/excursion.js')
middleware['pages/excursion'] = middleware['pages/excursion'].default || middleware['pages/excursion']

middleware['pages/excursions'] = require('../middleware/pages/excursions.js')
middleware['pages/excursions'] = middleware['pages/excursions'].default || middleware['pages/excursions']

middleware['pages/guide'] = require('../middleware/pages/guide.js')
middleware['pages/guide'] = middleware['pages/guide'].default || middleware['pages/guide']

middleware['pages/interactive-map'] = require('../middleware/pages/interactive-map.js')
middleware['pages/interactive-map'] = middleware['pages/interactive-map'].default || middleware['pages/interactive-map']

middleware['pages/journal'] = require('../middleware/pages/journal.js')
middleware['pages/journal'] = middleware['pages/journal'].default || middleware['pages/journal']

middleware['pages/municipality'] = require('../middleware/pages/municipality.js')
middleware['pages/municipality'] = middleware['pages/municipality'].default || middleware['pages/municipality']

middleware['pages/new'] = require('../middleware/pages/new.js')
middleware['pages/new'] = middleware['pages/new'].default || middleware['pages/new']

middleware['pages/organization'] = require('../middleware/pages/organization.js')
middleware['pages/organization'] = middleware['pages/organization'].default || middleware['pages/organization']

middleware['pages/place'] = require('../middleware/pages/place.js')
middleware['pages/place'] = middleware['pages/place'].default || middleware['pages/place']

middleware['pages/route'] = require('../middleware/pages/route.js')
middleware['pages/route'] = middleware['pages/route'].default || middleware['pages/route']

middleware['pages/travel-agency'] = require('../middleware/pages/travel-agency.js')
middleware['pages/travel-agency'] = middleware['pages/travel-agency'].default || middleware['pages/travel-agency']

middleware['photos'] = require('../middleware/photos.js')
middleware['photos'] = middleware['photos'].default || middleware['photos']

middleware['places-has-events'] = require('../middleware/places-has-events.js')
middleware['places-has-events'] = middleware['places-has-events'].default || middleware['places-has-events']

middleware['seasons'] = require('../middleware/seasons.js')
middleware['seasons'] = middleware['seasons'].default || middleware['seasons']

middleware['seo'] = require('../middleware/seo.js')
middleware['seo'] = middleware['seo'].default || middleware['seo']

middleware['tourism-types'] = require('../middleware/tourism-types.js')
middleware['tourism-types'] = middleware['tourism-types'].default || middleware['tourism-types']

middleware['viewport'] = require('../middleware/viewport.js')
middleware['viewport'] = middleware['viewport'].default || middleware['viewport']

middleware['welcome'] = require('../middleware/welcome.js')
middleware['welcome'] = middleware['welcome'].default || middleware['welcome']

export default middleware
