//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ContextAdditions",
  components: {
    AdditionMail: () => import('@/components/common/AdditionMail'),
    AdditionPhone: () => import('@/components/common/AdditionPhone'),
    AdditionSite: () => import('@/components/common/AdditionSite'),
    Additions: () => import('@/components/common/Additions')
  },
  props: {
    divider: {
      type: Boolean,
      default: () => false
    },
    mail: {
      type: String,
      default: () => ''
    },
    site: {
      type: String,
      default: () => ''
    },
    phone: {
      type: String,
      default: () => ''
    },
    additions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    show() {
      return this.site || this.phone || this.mail || this.additions.length > 0
    },
    typedAdditions() {
      return this.additions.map((item) => {
        if (this.$isSite(item.value)) {
            return {
              ...item,
              type: 'AdditionSite'
            }
        }
        if (this.$isPhone(item.value)) {
          return {
            ...item,
            type: 'AdditionPhone'
          }
        }
        if (this.$isEmail(item.value)) {
          return {
            ...item,
            type: 'AdditionMail'
          }
        }
        return {
          ...item,
          type: 'Additions'
        }
      })
    }
  }
}
