import axios from 'axios';

export const globalConfig = async () => {
  const name = 'stavropol'
  const host = process.env.API_HOST
  if (!host) console.error('Host is not defined')

  const defaultLocale = 'ru'

  const sentryKey = process.env.SENTRY_DSN
  if (!sentryKey) console.error('Sentry key is not defined')

  const coords = [44.1323407, 43.03009406]
  const zoom = 10

  // const disclaimer = true

  const singleCity = true

  const promoCode = true

  const ROUTES_LEVEL_FILTER = true
  const ROUTES_AGE_FILTER = true

  const EXCURSIONS_LEVEL_FILTER = true
  const EXCURSIONS_AGE_FILTER = true

  const yandex = {
    map: {
      apiKey: process.env.YANDEX_MAP_APIKEY,
    },
    metrika: {
      id: process.env.YANDEX_METRIKA_ID,
    },
    tickets: {
      key: process.env.YANDEX_TICKETS_KEY,
    },
    verification: process.env.YANDEX_VERIFICATION,
  }

  let locales = []
  await axios.get(`${host}/api/languages`)
    .then(response => locales = response.data.data.map(item => item.locale))
    .catch((error) => {
      if (error && error.response) {
        throw new Error(`${error?.response?.status}, ${error?.response?.data?.message}`)
        // eslint-disable-next-line no-unreachable
        process.exit(1)
      }
    })
  if (locales.length === 0) console.error('Locales is empty')

  let cities = []
  await axios.get(`${host}/api/cities`)
    .then(response => cities = response.data.data)
    .catch((error) => {
      if (error && error.response) {
        throw new Error(`${error?.response?.status}, ${error?.response?.data?.message}`)
        // eslint-disable-next-line no-unreachable
        process.exit(1)
      }
    })
  if (!cities.some(item => item.is_default)) console.error('You have not default city')

  return {
    name,
    host,
    locales,
    defaultLocale,
    coords,
    zoom,
    // disclaimer,
    yandex,
    singleCity,
    sentryKey,
    promoCode,
    ROUTES_LEVEL_FILTER,
    ROUTES_AGE_FILTER,
    EXCURSIONS_LEVEL_FILTER,
    EXCURSIONS_AGE_FILTER
  }
}
