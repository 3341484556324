//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "ArticleNotes",
  components: {
    TestSlider: () => import("@/components/common/TestSlider"),
    NoteCard: () => import("@/components/common/NoteCard")
  },
  props: {
    articles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pending: true,
    };
  },
  computed: {
    ...mapGetters({
      width: "viewport/width",
      media: "viewport/media",
    }),
    show() {
      return this?.articles.length > 0
    },
    showButtons() {
      return this.media !== "sm" && this?.articles.length > 1;
    },
    showPagination() {
      return this?.articles.length > 1;
    }
  },
  watch: {
    width() {
      this.pending = true
      setTimeout(() => {
        this.pending = false
      }, 1000)
    }
  },
  mounted() {
    if (this.articles.length > 0) {
      setTimeout(() => {
        this.pending = false
      }, 1000)
    }
  }
};
