// Паттерн на проверку мейла. Если значение соответсвует - true, иначе - false.
import {Plugin} from '@nuxt/types'

type Email = string | '';

declare module 'vue/types/vue' {
  interface Vue {
    $isEmail(email: Email): boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isEmail(email: Email): boolean
  }

  interface Context {
    $isEmail(email: Email): boolean
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $isEmail(email: Email): boolean
  }
}

const pluginIsEmail: Plugin = (context, inject) => {
  inject('isEmail', (email: Email) => {
    const regexpEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexpEmail.test(email)
  })
}

export default pluginIsEmail
