//
//
//
//
//
//
//
//
//

export default {
  name: "TestSliderContext",
  props: {
    id: {
      type: String,
      default: () => ''
    },
    gap: {
      type: Number,
      default: () => 32,
    },
    itemWidth: {
      type: Number,
      default: () => 0,
    },
    left: {
      type: Number,
      default: () => 0,
    }
  },
  data() {
    return {
      observer: null,
    }
  },
  computed: {
    style() {
      return {
        gap: `${this.gap}px`,
        left: `${this.left}px`,
      }
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(this.callback, {
      root: document.querySelector(`#${this.id}`),
      rootMargin: '0px',
    });
    const items = document.querySelectorAll(`#${this.id} .test-slider__slots > *`)
    items.forEach(item => {
      this.observer.observe(item)
    })
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    callback(entries) {
      entries.forEach(item => {
        if (item.intersectionRatio > 0) {
          item.target.classList.add('test-slider--visible')
          item.target.style.width = `${this.itemWidth}px`
        } else {
          item.target.classList.remove('test-slider--visible')
        }
      })
    }
  }
}
