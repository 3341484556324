//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RoundButton',
  props: {
    background: {
      type: String,
      default: () => 'var(--accent-color)'
    },
    border: {
      type: String,
      default: () => '1px solid transparent'
    },
    width: {
      type: String,
      default: () => '36px'
    },
    height: {
      type: String,
      default: () => '36px'
    },
    shadow: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    activeItem () {
      return this.active
    },
    classes () {
      return {
        'round-button--shadow': this.shadow,
        'round-button--disabled': this.disabled
      }
    }
  }
}
