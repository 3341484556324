import { parser } from '@/utils/parser'

export const state = () => ({
  photos: [],
  meta: null,
  pending: false
})

export const mutations = {
  setPhotos: (state, photos) => state.photos = [...state.photos, ...photos],
  setPhotosMeta: (state, meta) => state.meta = meta,
  setPhotosPending: (state, pending) => state.pending = pending,
  clearPhotos: (state) => {
    state.photos = []
    state.meta = null
  }
}

export const actions = {
  async photos ({ commit }, payload) {
    commit('setPhotosPending', true)
    if (payload.clear) {
      commit('clearPhotos')
    }
    await this.$axios.$get(`/api/photobank/photos?${parser(payload)}`)
      .then((response) => {
        commit('setPhotos', response?.data)
        commit('setPhotosMeta', response?.meta)
        commit('setPhotosPending', false)
      })
      .catch(() => {
        commit('setPhotos', [])
        commit('setPhotosMeta', null)
      })
      .finally(() => {
        commit('setPhotosPending', false)
      })
  }
}

export const getters = {
  photos: state => state.photos,
  meta: state => state.meta,
  pending: state => state.pending
}
