//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentLanguages",
  components: {
    Icon24FillLanguages: () => import('@/assets/icons/Icon24FillLanguages.svg?inline')
  },
  props: {
    languages: {
      type: Array,
      default: () => []
    }
  }
}
