//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  components: {
    Toaster: () => import("@/components/common/Toaster"),
    ScrollToTopButton: () => import('@/components/common/ScrollToTopButton/index'),
    Disclaimer: () => import('@/components/common/Disclaimer/index'),
    Footer: () => import('@/components/common/Footer/index'),
    Popup: () => import('@/components/common/Popup/index'),
    MobileMenu: () => import('@/components/common/MobileMenu/index'),
    Header: () => import('@/components/common/Header/index'),
  },
  computed: {
    ...mapGetters({
      menu: 'header-navigation/menu',
      logged: 'user/logged',
      token: 'user/token',
      user: 'user/user',
      show: 'popup/popup',
      browser: 'viewport/browser',
      os: 'viewport/os'
    })
  },
  created() {
    if (this.$route.query.api_token || this.token) {
      this.browser.name === 'Safari' || this.os.name === 'iOS'
        ? this.$axios.defaults.params = {api_token: this.token}
        : this.$axios.setToken(`Bearer ${this.token}`)
    }
    if (this.$route.query.api_token) {
      this.$router.replace({query: null})
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('dragover', function (e) {
      e = e || event
      e.preventDefault()
    }, false)
    window.addEventListener('drop', function (e) {
      e = e || event
      e.preventDefault()
    }, false)
    this.$store.commit('popup/close')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.$store.commit('viewport/setScroll', window.scrollY)
    },
    handleResize() {
      this.$store.commit('viewport/setViewport', {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      })
    }
  }
}
