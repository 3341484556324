export default async function ({
                                 params,
                                 store,
                                 app,
                                 error,
                               }) {
  await store.dispatch('guide/fetch', {
    id: params.id,
    params: {
      lang: app.i18n.locale,
      resolution: 'micro',
    }
  })
    .then(() => {
      const guide = store.getters['guide/guide']
      const user = store.getters['user/user']
      if (guide) {
        if (guide?.status !== 'active') {
          if (guide?.user_id !== user?.id) {
            error({statusCode: 404})
          }
        }
      } else {
        error({statusCode: 404})
      }
    })
}
