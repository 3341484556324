import { Plugin } from '@nuxt/types'
import {Entity} from "~/types/entity";
import {Card} from "~/types/card";

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $cardFromEntity(entity: Entity): Card
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $cardFromEntity(entity: Entity): Card
  }
  // nuxtContext.$cardFromEntity
  interface Context {
    $cardFromEntity(entity: Entity): Card
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $cardFromEntity(entity: Entity): Card
  }
}

const pluginCardFromEntity: Plugin = ({store}, inject) => {
  inject('cardFromEntity', (entity: Entity) => {
    if (!entity) {
      throw new Error('Entity must by define')
    }
    switch (entity) {
      case "places": return "PlaceCard"
      case "routes": return "RouteCard"
      case "excursions": return "ExcursionCard"
      case "events": return "EventCard"
      case "news": return "NewsCard"
      default : return "PlaceCard"
    }
  })
}

export default pluginCardFromEntity
