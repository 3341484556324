//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "Popup",
  components: {
    Icon24FillClose: () => import("@/assets/icons/Icon24FillClose.svg?inline"),
  },
  computed: {
    ...mapGetters({
      popup: "popup/popup"
    }),
    name() {
      return this.popup.name;
    },
    opened() {
      return this.popup.opened;
    },
    style() {
      return this?.popup?.params?.style;
    },
    route() {
      return this?.$route?.fullPath;
    },
    iconSize() {
      return this.$mediaSwitch({sm: "16px", defaultValue: "24px"});
    }
  },
  watch: {
    opened(newState) {
      newState ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
    },
    route() {
      if (this.name !== "PopupStory") {
        this.close();
      }
    }
  },
  destroyed() {
    document.body.style.overflow = "auto";
  },
  methods: {
    close() {
      this.$store.commit("popup/close");
    },
    closeCross() {
      switch (this.popup.name) {
        case "PopupAddRoute":
          this.$store.commit("add-route/clear");
          break;
        case "PopupAddPlace":
          this.$store.commit("add-place/clear");
          break;
        case "PopupAddEvent":
          this.$store.commit("add-event/clear");
          break;
        case "PopupAddExcursion":
          this.$store.commit("add-excursion/clear");
          break;
        default:
          break;
      }
      this.close()
    }
  }
};
