import {Plugin} from '@nuxt/types'

interface Viewports {
  hd?: any,
  lg?: any,
  md?: any,
  sm?: any,
  defaultValue: any,
}

declare module 'vue/types/vue' {
  // this.$mediaSwitch inside Vue components
  interface Vue {
    $mediaSwitch(viewports: Viewports): any
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$mediaSwitch inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $mediaSwitch(viewports: Viewports): any
  }

  // nuxtContext.$mediaSwitch
  interface Context {
    $mediaSwitch(viewports: Viewports): any
  }
}

declare module 'vuex/types/index' {
  // this.$mediaSwitch inside Vuex stores
  interface Store<S> {
    $mediaSwitch(viewports: Viewports): any
  }
}

const pluginMediaSwitch: Plugin = ({store}, inject) => {
  // @ts-ignore
  inject('mediaSwitch', ({hd, lg, md, sm, defaultValue}: Viewports) => {
    if (defaultValue === undefined) {
      throw new Error('You need set defaultValue')
    }
    switch (store.getters['viewport/media']) {
      case "hd": {
        return hd || defaultValue;
      }
      case "lg": {
        return lg || defaultValue;
      }
      case "md": {
        return md || defaultValue;
      }
      case "sm": {
        return sm || defaultValue;
      }
      default: {
        return defaultValue;
      }
    }
  })
}

export default pluginMediaSwitch
