//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AgenciesCard',
  components: {
    ImageComponent: () => import('@/components/common/ImageComponent'),
    Status: () => import('@/components/common/Status')
  },
  props: {
    full: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => null
    },
    title: {
      type: String,
      default: () => ''
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-image.svg')
    },
    types: {
      type: String,
      default: () => ''
    },
    status: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    link () {
      return `/account/${this?.entity}/${this?.id}/profile`
    },
    type () {
      switch (this.types) {
        case 'travel': {
          return this.$t('components.agencies-card.type-travel-agency')
        }
        case 'org': {
          return this.$t('components.agencies-card.type-organization')
        }
        case 'guide': {
          return this.$t('components.agencies-card.type-guide')
        }
        default: {
          return ''
        }
      }
    }
  }
  // methods: {
  //   openPopupEditAgency () {
  //     this.$store.commit('popup/open', {
  //       name: 'PopupEditAgency',
  //       params: {
  //         style: {
  //           width: '632px',
  //           maxHeight: '100vh'
  //         }
  //       },
  //       options: this.status !== 'new' ? this?.agency : null
  //     })
  //   }
  // }
}
