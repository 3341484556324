//
//
//
//
//
//
//

export default {
  name: 'Divider',
  props: {
    color: {
      type: String,
      default: () => ''
    },
    depth: {
      type: Number,
      default: () => 1
    }
  },
  computed: {
    style() {
      return {
        background: `${this.color}`,
        height: `${this.depth}px`
      }
    }
  }
}
