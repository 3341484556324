import { parser } from '@/utils/parser'

export const state = () => ({
  places: [],
  placesMeta: null,
  placesPending: false,

  excursions: [],
  excursionsMeta: null,
  excursionsPending: false,

  routes: [],
  routesMeta: null,
  routesPending: false,

  events: [],
  eventsMeta: null,
  eventsPending: false
})

export const mutations = {
  setPlaces: (state, places) => state.places = places,
  setPlacesMeta: (state, meta) => state.placesMeta = meta,
  setPlacesPending: (state, pending) => state.placesPending = pending,

  setExcursions: (state, excursions) => state.excursions = excursions,
  setExcursionsMeta: (state, meta) => state.excursionsMeta = meta,
  setExcursionsPending: (state, pending) => state.excursionsPending = pending,

  setRoutes: (state, routes) => state.routes = routes,
  setRoutesMeta: (state, meta) => state.routesMeta = meta,
  setRoutesPending: (state, pending) => state.routesPending = pending,

  setEvents: (state, events) => state.events = events,
  setEventsMeta: (state, meta) => state.eventsMeta = meta,
  setEventsPending: (state, pending) => state.eventsPending = pending
}

export const actions = {
  async places ({ commit }, payload) {
    commit('setPlacesPending', false)
    await this.$axios.$get(`/api/categories/places?${parser(payload)}`)
      .then(response => {
        commit('setPlaces', response.data || [])
        commit('setPlacesMeta', response.meta || null)
      })
      .catch(() => {
        commit('setPlaces', [])
      })
      .finally(() => {
        commit('setPlacesPending', false)
      })
  },
  async excursions ({ commit }, payload) {
    commit('setExcursionsPending', false)
    await this.$axios.$get(`/api/categories/excursions?not_empty=true${parser(payload)}`)
      .then(response => {
        commit('setExcursions', response.data || [])
        commit('setExcursionsMeta', response.meta || null)
      })
      .catch(() => {
        commit('setExcursions', [])
      })
      .finally(() => {
        commit('setExcursionsPending', false)
      })
  },
  async routes ({ commit }, payload) {
    commit('setRoutesPending', false)
    await this.$axios.$get(`/api/categories/routes?not_empty=true${parser(payload)}`)
      .then(response => {
        commit('setRoutes', response.data || [])
        commit('setRoutesMeta', response.meta || null)
      })
      .catch(() => {
        commit('setRoutes', [])
      })
      .finally(() => {
        commit('setRoutesPending', false)
      })
  },
  async events ({ commit }, payload) {
    commit('setEventsPending', false)
    await this.$axios.$get(`/api/categories/events?${parser(payload)}`)
      .then(response => {
        commit('setEvents', response.data || [])
        commit('setEventsMeta', response.meta || null)
      })
      .catch(() => {
        commit('setEvents', [])
      })
      .finally(() => {
        commit('setEventsPending', false)
      })
  }
}

export const getters = {
  places: state => state.places,
  placesMeta: state => state.placesMeta,
  placesPending: state => state.placesPending,

  excursions: state => state.excursions,
  excursionsMeta: state => state.excursionsMeta,
  excursionsPending: state => state.excursionsPending,

  routes: state => state.routes,
  routesMeta: state => state.routesMeta,
  routesPending: state => state.routesPending,

  events: state => state.events,
  eventsMeta: state => state.eventsMeta,
  eventsPending: state => state.eventsPending

}
