import { parser } from '@/utils/parser'

export const state = () => ({
  places: [],
  routes: [],
  events: [],
  journals: [],
  excursions: [],
  pending: false
})

export const mutations = {
  setPending: (state, pending) => state.pending = pending,
  setFavoritePlaces: (state, places) => state.places = places,
  setFavoriteRoutes: (state, routes) => state.routes = routes,
  setFavoriteExcursion: (state, excursions) => state.excursions = excursions,
  setFavoriteEvents: (state, events) => state.events = events,
  setFavoriteJournals: (state, journals) => state.journals = journals
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    const string = parser(payload)
    if (!payload.entity) {
      const places = await this.$axios.$get(`/api/places?favorited${string}`)
      commit('setFavoritePlaces', places.data)
      const routes = await this.$axios.$get(`/api/routes?favorited${string}`)
      commit('setFavoriteRoutes', routes.data)
      const excursions = await this.$axios.$get(`/api/excursions?&favorited${string}`)
      commit('setFavoriteExcursion', excursions.data)
      const events = await this.$axios.$get(`/api/events?favorited${string}`)
      commit('setFavoriteEvents', events.data)
      const journals = await this.$axios.$get(`/api/journals?favorited${string}`)
      commit('setFavoriteJournals', journals.data)
    } else if (payload.entity === 'places') {
      const places = await this.$axios.$get(`/api/places?favorited${string}`)
      commit('setFavoritePlaces', places.data)
    } else if (payload.entity === 'routes') {
      const routes = await this.$axios.$get(`/api/routes?&favorited${string}`)
      commit('setFavoriteRoutes', routes.data)
    } else if (payload.entity === 'excursions') {
      const excursions = await this.$axios.$get(`/api/excursions?&favorited${string}`)
      commit('setFavoriteExcursion', excursions.data)
    } else if (payload.entity === 'events') {
      const events = await this.$axios.$get(`/api/events?favorited${string}`)
      commit('setFavoriteEvents', events.data)
    } else if (payload.entity === 'journals') {
      const journals = await this.$axios.$get(`/api/journals?favorited${string}`)
      commit('setFavoriteJournals', journals.data)
    }
    commit('setPending', false)
  }
}

export const getters = {
  pending: state => state.pending,
  places: state => state.places,
  routes: state => state.routes,
  events: state => state.events,
  journals: state => state.journals,
  excursions: state => state.excursions
}
