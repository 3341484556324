//
//
//
//
//
//
//
//
//
//

export default {
  name: "CodeViewComponent",
  props: {
    code: {
      type: String,
      default: () => ``
    }
  }
}
