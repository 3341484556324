import { parser } from '@/utils/parser'

export const state = () => ({
  user: null,
  token: '',
  logged: false,

  agencies: [],
  agenciesPending: false,

  agency: null,
  agencyPlaces: [],
  agencyPlace: null,

  agencyExcursions: [],
  agencyExcursionsMeta: null,
  agencyExcursionsPending: false,

  agencyEvents: [],
  agencyEvent: null,
  eventPlaces: [],
  eventPlace: null,

  places: [],
  metaPlaces: null,
  placesPending: false,

  routes: [],
  routesPending: false,

  metaAgencyEvents: null,
  metaAgencyTrips: null,
  orders: [],

  bonusPointsBalance: 0,
})

export const getters = {
  user: state => state.user,
  token: state => state.token,
  logged: state => state.logged,

  agencies: state => state.agencies,
  agenciesPending: state => state.agenciesPending,

  agency: state => state.agency,
  agencyPlaces: state => state.agencyPlaces,
  agencyPlace: state => state.agencyPlace,

  agencyExcursions: state => state.agencyExcursions,
  agencyExcursionsMeta: state => state.agencyExcursionsMeta,
  agencyExcursionsPending: state => state.agencyExcursionsPending,

  agencyEvents: state => state.agencyEvents,
  agencyEvent: state => state.agencyEvent,
  eventPlaces: state => state.eventPlaces,
  eventPlace: state => state.eventPlace,

  places: state => state.places,
  placesPending: state => state.placesPending,
  metaPlaces: state => state.metaPlaces,

  routes: state => state.routes,
  routesPending: state => state.routesPending,

  metaAgencyEvents: state => state.metaAgencyEvents,
  metaAgencyTrips: state => state.metaAgencyTrips,
  orders: state => state.orders,
  bonusPointsBalance: state => state.bonusPointsBalance
}

export const mutations = {
  setToken: (state, token) => state.token = token,
  setUser: (state, user) => {
    state.user = user
    state.logged = true
    state.bonusPointsBalance = user.bonus_points_balance
  },
  logout: (state) => {
    state.user = null
    state.token = ''
    state.logged = false
    state.bonusPointsBalance = 0
  },

  setAgencies: (state, agencies) => state.agencies = agencies,
  setAgenciesPending: (state, agenciesPending) => state.agenciesPending = agenciesPending,

  setAgency: (state, agency) => state.agency = agency,
  setAgencyPlaces: (state, places) => state.agencyPlaces = places,

  setAgencyExcursions: (state, excursions) => state.agencyExcursions = [...state.agencyExcursions, ...excursions],
  setAgencyExcursionsMeta: (state, meta) => state.agencyExcursionsMeta = meta,
  setAgencyExcursionsPending: (state, pending) => state.agencyExcursionsPending = pending,
  clearAgencyExcursions: (state) => {
    state.agencyExcursions = []
    state.agencyExcursionsMeta = null
  },

  setAgencyEvents: (state, events) => state.agencyEvents = [...state.agencyEvents, ...events],
  setMetaAgencyEvents: (state, meta) => state.metaAgencyEvents = meta,
  clearAgencyEvents: state => state.agencyEvents = [],
  clearPlaces: state => state.places = [],

  setPlaces: (state, places) => state.places = [...state.places, ...places],
  setPlacesPending: (state, placesPending) => state.placesPending = placesPending,
  setMetaPlaces: (state, meta) => state.metaPlaces = meta,

  setRoutes: (state, routes) => state.routes = routes,
  setRoutesPending: (state, routesPending) => state.routesPending = routesPending,

  setOrders: (state, orders) => state.orders = orders,
  clearOrders: state => state.orders
}

export const actions = {
  async whoAmI ({ commit }) {
    await this.$axios.$get('/api/user/whoami')
      .then((resolve) => {
        commit('setUser', resolve.data)
      })
      .catch(() => false)
  },
  async agencies ({ commit }, payload) {
    commit('setAgenciesPending', true)
    commit('setAgencies', [])
    await this.$axios.$get(`/api/agencies?${parser(payload)}&user=current`)
      .then(response => commit('setAgencies', response.data))
      .catch(() => null)
      .finally(() => commit('setAgenciesPending', false))
  },
  async agency ({ commit }, payload) {
    const agency = await this.$axios.$get(`/api/agencies/${payload.id}?${parser(payload)}&user=current`)
    commit('setAgency', agency.data)
  },
  async places ({ commit }, payload) {
    commit('setPlacesPending', true)
    if (payload?.clear) {
      commit('clearPlaces')
    }
    await this.$axios.$get(`/api/places?user=current&count=100500${parser(payload)}`)
      .then(response => {
        commit('setPlaces', response.data)
        commit('setMetaPlaces', response.meta)
      })
      .catch(() => null)
      .finally(() => commit('setPlacesPending', false))
  },
  async routes ({ commit }, payload) {
    commit('setRoutesPending', true)
    await this.$axios.$get(`/api/routes?user=current&count=100500${parser(payload)}`)
      .then(response => {
        commit('setRoutes', response.data)
      })
      .catch(() => null)
      .finally(() => commit('setRoutesPending', false))
  },
  async agencyPlaces ({ commit }, { params }) {
    const places = await this.$axios.$get(`/api/places?agency_id=${params.agency_id}&user=current&count=100500`)
    commit('setAgencyPlaces', places.data)
  },
  async agencyExcursions ({ commit }, payload) {
    if (payload?.clear) {
      commit('clearAgencyTrips')
    }
    const trips = await this.$axios.$get(`/api/excursions?user=current&count=100500${parser(payload)}`)
    commit('setAgencyTrips', trips.data)
    commit('setMetaAgencyTrips', trips.meta)
  },
  async agencyEvents ({ commit }, payload) {
    if (payload?.clear) {
      commit('clearAgencyEvents')
    }
    const events = await this.$axios.$get(`/api/events?user=current&count=100500${parser(payload)}`)
    commit('setAgencyEvents', events.data)
    commit('setMetaAgencyEvents', events.meta)
  },
  async refreshToken ({ getters }) {
    return await this.$axios.$get(`/refresh?api_token=${getters.token}`)
  },
  async orders ({ commit }, payload) {
    if (payload?.clear) {
      commit('clearOrders')
    }
    const orders = await this.$axios.$get(`/api/orders?${parser(payload)}`)
    commit('setOrders', orders.data)
  }
}
