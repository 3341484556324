//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import seo from '@/middleware/seo'

export default {
  name: 'PhotoBankCreateView',
  components: {
    Section: () => import('@/components/common/Section/index'),
    PhotoUploader: () => import('@/components/common/PhotoUploader/index')
  },
  middleware: [seo],
  validate ({
    store,
    redirect
  }) {
    if (store.getters['user/logged']) {
      return true
    } else {
      return redirect('/login')
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      logged: 'user/logged',
    })
  }
}
