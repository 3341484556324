//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AccordionWidgets',
  components: {
    Empty: () => import('@/components/common/Empty'),
    Button: () => import('@/components/common/Button'),
    WidgetAccordionTab: () => import('@/components/common/AccordionWidgets/WidgetAccordionTab.vue')
  },
  props: {
    pending: {
      type: Boolean,
      default: () => false,
    },
    widgets: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale !== 'ru' ? `/${this.$i18n.locale}` : ''
    }
  }
}
