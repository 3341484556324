//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import seo from "@/middleware/seo";
import config from "@/configs/pages.config"
const components = {}
for (const component in config.HomeView.components) {
  const folder = config.HomeView.components[component].special ? 'special' : 'common'
  components[component] = () => ({
    component: import(`@/components/${folder}/${component}/index.vue`),
  })
}

export default {
  name: "HomeView",
  components,
  middleware: [seo],
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEOKeywords,
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t("pages.index.seo.site-name"),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
        }
      })
    };
  },
  computed: {
    ...mapGetters({
      SEOType: "seo/type",
      SEOTitle: "seo/title",
      SEOKeywords: "seo/keywords",
      SEODescription: "seo/description",
      SEOImage: "seo/image",
      SEOUrl: "seo/url",
      SEOLocale: "seo/locale",
      SEOSiteName: "seo/siteName",
    }),
  }
};
