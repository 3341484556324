export default async function ({
  app,
  store,
  query,
  route,
  params,
  redirect
}) {
  // if (!store.getters['user/logged']) {
  const api_token = query.api_token || app.$cookies.get('api_token')
  if (api_token) {
    app.$cookies.set('api_token', api_token)
    store.getters['viewport/browser'].name === 'Safari' || store.getters['viewport/os'].name === 'iOS'
      ? app.$axios.defaults.params = { api_token }
      : app.$axios.setToken(api_token, 'Bearer')
    store.commit('user/setToken', api_token)
    await store.dispatch('user/whoAmI')
  }
  if (Object.keys(query).includes('api_token')) {
    const newQuery = {}
    for (const key in query) {
      if (key !== 'api_token') {
        newQuery[key] = query[key]
      }
    }
    redirect({
      name: route.name,
      params,
      query: newQuery
    })
  }
  // }
}
