//
//
//
//
//
//
//
//
//
//

export default {
  name: "FooterCreditLink",
  props: {
    link: {
      type: String,
      default: () => '',
    }
  },
  computed: {
    href () {
      if (this.link) {
        const phoneRegExpPattern = '^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$'
        const phoneRegExp = new RegExp(phoneRegExpPattern)
        const mailRegExpPattern = '[a-z0-9]+@[a-z]+\\.[a-z]{2,3}'
        const mailRegExp = new RegExp(mailRegExpPattern)
        if (this.link.match(phoneRegExp)) {
          return `tel:${this.link}`
        }
        if (this.link.match(mailRegExp)) {
          return `mailto:${this.link}`
        }
        return this.link
      } else {
        return ''
      }
    }
  }
}
