import { parser } from '@/utils/parser'

export const state = () => ({
  excursions: [],
  meta: null,
  pending: false
})

export const mutations = {
  setExcursions: (state, excursions) => state.excursions = [...state.excursions, ...excursions],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending,
  clearExcursions: (state) => {
    state.excursions = []
    state.meta = null
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload.clear) {
      commit('clearExcursions')
    }
    const excursions = await this.$axios.$get(`/api/agencies/${payload.id}/excursions?${parser(payload)}&user=current`)
    commit('setExcursions', excursions.data)
    commit('setMeta', excursions.meta)
    commit('setPending', false)
  }
}

export const getters = {
  excursions: state => state.excursions,
  meta: state => state.meta,
  pending: state => state.pending
}
