export default async function ({store, params}) {
  await store.dispatch('user/agencies', {
    params: {
      count: 100500
    }
  })
  if (params.widget_id) {
    await store.dispatch('widget/fetch', {
      id: params.widget_id,
      params: {
        user: 'current'
      }
    })
  }
}
