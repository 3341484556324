//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentCountPlaces",
  components: {
    Icon24FillLocation: () => import('@/assets/icons/Icon24FillLocation.svg?inline')
  },
  props: {
    countPlaces: {
      type: Number,
      default: () => 0
    }
  }
}
