//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SelectSearchComponent',
  components: {
    InputComponent: () => import('@/components/common/InputComponent'),
    LabelComponent: () => import('@/components/common/LabelComponent'),
    ErrorComponent: () => import('@/components/common/ErrorComponent'),
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline'),
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Icon24FillSearch: () => import('@/assets/icons/Icon24FillSearch.svg?inline'),
    Icon24FillClose: () => import('@/assets/icons/Icon24FillClose.svg?inline'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar')
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      default: () => null
    },
    defaultText: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    array: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      opened: false,
      selected: null,
      search: null,
      filteredArray: []
    }
  },
  computed: {
    classList () {
      return [
        { 'select--disabled': this.disabled },
        { 'select--errors': Array.isArray(this.errors) ? this.errors[0] : this.errors }
      ]
    },
    contextTitle () {
      return this.array.find(item => item.id === this.value)?.title || this.defaultText
    }
  },
  watch: {
    search(value) {
      if(value.length > 0) {
        value = value.replace(/[-\\/\\^$*+?.()|[\]{}]/g, '\\$&')
        const regex = new RegExp(`(${value}[а-яА-Я0-9_]|${value}\\w+|${value})`, 'gi')
        this.filteredArray = this.array
        this.filteredArray = this.filteredArray.filter(item => item.title.match(regex))
      } else {
        this.filteredArray = this.array
      }
    }
  },
  created () {
    this.filteredArray = this.array
  },
  methods: {
    select (item) {
      this.selected = item
      this.$emit('change', this.selected)
      this.opened = false
      this.remove()
    },
    toggle () {
      if (!this.disabled) {
        this.opened = !this.opened
        this.remove()
        this.$emit('change-edit', true)
      }
    },
    close () {
      this.opened = false
      this.remove()
    },
    remove () {
      this.search = ''
    },
  }
}
