import moment from "moment";

function filteredDays({days, today, amount}) {
  return days.filter(day => day >= today)
    .filter(day => moment.unix(today).add(amount, 'months').startOf('month').unix() <= day &&
      day < moment.unix(today).add(amount, 'months').startOf('month').add(1, 'months').unix())
}

export const state = () => ({
  data: null,
  days: [],
  filteredDays: [],
  pending: true,
  filtersType: 'first-month',
  sortType: 'by-time-asc',
  id: null,
  entity: null,
  today: moment().startOf('day').unix(),
  currentDay: null,
  currentMonth: moment().month() + 1,
  agency: null,
  title: '',
  context: [],
  sortedContext: [],
  bookingTerms: null,
  cancellationTerms: null,
})

export const mutations = {
  reset: (state) => {
    state.data = null
    state.days = []
    state.filteredDays = []
    state.pending = true
    state.filtersType = 'first-month'
    state.sortType = 'by-time-asc'
    state.id = null
    state.entity = null
    state.today = moment().startOf('day').unix()
    state.currentDay = null
    state.currentMonth = moment().month() + 1
    state.agency = null
    state.title = ''
    state.context = []
    state.sortedContext = []
    state.bookingTerms = null
    state.cancellationTerms = null
  },
  setData: (state, data) => {
    state.data = data
    let days = []
    for (const key in data) {
      days = [...days, moment(key, 'DD-MM-YYYY').unix()]
    }
    state.days = days
  },
  setBookingTerms: (state, bookingTerms) => state.bookingTerms = bookingTerms,
  setCancellationTerms: (state, cancellationTerms) => state.cancellationTerms = cancellationTerms,
  setPending: (state, pending) => state.pending = pending,
  setFiltersType: (state, filtersType) => state.filtersType = filtersType,
  setFilteredDays: (state, filteredDays) => state.filteredDays = filteredDays,
  setId: (state, id) => state.id = id,
  setEntity: (state, entity) => state.entity = entity,
  setToday: (state, today) => state.today = today,
  setCurrentDay: (state, currentDay) => state.currentDay = currentDay,
  setContext: (state, context) => state.context = context,
  setSortedContext: (state, sortedContext) => state.sortedContext = sortedContext,
  setCurrentMonth: (state, currentMonth) => state.currentMonth = currentMonth,
  'first-month': (state) => {
    state.filteredDays = filteredDays({days: state.days, today: state.today, amount: 0})
    if (state.filteredDays.length > 0) {
      state.currentDay = state.filteredDays[0]
    }
  },
  'second-month': (state) => {
    state.filteredDays = filteredDays({days: state.days, today: state.today, amount: 1})
    if (state.filteredDays.length > 0) {
      state.currentDay = state.filteredDays[0]
    }
  },
  'third-month': (state) => {
    state.filteredDays = filteredDays({days: state.days, today: state.today, amount: 2})
    if (state.filteredDays.length > 0) {
      state.currentDay = state.filteredDays[0]
    }
  },
  weekend: (state) => {
    state.filteredDays = state.days.filter(day => moment.unix(state.today).startOf('week').add(6, 'days').unix() === day ||
      moment.unix(state.today).startOf('week').add(7, 'days').unix() === day)
    if (state.filteredDays.length > 0) {
      state.currentDay = state.filteredDays[0]
    }
  },
  'next-week': (state) => {
    state.filteredDays = state.days.filter(day => moment.unix(state.today).add(1, 'weeks').startOf('week').unix() < day &&
      day <= moment.unix(state.today).add(1, 'weeks').startOf('week').add(7, 'days').unix())
    if (state.filteredDays.length > 0) {
      state.currentDay = state.filteredDays[0]
    }
  },
  setAgency: (state, agency) => state.agency = agency,
  setTitle: (state, title) => state.title = title,
  setSortType: (state, sortType) => state.sortType = sortType,
}

export const actions = {
  setContext({state, commit}, context) {
    commit('setContext', context)
  },
  setCurrentDay({state, commit}, currentDay) {
    commit('setCurrentDay', currentDay)
    commit('setContext', currentDay ? state.data[moment.unix(currentDay).format('DD-MM-YYYY')] : [])
  },
  setFiltersType({state, commit}, filtersType) {
    commit('setFiltersType', filtersType)
    commit(filtersType)
  },
  async fetch({state, commit, dispatch}) {
    if (!state.id) throw new Error('id undefined')
    if (!state.entity) throw new Error('entity undefined')
    if (!state.currentMonth) throw new Error('currentMonth undefined')
    commit('setPending', true)
    Promise.all([
      await this.$axios.get(`/api/${state.entity}/${state.id}/schedules-calendar?month=${moment().month() + 1}`),
      await this.$axios.get(`/api/${state.entity}/${state.id}/schedules-calendar?month=${moment().month() + 2}`),
      await this.$axios.get(`/api/${state.entity}/${state.id}/schedules-calendar?month=${moment().month() + 3}`)
    ])
      .then((response) => {
        if (response.length > 0) {
          let data = {}
          for (let i = 0; i < response.length; i++) {
            data = {...data, ...response[i].data.data}
          }
          commit('setData', data)
          dispatch('setFiltersType', state.filtersType)
        }
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  data: state => state.data,
  days: state => state.days,
  filteredDays: state => state.filteredDays,
  pending: state => state.pending,
  filtersType: state => state.filtersType,
  sortType: state => state.sortType,
  id: state => state.id,
  entity: state => state.entity,
  today: state => state.today,
  currentDay: state => state.currentDay,
  currentMonth: state => state.currentMonth,
  agency: state => state.agency,
  smRegistered: state => state.agency?.sm_registered || false,
  context: state => state.context,
  sortedContext: state => state.sortedContext,
  title: state => state.title,
  bookingTerms: state => state.bookingTerms,
  cancellationTerms: state => state.cancellationTerms,
}
