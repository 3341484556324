//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
  name: 'UserInfo',
  components: {
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    Icon24FillRefresh: () => import('@/assets/icons/Icon24FillRefresh.svg?inline'),
    Icon24FillDistance: () => import('@/assets/icons/Icon24FillDistance.svg?inline'),
    Icon24FillLocation: () => import('@/assets/icons/Icon24FillLocation.svg?inline'),
    Icon24FillComments: () => import('@/assets/icons/Icon24FillComments.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton')
  },
  props: {
    user: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      isUpdatingUserInfo: false
    }
  },
  computed: {
    user_account_page_url () {
      return this?.user?.user_account_page_url
    },
    name () {
      return this?.user?.name
    },
    email () {
      return this?.user?.email
    },
    avatar () {
      return this?.user?.avatar || require('@/assets/images/default-user-avatar.svg')
    },
    tripsCount () {
      return this?.user?.tripsCount
    },
    placesCount () {
      return this?.user?.placesCount
    },
    commentsCount () {
      return this?.user?.commentsCount
    },
    activity () {
      return [
        {
          id: 'trips',
          icon: 'Icon24FillDistance',
          name: this.$t('components.user-info.routes'),
          value: this.tripsCount
        },
        {
          id: 'places',
          icon: 'Icon24FillLocation',
          name: this.$t('components.user-info.places'),
          value: this.placesCount
        },
        {
          id: 'comments',
          icon: 'Icon24FillComments',
          name: this.$t('components.user-info.comments'),
          value: this.commentsCount || 0
        }
      ]
    }
  },
  methods: {
    async updateUserInfo () {
      this.isUpdatingUserInfo = true
      try {
        await this.$store.dispatch('user/refreshToken')
        await this.$store.dispatch('user/whoAmI')
      } catch (e) {
        this.$login()
      }
      this.isUpdatingUserInfo = false
    },
    load (element) {
      if (element.target.src.match(/micro|small|medium|large|extra?/gi)) {
        const max = this.resolutions.indexOf(this?.lazyload?.resolutions?.max || 'medium')
        const current = this.resolutions.indexOf(element.target.src.match(/micro|small|medium|large|extra?/gi)[0])
        if (current < max) {
          if (element.target.src.match(/micro/i)) {
            element.target.src = element.target.src.replace(/micro/i, 'small')
            return true
          }
        }
      }
    },
    editProfile() {
      window.open(`${this.user_account_page_url}/restxq/profile-edit`)
    }
  }
}
