import { errors } from '@/utils/errors'
import { feedbackAppend } from '@/utils/feedback-append'
import defaultStore from '@/utils/feedback-default-store-config'

export const state = () => ({
  errors: null,
  name: null,
  email: null,
  phone: null,
  city_id: null,
  message: null,
  pending: false
})

export const mutations = {
  setCityId: (state, city_id) => state.city_id = city_id,
  setPhone: (state, phone) => {
    state.phone = phone
    state.errors = errors('phone', state.errors)
  },
  setName: (state, name) => {
    state.name = name
    state.errors = errors('name', state.errors)
  },
  setEmail: (state, email) => {
    state.email = email
    state.errors = errors('email', state.errors)
  },
  setMessage: (state, message) => {
    state.message = message
    state.errors = errors('message', state.errors)
  },
  setErrors: (state, errors) => (state.errors = { ...state.errors, ...errors }),
  setPending: (state, pending) => (state.pending = pending),

  clear: (state) => {
    for (const key in defaultStore) {
      state[key] = defaultStore[key]
    }
  }
}

export const actions = {
  async send ({state, commit}) {
    commit('setPending', true)
    const data = feedbackAppend(state)
    await this.$axios.$post('/api/feedback', data)
      .then(() => {
        commit('clear')
        commit('popup/close', null, { root: true })
        commit('popup/open', {
          name: 'PopupSuccess'
        }, { root: true })
      })
      .catch((error) => {
        commit('setErrors', error.response.data)
        commit('setPending', false)
      })
  }
}

export const getters = {
  errors: (state) => state.errors,
  name: (state) => state.name,
  email: (state) => state.email,
  phone: (state) => state.phone,
  city_id: (state) => state.city_id,
  message: (state) => state.message,
  pending: (state) => state.pending
}
