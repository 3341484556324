import {parser} from "~/utils/parser";

export const state = () => ({
  story: null,
  pending: true,
  partCurrentIndex: 0,
})

export const actions = {
  async fetch({commit}, {id, params}) {
    await this.$axios.$get(`/api/stories/${id}?${parser(params)}`)
      .then(response => commit('setStory', response.data))
      .catch(() => null)
  }
}

export const getters = {
  pending: state => state.pending,
  story: state => state.story,
  parts: state => state.story?.imgs || [],
  part: state => state.story?.imgs[state.partCurrentIndex],
  image: state => state.story?.imgs[state.partCurrentIndex]?.image || '',
  title: state => state.story?.imgs[state.partCurrentIndex]?.title || '',
  description: state => state.story?.imgs[state.partCurrentIndex]?.description || '',
  buttonTitle: state => state.story?.imgs[state.partCurrentIndex]?.button?.title || '',
  buttonTitleTranslations: state => state.story?.imgs[state.partCurrentIndex]?.button_translations,
  buttonLink: state => state.story?.imgs[state.partCurrentIndex]?.button?.link || '',
  buttonExternal: state => state.story?.imgs[state.partCurrentIndex]?.button?.external,
  partCurrentIndex: state => state.partCurrentIndex,
}

export const mutations = {
  clear: state => {
    state.story = null
    state.pending = true
    state.partCurrentIndex = 0
  },
  setStory: (state, story) => state.story = story,
  setPending: (state, pending) => state.pending = pending,
  setPartCurrentIndex: (state, partCurrentIndex) => state.partCurrentIndex = partCurrentIndex,
}
