//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AccountMyOrder',
  components: {
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline'),
    Button: () => import('@/components/common/Button'),
    Divider: () => import('@/components/common/Divider'),
    Status: () => import('@/components/common/Status'),
    RoundButton: () => import('@/components/common/RoundButton')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    orderable_id: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    orderable_type: {
      type: String,
      default: () => ''
    },
    orderable: {
      type: Object,
      default: () => null
    },
    status: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    created_at: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    order_items: {
      type: Array,
      default: () => []
    },
    // eslint-disable-next-line vue/prop-name-casing
    total_price: {
      type: String,
      default: () => ''
    },
    payable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters({
      host: 'host'
    }),
    orderableTitle () {
      return this?.orderable?.title
    },
    orderableAgencyTitle () {
      return this?.orderable?.agency?.title
    },
    orderableAgencyPhone () {
      return this?.orderable?.agency?.phone
    },
    orderableAgencyLink () {
      return `/${this?.orderable?.agency?.entity}/${this?.orderable?.agency?.id}`
    },
    orderableLink () {
      return `/${this.orderable_type}/${this.orderable_id}`
    },
    orderableDateTime () {
      return this.$moment.unix(this?.order_items[0].datetime).format('DD.MM.YYYY HH:mm')
    },
    number () {
      return `${this.id}`.padStart(10, '0')
        .padStart(12, '№ ')
    },
    success_url () {
      const locale = this.$i18n.locale
      return `${this.host}${locale === 'ru' ? '' : `/${locale}`}/account/my/orders?order=success`
    },
    fail_url () {
      const locale = this.$i18n.locale
      return `${this.host}${locale === 'ru' ? '' : `/${locale}`}/account/my/orders?order=fail`
    }
  },
  methods: {
    payment () {
      this.$store.dispatch('order/put', {
        id: this.id,
        params: {
          fail_url: this.fail_url,
          success_url: this.success_url
        }
      })
    },
    click () {
      this.show = !this.show
    }
  }
}
