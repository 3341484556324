//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'MapNavigationPlacesType',
  components: {
    MapNavigationBackButton: () => import('@/components/common/MapNavigation/MapNavigationBackButton.vue'),
    HorizontalEntityCard: () => import('@/components/common/HorizontalEntityCard'),
    Empty: () => import('@/components/common/Empty'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar')
  },
  computed: {
    ...mapGetters({
      placesPending: 'interactive-map/placesPending',
      places: 'interactive-map/places',
      entity: 'interactive-map/entity',
      categories: 'categories/places',
      category_id: 'interactive-map/category_id'
    }),
    showPlaces () {
      return this.category_id && this.entity === 'categories'
    },
    title() {
      return this.categories.find(item => item.id === Number(this.category_id))?.title || ''
    }
  },
  methods: {
    click(id) {
      this.$store.commit('interactive-map/setEntity', 'places')
      this.$store.commit('interactive-map/setId', id)
    },
    back() {
      this.$store.commit('interactive-map/setEntity', 'categories')
      this.$store.commit('interactive-map/setCategoryId', null)
    }
    // click(id) {
    //   this.$store.commit('interactive-map/setHistory', this.$route.query)
    //   this.$router.replace({
    //     query: {
    //       ...this.$route.query,
    //       id,
    //       entity: 'place'
    //     }
    //   })
    // },
    // back() {
    //   this.$store.commit('interactive-map/setHistory', this.$route.query)
    //   this.$router.replace({
    //     query: {
    //       ...this.$route.query,
    //       category_id: null
    //     }
    //   })
    // }
  }
}
