// Паттерн на проверку телефонв. Если значение соответсвует - true, иначе - false.

import {Plugin} from '@nuxt/types'

type Phone = string | '';

declare module 'vue/types/vue' {
  interface Vue {
    $isPhone(phone: Phone): boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isPhone(phone: Phone): boolean
  }

  interface Context {
    $isPhone(phone: Phone): boolean
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $isPhone(phone: Phone): boolean
  }
}

const pluginIsPhone: Plugin = (context, inject) => {
  inject('isPhone', (phone: Phone) => {
    const regexpPhone = /\+?7\d{10}/
    return regexpPhone.test(phone)
  })
}

export default pluginIsPhone
