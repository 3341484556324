//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import PseudoSelectComponent from "@/components/common/WidgetPreview/PseudoSelectComponent";
import PseudoInputComponent from "@/components/common/WidgetPreview/PseudoInputComponent";

export default {
  name: "WidgetPreview",
  components: {
    PseudoInputComponent,
    PseudoSelectComponent,
    Button: () => import("@/components/common/Button"),
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Icon24FillCalendar: () => import('@/assets/icons/Icon24FillCalendar.svg?inline'),
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    background: {
      type: String,
      default: () => ''
    },
    main: {
      type: String,
      default: () => ''
    },
    textButtonColor: {
      type: String,
      default: () => ''
    },
    textMainColor: {
      type: String,
      default: () => ''
    },
    src: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapGetters({host: "host"}),
    logotype () {
      return this.src ? this.src : `${this.host}/widget-logo.svg`
    }
  }
}
