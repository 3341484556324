//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'BookingTermsView',
  components: { Section: () => import('@/components/common/Section') },
  layout: 'documentation',
  computed: {
    ...mapGetters({
      booking: 'terms/booking'
    })
  },
  mounted () {
    this.$store.dispatch('terms/fetch', {
      id: this.$route.params.entity_id,
      entity: this.$route.params.entity,
      params: {
        lang: this.$i18n.locale
      }
    })
  }
}
