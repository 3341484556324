//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Brochure',
  props: {
    params: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      brochure: null
    }
  },
  computed: {
    show () {
      return !!this.brochure
    },
    image () {
      return this?.brochure?.image || require('~/assets/images/default-image.svg')
    },
    title () {
      return this?.brochure?.title
    },
    link () {
      return this?.brochure?.link
    }
  },
  async mounted () {
    await this.$axios.$get(`/api/brochure?resolution=medium&type=web&lang=${this.$i18n.locale}`)
      .then((resolve) => {
        if (!Array.isArray(resolve?.data)) {
          this.brochure = resolve?.data
        }
      })
  }
}
