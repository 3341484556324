//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Toggle',
  props: {
    initial: {
      type: Number,
      default: () => 0
    },
    labels: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      piked: ''
    }
  },
  computed: {
    name () {
      return `toggle-${parseInt(Math.random() * 10000, 10)}`
    }
  },
  watch: {
    value (val) {
      this.piked = val
    }
  },
  mounted () {
    this.piked = this?.value || this?.labels[this?.initial]?.id
  },
  methods: {
    trigger (e) {
      this.$emit('toggle-change', e.target.value)
    }
  }
}
