import { parser } from '@/utils/parser'

export const state = () => ({
  guides: [],
  meta: null
})

export const mutations = {
  clear: state => state.guides = [],
  setGuides: (state, guides) => state.guides = [...state.guides, ...guides],
  setMeta: (state, meta) => state.meta = meta
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload?.clear) {
      commit('clear')
    }
    const guides = await this.$axios.$get(`/api/guides?${parser(payload)}`)
    commit('setGuides', guides.data)
    commit('setMeta', guides.meta)
  }
}

export const getters = {
  guides: state => state.guides,
  meta: state => state.meta
}
