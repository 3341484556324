//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "EntityFiltersWrapper",
  components: {
    Icon24FillSettings: () => import("@/assets/icons/Icon24FillSettings.svg?inline"),
    Icon24FillSearch: () => import("@/assets/icons/Icon24FillSearch.svg?inline")
  },
  props: {
    entity: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      type: "filters"
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    locale(n) {
      if (n !== "ru" && this.type !== "filters") {
        this.changeType("close");
      }
    }
  },
  mounted() {
    const pages = JSON.parse(this.$getCookie("pages"));
    if (pages) {
      if (pages[this.entity]) {
        this.type = pages[this.entity].type;
        this.$emit("change", this.type);
      }
    }
  },
  methods: {
    setCookie() {
      const pages = JSON.parse(this.$getCookie("pages"));
      this.$setCookie("pages", JSON.stringify({
        ...pages,
        [this.entity]: {
          type: this.type,
        }
      }));
    },
    changeType(type) {
      if (this.type === type) {
        this.type = "close";
      } else {
        this.type = type;
      }
      this.$emit("change", this.type);
      this.setCookie();
    },
  }
};
