//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Button',
  components: {
    Icon24FillLoading: () => import('@/assets/icons/Icon24FillLoading.svg?inline')
  },
  props: {
    full: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    pending: {
      type: Boolean,
      default: () => false
    },
    circle: {
      type: Boolean,
      default: () => false
    },
    background: {
      type: String,
      default: () => 'var(--accent-color)'
    },
    color: {
      type: String,
      default: () => 'var(--white)'
    },
    border: {
      type: String,
      default: () => null
    }
  },
  computed: {
    classList () {
      return {
        'button--full': this.full,
        'button--disabled': this.disabled || this.pending,
        'button--pending': this.pending,
        'button--circle': this.circle
      }
    }
  }
}
