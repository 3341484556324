//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SocialsRoundedComponent',
  components: {
    Icon24FillFacebook: () => import('@/assets/icons/Icon24FillFacebook.svg?inline'),
    Icon24FillVk: () => import('@/assets/icons/Icon24FillVk.svg?inline'),
    Icon24FillOk: () => import('@/assets/icons/Icon24FillOk.svg?inline'),
    Icon24FillInstagram: () => import('@/assets/icons/Icon24FillInstagram.svg?inline'),
    Icon24FillYoutube: () => import('@/assets/icons/Icon24FillYoutube.svg?inline'),
    Icon24FillTiktok: () => import('@/assets/icons/Icon24FillTiktok.svg?inline'),
    Icon24FillTelegram: () => import('@/assets/icons/Icon24FillTelegram.svg?inline')
  },
  props: {
    socials: {
      type: Object,
      default: () => null
    },
    background: {
      type: String,
      default: () => 'var(--main-color)'
    },
    fillIcon: {
      type: String,
      default: () => 'var(--dark-color)'
    },
    size: {
      type: Number,
      default: () => null
    }
  },
  computed: {
    gridColumns () {
      return {
        gridTemplateColumns: `repeat(auto-fill, ${this.iconSize})`
      }
    },
    styleIcon () {
      return {
        width: this.iconSize,
        height: this.iconSize,
        background: this.background
      }
    },
    iconSize () {
      if (this.size) {
        return `${this.size}px`
      } else {
        switch (this?.$media()) {
          case 'sm': {
            return '36px'
          }
          default: {
            return '48px'
          }
        }
      }
    }
  },
  methods: {
    socialsIcons (name) {
      switch (name) {
        case 'facebook': {
          return 'Icon24FillFacebook'
        }
        case 'instagram': {
          return 'Icon24FillInstagram'
        }
        case 'youtube': {
          return 'Icon24FillYoutube'
        }
        case 'ok': {
          return 'Icon24FillOk'
        }
        case 'vk': {
          return 'Icon24FillVk'
        }
        case 'tiktok': {
          return 'Icon24FillTiktok'
        }
        case 'telegram': {
          return 'Icon24FillTelegram'
        }
      }
    }
  }
}
