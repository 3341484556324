//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Calendar',
  components: {
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline')
  },
  props: {
    arrows: {
      type: Array,
      default: () => ['left', 'right']
    },
    days: {
      type: Array,
      default: () => []
    },
    currentMonth: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    showPreviousIcon () {
      return this.arrows.includes('left')
    },
    showNextIcon () {
      return this.arrows.includes('right')
    },
    week () {
      return [
        this.$t('common.unit.week.monday.short'),
        this.$t('common.unit.week.tuesday.short'),
        this.$t('common.unit.week.wednesday.short'),
        this.$t('common.unit.week.thursday.short'),
        this.$t('common.unit.week.friday.short'),
        this.$t('common.unit.week.saturday.short'),
        this.$t('common.unit.week.sunday.short')
      ]
    }
  },
  methods: {
    previous () {
      this.$emit('previous-month', this.$moment(this.currentMonth, 'MM.YYYY')
        .subtract(1, 'month')
        .format('MM.YYYY'))
    },
    next () {
      this.$emit('next-month', this.$moment(this.currentMonth, 'MM.YYYY')
        .add(1, 'month')
        .format('MM.YYYY'))
    }
  }
}
