//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import accountMyOrders from '@/middleware/account-my-orders'
// import seo from '@/middleware/seo'

export default {
  name: 'AccountMyOrdersView',
  components: {
    AccountMyOrder: () => import('@/components/common/AccountMyOrder')
  },
  layout: 'personal-area',
  middleware: [accountMyOrders],
  // middleware: [accountMyOrders, seo],
  head () {
    return {
      title: this.$t('pages.account-my-orders.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      orders: 'user/orders'
    })
  }
}
