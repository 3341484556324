//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "RutubeVideo",
  props: {
    rutube: {
      type: [String, null],
      default: () => ''
    }
  },
  computed: {
    src() {
      if (this?.rutube?.length > 0) {
        return `https://rutube.ru/play/embed/${this.rutube}`
      }
      return ''
    }
  }
}
