export const state = () => ({
  opened: false,
  params: null,
  options: null,
  submit: false,
  name: ''
})

export const getters = {
  popup: state => state,
  opened: state => state.opened,
  options: state => state.options,
  submit: state => state.submit,
  params: state => state.params
}

export const mutations = {
  open: (state, { params, name, options }) => {
    state.opened = true
    state.params = params
    state.options = options
    state.name = name
  },
  close: (state) => {
    state.opened = false
    state.params = null
    state.options = null
    state.submit = false
    state.name = ''
  },
  submit: (state) => {
    state.submit = true
  }
}

export const actions = {}
