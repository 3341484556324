//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import excursion from '~/middleware/pages/excursion'
import config from "@/configs/pages.config"
const components = {}
for (const component in config.ExcursionView.components) {
  const folder = config.ExcursionView.components[component].special ? 'special' : 'common'
  components[component] = () => ({
    component: import(`@/components/${folder}/${component}/index.vue`),
  })
}

export default {
  name: 'ExcursionView',
  middleware: [excursion],
  head() {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.title,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.$seoImage(this.images) || this.SEOImage,
          url: this.SEOUrl,
          locale: this.SEOLocale,
          site_name: this.SEOSiteName
        },
        vk: {
          image: this.$seoImage(this.images) || this.SEOImage,
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      title: 'excursion/title',
      images: 'excursion/images'
    })
  },
}
