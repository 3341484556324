export const featureNormalizer = (payload) => {
  return {
    type: 'Feature',
    id: payload?.id,
    geometry: {
      type: 'Point',
      coordinates: payload?.location.slice().reverse()
    },
    properties: {
      balloonContent: {
        title: payload?.title,
        address: payload?.address
      },
      hintContent: payload?.title,
      image: payload?.images[0] || require('@/assets/images/default-image.svg'),
      city_id: payload?.city_id,
      category_id: payload?.category_id
    }
  }
}
