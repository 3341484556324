//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'


export default {
  name: 'EventCard',
  components: {
    Icon24StrokeFavorites: () => import('@/assets/icons/Icon24StrokeFavorites.svg?inline'),
    Icon24FillTrash: () => import('@/assets/icons/Icon24FillTrash.svg?inline'),
    LabelTag: () => import('@/components/common/LabelTag'),
    RoundButton: () => import('@/components/common/RoundButton'),
    ImageComponent: () => import('@/components/common/ImageComponent')
  },
  props: {
    travel: {
      type: Boolean,
      default: () => false
    },
    full: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    favorited: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    images: {
      type: Array,
      default: () => [require('@/assets/images/default-image.svg')]
    },
    // eslint-disable-next-line vue/prop-name-casing
    started_at: {
      type: Number,
      default: () => null
    },
    // eslint-disable-next-line vue/prop-name-casing
    stopped_at: {
      type: Number,
      default: () => null
    },
    // eslint-disable-next-line vue/prop-name-casing
    created_at: {
      type: Number,
      default: () => null
    },
    category: {
      type: Object,
      default: () => null
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      innerFavorited: false,
      formatDate: 'DD.MM.YYYY',
      formatTime: 'HH:mm'
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    }),
    roundButtonSize () {
      switch (this?.$media()) {
        case 'hd': {
          return '38px'
        }
        default: {
          return '36px'
        }
      }
    },
    date () {
      const start = this.$moment.unix(this.started_at || this.created_at)
        .format(this.formatDate) || ''
      const stop = this.$moment.unix(this.stopped_at)
        .format(this.formatDate) || ''
      return start !== stop ? `${start}${stop ? ` - ${stop}` : ''}` : stop
    },
    time () {
      // Строкове время начала события
      const startTime = this.$moment.unix(this.started_at)
        .format(this.formatTime)
      // Строкове время окончания события
      const stopTime = this.$moment.unix(this.stopped_at)
        .format(this.formatTime)
      // Строкове время создания события
      const creatTime = this.$moment.unix(this.created_at)
        .format(this.formatTime)
      // Разделение строкового времени начала события на массив ЧЧ ММ
      const startTimeArr = startTime.split(':')
      // Числовое значение часов начала события
      const startHours = +startTimeArr[0]
      // Числовое значение минут начала события
      const startMinutes = +startTimeArr[1]
      // Общее кол-во минут начала события
      const start = () => {
        if (startHours === 0) {
          return (startHours + 24) * 60 + startMinutes
        } else {
          return startHours * 60 + startMinutes
        }
      }
      // Окончание события, аналогично началу события
      const stopTimeArr = stopTime.split(':')
      const stopHours = +stopTimeArr[0]
      const stopMinutes = +stopTimeArr[1]
      const stop = stopHours * 60 + stopMinutes
      // Разница в минутых между началом и окончанием события
      const different = stop - start()
      // Время, отображаемое в карточке (строка)
      if (startTime) { // Если есть время начала события
        if (startTime !== stopTime) {
          if (different <= 1) { // Если разница между началом и окончанием меньше 1, то круглосуточно
            return this.$t('components.event-card.time-around-the-clock')
          } else {
            return `${startTime} - ${stopTime}`
          }
        } else {
          return `${startTime}`
        }
      } else if (creatTime !== stopTime) { // Если нет времени начала события
        return `${creatTime} - ${stopTime}`
      } else {
        return `${creatTime}`
      }
    },
    link () {
      return `/${this?.entity}/${this?.id}`
    },
    image () {
      return this.images[0] || require('@/assets/images/default-image.svg')
    },
    categoryTitle () {
      return this?.category?.title || this.$t('components.event-card.category-default')
    }
  },
  created () {
    this.innerFavorited = this.favorited
  },
  methods: {
    toggleFavorite () {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites${this.$store.getters['user/apple']
          ? '?api_token=' + this.$store.getters['user/token']
          : ''}`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited
            this.$store.dispatch('favorites/fetch', {
              entity: 'events',
              params: {
                count: 100500,
                lang: this.$i18n.locale
              }
            })
          })
      } else {
        this.$login()
      }
    },
    remove () {
      this.$axios.$post(`api/events/${this.id}/travels/${this.$route.params.id}/remove`)
        .then(() => {
          this.$store.dispatch('travel/fetch', {
            id: this.$route.params.id,
            params: {
              lang: this.$i18n.locale
            }
          })
        })
    }
  }
}
