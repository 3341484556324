//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import HeaderNavigationList from "@/components/common/HeaderNavigation/HeaderNavigationList";

export default {
  name: 'HeaderNavigationCityComponent',
  components: {
    HeaderNavigationList
  },
  computed: {
    ...mapGetters({
      cities: 'cities/cities',
      defaultCity: 'cities/default'
    }),
    array() {
      const locale = this.$i18n.locale === 'ru' ? '' : `/${this.$i18n.locale}`
      return this.cities.map(item => ({title: item.title, link: `${locale}/municipality/${item.slug}`}))
    },
    showSingleCity() {
      return this.$config.singleCity
    },
    show() {
      return this.showSingleCity && this.showCityNavigation
    },
    showCityNavigation () {
      return !this.$config.layouts.header.disableCitiesNavigation
    },
    showCitiesOnPC() {
      return !this.$config.singleCity && this.showCityNavigation && (this.$hd() || this.$lg())
    },
    showCitiesOnMobileDevises() {
      return !this.$config.singleCity && this.showCityNavigation && (this.$md() || this.$sm())
    },
    cityRoute() {
      return this.$i18n.locale === this.$i18n.defaultLocale
        ? `/municipality/${this.defaultCity.slug}`
        : `/${this.$i18n.locale}/municipality/${this.defaultCity.slug}`
    }
  },
  methods: {
    changeMunicipality() {
      this.$store.commit('popup/open', {
        name: 'PopupMunicipality'
      })
    }
  },
}
