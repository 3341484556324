//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'YoutubeVideo',
  props: {
    youtube: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    youtubeLink() {
      if (this?.youtube.match(/(www.youtube.com\/watch\?v=)/)) {
        return this?.youtube.replace(/(www.youtube.com\/watch\?v=)/, 'www.youtube.com/embed/')
      } else if (this?.youtube.match(/(youtu.be)/)) {
        return this?.youtube.replace(/(youtu.be)/, 'www.youtube.com/embed/')
      } else {
        return this?.youtube ? `https://www.youtube.com/embed/${this?.youtube}` : ''
      }
    }
  },
}
