import {Plugin} from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $closePopupStoryCard(): void
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $closePopupStoryCard(): void
  }

  // nuxtContext.$cardFromEntity
  interface Context {
    $closePopupStoryCard(): void
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $closePopupStoryCard(): void
  }
}

const pluginClosePopupStoryCard: Plugin = (context, inject) => {
  inject('closePopupStoryCard', () => {
    // @ts-ignore
    context.app.router.push(context.app.router.from || context.app.$prefixLocale())
      .then(() => {
        context.store.commit('story/clear')
      })
  })
}

export default pluginClosePopupStoryCard
