var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{class:[
    'status',
    { 'status--none': _vm.status === 'none' },
    { 'status--new': _vm.status === 'new' },
    { 'status--moderated': _vm.status === 'moderated' },
    { 'status--edited': _vm.status === 'edited' },
    { 'status--confirmed': _vm.status === 'confirmed' },
    { 'status--paid': _vm.status === 'paid' },
    { 'status--canceled': _vm.status === 'canceled' },
    { 'status--provided': _vm.status === 'provided' },
    { 'status--closed': _vm.status === 'closed' },
    { 'status--active': _vm.status === 'active' },
    { 'status--available': _vm.status === 'available' },
    { 'status--nonactive': _vm.status === 'nonactive' }
  ]},[_vm._v("\n  "+_vm._s(_vm.condition)+"\n")])}
var staticRenderFns = []

export { render, staticRenderFns }