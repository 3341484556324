//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "FooterSocials",
  components: {
    FooterFeedback: () => import("@/components/common/Footer/FooterFeedback"),
    SocialsRoundedComponent: () => import('@/components/common/SocialsRoundedComponent')
  },
  computed: {
    ...mapGetters({
      socials: 'footer/socials'
    }),
    buttons () {
      const filtered = {}
      Object.keys(this.socials).forEach((key) => {
        if (this.socials[key] !== null) {
          filtered[key] = this.socials[key];
        }
      });
      return filtered
    },
    columns () {
      switch (this?.$media()) {
        case 'hd': {
          return 5
        }
        case 'lg': {
          return 3
        }
        default: {
          return 6
        }
      }
    },
    show() {
      return Object.values(this.socials).filter(item => item !== null).length > 0;
    }
  },
}
