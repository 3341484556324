import Vue from 'vue'

Vue.mixin({
  computed: {
    $theme () {
      return this.$store.getters['theme/theme']
    },
    $colors () {
      return this.$store.getters['theme/colors']
    }
  },
  methods: {
    $getCookie (name) {
      const matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
      ))
      return matches ? decodeURIComponent(matches[1]) : null
    },
    $setCookie (name, value, options = {}) {
      options = {
        path: '/',
        ...options
      }
      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString()
      }
      let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)
      for (const optionKey in options) {
        updatedCookie += '; ' + optionKey
        const optionValue = options[optionKey]
        if (optionValue !== true) {
          updatedCookie += '=' + optionValue
        }
      }
      document.cookie = updatedCookie
    },
    $media () {
      return this.$store.getters['viewport/media'] || ''
    },
    $hd () {
      return this.$store.getters['viewport/hd'] || false
    },
    $lg () {
      return this.$store.getters['viewport/lg'] || false
    },
    $md () {
      return this.$store.getters['viewport/md'] || false
    },
    $sm () {
      return this.$store.getters['viewport/sm'] || false
    }
  }
})
