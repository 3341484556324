//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TravelCard',
  components: {
    Icon24FillDistance: () => import('@/assets/icons/Icon24FillDistance.svg?inline'),
    Icon24FillAfisha: () => import('@/assets/icons/Icon24FillAfisha.svg?inline'),
    Icon24FillLocation: () => import('@/assets/icons/Icon24FillLocation.svg?inline')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    title: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    started_at: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    stopped_at: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    events_count: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    places_count: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    trips_count: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    parseStartedAt () {
      return this?.parseDates(this?.started_at)
    },
    parseStoppedAt () {
      return this?.parseDates(this?.stopped_at)
    },
    link () {
      return `/account/travels/${this?.id}`
    }
  },
  methods: {
    parseDates (timestamp) {
      const date = new Date(timestamp * 1000)
      const years = date.getFullYear()
      const months = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const days = date.getDate() < 9 ? '0' + date.getDate() : date.getDate()
      const hours = date.getHours() < 9 ? '0' + date.getHours() : date.getHours()
      const minutes = date.getMinutes() < 9 ? '0' + date.getMinutes() : date.getMinutes()
      return days + '.' + months + '.' + years + ', ' + hours + ':' + minutes
    }
  }
}
