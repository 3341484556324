//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'MapNavigationPlace',
  components: {
    MapNavigationBackButton: () => import('@/components/common/MapNavigation/MapNavigationBackButton'),
    // Icon24FillClock: () => import('@/assets/icons/Icon24FillClock.svg?inline'),
    Icon24FillEmail: () => import('@/assets/icons/Icon24FillEmail.svg?inline'),
    Icon24FillLocation: () => import('@/assets/icons/Icon24FillLocation.svg?inline'),
    Icon24FillPhone: () => import('@/assets/icons/Icon24FillPhone.svg?inline'),
    Icon24FillWeb: () => import('@/assets/icons/Icon24FillWeb.svg?inline'),
    LinkSite: () => import('@/components/common/LinkSite'),
    // WorkTime: () => import('@/components/WorkTime'),
    Button: () => import('@/components/common/Button'),
    LinkPhone: () => import('@/components/common/LinkPhone'),
    LinkMail: () => import('@/components/common/LinkMail'),
    Divider: () => import('@/components/common/Divider'),
    TopEntityRating: () => import('@/components/common/TopEntityRating'),
    Favorite: () => import('@/components/common/Favorite'),
    ImageComponent: () => import('@/components/common/ImageComponent'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar')
  },
  computed: {
    ...mapGetters({
      place: 'interactive-map/place',
      placePending: 'interactive-map/placePending',
      id: 'interactive-map/id',
      entity: 'interactive-map/entity'
    }),
    showPlace() {
      return this.entity === 'places' && this.id && !this.placePending
    },
    id() {
      return this?.place?.id
    },
    entity() {
      return this?.place?.entity
    },
    title() {
      return this?.place?.title
    },
    city() {
      return this?.place?.city?.title
    },
    phone() {
      return this?.place?.contacts?.phone
    },
    email() {
      return this?.place?.contacts?.email
    },
    website() {
      return this?.place?.contacts?.website
    },
    address() {
      return this?.place?.address
    },
    work_hours() {
      return this?.place?.work_hours
    },
    type() {
      return this?.place?.type?.title
    },
    favorited() {
      return this?.place?.favorited
    },
    image() {
      return this?.place?.images[0] || require('@/assets/images/default-image.svg')
    },
    rating() {
      return this?.place?.rating
    },
    currentUserRating() {
      return this?.place?.currentUserRating
    }
  },
  methods: {
    back() {
      // this.$store.dispatch('interactive-map/places', {clear: true})
      this.$store.commit('interactive-map/setId', null)
    }
  }
  // methods: {
  //   back() {
  //     this.$store.commit('interactive-map/setHistory', this.$route.query)
  //     this.$router.replace({
  //       query: this.history.length > 2
  //         ? this.history[this.history.length - 2]
  //         : {
  //           id: null,
  //           entity: 'places'
  //         }
  //     })
  //   },
  //   changeInnerFavorited() {
  //     this.$store.dispatch('interactive-map/place', {
  //       id: this.$route.query.id,
  //       params: {
  //         lang: this.$i18n.locale
  //       }
  //     })
  //   },
  //   visibleWorkNow() {
  //     if (this?.work_hours) {
  //       return !!Object.values(this.work_hours)
  //         .find(item => item)
  //     }
  //     return false
  //   }
  // }
}
