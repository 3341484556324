import { parser } from '@/utils/parser'

export const state = () => ({
  tourism_types: []
})

export const mutations = {
  setTourismTypes: (state, tourism_types) => state.tourism_types = tourism_types
}

export const actions = {
  async fetch ({ commit }, payload) {
    const tourism_types = await this.$axios.$get(`/api/tourism_types?${parser(payload)}`)
    commit('setTourismTypes', tourism_types.data)
  }
}

export const getters = {
  tourism_types: state => state.tourism_types
}
