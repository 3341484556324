import {Plugin} from '@nuxt/types'

import {Viewport} from "~/types/viewport";

type AllowedLanguages = string | string[] | null
type AllowedViewports = Viewport | Viewport[] | null

interface Params {
  allowedLanguages?: AllowedLanguages,
  allowedViewports?: AllowedViewports
}

interface CheckLocaleFunctionParams {
  currentLocale: string,
  allowedLanguages: string | string[] | null
}

interface CheckViewportFunctionParams {
  currentViewport: Viewport,
  allowedViewports: Viewport | Viewport[] | null
}


declare module 'vue/types/vue' {
  // this.$checkViewportLanguage inside Vue components
  interface Vue {
    $checkViewportLanguage(params: Params): boolean
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$checkViewportLanguage inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $checkViewportLanguage(params: Params): boolean
  }

  // nuxtContext.$checkViewportLanguage
  interface Context {
    $checkViewportLanguage(params: Params): boolean
  }
}

declare module 'vuex/types/index' {
  // this.$checkViewportLanguage inside Vuex stores
  interface Store<S> {
    $checkViewportLanguage(params: Params): boolean
  }
}

const pluginCheckViewportLanguage: Plugin = ({store, app}, inject) => {
  // @ts-ignore
  inject('checkViewportLanguage', ({allowedLanguages = null, allowedViewports = null}: Params) => {
    function checkLocaleFunction(checkLocaleFunctionParams: CheckLocaleFunctionParams): boolean {
      if (!checkLocaleFunctionParams.allowedLanguages) {
        return true
      } else if (Array.isArray(checkLocaleFunctionParams.allowedLanguages)) {
        return checkLocaleFunctionParams.allowedLanguages.includes(checkLocaleFunctionParams.currentLocale)
      } else if (typeof checkLocaleFunctionParams.allowedLanguages === 'string') {
        return checkLocaleFunctionParams.allowedLanguages.includes(checkLocaleFunctionParams.currentLocale)
      }
      return false
    }

    function checkViewportFunction(checkViewportFunctionParams: CheckViewportFunctionParams): boolean {
      if (!checkViewportFunctionParams.allowedViewports) {
        return true
      } else if (Array.isArray(checkViewportFunctionParams.allowedViewports)) {
        return checkViewportFunctionParams.allowedViewports.includes(checkViewportFunctionParams.currentViewport)
      } else if (typeof checkViewportFunctionParams.allowedViewports === 'string') {
        return checkViewportFunctionParams.allowedViewports.includes(checkViewportFunctionParams.currentViewport)
      }
      return false
    }

    const currentLocale: string = app.i18n.locale
    const currentViewport: Viewport = store.getters['viewport/media']
    const checkLocale = checkLocaleFunction({currentLocale, allowedLanguages})
    const checkViewport = checkViewportFunction({currentViewport, allowedViewports})

    return checkLocale && checkViewport
  })
}

export default pluginCheckViewportLanguage
