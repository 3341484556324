//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentPlaceTitle",
  components: {
    Icon24FillCity: () => import('@/assets/icons/Icon24FillCity.svg?inline')
  },
  props: {
    title: {
      type: String,
      default: () => ''
    }
  }
};
