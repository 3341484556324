//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Section",
  props: {
    background: {
      type: String,
      default: () => null
    },
    hide: {
      type: Boolean,
      default: () => false
    },
    limiter: {
      type: Boolean,
      default: () => false
    },
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    style() {
      return {
        background: this.background
      };
    },
    classMarginList() {
      return [
        this.disableMarginTop ? "section--disable-margin-top" : "",
        this.disableMarginBottom ? "section--disable-margin-bottom" : ""
      ];
    },
    classList() {
      return [
        this.hide ? "section__hide" : ""
      ];
    }
  },
};
