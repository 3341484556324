import { parser } from '@/utils/parser'

export const state = () => ({
  travels: [],
  innerTravels: false
})

export const mutations = {
  setTravels: (state, travels) => state.travels = travels,
  setInnerTravels: (state, innerTravels) => state.innerTravels = innerTravels
}

export const actions = {
  async fetch ({ commit }, payload) {
    const travels = await this.$axios.$get(`/api/travels?user${parser(payload)}`)
    commit('setTravels', travels.data)
  }
}

export const getters = {
  travels: state => state.travels,
  innerTravels: state => state.innerTravels
}
