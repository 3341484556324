import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1f2a2e13&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopEntityAgencyProfile: require('/srv/rir-platform/tourism/src/tourism-front/components/common/TopEntityAgencyProfile/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default,TradingPlatformMemberBecome: require('/srv/rir-platform/tourism/src/tourism-front/components/common/TradingPlatformMemberBecome/index.vue').default,ContextAdditions: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ContextAdditions/index.vue').default,RoundButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/RoundButton/index.vue').default,PlaceCard: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PlaceCard/index.vue').default,PendingButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PendingButton/index.vue').default,Button: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Button/index.vue').default,Empty: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Empty/index.vue').default,SectionAgencyBranches: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionAgencyBranches/index.vue').default,Tabs: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Tabs/index.vue').default,SectionAgencyLegalInformation: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionAgencyLegalInformation/index.vue').default})
