//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AdditionSite',
  components: {
    LinkSite: () => import('@/components/common/LinkSite')
  },
  props: {
    title: {
      type: String,
      default: () => 'Сайт'
    },
    value: {
      type: String,
      default: () => ''
    },
    divider: {
      type: Boolean,
      default: () => false
    }
  }
}
