import { parser } from '@/utils/parser'

export const state = () => ({
  places: [],
  routes: [],
  excursions: [],
  events: [],
  news: []
})

export const getters = {
  places: state => state.places,
  excursions: state => state.excursions,
  routes: state => state.routes
}

export const mutations = {
  setFilteredPlaces: (state, places) => state.places = places,
  setPlaces: (state, places) => state.places = places,
  setExcursions: (state, excursions) => state.excursions = excursions,
  setRoutes: (state, routes) => state.routes = routes,
  setEvents: (state, events) => state.events = events
}

export const actions = {
  async filter ({ commit }, payload) {
    await this.$axios.$get(`/api/map/places?${parser(payload)}`)
      .then(resolve => commit('setFilteredPlaces', resolve.features))
  },
  async fetch ({ commit }) {
    await this.$axios.$get('/api/map/places')
      .then(resolve => commit('setPlaces', resolve.features))
    await this.$axios.$get('/api/map/routes')
      .then(resolve => commit('setTrips', resolve.features))
  },
  async fetchPlaces ({ commit }) {
    await this.$axios.$get('/api/map/places')
      .then(resolve => commit('setPlaces', resolve.features))
  },
  async routes ({ commit }) {
    await this.$axios.$get('/api/map/routes')
      .then(resolve => commit('setRoutes', resolve.features))
  },
  async excursions ({ commit }) {
    await this.$axios.$get('/api/map/excursions')
      .then(resolve => commit('setExcursions', resolve.features))
  }
}
