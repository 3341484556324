export default {
  travels: [],
  innerTravels: false,
  entity: null,
  object_id: null,
  user_id: null,
  started_at: null,
  stopped_at: null,
  title: null,
  errors: null,
  pending: false
}
