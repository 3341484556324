export default async function ({
  app,
  route,
  store,
}) {
  const array = route.path.split('/')
  if (array[array.length - 1] === '') {
    await store.dispatch('seo/fetch', {
      page: 'index',
      siteName: app.i18n.messages[app.i18n.locale]?.seo?.site_name || null,
      locale: app.i18n.locale,
      fullPath: route.fullPath
    })
  } else {
    await store.dispatch('seo/fetch', {
      page: array[array.length - 1],
      siteName: app.i18n.messages[app.i18n.locale]?.seo?.site_name || null,
      locale: app.i18n.locale,
      fullPath: route.fullPath
    })
  }
}
