import {Plugin} from '@nuxt/types'

type Locale = string

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $currentLocale(): Locale
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $currentLocale(): Locale
  }

  // nuxtContext.$cardFromEntity
  interface Context {
    $currentLocale(): Locale
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $currentLocale(): Locale
  }
}

const pluginCurrentLocale: Plugin = ({app}, inject) => {
  inject('currentLocale', () => app.i18n.locale)
}

export default pluginCurrentLocale
