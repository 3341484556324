export default {
  title: null,
  description: null,
  address: null,
  email: null,
  phone: null,
  website: null,
  facebook: null,
  vk: null,
  instagram: null,
  ok: null,
  youtube: null,
  city_id: null,
  latitude: null,
  longitude: null,
  image: null,
  errors: null,
  type: 0,
  pending: false,
  status: null,
  config: { headers: { 'Content-Type': 'multipart/form-data' } }
}
