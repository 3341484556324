import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=5a53ac70&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopTitleComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/TopTitleComponent/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default,TopEntityComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/TopEntityComponent/index.vue').default,ContextAdditions: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ContextAdditions/index.vue').default,SocialVideoFrameComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SocialVideoFrameComponent/index.vue').default,Brochure: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Brochure/index.vue').default,EntityContent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/EntityContent/index.vue').default,Gallery: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Gallery/index.vue').default,SectionSharing: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionSharing/index.vue').default,SectionBannerAppMobile: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionBillboard/index.vue').default})
