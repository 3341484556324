import { parser } from '@/utils/parser'

export const state = () => ({
  articles: []
})

export const getters = {
  articles: state => state.articles
}

export const mutations = {
  setArticles: (state, articles) => state.articles = articles
}

export const actions = {
  async fetch ({ commit }, payload) {
    const articles = await this.$axios.$get(`/api/articles?${parser(payload)}`)
    commit('setArticles', articles.data)
  }
}
