export const state = () => ({
  title: null,
  keywords: null,
  description: null,
  image: null,
  url: null,
  locale: null,
  type: 'website',
  site_name: null,
})

export const getters = {
  title: state => state.title,
  keywords: state => state.keywords,
  description: state => state.description,
  image: state => state.image,
  url: state => state.url,
  locale: state => state.locale,
  type: state => state.type,
  siteName: state => state.siteName
}

export const mutations = {
  setTitle: (state, title) => state.title = title,
  setKeywords: (state, keywords) => state.keywords = keywords,
  setDescription: (state, description) => state.description = description,
  setImage: (state, image) => state.image = image,
  setUrl: (state, url) => state.url = url,
  setLocale: (state, locale) => state.locale = locale,
  setType: (state, type) => state.type = type,
  setSiteName: (state, siteName) => state.siteName = siteName,
  clear: (state) => {
    state.title = null
    state.keywords = null
    state.description = null
    state.image = null
    state.url = null
    state.locale = null
    state.type = 'website'
    state.site_name = null
  }
}

export const actions = {
  async fetch ({
    commit,
    rootState
  }, {
    page,
    locale,
    siteName,
    fullPath
  }) {
    await this.$axios.$get(`api/metadata/${page}`)
      .then(response => {
        commit('setTitle', response?.data?.title || null)
        commit('setKeywords', response?.data?.keywords || null)
        commit('setDescription', response?.data?.description || null)
        commit('setImage', response?.data?.image || rootState?.host + '/meta-image-default-small.jpg?v=2')
        commit('setUrl', response?.data?.url || rootState?.host + fullPath)
        commit('setLocale', response?.data?.locale || `${locale}_${locale.toUpperCase()}`)
        commit('setType', response?.date?.type || 'website')
        commit('setSiteName', response?.date?.site_name || siteName)
      })
      .catch(() => {
        commit('setTitle', null)
        commit('setKeywords', null)
        commit('setDescription', null)
        commit('setImage', rootState?.host + '/meta-image-default-small.jpg?v=2')
        commit('setUrl', rootState?.host + fullPath)
        commit('setLocale', `${locale}_${locale.toUpperCase()}`)
        commit('setType', 'website')
        commit('setSiteName', siteName)
      })
  }
}
