//
//
//
//
//
//
//
//

export default {
  name: "FooterDocument",
  props: {
    title: {
      type: String,
      default: () => ''
    },
    href: {
      type: String,
      default: () => ''
    }
  }
}
