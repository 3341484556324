//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "ArticleEntityCardsBlock",
  components: {
    Button: () => import("@/components/common/Button"),
    Scroller: () => import("@/components/common/Scroller"),
    HorizontalEntityCard: () => import("@/components/common/HorizontalEntityCard"),
    NewsCard: () => import("@/components/common/NewsCard"),
    PlaceCard: () => import("@/components/common/PlaceCard"),
    EventCard: () => import("@/components/common/EventCard"),
    RouteCard: () => import("@/components/common/RouteCard"),
    ExcursionCard: () => import("@/components/common/ExcursionCard")
  },
  props: {
    places: {
      type: Array,
      default: () => []
    },
    routes: {
      type: Array,
      default: () => []
    },
    excursions: {
      type: Array,
      default: () => []
    },
    events: {
      type: Array,
      default: () => []
    },
    news: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      count: 3,
      current: 3,
    };
  },
  computed: {
    ...mapGetters({
      media: "viewport/media",
    }),
    items() {
      return [
        ...this.places,
        ...this.routes,
        ...this.excursions,
        ...this.events,
        ...this.news
      ];
    },
    show() {
      return this.items.length > 0;
    },
    showButton() {
      return this.items.length > 0 && this.media !== "sm" && this.current < this.total;
    },
    entities() {
      return this.items.slice(0, this.current);
    },
    total() {
      return this.items.length;
    },
    currentCount() {
      return this.entities.length
    }
  },
  methods: {
    avatar(item) {
      return item?.guide?.images[0] || item?.agency?.images[0] || item?.agency?.logo || "";
    },
    showMore() {
      if (this.current < this.items.length) {
        this.current = this.current + this.count;
      }
    },
  }
};
