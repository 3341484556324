//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "AgencyBranchCard",
  components: {
    Status: () => import("@/components/common/Status"),
    LabelTag: () => import("@/components/common/LabelTag"),
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    mode: {
      type: String,
      default: () => "link"
    },
    id: {
      type: Number,
      default: () => 0
    },
    title: {
      type: String,
      default: () => ""
    },
    address: {
      type: String,
      default: () => ""
    },
    image: {
      type: String,
      default: () => require("@/assets/images/default-image.svg")
    },
    entity: {
      type: String,
      default: () => ""
    },
    label: {
      type: String,
      default: () => ""
    },
    status: {
      type: String,
      default: () => ""
    },
    private: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    link() {
      return `/${this?.entity}/${this?.id}`;
    },
  }
};
