export const agencyAppend = (state) => {
  const data = new FormData()
  if (state.image) {
    data.append('image', state.image.link)
  }
  if (state.title) {
    data.append('title', state.title)
  }
  if (state.description) {
    data.append('description', state.description)
  }
  if (state.city_id) {
    data.append('city_id', state.city_id)
  }
  if (state.latitude) {
    data.append('latitude', state.latitude)
  }
  if (state.longitude) {
    data.append('longitude', state.longitude)
  }
  if (state.address) {
    data.append('address', state.address)
  }
  if (state.email) {
    data.append('email', state.email)
  }
  if (state.phone) {
    data.append('phone', Number(state.phone))
  }
  if (state.website) {
    data.append('website', state.website)
  } else {
    data.append('website', ' ')
  }
  if (state.additions.length > 0) {
    const additionsFiltered = state.additions.filter(item => item.key.length > 0 && item.value.length > 0)
    additionsFiltered.forEach((element, index) => {
      if (element.id.toString().includes('hash-')) {
        data.append(`additions[${index}][value]`, element.value)
        data.append(`additions[${index}][key]`, element.key)
      } else {
        data.append(`additions[${index}][value]`, element.value)
        data.append(`additions[${index}][key]`, element.key)
        data.append(`additions[${index}][id]`, element.id)
      }
    })
  } else {
    data.append("additions[]", state.additions);
  }
  if (state.facebook || state.vk || state.instagram || state.youtube || state.ok || state.tiktok) {
    data.append('socials', JSON.stringify({
      facebook: state.facebook,
      vk: state.vk,
      instagram: state.instagram,
      youtube: state.youtube,
      ok: state.ok,
      tiktok: state.tiktok
    }))
  }
  const types = [
    {
      id: 0,
      value: 'org'
    },
    {
      id: 1,
      value: 'travel'
    },
    {
      id: 2,
      value: 'guide'
    }
  ]
  data.append('type', types[state.type_id].value)
  data.append('_method', 'PUT')
  return data
}
