//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'Favorite',
  components: {
    Icon24StrokeFavorites: () => import('@/assets/icons/Icon24StrokeFavorites.svg?inline')
  },
  model: {
    prop: 'favorited',
    event: 'change'
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    favorited: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged',
      api_token: 'user/token'
    })
  },
  methods: {
    toggle () {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites${this.$store.getters['user/apple']
          ? `?api_token=${this.api_token}`
          : ''}`)
          .then(() => {
            this.$emit('change-favorited', !this.favorited)
          })
      } else {
        this.$login()
      }
    }
  }
}
