//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SliderWithBullets',
  components: {
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton')
  },
  props: {
    columns: {
      type: Number,
      default: () => 3
    },
    color: {
      type: String,
      default: () => 'dark'
    },
    indention: {
      type: Number,
      default: () => 32
    }
  },
  data () {
    return {
      id: Math.random()
        .toString(36)
        .replace(/[^a-zA-Z0-9]+/g, '')
        .substr(0, 8),
      observer: null,
      target: null,
      config: {
        childList: true
      },
      bullets: 0,
      wrapper: null,
      wrapperWidth: 0,
      current: 0
    }
  },
  computed: {
    style () {
      return {
        transform: `translateX(${this.left}px)`
      }
    },
    left () {
      let left = 0
      return left -= (this.wrapperWidth + this.indention - 16) * this.current
    }
  },
  watch: {
    bullets () {
      this.current = 0
    }
  },
  updated () {
    this.$nextTick(() => {
      this.wrapperWidth = this.$el.clientWidth
      this.target = document.querySelector(`#slider-${this.id}`)
      this.targets = document.querySelector('.slider-with-bullets__slides').children
      if (this.target) {
        this.observer = new MutationObserver(this.callback)
        this.observer.observe(this?.target, this.config)
        this.bullets = Math.ceil(this?.target?.children?.length / this.columns)
      }
    })
  },
  methods: {
    callback (mutationRecord, observer) {
      this.bullets = Math.ceil(mutationRecord[0]?.target?.children?.length / this.columns)
      const difference = this.targets.length - this.columns * this.bullets
      if (!difference && this.current === this.bullets) {
        this.current -= 1
      }
    },
    changeCurrent (index) {
      this.current = index
    },
    next () {
      if (this.current < this.bullets - 1) {
        this.current += 1
      }
    },
    preview () {
      if (this.current > 0) {
        this.current -= 1
      }
    }
  }
}
