export default async function ({
  app,
  store,
  error,
  params,
  redirect
}) {
  function statusValidation (status) {
    switch (status) {
      case 'active':
        return true
      default:
        return false
    }
  }

  async function validation () {
    if (store.getters['user/user']) {
      await store.dispatch('account-agency-profile/fetch', {
        id: params.agency_id,
        params: {
          lang: app.i18n.locale
        }
      })
        .then(async () => {
          const agency = store.getters['account-agency-profile/agency']
          const user = store.getters['user/user']
          const token = store.getters['user/token']
          if (agency?.user_id === user?.id) {
            if (statusValidation(agency?.status) === false) {
              error({status: 400, message: app.i18n.t('pages.400.message')})
            } else {
              await store.dispatch('account-agency-orders/fetch', {
                id: params.agency_id,
                clear: true,
                params: {
                  page: 1,
                  count: store.getters['account-agency-orders-view/count'],
                  lang: app.i18n.locale,
                  payable: 0
                }
              })
              await store.dispatch('account-agency-orders/orderables', {
                id: params.agency_id,
                params: {
                  api_token: token,
                }
              })
            }
          } else {
            error({status: 404, message: app.i18n.t('pages.404.message')})
          }
        })
        .catch(() => {
          error({status: 404, message: app.i18n.t('pages.404.message')})
        })
    } else {
      redirect('/login')
    }
  }

  await validation()
}
