//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ArticleBlock',
  components: {
    Gallery: () => import('@/components/common/Gallery')
  },
  props: {
    article: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    title () {
      return this?.article?.title
    },
    description () {
      return this?.article?.description
    },
    images () {
      return this?.article?.images
    },
    gallery () {
      return this.images.map((item, index) => ({
        image: item,
        id: index,
        title: '',
        description: ''
      }))
    }
  }
}
