export const state = () => ({
  theme: {
    colors: {
      mainColor: 'var(--main-color)',
      mainLightColor: 'var(--main-light-color)',
      accentColor: 'var(--accent-color)',
      darkColor: 'var(--dark-color)',

      black: 'var(--black)',
      black60: 'var(--black-60)',
      black40: 'var(--black-40)',
      black20: 'var(--black-20)',
      black5: 'var(--black-5)',

      white: 'var(--white)',
      white80: 'var(--white-80)',
      white60: 'var(--white-60)',
      white40: 'var(--white-40)',
      white20: 'var(--white-20)',

      greyDark: 'var(--grey-dark)',
      greyMedium: 'var(--grey-medium)',
      greyLight: 'var(--grey-light)',

      backgroundBody: 'var(--background-body)',

      systemRed: 'var(--system-red)',
      systemYellow: 'var(--system-yellow)',
      systemGreen: 'var(--system-green)',
      systemBlue: 'var(--system-blue)',

      statusNewBackground: 'var(--status-new-background)',
      statusNewText: 'var(--status-new-text)',

      statusModeratedBackground: 'var(--status-moderated-background)',
      statusModeratedText: 'var(--status-moderated-background)',

      statusNonactiveBackground: 'var(--status-nonactive-background)',
      statusNonactiveText: 'var(--status-nonactive-text)',

      statusClosedBackground: 'var(--status-closed-background)',
      statusClosedText: 'var(--status-closed-text)',

      statusCanceledBackground: 'var(--status-canceled-background)',
      statusCanceledText: 'var(--status-canceled-text)',

      statusPendingBackground: 'var(--status-pending-background)',
      statusPendingText: 'var(--status-pending-text)',

      statusActiveBackground: 'var(--status-active-background)',
      statusActiveText: 'var(--status-active-text)',

      statusPaidBackground: 'var(--status-paid-background)',
      statusPaidText: 'var(--status-paid-text)',

      statusAvailableBackground: 'var(--status-available-background)',
      statusAvailableText: 'var(--status-available-text)',

      statusProcessedBackground: 'var(--status-processed-background)',
      statusProcessedText: 'var(--status-processed-text)'

    },
    resolutions: ['micro', 'small', 'medium', 'large', 'extra']
  }
})

export const getters = {
  theme: state => state.theme,
  colors: state => state.theme.colors,
  resolutions: state => state.theme.resolutions
}

export const mutations = {}

export const actions = {}
