//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'MapNavigationTypes',
  components: {
    MapNavigationType: () => import('@/components/common/MapNavigation/MapNavigationType'),
    MapNavigationPlacesType: () => import('@/components/common/MapNavigation/MapNavigationPlacesType'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar')
  },
  computed: {
    ...mapGetters({
      id: 'interactive-map/id',
      category_id: 'interactive-map/category_id',
      entity: 'interactive-map/entity',
      categories: 'categories/places',
      categoriesPlacesPending: 'categories/placesPending',
    }),
    show() {
      return this.entity === 'categories'
    },
    showCategories() {
      return this.entity === 'categories' && !this.category_id
    },
    showPlaces() {
      return this.entity === 'categories' && this.category_id
    }
  }
}
