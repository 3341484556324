//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'PhotoUploader',
  components: {
    PhotoUploaderForm: () => import('@/components/common/PhotoUploader/PhotoUploaderForm')
  },
  computed: {
    ...mapGetters({
      photos: 'add-photos/photos',
      toasterOpen: "toasters/opened"
    })
  },
  methods: {
    change (event) {
      [...event.target.files].forEach((file) => {
        const formData = new FormData()
        formData.append('file', file)
        this.$store.dispatch('add-photos/add', formData).then(() => {
          document.getElementById("file-drop-input").value = "";
        })
      })
    },
    dragover (event) {
      if (!event.currentTarget.classList.contains('photo-uploader__drop-zone--dragover')) {
        event.currentTarget.classList.remove('photo-uploader__drop-zone--dragover')
        event.currentTarget.classList.add('photo-uploader__drop-zone--dragover')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('photo-uploader__drop-zone--dragover')
    },
    drop (event) {
      [...event.dataTransfer.files].forEach((file) => {
        const formData = new FormData()
        formData.append('file', file)
        this.$store.dispatch('add-photos/add', formData).then(() => {
          document.getElementById("file-drop-input").value = "";
        })
      })
    }
  }
}
