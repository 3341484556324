//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SelectComponent',
  components: {
    LabelComponent: () => import('@/components/common/LabelComponent'),
    ErrorComponent: () => import('@/components/common/ErrorComponent'),
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline'),
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar')
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      default: () => null
    },
    defaultText: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    array: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    obligatory: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      opened: false,
      selected: null
    }
  },
  computed: {
    classList () {
      return [
        { 'select--disabled': this.disabled },
        { 'select--errors': Array.isArray(this.errors) ? this.errors[0] : this.errors }
      ]
    },
    contextTitle () {
      return this.array.find(item => item.id === this.value)?.title || this.defaultText
    }
  },
  created () {
    if (this.value) {
      this.select(this.array.find(item => parseInt(item.id, 10) === this.value))
    }
  },
  methods: {
    select (item) {
      this.selected = item
      this.$emit('change', this.selected)
      this.opened = false
    },
    toggle () {
      if (!this.disabled) {
        this.opened = !this.opened
        this.$emit('change-edit', true)
      }
    },
    close () {
      this.opened = false
    }
  }
}
