export default function ({
                           app,
                           store
                         }) {
  for (const locale in store.state.messages) {
    app.i18n.setLocaleMessage(locale, store.state.messages[locale])
    app.$moment.locale(locale, store.getters.dateLocaleByLang(locale).formatLocale)
  }

  const setLocale = (locale) => {
    store.commit('languages/setCurrentLanguage', locale)
    app.$moment.locale(locale)
  }

  // todo потенциально хорошо для оповещалки
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // app.i18n.missing = (locale, path, vue) => {
  //   console.log('+++++++++++++++++++++++++++++')
  //   console.log(path)
  //   console.log('+++++++++++++++++++++++++++++')
  //   console.log(vue)
  // }

  setLocale(app.i18n.locale)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    setLocale(newLocale)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  app.i18n.onBeforeLanguageSwitch = async (oldLocale, newLocale, isInitialSetup, context) => {
    if (!isInitialSetup) {
      const entities = [
        'excursions',
        'events',
        'places',
        'routes'
      ]
      let promises = []
      entities.forEach(entity => {
        promises = [...promises, store.dispatch(`categories/${entity}`, {
          params: {
            lang: newLocale,
          }
        })]
      })
      promises = [...promises, store.dispatch('cities/fetch', {
        params: {
          lang: newLocale,
          count: 100500
        }
      })]
      promises = [...promises, store.dispatch('header-navigation/fetch', {
        params: {
          lang: newLocale
        }
      })]
      promises = [...promises, store.dispatch('footer/fetch', {
        params: {
          lang: newLocale
        }
      })]
      await Promise.all(promises)
    }
  }
}
