import {Plugin} from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$functionality inside Vue components
  interface Vue {
    $isEmptyArray(array: Array<any>): boolean
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$functionality inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $isEmptyArray(array: Array<any>): boolean
  }

  // nuxtContext.$functionality
  interface Context {
    $isEmptyArray(array: Array<any>): boolean
  }
}

declare module 'vuex/types/index' {
  // this.$functionality inside Vuex stores
  interface Store<S> {
    $isEmptyArray(array: Array<any>): boolean
  }
}

const pluginIsEmptyArray: Plugin = ({store}, inject) => {
  inject('isEmptyArray', (array: Array<any>) => {
    return array?.length === 0
  })
}

export default pluginIsEmptyArray
