import { parser } from '@/utils/parser'

export const state = () => ({
  agency: null,
  sm_registered: null,
})

export const mutations = {
  setAgency: (state, agency) => state.agency = agency,
  setSmRegistred: (state, sm_registered) => state.sm_registered = sm_registered,
  clearAgency: state => state.agency = null
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload.clear) {
      commit('clearAgency')
    }
    const agency = await this.$axios.$get(`/api/agencies/${payload.id}?${parser(payload)}&user=current`)
    commit('setAgency', agency.data)
    commit('setSmRegistred', agency.data.sm_registered)
  }
}

export const getters = {
  sm_registered: state => state.sm_registered,
  agency: state => state.agency
}
