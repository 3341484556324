//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "TestSlider",
  components: {
    TestSliderSlots: () => import("@/components/common/TestSlider/TestSliderSlots"),
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline'),
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    pendingComponentName: {
      type: String,
      default: () => 'EmptyDefaultSlider'
    },
    navigation: {
      type: Boolean,
      default: () => true,
    },
    navigationButtons: {
      type: Boolean,
      default: () => true,
    },
    navigationPagination: {
      type: Boolean,
      default: () => true,
    },
    pending: {
      type: Boolean,
      default: () => false,
    },
    columns: {
      type: Number,
      default: () => 3
    },
    gap: {
      type: Number,
      default: () => 32
    },
    swipe: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      groupNumber: 0,
      observer: null,
      wrapperWidth: 0,
      itemWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      width: 'viewport/width',
      media: 'viewport/media',
    }),
    id() {
      return `test-slider-id-${this.randomId()}`
    },
    groups() {
      if (this.$slots?.default?.length > 0) {
        return Math.ceil(this.$slots.default.length / this.columns)
      }
      return 0
    },
    left() {
      return -(this.wrapperWidth + this.gap) * this.groupNumber || 0
    }
  },
  watch: {
    width() {
      this.groupNumber = 0
      this.$emit('change', true)
    },
    media() {
      this.groupNumber = 0
      this.$emit('change', true)
    },
    columns() {
      this.$emit('change', true)
    }
  },
  methods: {
    swipeNextGroup() {
      if (this.swipe && this.groupNumber < this.groups - 1) {
        this.nextGroup()
      }
    },
    swipePrevGroup() {
      if (this.swipe && this.groupNumber > 0) {
        this.prevGroup()
      }
    },
    nextGroup() {
      this.groupNumber += 1
    },
    prevGroup() {
      this.groupNumber -= 1
    },
    setGroup(index) {
      this.groupNumber = index
    },
    randomId() {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength))
      }
      return result
    },
    setWrapperWidth(wrapperWidth) {
      this.wrapperWidth = wrapperWidth
    },
    setItemWidth(itemWidth) {
      this.itemWidth = itemWidth
    }
  }
}
