export const state = () => ({
  buttonBackTitle: '',
  buttonBackLink: '/account/agencies'
})

export const getters = {
  buttonBackTitle: state => state.buttonBackTitle,
  buttonBackLink: state => state.buttonBackLink
}

export const mutations = {
  setButtonBackTitle: (state, buttonBackTitle) => {
    state.buttonBackTitle = buttonBackTitle
  },
  setButtonBackLink: (state, buttonBackLink) => {
    state.buttonBackLink = buttonBackLink
  }
}

export const actions = {}
