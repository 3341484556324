//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AccountTravelsView',
  components: {
    TravelCard: () => import('@/components/common/TravelCard/index'),
    Button: () => import('@/components/common/Button/index'),
    EmptyEntity: () => import('@/components/common/EmptyEntity/index')
  },
  layout: 'personal-area',
  async fetch ({
    app,
    store
  }) {
    await store.dispatch('travels/fetch', {
      params: {
        lang: app.i18n.locale
      }
    })
  },
  head () {
    return {
      title: this.$t('pages.account-travels.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      travels: 'travels/travels',
      logged: 'user/logged'
    })
  },
  methods: {
    openPopup () {
      if (this.logged) {
        this.$store.commit('popup/open', {
          name: 'PopupAddTravel',
          params: {
            style: {
              width: '100%',
              maxWidth: '632px',
              maxHeight: '100vh',
              overflow: 'visible',
              position: 'relative',
              overflowY: 'visible',
              overflowX: 'visible'
            }
          }
        })
      } else {
        this.$login()
      }
    }
  }
}
