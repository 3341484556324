//
//
//
//
//
//
//
//

export default {
  name: "StoryLayout",
  data() {
    return {
      bodyBackground: ''
    }
  },
  created() {
    if (!process.server) {
      // eslint-disable-next-line
      this.bodyBackground = document.documentElement.style.getPropertyValue('--background-body')
      // eslint-disable-next-line
      document.documentElement.style.setProperty('--background-body', 'var(--black)');
    }
  },
  destroyed() {
    document.documentElement.style.setProperty('--background-body', this.bodyBackground);
  }
}
