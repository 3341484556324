//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DesktopNavigation",
  components: {
    DesktopNavigationIconAvatar: () => import("@/components/common/DesktopNavigationIconAvatar"),
    DesktopNavigationIconSearch: () => import("@/components/common/DesktopNavigationIconSearch"),
    DesktopNavigationIconLanguage: () => import("@/components/common/DesktopNavigationIconLanguage"),
    HeaderNavigation: () => import("@/components/common/HeaderNavigation"),
  },
}
