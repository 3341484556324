export default {
  config: { headers: { 'Content-Type': 'multipart/form-data' } },
  errors: null,
  pending: false,
  facebook: null,
  vk: null,
  instagram: null,
  youtube: null,
  youtube_announcement: null,
  rutube_announcement: null,
  ok: null,
  website: null,
  phone: null,
  email: null,
  address: null,
  title: null,
  description: null,
  annotation: null,
  city_id: null,
  category_id: null,
  tourism_type_id: null,
  latitude: null,
  longitude: null,
  images: [],
  accessible_environment: false,
  work_hours: {
    day_1_open: null,
    day_1_close: null,
    day_2_open: null,
    day_2_close: null,
    day_3_open: null,
    day_3_close: null,
    day_4_open: null,
    day_4_close: null,
    day_5_open: null,
    day_5_close: null,
    day_6_open: null,
    day_6_close: null,
    day_7_open: null,
    day_7_close: null
  },
  edit: false,
  id: null,
  tourism_types: [],
  additions: []
}
