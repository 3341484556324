export default {
  data () {
    return {
      showCalendar: false,
      currentMonth: null,
      currentMonthPlusMonth: null,
      schedules: []
    }
  },
  watch: {
    async currentMonth () {
      await this.getSchedules()
    }
  },
  computed: {
    currentNumberMonth () {
      return this.$moment(this.currentMonth, 'MM.YYYY')
        .format('M')
    },
    currentNumberMonthPlusOneMonth () {
      return this.$moment(this.currentMonth, 'MM.YYYY')
        .add(1, 'month')
        .format('M')
    }
  },
  methods: {
    async getSchedules () {
      this.schedules = Promise.all([
        await this.$axios.get(`/api/${this.entity}/${this.id}/schedules-calendar?month=${this.currentNumberMonth}`),
        await this.$axios.get(`/api/${this.entity}/${this.id}/schedules-calendar?month=${this.currentNumberMonthPlusOneMonth}`)
      ])
        .then(resolve => this.schedules = resolve.map(item => item.data.data))
        .then(() => {
          if (this.schedules.flat().length > 0) {
            this.showCalendar = true
          }
        })
    },
    dayMonthYear (schedule) {
      return Object.keys(schedule)[0]
    },
    daysOfCurrentMonth ({ month, index }) {
      let array = []
      // Собираем в новый массив дни с шедулес
      for (const key in this.schedules[index]) {
        const item = {}
        item[key] = this.schedules[index][key]
        array = [...array, item]
      }
      // Месяц болванка
      const week = this.weeksOfCurrentMonth({ month })
        .flat(2)
      // Перебираем и сравниваем по ключам массив шедулес и месяца болванки, при совпадении ключей, в месяц болванку записывается шедуль
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < week.length; j++) {
          if (Object.keys(week[j])[0] === Object.keys(array[i])[0]) {
            week[j] = array[i]
          }
        }
      }
      // Недели месяца с содержанием шедулес
      return week
    },
    weeksOfCurrentMonth ({ month }) {
      let weeks = []
      // Начальная дата ограничивающего диапазона
      const startDay = this.$moment(month, 'MM.YYYY')
        .clone()
        .startOf('month')
        .startOf('week')
      // Конечная дата ограничивающего диапазона
      const endDay = this.$moment(month, 'MM.YYYY')
        .clone()
        .endOf('month')
        .endOf('week')
      // Обозреваемый месяц
      const date = startDay.clone()
      if (date.date() === 1) {
        date.subtract(1, 'week')
      }
      // Пока обозреваемый месяц меньше конечной даты собираем массив недель с днями
      while (date.isBefore(endDay, 'day')) {
        weeks = [...weeks,
          Array(7)
            .fill(0)
            .map(() => {
              const day = {}
              day[`${date.add(1, 'day')
                .clone()
                .format('DD-MM-YYYY')}`] = []
              return day
            })]
      }
      return weeks // Недели месяца
    },
    active (schedule) {
      if (schedule[this.dayMonthYear(schedule)]?.some(item => item.status === 'active' && item.items_remain > 0)) {
        return !this.available(schedule)
      }
      return false
    },
    available (schedule) {
      if (schedule[this.dayMonthYear(schedule)].some(item => item.status === 'available' && item.items_remain > 0)) {
        return true
      }
      if (schedule[this.dayMonthYear(schedule)].every(item => item.status === 'available' && item.items_remain === 0)) {
        return false
      }
      return false
    },
    classList ({
      schedule,
      currentMonth
    }) {
      return [{
        'calendar__day--current': this.dayMonthYear(schedule) === this.$moment()
          .format('DD-MM-YYYY')
      }, {
        'calendar__day--active': this.active(schedule)
      }, {
        'calendar__day--available': this.available(schedule)
      }, {
        'calendar__day--not-current-month': this.isCurrentMonth({
          date: this.dayMonthYear(schedule),
          currentMonth
        })
      }]
    },
    isCurrentMonth ({ date, currentMonth }) {
      return this.$moment(date, 'DD-MM-YYYY')
        .format('M') !== this.$moment(currentMonth, 'MM-YYYY')
        .format('M')
    },
    previousMonth () {
      this.currentMonth = this.$moment(this.currentMonth, 'MM.YYYY')
        .subtract(1, 'month')
        .format('MM.YYYY')
      this.currentMonthPlusMonth = this.$moment(this.currentMonth, 'MM.YYYY')
        .add(1, 'month')
        .format('MM.YYYY')
    },
    nextMonth () {
      this.currentMonth = this.currentMonth = this.$moment(this.currentMonth, 'MM.YYYY')
        .add(1, 'month')
        .format('MM.YYYY')
      this.currentMonthPlusMonth = this.$moment(this.currentMonth, 'MM.YYYY')
        .add(1, 'month')
        .format('MM.YYYY')
    }
  },
  created () {
    this.currentMonth = this.$moment()
      .format('MM.YYYY')
    this.currentMonthPlusMonth = this.$moment(this.currentMonth, 'MM.YYYY')
      .add(1, 'month')
      .format('MM.YYYY')
  },
  async mounted () {
    this.showCalendar = false
    await this.getSchedules()
  }
}
