export default async function ({
                                 store,
                                 params,
                                 error,
                                 app
                               }) {
  await store.dispatch('event/fetch', {
    id: params.id,
    params: {
      lang: app.i18n.locale,
      resolution: 'micro',
    }
  })
    .then(() => {
      const event = store.getters['event/event']
      if (!(event && event?.status === 'active')) {
        error({statusCode: 404})
      }
    })
}
