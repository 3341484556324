//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {accountMenu} from "@/configs/account.config.ts";

const components = {}
accountMenu.forEach(item => {
  components[item.icon] = () => import(`@/assets/icons/${item.icon}.svg?inline`)
})

export default {
  name: 'PersonalAreaMenuMobile',
  components: {
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Icon24FillDot: () => import('@/assets/icons/Icon24FillDot.svg?inline'),
    Icon24FillExit: () => import('@/assets/icons/Icon24FillExit.svg?inline'),
    ...components
  },
  data() {
    return {
      open: false,
      title: '',
      menu: accountMenu
    }
  },
  computed: {
    menuTitle() {
      return this.title
    }
  },
  watch: {
    $route() {
      this.title = this.currentTitle()
    }
  },
  mounted() {
    this.title = this.currentTitle()
  },
  methods: {
    changeShowById(id) {
      this.menu = this.menu.map((item) => {
        if (item.id === id) {
          item.show = !item.show
        }
        return item
      })
    },
    currentTitle() {
      switch (this.$route.fullPath) {
        case '/account': {
          return this.$t('components.personal-area-menu.profile')
        }
        case '/account/agencies': {
          return this.$t('components.personal-area-menu.my-agencies')
        }
        case '/account/favorites/places': {
          return this.$t('components.personal-area-menu.favorite-places')
        }
        case '/account/favorites/routes': {
          return this.$t('components.personal-area-menu.favorite-routes')
        }
        case '/account/favorites/excursions': {
          return this.$t('components.personal-area-menu.favorite-excursions')
        }
        case '/account/favorites/events': {
          return this.$t('components.personal-area-menu.favorite-events')
        }
        case '/account/favorites/journals': {
          return this.$t('components.personal-area-menu.favorite-journals')
        }
        case '/account/travels': {
          return this.$t('components.personal-area-menu.my-travels')
        }
        case '/account/my/orders': {
          return this.$t('components.personal-area-menu.my-orders')
        }
        case '/account/my/routes': {
          return this.$t('components.personal-area-menu.my-activity-routes')
        }
        case '/account/my/places': {
          return this.$t('components.personal-area-menu.my-activity-places')
        }
        default: {
          return ''
        }
      }
    },
    opened() {
      if (this.open === false) {
        this.open = true
        document.body.style.overflow = 'hidden'
      } else {
        this.open = false
        document.body.style.overflow = 'auto'
      }
    }
  }
}
