import { render, staticRenderFns } from "./personal-area.vue?vue&type=template&id=2d7aef37&"
import script from "./personal-area.vue?vue&type=script&lang=js&"
export * from "./personal-area.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Header/index.vue').default,Button: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Button/index.vue').default,PersonalAreaMenuMobile: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PersonalAreaMenuMobile/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default,PersonalAreaMenu: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PersonalAreaMenu/index.vue').default,Footer: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Footer/index.vue').default,MobileMenu: require('/srv/rir-platform/tourism/src/tourism-front/components/common/MobileMenu/index.vue').default,Popup: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Popup/index.vue').default,Disclaimer: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Disclaimer/index.vue').default,ScrollToTopButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ScrollToTopButton/index.vue').default,Toaster: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Toaster/index.vue').default})
