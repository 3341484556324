import { errors } from '@/utils/errors'
import  { travelAppend} from '~/utils/travel-append'
import defaultStore from '@/utils/travel-default-store-config'

export const state = () => ({
  actual_travel_event: null,
  entity: null,
  object_id: null,
  user_id: null,
  travel_id: null,
  started_at: null,
  stopped_at: null,
  title: null,
  errors: null,
  pending: false
})

export const mutations = {
  setActualTravelEvent: (state, actual) => state.actual_travel_event = actual,
  setUserId: (state, user_id) => state.user_id = user_id,
  setObjectId: (state, object_id) => state.object_id = object_id,
  setEntity: (state, entity) => state.entity = entity,
  setTravelId: (state, travel_id) => state.travel_id = travel_id,
  setStartedAt: (state, started_at) => {
    state.started_at = started_at
    state.errors = errors('started_at', state.errors)
  },
  setStoppedAt: (state, stopped_at) => {
    state.stopped_at = stopped_at
    state.errors = errors('stopped_at', state.errors)
  },
  setTitle: (state, title) => {
    state.title = title
    state.errors = errors('title', state.errors)
  },
  setErrors: (state, errors) => (state.errors = { ...state.errors, ...errors }),
  setPending: (state, pending) => (state.pending = pending),

  clear: (state) => {
    for (const key in defaultStore) {
      state[key] = defaultStore[key]
    }
  }
}

export const actions = {
  async submit ({ state, commit, dispatch, rootGetters}, payload) {
    commit('setPending', true)
    const data = travelAppend(state)
    await this.$axios.$post('/api/travels', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(() => {
        if (state.object_id) {
          dispatch('travels/fetch', payload, { root: true })
            .then(() => {
              const travels = rootGetters['travels/travels']
              commit('setTravelId', travels[0].id)
              if (state.actual_travel_event) {
                this.$axios.$post(`api/${state.entity}/${state.object_id}/travels/${state.travel_id}/add`)
                  .then(() => {
                    commit('setPending', false)
                    commit('travels/setInnerTravels', true, { root: true })
                    commit('popup/close', null, { root: true })
                    commit('popup/open', {
                      name: 'PopupAddPlaceSuccess'
                    }, { root: true })
                  })
              } else {
                commit('setPending', false)
                commit('popup/close', null, { root: true })
                commit('popup/open', {
                  name: 'PopupAddPlaceSuccess'
                }, { root: true })
              }
            })
        } else {
          dispatch('travels/fetch', payload, { root: true })
          commit('setPending', false)
          commit('popup/close', null, { root: true })
          commit('popup/open', {
            name: 'PopupAddPlaceSuccess'
          }, { root: true })
        }

      })
      .catch((error) => {
        commit('setErrors', error.response.data)
        commit('setPending', false)
      })
  }
}

export const getters = {
  entity: (state) => state.entity,
  object_id: (state) => state.object_id,
  user_id: (state) => state.user_id,
  started_at: (state) => state.started_at,
  stopped_at: (state) => state.stopped_at,
  title: (state) => state.title,
  errors: (state) => state.errors,
  pending: (state) => state.pending
}
