//
//
//
//
//
//
//
//

export default {
  name: "EmptyDefaultText",
  props: {
    mode: {
      type: String,
      default: () => 'black'
    },
    width: {
      type: String,
      default: () => Math.floor(Math.random() * (100 - 65)) + 65 + '%'
    },
    tag: {
      type: String,
      default: () => 'p'
    }
  },
  computed: {
    style() {
      return {
        width: this.width
      }
    },
    classList() {
      return [
        `empty-default-text--${this.tag}`,
        `empty-default-text--${this.mode}`,
      ]
    }
  }
}
