import objectToQueryString from "~/utils/object-to-query-string";

export default async function ({
                                 redirect,
                                 store,
                                 route,
                                 app,
                               }) {
  if (!store.getters['user/logged']) {
    const locale = app.i18n.locale === 'ru' ? '' : `/${app.i18n.locale}`
    if (!process.server) {
      await window.$nuxt.$router.push(`${locale}/login?${objectToQueryString({latest_url: `${store.getters.host}${route.fullPath}`})}`)
    } else {
      return redirect(`${locale}/login?${objectToQueryString({latest_url: `${store.getters.host}${route.fullPath}`})}`)
    }
  }
}
