//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NewsCard',
  components: {
    ImageComponent: () => import('@/components/common/ImageComponent')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    images: {
      type: Array,
      default: () => [require('@/assets/images/default-image.svg')]
    },
    // eslint-disable-next-line vue/prop-name-casing
    started_at: {
      type: Number,
      default: () => null
    },
    // eslint-disable-next-line vue/prop-name-casing
    created_at: {
      type: Number,
      default: () => null
    },
    type: {
      type: String,
      default: () => 'Новость'
    }
  },
  computed: {
    date () {
      return this.$moment.unix(this.started_at || this.created_at)
        .format('DD.MM.YYYY')
    },
    link () {
      return `/${this?.entity}/${this?.id}`
    },
    image () {
      return this.images[0] || require('@/assets/images/default-image.svg')
    }
  }
}
