//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "FooterCredits",
  components: {
    RosatomLogotype: () => import('@/static/rosatom.svg?inline'),
    FooterCredit: () => import("@/components/common/Footer/FooterCredit")
  },
  computed: {
    ...mapGetters({
      credits: 'footer/credits'
    }),
    show() {
      return this.credits.length > 0
    }
  }
}
