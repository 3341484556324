import { parser } from '@/utils/parser'

export const state = () => ({
  single: true,
  default: null,
  cities: [],
  current: ''
})

export const mutations = {
  setCities: (state, cities) => {
    state.cities = cities
    const defaultCity = cities.find(item => item.is_default)
    if (defaultCity) {
      state.default = defaultCity
    } else {
      throw new Error('specify at least one default city')
    }
  }
  ,
  setCurrentCity: (state, slug) => state.current = slug,
  setSingleCity: (state, single) => state.single = single,
}

export const actions = {
  async fetch ({ commit }, payload) {
    await this.$axios.$get(`/api/cities?${parser(payload)}`)
      .then(response => {
        commit('setCities', response.data)
      })
  }
}

export const getters = {
  single: state => state.single,
  cities: state => state.single ? state.cities.filter(item => item.is_default) : state.cities,
  default: state => state.default
}
