//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ScrollToTopButton',
  components: {
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton')
  },
  computed: {
    iconSize () {
      switch (this?.$media()) {
        case 'md': {
          return '18px'
        }
        case 'sm': {
          return '16px'
        }
        default: {
          return '28px'
        }
      }
    },
    btnSize () {
      switch (this?.$media()) {
        case 'md': {
          return '48px'
        }
        case 'sm': {
          return '40px'
        }
        default: {
          return '65px'
        }
      }
    },
    style () {
      return {
        opacity: this.$store.getters['viewport/scroll'] > 100 ? 1 : 0
      }
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
