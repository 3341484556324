//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "PopupStoryCardButton",
  computed: {
    ...mapGetters({
      buttonTitle: 'story/buttonTitle',
      buttonTitleTranslations: 'story/buttonTitleTranslations',
      link: 'story/buttonLink',
      external: 'story/buttonExternal',
    }),
    title() {
      if (this.$i18n.locale === this.$defaultLocale()) {
        return this.buttonTitle
      }
      if (this.$i18n.locale !== this.$defaultLocale()) {
        return this.buttonTitleTranslations[this.$i18n.locale] || this.buttonTitleTranslations.en || this.buttonTitle || ''
      }
      return ''
    }
  }
}
