import { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$showCitySelector inside Vue components
  interface Vue {
    $showCitySelector(): boolean
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$showCitySelector inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $showCitySelector(): boolean
  }
  // nuxtContext.$showCitySelector
  interface Context {
    $showCitySelector(): boolean
  }
}

declare module 'vuex/types/index' {
  // this.$showCitySelector inside Vuex stores
  interface Store<S> {
    $showCitySelector(): boolean
  }
}

const pluginShowCitySelector: Plugin = ({store,$config}, inject) => {
  inject('showCitySelector', () => store.getters['cities/cities'].length > 1 || !$config.singleCity)
}

export default pluginShowCitySelector
