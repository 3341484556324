import { render, staticRenderFns } from "./HeaderNavigationCityComponent.vue?vue&type=template&id=5c906418&"
import script from "./HeaderNavigationCityComponent.vue?vue&type=script&lang=js&"
export * from "./HeaderNavigationCityComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderNavigationList: require('/srv/rir-platform/tourism/src/tourism-front/components/common/HeaderNavigation/HeaderNavigationList.vue').default})
