export const state = () => ({
  article: null
})

export const mutations = {
  setArticle: (state, article) => state.article = article
}

export const actions = {
  async fetch ({ commit }, payload) {
    const article = await this.$axios.$get(`/api/articles/${payload}`)
    commit('setArticle', article.data)
  }
}

export const getters = {
  article: state => state.article
}
