//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionJournalTop",
  components: {
    EntityContent: () => import("@/components/common/EntityContent"),
    Section: () => import("@/components/common/Section/index"),
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    description: {
      type: String,
      default: () => ''
    }
  }
};
