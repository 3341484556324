import {parser} from "~/utils/parser";
import {featureNormalizer} from "~/utils/feature-normalizer";

export const state = () => ({
  id: null,

  query: '',
  queryPending: false,
  search: '',

  lang: 'ru',
  count: 100500,
  city_id: null,
  entity: 'places',
  category_id: null,

  place: null,
  placePending: false,

  places: [],
  placesPending: false,

  route: null,
  routePending: false,

  routes: [],
  routesPending: false,

  features: [],
  featuresCollection: [],

  layer: null,
  layerPending: false,

  layers: [],
  layersPending: false,

  area: null,
  areaPending: false,

  areas: null,
  areasPending: false
})

export const mutations = {
  setDefaultStore: (state) => {
    state.id = null

    state.query = ''
    state.queryPending = false
    state.search = ''

    state.lang = 'ru'
    state.city_id = null
    state.entity = 'places'
    state.category_id = null

    state.place = null
    state.placePending = false

    state.places = []
    state.placesPending = false

    state.route = null
    state.routePending = false

    state.routes = []
    state.routesPending = false

    state.features = []
    state.featuresCollection = []

    state.layer = []
    state.layerPending = false

    state.layers = []
    state.layersPending = false

    state.area = null
    state.areaPending = false

    state.areas = null
    state.areasPending = false
  },
  setId: (state, id) => {
    state.id = id
    if (id === null) {
      state.category_id = null
      state.id = null
      state.place = null
      state.route = null
      state.area = null
      state.areas = null
      state.layer = null
      state.areaPending = true
      state.areasPending = true
      state.layerPending = true
      state.layersPending = true
      state.placesPending = true
      state.routesPending = true
    }
  },
  setQuery: (state, query) => state.query = query,
  setQueryPending: (state, queryPending) => state.queryPending = queryPending,
  setSearch: (state, search) => state.search = search,
  setLang: (state, lang) => state.lang = lang,
  setCategoryId: (state, category_id) => state.category_id = category_id,
  setEntity: (state, entity) => {
    state.category_id = null
    state.entity = entity
    if (entity === 'layers') {
      state.query = ''
      state.search = ''
      state.city_id = null
    }
    state.id = null
    state.place = null
    state.places = []
    state.features = []
    state.route = null
    state.routes = []
    state.featuresCollection = []
    state.area = null
    state.area = []
    state.layer = null
    state.layers = []
    state.areaPending = true
    state.areasPending = true
    state.layerPending = true
    state.layersPending = true
    state.placesPending = true
    state.routesPending = true
  },
  setCityId: (state, city_id) => state.city_id = city_id,

  clearPlace: (state) => state.place = [],
  setPlace: (state, place) => state.place = place,
  setPlacePending: (state, placePending) => state.placePending = placePending,

  clearPlaces: (state) => state.places = [],
  setPlaces: (state, places) => state.places = places,
  setPlacesPending: (state, placesPending) => state.placesPending = placesPending,

  clearRoute: (state) => state.route = [],
  setRoute: (state, route) => state.route = route,
  setRoutePending: (state, routePending) => state.routePending = routePending,

  clearRoutes: (state) => state.routes = [],
  setRoutes: (state, routes) => state.routes = routes,
  setRoutesPending: (state, routesPending) => state.routesPending = routesPending,

  clearFeatures: state => state.features = [],
  setFeatures: (state, places) => {
    state.features = places.map((item) => {
      return featureNormalizer(item)
    })
  },

  clearFeaturesCollection: state => state.featuresCollection = [],
  setFeaturesCollection: (state, route) => {
    let collection = route?.places?.map((item) => {
      return featureNormalizer(item)
    })
    if (route?.direction?.length > 0) {
      const coordinates = route?.direction?.map(item => item.reverse())
      collection = [...collection, {
        type: 'Feature',
        properties: [],
        geometry: {
          type: 'LineString',
          coordinates
        }
      }]
    }
    state.featuresCollection = collection
  },

  changeFavorite: (state, favorited) => {
    if (state.entity === 'routes') {
      state.route.favorited = favorited
    }
    if (state.entity === 'places') {
      state.place.favorited = favorited
    }
  },

  clearLayer: (state) => state.layer = null,
  setLayer: (state, layer) => state.layer = layer,
  setLayerPending: (state, layerPending) => state.layerPending = layerPending,

  clearLayers: (state) => state.layers = [],
  setLayers: (state, layers) => state.layers = layers,
  setLayersPending: (state, layersPending) => state.layersPending = layersPending,

  clearArea: (state) => state.area = null,
  setArea: (state, area) => state.area = area,
  setAreaPending: (state, areaPending) => state.areaPending = areaPending,

  clearAreas: (state) => state.areas = null,
  setAreas: (state, areas) => state.areas = areas,
  setAreasPending: (state, areasPending) => state.areasPending = areasPending,
}

export const actions = {
  async query({state, commit}, payload) {
    commit('setQueryPending', true)
    if (payload?.clear) {
      commit('clearPlaces')
      commit('clearFeatures')
    }
    const show = () => {
      switch (state.entity) {
        case 'places':
          return 'id,title,address,work_hours,images,location,category_id,rating,city_id,favorited,category'
        case 'routes':
          return 'id,title,entity,address,direction,images,fovarited,rating,category'
        default:
          return ''
      }
    }
    const results = await this.$axios.$get(`/api/search/${state.entity}?${parser({
      params: {
        query: state.query,
        count: 100500,
        lang: state.lang,
        resolution: 'micro',
        show: show(),
        city_id: state.city_id
      }
    })}`)
    if (state.entity === 'places') {
      commit('setPlaces', results.data)
      commit('setFeatures', results.data)
    } else if (state.entity === 'routes') {
      commit('setRoutes', results.data)
    }
    commit('setQueryPending', false)
  },
  async places({state, commit}) {
    commit('setPlacesPending', true)
    await this.$axios.$get(`/api/places?${parser({
      params: {
        count: 100500,
        lang: state.lang,
        resolution: 'micro',
        show: 'id,title,address,work_hours,images,location,category_id,rating,city_id,favorited,category',
        city_id: state.city_id,
        category_id: state.category_id
      }
    })}`)
      .then(response => {
        commit('setPlaces', response.data)
        commit('setFeatures', response.data)
      })
      .catch(() => null)
      .finally(() => commit('setPlacesPending', false))
  },
  async place({state, commit}, payload) {
    commit('setPlacePending', true)
    if (payload?.clear) {
      commit('clearPlace')
      commit('clearFeatures')
    }
    await this.$axios.$get(`/api/places/${state.id}?${parser({
      params: {
        count: 100500,
        resolution: 'micro',
        lang: state.lang
      }
    })}`)
      .then(response => {
        commit('setPlace', response.data)
        commit('setFeatures', [response.data])
      })
      .catch(() => null)
      .finally(() => commit('setPlacePending', false))
  },
  async route({state, commit}, payload) {
    commit('setRoutePending', true)
    if (payload?.clear) {
      commit('clearRoute')
      commit('clearFeaturesCollection')
    }
    const route = await this.$axios.$get(`/api/routes/${state.id}?${parser({
      params: {
        count: 100500,
        resolution: 'micro',
        lang: state.lang
      }
    })}`)
    commit('setRoute', route.data)
    commit('setFeaturesCollection', route.data)
    commit('setRoutePending', false)
  },
  async routes({state, commit}, payload) {
    commit('setRoutesPending', true)
    if (payload?.clear) {
      commit('clearRoutes')
      commit('clearFeaturesCollection')
    }
    const routes = await this.$axios.$get(`/api/routes?${parser({
      params: {
        count: 100500,
        lang: state.lang,
        resolution: 'micro',
        show: 'id,title,entity,images,favorited,distance_int,duration_int,category,category_id,direction',
        city_id: state.city_id
      }
    })}`)
    commit('setRoutes', routes.data)
    commit('setFeaturesCollection', routes.data)
    commit('setRoutesPending', false)
  },
  async layer({state, commit}, payload) {
    commit('setLayerPending', true)
    if (payload?.clear) {
      commit('clearLayer')
    }
    await this.$axios.$get(`/api/layers/${state.id}?${parser({
      params: {
        count: 100500,
        lang: state.lang,
        resolution: 'micro',
      }
    })}`)
      .then(response => commit('setLayer', response.data))
      .catch(() => null)
      .finally(() => commit('setLayerPending', false))
  },
  async layers({state, commit}, payload) {
    commit('setLayersPending', true)
    if (payload?.clear) {
      commit('clearLayers')
    }
    await this.$axios.$get(`/api/layers?${parser({
      params: {
        count: 100500,
        lang: state.lang,
        resolution: 'micro',
      }
    })}`)
      .then(response => commit('setLayers', response.data))
      .catch(() => null)
      .finally(() => commit('setLayersPending', false))
  },
  async area({state, commit}, payload) {
    commit("setAreaPending", true)
    if (payload?.clear) {
      commit('clearArea')
    }
    await this.$axios.$get(`/api/map/layers/${state.id}/areas?${parser({
      params: {
        count: 100500,
        lang: state.lang
      }
    })}`)
      .then(response => commit('setArea', response))
      .catch(() => null)
      .finally(() => commit('setLayersPending', false))
  },
  async areas({state, commit}, payload) {
    commit("setAreasPending", true)
    if (payload?.clear) {
      commit('clearAreas')
    }
    await this.$axios.$get(`/api/layers/${state.id}/areas?${parser({
      params: {
        count: 100500,
        lang: state.lang
      }
    })}`)
      .then(response => commit('setAreas', response.data))
      .catch(() => null)
      .finally(() => commit('setAreasPending', false))
  }
}

export const getters = {
  id: state => state.id,
  query: state => state.query,
  search: state => state.search,
  city_id: state => state.city_id,
  entity: state => state.entity,
  category_id: state => state.category_id,

  place: state => state.place,
  placePending: state => state.placePending,

  places: state => state.places,
  placesPending: state => state.placesPending,

  route: state => state.route,
  routePending: state => state.routePending,

  routes: state => state.routes,
  routesPending: state => state.routesPending,

  features: state => state.features,
  featuresCollection: state => state.featuresCollection,

  layer: state => state.layer,
  layerPending: state => state.layerPending,

  layers: state => state.layers,
  layersPending: state => state.layersPending,

  area: state => state.area,
  areaPending: state => state.areaPending,

  areas: state => state.areas,
  areasPending: state => state.areasPending,

  urlQuery: state => ({
    entity: state.entity,
    city_id: state.city_id,
    category_id: state.category_id,
    search: state.search,
    id: state.id
  })
}


// import { parser } from '@/utils/parser'
// import { featureNormalizer } from '@/utils/feature-normalizer'
//
// function parseQuery (value) {
//   return value || ''
// }
//
// export const state = () => ({
//   id: null,
//
//   url: null,
//
//   query: '',
//
//   place: null,
//   placeMeta: null,
//   placePending: false,
//
//   places: [],
//   placesMeta: null,
//   placesPending: false,
//
//   features: [],
//   featuresCollection: [],
//
//   route: null,
//   routeMeta: null,
//   routePending: false,
//
//   routes: [],
//   routesMeta: null,
//   routesPending: false,
//
//   tabs: [],
//   currentTab: 'places',
//
//   city_id: null,
//
//   type_id: null,
//
//   pending: true,
//
//   history: []
// })
//
// export const mutations = {
//   setId: (state, id) => state.id = Number(id),
//
//   setUrl: (state, query) => state.url = { ...state.url, ...query },
//
//   clearQuery: state => state.query = '',
//   setQuery: (state, query) => state.query = parseQuery(query),
//
//   setPlaces: (state, places) => state.places = places,
//   setPlacesMeta: (state, meta) => state.placesMeta = meta,
//   setPlacesPending: (state, pending) => state.placesPending = pending,
//   clearPlaces: state => state.places = [],
//
//   setPlace: (state, place) => state.place = place,
//   setPlaceMeta: (state, meta) => state.placeMeta = meta,
//   setPlacePending: (state, pending) => state.placePending = pending,
//   clearPlace: state => state.place = null,
//
//   clearRoutes: state => state.routes = [],
//   clearRoute: state => state.route = null,
//   setRoutes: (state, routes) => state.routes = routes,
//   setRoute: (state, route) => state.route = route,
//
//   clearFeatures: state => state.features = [],
//   setFeatures: (state, places) => {
//     state.features = places.map((item) => {
//       return featureNormalizer(item)
//     })
//   },
//
//   clearFeaturesCollection: state => state.featuresCollection = [],
//   setFeaturesCollection: (state, route) => {
//     let collection = route?.places?.map((item) => {
//       return featureNormalizer(item)
//     })
//     if (route?.direction?.length > 0) {
//       const coordinates = route?.direction?.map(item => item.reverse())
//       collection = [...collection, {
//         type: 'Feature',
//         properties: [],
//         geometry: {
//           type: 'LineString',
//           coordinates
//         }
//       }]
//     }
//     state.featuresCollection = collection
//   },
//
//   setTabs: (state, tabs) => state.tabs = tabs,
//   setCurrentTab: (state, currentTab) => state.currentTab = currentTab,
//
//   setCityId: (state, city_id) => state.city_id = Number(city_id),
//
//   setTypes: (state, types) => state.types = types,
//   setTypeId: (state, type_id) => state.type_id = Number(type_id),
//
//   setPending: (state, pending) => state.pending = pending,
//
//   setHistory: (state, history) => state.history = [...state.history, history]
// }
//
// export const actions = {
//   async query ({ commit }, payload) {
//     commit('setPending', true)
//     if (payload?.clear) {
//       commit('clearPlaces')
//       commit('clearFeatures')
//     }
//     const results = await this.$axios.$get(`/api/search/${payload.entity}?${parser(payload)}`)
//     if (payload.entity === 'places') {
//       commit('setPlaces', results.data)
//       commit('setFeatures', results.data)
//     } else if (payload.entity === 'routes') {
//       commit('setRoutes', results.data)
//     }
//     commit('setPending', false)
//   },
//   async places ({ commit }, payload) {
//     commit('setPlacesPending', true)
//     if (payload?.clear) {
//       commit('clearPlaces')
//       commit('clearFeatures')
//     }
//     await this.$axios.$get(`/api/places?${parser(payload)}`)
//       .then(response => {
//         commit('setPlaces', response.data)
//         commit('setFeatures', response.data)
//       })
//       .catch(() => null)
//       .finally(() => commit('setPlacesPending', false))
//   },
//   async place ({ commit }, payload) {
//     commit('setPlacePending', true)
//     if (payload?.clear) {
//       commit('clearPlace')
//       commit('clearFeatures')
//     }
//     await this.$axios.$get(`/api/places/${payload.id}?${parser(payload)}`)
//       .then(response => {
//         commit('setPlace', response.data)
//         commit('setFeatures', [response.data])
//       })
//       .catch(() => null)
//       .finally(() => commit('setPlacePending', false))
//   },
//   async route ({ commit }, payload) {
//     commit('setPending', true)
//     if (payload?.clear) {
//       commit('clearRoute')
//       commit('clearRoutes')
//     }
//     const route = await this.$axios.$get(`/api/routes/${payload.id}?${parser(payload)}`)
//     commit('setRoute', route.data)
//     commit('setFeaturesCollection', route.data)
//     commit('setPending', false)
//   },
//   async routes ({ commit }, payload) {
//     commit('setPending', true)
//     if (payload?.clear) {
//       commit('clearRoutes')
//     }
//     const routes = await this.$axios.$get(`/api/routes?${parser(payload)}`)
//     commit('setRoutes', routes.data)
//     commit('setPending', false)
//   }
// }
//
// export const getters = {
//   id: state => state.id,
//
//   url: state => state.url,
//
//   query: state => state.query,
//
//   places: state => state.places,
//   placesMeta: state => state.placesMeta,
//   placesPending: state => state.placesPending,
//
//   place: state => state.place,
//   placeMeta: state => state.placeMeta,
//   placePending: state => state.placePending,
//
//   features: state => state.features,
//   featuresCollection: state => state.featuresCollection,
//
//   routes: state => state.routes,
//   routesMeta: state => state.routesMeta,
//   routesPending: state => state.routesPending,
//
//   route: state => state.route,
//   routeMeta: state => state.routeMeta,
//   routePending: state => state.routePending,
//
//   tabs: state => state.tabs,
//   currentTab: state => state.currentTab,
//
//   city_id: state => state.city_id,
//
//   types: state => state.types,
//   type_id: state => state.type_id,
//
//   pending: state => state.pending,
//
//   history: state => state.history,
//
//   state: state => state
// }
