export default async function ({
                                 params,
                                 store,
                                 app,
                                 error,
                               }) {
  await store.dispatch('municipality/fetch', {
    params: {
      slug: params.slug,
      lang: app.i18n.locale,
      resolution: 'micro'
    }
  })
    .then(() => {
      const municipality = store.getters['municipality/municipality']
      if (!municipality) {
        error({statusCode: 404})
      }
    })
}
