//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: 'BurgerButton',
  computed: {
    ...mapGetters({
      open: 'mobile-menu/opened'
    })
  },
  watch: {
    $route() {
      this.$store.commit('mobile-menu/setOpened', false);
    }
  }
}
