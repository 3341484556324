//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionAviaKassaWidgetComponent",
  components: {
    AviaKassaWidgetComponent: () => import("@/components/common/AviaKassaWidgetComponent"),
    Section: () => import("@/components/common/Section/index")
  },
  props: {
    title: {
      type: String,
      default: () => ""
    },
    characteristics: {
      type: Object,
      required: true
    }
  }
};
