export const state = () => ({
  url: '',
  song: null,
  active: false,
  uid: 0
})

export const mutations = {
  setSong: (state, url) => state.song = new Audio(url),
  setUrl: (state, url) => state.url = url,
  setPause: (state) => {
    state.song?.pause()
    state.active = false
  },
  setPlay: (state, { active, uid }) => {
    state.active = active
    state.uid = uid
  }
}

export const actions = {}

export const getters = {
  song: state => state.song,
  url: state => state.url,
  active: state => state.active,
  uid: state => state.uid
}
