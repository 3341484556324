//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import accountAgencyOrdersValidation from '@/middleware/account-agency-orders-validation'

export default {
name: "AccountAgencyOrdersViewComponent",
  components: {
    DatePickerComponent: () => import("@/components/common/DatePickerComponent"),
    RoundButton: () => import('@/components/common/RoundButton'),
    Divider: () => import('@/components/common/Divider'),
    Button: () => import('@/components/common/Button'),
    Empty: () => import('@/components/common/Empty'),
    AccountAgencyOrder: () => import('@/components/common/AccountAgencyOrder'),
    SelectComponent: () => import('@/components/common/SelectComponent'),
    InputComponent: () => import('@/components/common/InputComponent')
  },
  middleware: [accountAgencyOrdersValidation],
  props: {
    payable: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      order: null,
      statuses: [
        {
          id: null,
          title: this.$t('components.account-agency-orders-view.select-statuses-all'),
          status: null
        },
        {
          id: 0,
          title: this.$t('components.account-agency-orders-view.select-statuses-new'),
          status: 'new'
        },
        {
          id: 1,
          title: this.$t('components.account-agency-orders-view.select-statuses-paid'),
          status: 'paid'
        },
        {
          id: 2,
          title: this.$t('components.account-agency-orders-view.select-statuses-confirmed'),
          status: 'confirmed'
        },
        {
          id: 3,
          title: this.$t('components.account-agency-orders-view.select-statuses-canceled'),
          status: 'canceled'
        },
        {
          id: 4,
          title: this.$t('components.account-agency-orders-view.select-statuses-provided'),
          status: 'provided'
        },
        {
          id: 5,
          title: this.$t('components.account-agency-orders-view.select-statuses-closed'),
          status: 'closed'
        }
      ],
      status: null,
      status_id: null,
      type_id: null,
      type: null,
      dates: []
    }
  },
  computed: {
    ...mapGetters({
      orders: 'account-agency-orders/orders',
      meta: 'account-agency-orders/meta',
      pending: 'account-agency-orders/pending',
      current: 'account-agency-orders-view/current',
      counts: 'account-agency-orders-view/counts',
      count: 'account-agency-orders-view/count',
      userToken: 'user/token'
    }),
    types () {
      return [
        {
          id: null,
          orderable_id: null,
          title: this.$t('components.account-agency-orders-view.select-types-all'),
          orderable_type: null
        }, ...this.$store.getters['account-agency-orders/orderables'].map((item) => {
          return {
            ...item,
            id: item.orderable_id
          }
        })
      ]
    },
    pages () {
      if (this?.meta) {
        if (this?.meta?.total !== 0) {
          const array = []
          for (let i = 1; i < this.last_page + 1; i++) {
            if (this?.from === this.last_page) {
              return [this.last_page]
            }
            if (i === 1) {
              array.push(i)
            }
            if (i === this.current_page - 1 ||
              i === this.current_page ||
              i === this.current_page + 1) {
              array.push(i)
            }
            if (i === this.current_page - 2) {
              array.push('before')
            }
            if (i === this.current_page + 2) {
              array.push('after')
            }
            if (i === this.last_page) {
              array.push(i)
            }
          }
          return [...new Set(array)]
        }
      }
      return []
    },
    from () {
      return this?.meta?.from
    },
    current_page () {
      return this?.meta?.current_page
    },
    last_page () {
      return this?.meta?.last_page
    },
    empty () {
      return this.pending
        ? this.$t('components.account-agency-orders-view.pending')
        : this.$t('components.account-agency-orders-view.empty')
    },
  },
  watch: {
    payable() {
      this.status = null
      this.status_id = null
      this.dates = []
      this.type_id = null
      this.type = null
      this.order = null
    }
  },
  created () {
    this.$store.commit('layout-personal-area-agency/setButtonBackLink', '/account/agencies')
    this.$store.commit('layout-personal-area-agency/setButtonBackTitle', this.$t('layouts.personal-area-layout.link-my-organizations'))
  },
  methods: {
    reset () {
      this.order = null
      this.status_id = null
      this.status = null
      this.type_id = null
      this.type = null
      this.dates = []
      this.search()
    },
    orderNumber() {
      return /^[0-9]+$/.test(this.order) ? this.order : null
    },
    roundButtonContent (page) {
      return typeof page !== 'number' ? '...' : page
    },
    border () {
      return `1px solid ${this.$colors.darkColor}`
    },
    background (page) {
      return page === this.current_page ? this.$colors.darkColor : 'transparent'
    },
    changeCurrentCountPerPage (index) {
      this.$store.commit('account-agency-orders-view/setCurrent', index)
      this.search()
    },
    changeStatus (item) {
      this.status_id = item.id
      this.status = item.status
    },
    changeTypes (item) {
      this.type_id = item.orderable_id
      this.type = item.orderable_type
    },
    changeDate (dates) {
      this.dates = dates
    },
    changePage (page) {
      if (typeof page === 'number') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$store.dispatch('account-agency-orders/search', {
          id: this.$route.params.agency_id,
          clear: true,
          params: {
            status: this.status,
            order_id: this.orderNumber(),
            orderable_id: this.type_id,
            orderable_type: this.type,
            page,
            count: this.count,
            payable: `${this.payable}`,
            datetime_from: this.dates[0],
            datetime_before: this.dates[1],
          }
        })
      }
    },
    search () {
      this.$store.dispatch('account-agency-orders/search', {
        id: this.$route.params.agency_id,
        clear: true,
        params: {
          status: this.status,
          order_id: this.orderNumber(), // id заказа
          orderable_id: this.type_id, // id сущности
          orderable_type: this.type, // тип сущности
          page: 1,
          count: this.count,
          payable: `${this.payable}`,
          datetime_from: this.dates[0], // от даты
          datetime_before: this.dates[1], // до даты
        }
      })
    },
    download() {
      this.$store.dispatch('account-agency-orders/export', {
        id: this.$route.params.agency_id,
        params: {
          api_token: this.userToken,
          status: this.status,
          order_id: this.orderNumber(), // id заказа
          orderable_id: this.type_id, // id сущности
          orderable_type: this.type, // тип сущности
          payable: `${this.payable}`,
          datetime_from: this.dates[0] || '', // от даты
          datetime_before: this.dates[1] || '', // до даты
        }
      })
    },
  }
}
