//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionTags",
  components: {
    Section: () => import('@/components/common/Section'),
    Tags: () => import('@/components/common/Tags'),
    Tag: () => import('@/components/common/Tag')
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    entity: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    tagGridGap() {
      switch (this?.$media()) {
        case 'hd': {
          return '8px'
        }
        case 'sm': {
          return '2px'
        }
        default: {
          return '4px'
        }
      }
    },
    tagsArray () {
      if (this.tags.length) {
        return this.tags
      } else if (this.entity && !this.tags.length) {
        return this.$store.getters[`${this.entity}/tags`] ? this.$store.getters[`${this.entity}/tags`] : []
      } else {
        return []
      }
    }
  }
}
