export const feedbackAppend = (state) => {
  const data = new FormData()
  if (state.name) {
    data.append('name', state.name)
  }
  if (state.email) {
    data.append('email', state.email)
  }
  if (state.phone) {
    data.append('phone', Number(state.phone))
  }
  if (state.city_id) {
    data.append('city_id', state.city_id)
  }
  if (state.message) {
    data.append('message', state.message)
  }
  return data
}
