import { errors } from '~/utils/errors'

function id (string) {
  const temp = string.split('/')
  return temp[temp.length - 1].split('.')[0]
}

export const state = () => ({
  photos: []
})

function index (array, id) {
  return array.findIndex(item => item.id === id)
}

export const mutations = {
  setErrors: (state, {
    id,
    errors
  }) => {
    const array = state.photos
    state.photos[index(array, id)].errors = errors
    if (Object.values(state.photos[index(array, id)].errors).every(key => key === null)) {
      state.photos[index(array, id)].errors = null
    }
  },
  setTitle: (state, {
    id,
    title
  }) => {
    const array = state.photos
    state.photos[index(array, id)].title = title
    state.photos[index(array, id)].errors = errors('title', state.photos[index(array, id)].errors)
  },
  setDescription: (state, {
    id,
    description
  }) => {
    const array = state.photos
    state.photos[index(array, id)].description = description
    state.photos[index(array, id)].errors = errors('description', state.photos[index(array, id)].errors)
  },
  setSourceLink: (state, {
    id,
    sourceLink
  }) => {
    const array = state.photos
    state.photos[index(array, id)].sourceLink = sourceLink
    state.photos[index(array, id)].errors = errors('sourceLink', state.photos[index(array, id)].errors)
  },
  setSourceTitle: (state, {
    id,
    sourceTitle
  }) => {
    const array = state.photos
    state.photos[index(array, id)].sourceTitle = sourceTitle
    state.photos[index(array, id)].errors = errors('sourceTitle', state.photos[index(array, id)].errors)
  },
  setPhoto: (state, image) => state.photos = [...state.photos, {
    id: id(image),
    title: '',
    description: '',
    pending: false,
    errors: null,
    sourceTitle: '',
    sourceLink: '',
    image
  }],
  setPending: (state, {
    id,
    pending
  }) => {
    const array = state.photos
    state.photos[index(array, id)].pending = pending || null
  },
  remove: (state, id) => state.photos = state.photos.filter(item => item.id !== id)
}

export const actions = {
  async add ({ commit }, payload) {
    await this.$axios.$post('/api/image', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resolve => commit('setPhoto', resolve?.data?.src))
  },
  async submit ({ commit }, {
    id,
    title,
    description,
    image,
    sourceLink,
    sourceTitle
  }) {
    commit('setPending', {
      id,
      pending: true
    })
    const formData = new FormData()
    if (title) {
      formData.append('title', title)
    }
    if (image) {
      formData.append('image', image)
    }
    if (description) {
      formData.append('description', description)
    }
    if (sourceLink || sourceTitle) {
      formData.append('source', JSON.stringify({
        title: sourceTitle,
        link: sourceLink,
      }))
    }
    await this.$axios.$post('/api/photobank/photos', formData)
      .then(() => {
        commit('setPending', {id, pending: false})
        commit('remove', id)
      })
      .catch(errors => {
        commit('setPending', {id, pending: false})
        commit('setErrors', {id, errors: errors.response.data})
      })
  }
}

export const getters = {
  photos: state => state.photos
}
