export default ({ store }, inject) => {
  const scrollToValidation = (errors, indent) => {
    if (errors) {
      const popup = store.getters['popup/popup']
      const name = popup.name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
      const keys = Object.keys(errors)
      let allElements = []
      keys.forEach(key => allElements.push(document.querySelector('#' + key)?.offsetTop))
      allElements = allElements.sort((a, b) => a - b)
      const key = keys.find(key => document.querySelector('#' + key)?.offsetTop === allElements[0])
      const el = document.querySelector('#' + key)
      const anchor = document.createElement('div')
      anchor.id = 'anchor'
      anchor.style.top = `-${indent || 8}px`
      anchor.classList.add('popup__anchor')
      if (el.parentElement.classList.contains(`${name}__wrapper`) || el.parentElement.classList.contains('container')) {
        el.style.position = 'relative'
        el.appendChild(anchor).scrollIntoView({ behavior: 'smooth' })
      } else {
        el.parentElement.style.position = 'relative'
        el.parentElement.appendChild(anchor).scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
  inject('scrollToValidation', scrollToValidation)
}
