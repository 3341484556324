import { parser } from '@/utils/parser'

export const state = () => ({
  seasons: []
})

export const getters = {
  seasons: state => state.seasons
}

export const mutations = {
  setSeasons: (state, seasons) => {
    state.seasons = seasons
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    const seasons = await this.$axios.$get(`/api/lists/seasons?${parser(payload)}`)
    commit('setSeasons', seasons.data)
  }
}
