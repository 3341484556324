import { parser } from '@/utils/parser'

export const state = () => ({
  news: [],
  meta: null,
  pending: false
})

export const mutations = {
  clear: (state) => {
    state.news = []
    state.meta = null
  },
  setNews: (state, news) => state.news = [...state.news, ...news],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    const news = await this.$axios.$get(`/api/news?${parser(payload)}`)
    commit('setNews', news.data)
    commit('setMeta', news.meta)
    commit('setPending', false)
  }
}

export const getters = {
  news: state => state.news,
  meta: state => state.meta,
  pending: state => state.pending
}
