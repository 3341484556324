//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'Gallery',
  components: {
    RoundButton: () => import('@/components/common/RoundButton'),
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline'),
    GalleryFrame: () => import('@/components/common/Gallery/GalleryFrame')
  },
  props: {
    seeAll: {
      type: Boolean,
      default: () => false
    },
    galleryTitleColor: {
      type: String,
      default: () => 'var(--black)'
    },
    frameWidth: {
      type: Number,
      default: () => 0
    },
    frameTitleColor: {
      type: String,
      default: () => 'var(--black)'
    },
    frameDescriptionColor: {
      type: String,
      default: () => 'var(--black)'
    },
    indent: {
      type: Number,
      default: () => 0
    },
    images: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      id: null,
      current: 0,
      left: 0,
      galleryWidth: null,
      frames: null,
      scrollWidth: null
    }
  },
  computed: {
    ...mapGetters({
      viewportWidth: 'viewport/width',
      media: 'viewport/media'
    }),
    galleryStyle() {
      return {
        width: this.galleryWidth ? this.galleryWidth + 'px' : '100%'
      }
    },
    galleryTitleStyle() {
      return {
        color: `var(--gallery-${this.id}-title-color)`
      }
    },
    framesStyle() {
      return {
        'grid-gap': `var(--gallery-${this.id}-indent)`,
        'grid-template-columns': `var(--gallery-${this.id}-template-columns)`
      }
    },
    frameStyle() {
      return {
        width: `var(--gallery-${this.id}-frame-width)`
      }
    },
    showPreview() {
      return this.current > 0 && (this.media === 'hd' || this.media === 'lg')
    },
    showNext() {
      return this.current >= 0 && this.current < this?.images?.length - 1 && (this.media === 'hd' || this.media === 'lg')
    }
  },
  watch: {
    viewportWidth() {
      const gallery = document.querySelector(`#gallery-${this.id}`)
      gallery.style.width = null
      this.frames.style.display = 'none'
      this.galleryWidth = gallery.clientWidth
      this.frames.style.display = 'grid'
      this.current = 0
      this.left = 0
      this.buildCSS()
    },
    images() {
      this.buildCSS()
    },
    left(n) {
      this.scrollWidth = this.frames.scrollWidth - this.frames.clientWidth
      if (n % (this.frameWidth + this.indent) === 0) {
        this.current = n / (this.frameWidth + this.indent)
      }
      if (n === this.scrollWidth) {
        this.current = this?.images?.length - 1
      }
    }
  },
  created() {
    this.id = this.$randomString()
  },
  mounted() {
    this.galleryWidth = document.querySelector(`#gallery-${this.id}`).clientWidth
    this.frames = document.querySelector(`#gallery-${this.id}-frames`)
    this.scrollWidth = this.frames.scrollWidth - this.frames.clientWidth
    this.buildCSS()
  },
  beforeDestroy() {
    this.destroyCSS()
  },
  methods: {
    click(id) {
      if (this.zoom) {
        this.$store.commit('popup/open', {
          name: 'PopupFullSizeSlider',
          options: {
            list: this.images,
            active: id
          }
        })
      }
    },
    scroll(e) {
      this.left = e.target.scrollLeft
    },
    scrollTo() {
      this.frames.scrollTo({
        top: 0,
        left: (this.frameWidth + this.indent) * this.current,
        behavior: 'smooth'
      })
    },
    next() {
      if (this.current < this?.images?.length - 1) {
        this.current += 1
        this.scrollTo()
      }
    },
    preview() {
      if (this.current > 0) {
        this.current -= 1
        this.scrollTo()
      }
    },
    buildCSS() {
      const root = document.documentElement.style
      root.setProperty(`--gallery-${this.id}-title-color`, this.galleryTitleColor)
      root.setProperty(`--gallery-${this.id}-frame-width`, `${this.frameWidth}px`)
      root.setProperty(`--gallery-${this.id}-frame-title-color`, this.frameTitleColor)
      root.setProperty(`--gallery-${this.id}-frame-description-color`, this.frameDescriptionColor)
      root.setProperty(`--gallery-${this.id}-indent`, `${this.indent}px`)
      root.setProperty(`--gallery-${this.id}-template-columns`, `repeat(${this?.images?.length}, ${this.frameWidth}px)`)
    },
    destroyCSS() {
      const root = document.documentElement.style
      root.setProperty(`--gallery-${this.id}-title-color`, null)
      root.setProperty(`--gallery-${this.id}-frame-width`, null)
      root.setProperty(`--gallery-${this.id}-frame-title-color`, null)
      root.setProperty(`--gallery-${this.id}-frame-description-color`, null)
      root.setProperty(`--gallery-${this.id}-indent`, null)
      root.setProperty(`--gallery-${this.id}-template-columns`, null)
    }
  }
}
