//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'


export default {
  name: 'ExcursionCard',
  components: {
    Icon24StrokeFavorites: () => import('@/assets/icons/Icon24StrokeFavorites.svg?inline'),
    Icon24FillTrash: () => import('@/assets/icons/Icon24FillTrash.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    LabelTag: () => import('@/components/common/LabelTag'),
    RatingTag: () => import('@/components/common/RatingTag'),
    ImageComponent: () => import('@/components/common/ImageComponent'),
    Avatar: () => import('@/components/common/Avatar')
  },
  props: {
    travel: {
      type: Boolean,
      default: () => false
    },
    full: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    favorited: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    images: {
      type: Array,
      default: () => [require('@/assets/images/default-image.svg')]
    },
    category: {
      type: Object,
      default: () => null
    },
    avatar: {
      type: String,
      default: () => ''
    },
    rating: {
      type: Number,
      default: () => 0
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      innerFavorited: false
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    }),
    roundButtonSize () {
      switch (this?.$media()) {
        case 'hd': {
          return '38px'
        }
        default: {
          return '36px'
        }
      }
    },
    link () {
      return `/${this?.entity}/${this?.id}`
    },
    image () {
      return this?.images[0] || require('@/assets/images/default-image.svg')
    },
    categoryTitle () {
      return this?.category?.title || this.$t('components.excursion-card.category-default')
    }
  },
  created () {
    this.innerFavorited = this.favorited
  },
  methods: {
    toggleFavorite () {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites${this.$store.getters['user/apple']
          ? '?api_token=' + this.$store.getters['user/token']
          : ''}`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited
            this.$store.dispatch('favorites/fetch', {
              entity: 'excursions',
              params: {
                count: 100500,
                lang: this.$i18n.locale
              }
            })
          })
      } else {
        this.$login()
      }
    },
    remove () {
      this.$axios.$post(`api/excursions/${this.id}/travels/${this.$route.params.id}/remove`)
        .then(() => {
          this.$store.dispatch('travel/fetch', {
            id: this.$route.params.id,
            params: {
              lang: this.$i18n.locale
            }
          })
        })
    }
  }
}
