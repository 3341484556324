import {parser} from '@/utils/parser'

export const state = () => ({
  guide: null,
  pending: false,
})

export const mutations = {
  setGuide: (state, guide) => state.guide = guide,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async fetch({commit}, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/guides/${payload.id}?${parser(payload)}`)
      .then(response => commit('setGuide', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  guide: state => state.guide,
  pending: state => state.pending,
}
