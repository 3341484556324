import objectToQueryString from "~/utils/object-to-query-string";

export default async function ({query, redirect, route}) {
  if (process.server) {
    if (!query.entity) {
      await redirect({query: {...query, entity: 'places'}})
    }
  } else {
    if (!query.entity) {
      await window.$nuxt.$router.push(`${route.fullPath}?${objectToQueryString({...query, entity: 'places'})}`)
    }
  }
}
