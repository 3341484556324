import { parser } from '@/utils/parser'

export const state = () => ({
  places: [],
  meta: null,
  pending: false,

  has_events: []
})

export const mutations = {
  clear: (state) => {
    state.places = []
    state.meta = null
  },
  setPlaces: (state, places) => state.places = [...state.places, ...places],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending,

  setHasEvents: (state, has_events) => state.has_events = has_events
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/places?${parser(payload)}`)
      .then((response) => {
        commit('setPlaces', response.data)
        commit('setMeta', response.meta)
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  },
  async hasEvents ({ commit }, payload) {
    await this.$axios.$get(`/api/places?has_events&show=id,title${parser(payload)}`)
      .then(response => commit('setHasEvents', response.data))
      .catch(() => null)
  }
}

export const getters = {
  places: state => state.places,
  meta: state => state.meta,
  pending: state => state.pending,

  has_events: state => state.has_events
}
