//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SelectCheckBoxComponent',
  components: {
    VueCustomScrollbar: () => import('vue-custom-scrollbar'),
    LabelComponent: () => import('@/components/common/LabelComponent'),
    ErrorComponent: () => import('@/components/common/ErrorComponent'),
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline'),
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Icon24StrokeCheck: () => import('@/assets/icons/Icon24StrokeCheck.svg?inline'),
  },
  props: {
    id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => ''
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    array: {
      type: Array,
      default: () => []
    },
    errors: {
      type: [Array, String],
      default: () => ''
    },
    edit: {
      type: Boolean,
      default: () => false
    },
    defaultText: {
      type: String,
      default: () => ''
    },
    checkedText: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      opened: false,
      checkedAll: false
    }
  },
  computed: {
    classList () {
      return [
        { 'select--disabled': this.disabled },
        { 'select--errors': Array.isArray(this.errors) ? this.errors[0] : this.errors }
      ]
    },
    contextTitle () {
      return this.edit
        ? `${this.checkedText || this.$t('components.select-check-box-component.checked-count')}: ${this.checkedCount}`
        : `${this.defaultText}`
    },
    checkedCount () {
      return this.array.filter(item => item.checked === true).length
    }
  },
  watch: {
    array () {
      this.checkedAll = this.array.every(item => item.checked === true)
    }
  },
  created () {
    this.checkedAll = this.array.every(item => item.checked === true)
  },
  methods: {
    close () {
      this.opened = false
    },
    toggle () {
      if (!this.disabled) {
        this.opened = !this.opened
        this.$emit('change-edit', true)
      }
    },
    click (id) {
      this.$emit('change', this.array.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked: !item.checked
          }
        } else {
          return item
        }
      }))
    },
    clickAll () {
      this.checkedAll = !this.checkedAll
      this.$emit('change-all', this.array.map(item => ({
        ...item,
        checked: this.checkedAll
      })))
    }
  }
}
