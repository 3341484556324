import { render, staticRenderFns } from "./MapNavigationRoute.vue?vue&type=template&id=1be330d9&"
import script from "./MapNavigationRoute.vue?vue&type=script&lang=js&"
export * from "./MapNavigationRoute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapNavigationBackButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/MapNavigation/MapNavigationBackButton.vue').default,Favorite: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Favorite/index.vue').default,ImageComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ImageComponent/index.vue').default,TopEntityRating: require('/srv/rir-platform/tourism/src/tourism-front/components/common/TopEntityRating/index.vue').default,Divider: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Divider/index.vue').default,Duration: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Duration/index.vue').default,Distance: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Distance/index.vue').default,HorizontalEntityCard: require('/srv/rir-platform/tourism/src/tourism-front/components/common/HorizontalEntityCard/index.vue').default,Button: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Button/index.vue').default})
