//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'GuideCard',
  components: {
    Icon24FillCertificate: () => import('@/assets/icons/Icon24FillCertificate.svg?inline'),
    Avatar: () => import('@/components/common/Avatar')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    currentUserRating: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    image: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    },
    accredited: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    AvatarSize() {
      switch (this?.$media()) {
        case 'hd': {
          return 200
        }
        case 'lg': {
          return 160
        }
        case 'md': {
          return 120
        }
        case 'sm': {
          return 112
        }
        default: {
          return 32
        }
      }
    },
    typeText() {
      switch (this?.type) {
        case 'travel': {
          return this.$t('components.guide-card.travel-agency')
        }
        case 'guide': {
          return this.$t('components.guide-card.guide')
        }
        case 'org': {
          return this.$t('components.guide-card.organization')
        }
        default: {
          return ''
        }
      }
    },
    imageAvatar() {
      return this.image || require('@/assets/images/default-image.svg')
    },
    link() {
      return `/${this?.entity}/${this?.id}`
    }
  }
}
