//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Header',
  components: {
    DesktopNavigation: () => import("@/components/common/DesktopNavigation"),
    MobileNavigation: () => import("@/components/common/MobileNavigation"),
    Logotype: () => import('@/static/logotype.svg?inline'),
  },
  computed: {
    direction() {
      return this.$config?.layouts?.header?.direction || 'vertical'
    }
  }
}
