//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Duration',
  components: {
    Icon24FillClock: () => import('@/assets/icons/Icon24FillClock.svg?inline')
  },
  props: {
    duration: {
      type: Number,
      default: () => null
    }
  }
}
