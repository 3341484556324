//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "MapNavigationLayers",
  components: {
    HorizontalLayerCard: () => import("@/components/common/HorizontalLayerCard"),
    VueCustomScrollbar: () => import('vue-custom-scrollbar'),
    Empty: () => import("@/components/common/Empty")
  },
  computed: {
    ...mapGetters({
      id: 'interactive-map/id',
      entity: 'interactive-map/entity',
      layers: 'interactive-map/layers',
      pending: 'interactive-map/layersPending'
    }),
    showLayers() {
      return this.entity === 'layers' && !this.id
    }
  }
}
