//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "StoryView",
  components: {
    Icon24FillClose: () => import("@/assets/icons/Icon24FillClose.svg?inline"),
    PopupStoryCard: () => import("~/components/common/PopupStory/PopupStoryCard"),
  },
  layout: 'story',
  mounted() {
    this.$store.dispatch('story/fetch', {
      id: this.$route.params.story_id,
      params: {
        lang: this.$i18n.locale
      }
    })
  },
}
