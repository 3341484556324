//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import {featureNormalizer} from '@/utils/feature-normalizer'
import place from '~/middleware/pages/place'

export default {
  name: 'PlaceView',
  components: {
    SectionSharing: () => import("@/components/common/SectionSharing"),
    SocialVideoFrameComponent: () => import("@/components/common/SocialVideoFrameComponent"),
    ContextSchedule: () => import("@/components/common/ContextSchedule"),
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    SectionComments: () => import("@/components/common/SectionComments"),
    SectionTags: () => import("@/components/common/SectionTags"),
    Icon24FillFavorites: () => import('@/assets/icons/Icon24FillFavorites.svg?inline'),
    Icon24FillDistance: () => import('@/assets/icons/Icon24FillDistance.svg?inline'),
    EntityContent: () => import('@/components/common/EntityContent'),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    RoundButton: () => import('@/components/common/RoundButton'),
    AudioPlayer: () => import('@/components/common/AudioPlayer/index'),
    Brochure: () => import('@/components/common/Brochure/index'),
    Hint: () => import('@/components/common/Hint/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    Gallery: () => import('@/components/common/Gallery/index'),
    OnMap: () => import('@/components/common/OnMap/index'),
    PlaceCard: () => import('@/components/common/PlaceCard/index'),
    Scroller: () => import('@/components/common/Scroller/index'),
    Section: () => import('@/components/common/Section/index'),
    SliderWithBullets: () => import('@/components/common/SliderWithBullets/index'),
    TopEntityComponent: () => import('@/components/common/TopEntityComponent'),
    TopEntityRating: () => import('@/components/common/TopEntityRating'),
    SectionBannerAppMobile: () => import('@/components/common/SectionBannerAppMobile/index')
  },
  middleware: [place],
  data() {
    return {
      places: [],
      innerFavorited: false,
      hideHint: false
    }
  },
  head() {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.title,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.$seoImage(this.images) || this.SEOImage,
          url: this.SEOUrl,
          locale: this.SEOLocale,
          site_name: this.SEOSiteName
        },
        vk: {
          image: this.$seoImage(this.images) || this.SEOImage,
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      cities: 'cities/cities',
      city: 'cities/default',
      place: 'place/place',
      travels: 'travels/travels',
      innerTravels: 'travels/innerTravels',
      categories: 'categories/places',
      logged: 'user/logged',
      accessible_environment: 'place/accessibleEnvironment',
      status: 'place/status',
      images: 'place/images',
      rutube: 'place/rutube',
      youtube: 'place/youtube',
      description: 'place/description',
      title: 'place/title',
      entity: 'place/entity',
      id: 'place/id',
      userTravels: 'place/userTravels',
      contacts: 'place/contacts',
      additions: 'place/additions',
      label: 'place/label',
      tags: 'place/tags',
      rating: 'place/rating',
      currentUserRating: 'place/currentUserRating',
      favorited: 'place/favorited',
      socials: 'place/socials',
      audios: 'place/audios',
      workHours: 'place/workHours',
      address: 'place/address',
      type: 'place/type',

    }),
    components() {
      return this.$config?.pages?.PlaceView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.showSectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showSectionTags() {
      return this.components?.SectionTags
    },
    showSectionComments() {
      return this.components?.SectionComments
    },
    showContextAdditions() {
      return this.components?.ContextAdditions && this.$i18n.locale === 'ru'
    },
    showContextSchedule() {
      return this.components?.ContextSchedule
    },
    roundButtonSize() {
      switch (this?.$media()) {
        case 'sm': {
          return '36px'
        }
        default: {
          return '48px'
        }
      }
    },
    zoom() {
      return this.city?.zoom
    },
    coords() {
      return [this.city?.latitude, this.city?.longitude] || this.$config.coords
    },
    feature() {
      return [featureNormalizer(this?.place)]
    },
    travelId() {
      return this?.travels[0]?.id
    },
    gallery() {
      return this.images.map((item, index) => ({
        image: item,
        id: index,
        title: '',
        description: ''
      }))
    },
    image() {
      return this?.place?.images[0]
    },
    phone() {
      return this?.contacts?.phone
    },
    email() {
      return this?.contacts?.email
    },
    website() {
      return this?.contacts?.website
    },
    topEntityIconSize() {
      switch (this?.$media()) {
        case 'sm': {
          return '22px'
        }
        default: {
          return '48px'
        }
      }
    },
    typeText() {
      return this.$t('pages.place-view.suptitle')
    }
  },
  created() {
    this.innerFavorited = this.favorited
    this.$store.commit('travels/setInnerTravels', !!this.userTravels[0]?.id)
  },
  mounted() {
    this.$axios.$get(`/api/places?category_id=${this?.place?.category_id}&lang=${this.$i18n.locale}&resolution=micro`)
      .then(resolve => this.places = resolve?.data?.filter(item => Number(item.id) !== Number(this.id)))
  },
  methods: {
    toggleFavorite() {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited
          })
      } else {
        this.$login()
      }
    },
    toggleTravel() {
      if (this.logged) {
        if (this.innerTravels) {
          this.hideHint = true
        }
        this.travels.length > 0
          ? this.addAndRemoveTravel()
          : this.openPopupAddTravel()
      } else {
        this.$login()
      }
    },
    addAndRemoveTravel() {
      if (this.innerTravels) {
        this.$axios.$post(`api/places/${this.$route.params.id}/travels/${this.travelId}/remove`)
          .then(() => this.$store.commit('travels/setInnerTravels', false))
      } else {
        this.$axios.$post(`api/places/${this.$route.params.id}/travels/${this.travelId}/add`)
          .then(() => this.$store.commit('travels/setInnerTravels', true))
      }
    },
    openPopupAddTravel() {
      this.$store.commit('popup/open', {
        name: 'PopupAddTravel',
        params: {
          style: {
            width: '100%',
            maxWidth: '632px',
            maxHeight: '100vh',
            overflow: 'visible',
            position: 'relative',
            overflowY: 'visible',
            overflowX: 'visible'
          },
          objectId: this.$route.params.id,
          entity: this.entity
        }
      })
    }
  }
}
