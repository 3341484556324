export default (context, inject) => {
  function distance (value, card) {
    if (value > 999) {
      if (card === 'card') {
        return value > 9999
          ? `${Math.round(value / 1000)} ${this.$t('common.unit.kilometer')}`
          : `${Math.round((value / 1000) * 10) / 10} ${this.$t('common.unit.kilometer')}`
      } else {
        const dist = value.toString().split('').reverse()
        const m = parseInt(dist.slice(0, 3).reverse().join(''))
        const km = dist.slice(3).reverse().join('')
        return m
          ? `${km} ${this.$t('common.unit.kilometer')} ${m} ${this.$t('common.unit.meter')}`
          : `${km} ${this.$t('common.unit.kilometer')}`
      }
    } else {
      return `${value} ${this.$t('common.unit.meter')}`
    }
  }
  inject('distance', distance)
}
