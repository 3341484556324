//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentLabel",
  components: {
    Icon24FillInfo: () => import('@/assets/icons/Icon24FillInfo.svg?inline')
  },
  props: {
    label: {
      type: String,
      default: () => ''
    }
  }
}
