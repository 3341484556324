//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "Footer",
  components: {
    FooterDocuments: () => import("@/components/common/Footer/FooterDocuments.vue"),
    FooterCredits: () => import("@/components/common/Footer/FooterCredits.vue"),
    FooterLicense: () => import("@/components/common/Footer/FooterLicense.vue"),
    FooterGroups: () => import("@/components/common/Footer/FooterGroups.vue")
  },
  computed: {
    ...mapGetters({
      show: 'footer/show'
    })
  },
  mounted() {
    if (this.show) {
      this.$store.dispatch('footer/fetch', {
        params: {
          lang: this.$i18n.locale
        }
      })
    }
  }
}
