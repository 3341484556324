//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapGetters} from 'vuex'


export default {
  name: 'Comments',
  components: {
    TextareaComponent: () => import('@/components/common/TextareaComponent'),
    Comment: () => import('@/components/common/Comment'),
    Button: () => import('@/components/common/Button'),
    Attention: () => import('@/components/common/Attention')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      showTextareaComponent: false,
      showOnModeration: false,
      message: '',
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      comments: ['comments/comments'],
      logged: ['user/logged']
    }),
    disabled() {
      return !this.message.length > 0
    },
    filteredComments() {
      return this?.comments?.filter(item => item.parent_id === null)
    }
  },
  mounted() {
    this.$store.dispatch('comments/getCommentsFromServerWithParams', {
      id: this.id,
      entity: this.entity
    })
  },
  methods: {
    input(value) {
      this.message = value
      if (this.error) {
        this.error = ''
      }
    },
    toggle() {
      if (this.logged) {
        this.showTextareaComponent = !this.showTextareaComponent
      } else {
        this.$login()
      }
    },
    submit() {
      if (this.message.length < 1000) {
        let string = '?'
        string += `&comment=${this.message}`
        this.$axios.$post(`/api/${this.entity}/${this.id}/comments${string}`)
          .then(() => {
            this.showTextareaComponent = false
            this.showOnModeration = true
          }).catch((e) => {
          this.error = e.comment
        })
        this.message = ''
      } else {
        this.error = this.$t('components.comments.error')
      }
    }
  }
}
