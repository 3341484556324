import { parser } from '@/utils/parser'

export const state = () => ({
  places: [],
  placesMeta: null,
  placesPending: false
})

export const mutations = {
  setPlaces: (state, places) => state.places = [...state.places, ...places],
  setPlacesMeta: (state, meta) => state.placesMeta = meta,
  setPlacesPending: (state, pending) => state.placesPending = pending,
  clearPlaces: (state) => {
    state.places = []
    state.placesMeta = null
  },
}

export const actions = {
  async places ({ commit }, payload) {
    commit('setPlacesPending', true)
    if (payload?.clear) {
      commit('clearPlaces')
    }
    const places = await this.$axios.$get(`/api/places?${parser(payload)}`)
    commit('setPlaces', places.data)
    commit('setPlacesMeta', places.meta)
    commit('setPlacesPending', false)
  },
}

export const getters = {
  places: state => state.places,
  placesMeta: state => state.placesMeta,
  placesPending: state => state.placesPending,
}
