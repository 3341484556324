export default {
  started_at: null,
  stopped_at: null,
  tariff: '',
  id_tariff: null,
  price: 0,
  max_bonus_points: 0,
  time: null,
  days: [],
  quota: 0,
  pending: false,
  errors: null,
  edit: false
}
