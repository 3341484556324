//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FooterCredit",
  components: {
    FooterCreditLink: () => import("@/components/common/Footer/FooterCreditLink")
  },
  props: {
    image: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    link: {
      type: String,
      default: () => ''
    }
  },
}
