export default async function ({
  app,
  store
}) {
  await store.dispatch('header-navigation/fetch', {
    params: {
      lang: app.i18n.locale
    }
  })
}
