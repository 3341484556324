// Паттерн на проверку видео с Ютуб. Если значение соответсвует - true, иначе - false.

import {Plugin} from '@nuxt/types'

type YoutubeVideo = string | '';

declare module 'vue/types/vue' {
  interface Vue {
    $isYoutubeVideo(video: YoutubeVideo): boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isYoutubeVideo(video: YoutubeVideo): boolean
  }

  interface Context {
    $isYoutubeVideo(video: YoutubeVideo): boolean
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $isYoutubeVideo(video: YoutubeVideo): boolean
  }
}

const pluginIsYoutubeVideo: Plugin = (context, inject) => {
  inject('isYoutubeVideo', (video: YoutubeVideo) => {
    const regexpEmail = /^(?:http(?:s)?:\/\/youtu\.be\/|http(?:s)?:\/\/(?:www\.)?youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^?&"'>]+)/
    return regexpEmail.test(video)
  })
}

export default pluginIsYoutubeVideo
