export const scheduleRequest = (state) => {
  if (state.edit) {
    if (state.type === 'event') {
      return `/api/events/${state.id}/schedules/${state.id_tariff}`
    }
    if (state.type === 'excursion') {
      return `/api/excursions/${state.id}/schedules/${state.id_tariff}`
    }
  } else {
    if (state.type === 'event') {
      return `/api/events/${state.id}/schedules`
    }
    if (state.type === 'excursion') {
      return `/api/excursions/${state.id}/schedules`
    }
  }
}
