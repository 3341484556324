import {Plugin} from '@nuxt/types'

interface Category {
  id: number,
  title: string,
  checked: boolean
}

declare module 'vue/types/vue' {
  // this.$functionality inside Vue components
  interface Vue {
    $categoryIdToString(categories: Category[]): string
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$functionality inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $categoryIdToString(categories: Category[]): string
  }

  // nuxtContext.$functionality
  interface Context {
    $categoryIdToString(categories: Category[]): string
  }
}

declare module 'vuex/types/index' {
  // this.$functionality inside Vuex stores
  interface Store<S> {
    $categoryIdToString(categories: Category[]): string
  }
}

const pluginCategoryIdToString: Plugin = ({store}, inject) => {
  inject('categoryIdToString', (categories: Category[]) => {
    return categories
      .filter((category) => category.checked)
      .map(category => category.id)
      .join(',')
  })
}

export default pluginCategoryIdToString
