//
//
//
//
//
//
//
//

// nuxt-link replace, который форсит перезагрузку страницы почему-то не работает
// todo проверить в поздних версиях nuxt
export default {
  name: 'HeaderNavigationLink',
  props: {
    link: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    href() {
      return `${this.$i18n.locale === 'ru' ? this.link : `/${this.$i18n.locale}${this.link}`}`
    }
  }
}
