import { parser } from '@/utils/parser'

export const state = () => ({
  booking: null,
  cancellation: null
})

export const getters = {
  booking: state => state.booking,
  cancellation: state => state.cancellation
}

export const mutations = {
  setBooking: (state, booking) => {
    state.booking = booking
  },
  setCancellation: (state, cancellation) => {
    state.cancellation = cancellation
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    const entity = await this.$axios.$get(`/api/${payload.entity}/${payload.id}?${parser(payload)}`)
    commit('setBooking', entity.data.booking_terms)
    commit('setCancellation', entity.data.cancellation_terms)
  }
}
