export default async function ({
  store,
  app
}) {
  await store.dispatch('photos/photos', {
    clear: true,
    params: {
      lang: app.i18n.locale
    }
  })
}
