export default async function ({
  store,
  app
}) {
  // const guidebook = store.getters['journals/guidebook']
  // if (guidebook.length === 0) {
  await store.dispatch('journals/getGuidebook', {
    params: {
      resolution: 'micro',
      lang: app.i18n.locale
    }
  })
  // }
}
