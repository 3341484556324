//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionAgencyLegalInformationOkved",
  props: {
    okved: {
      type: String,
      default: () => ""
    }
  }
};
