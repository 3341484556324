//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentSite",
  components: {
    LinkSite: () => import('@/components/common/LinkSite'),
    Icon24FillWeb: () => import('@/assets/icons/Icon24FillWeb.svg?inline')
  },
  props: {
    site: {
      type: String,
      default: () => ''
    }
  }
}
