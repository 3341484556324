//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentDuration",
  components: {
    Icon24FillClock: () => import('@/assets/icons/Icon24FillClock.svg?inline')
  },
  props: {
    duration: {
      type: Number,
      default: () => 0
    }
  }
}
