//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "FooterDocuments",
  components: {
    FooterDocument: () => import("@/components/common/Footer/FooterDocument"),
  },
  computed: {
    ...mapGetters({
      documents: 'footer/documents'
    }),
    show() {
      return this.documents.length > 0
    }
  }
}
