//
//
//
//
//
//
//
//
//
//
//
//
//

import categoryCollections from '@/middleware/pages/category-collections';
import tourismTypes from '@/middleware/tourism-types';
import hasEvents from '@/middleware/pages/category-collections/has-events'
import seasons from "@/middleware/seasons";
import levels from "@/middleware/levels";
import age from "@/middleware/age";
import config from "@/configs/pages.config"

const components = {}
for (const component in config.CategoryCollectionsView.components) {
  const folder = config.CategoryCollectionsView.components[component].special ? 'special' : 'common'
  components[component] = () => ({
    component: import(`@/components/${folder}/${component}/index.vue`),
  })
}

export default {
  name: "CategoryCollectionsView",
  components,
  middleware: [tourismTypes, seasons, levels, age, hasEvents, categoryCollections],
}
