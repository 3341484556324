//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EntityContent',
  props: {
    // Расположить контент в один столбец
    column: {
      type: Boolean,
      default: () => false
    },
    // Расположить контент наоборот на всех размерах
    reverse: {
      type: Boolean,
      default: () => false
    },
    // Расположить контент наоборот только на планшете и мобиле
    reverseMobile: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    classList () {
      return {
        'entity-content__wrapper--column': this.column,
        'entity-content__wrapper--reverse': this.reverse,
        'entity-content__wrapper--reverse-md-sm': this.reverseMobile
      }
    }
  }
}
