import {globalConfig} from "~/configs/global.config.js";

import TestView from '@/pages/test.vue'
import HomeView from '@/pages/index.vue'
import TravelAgenciesView from "@/pages/travel-agencies/index.vue";
import TravelAgencyView from "@/pages/travel-agencies/_id.vue";
import SearchView from '@/pages/search/index.vue'
import RoutesView from '@/pages/routes/index.vue'
import RouteView from '@/pages/routes/_id.vue'
import PlacesView from '@/pages/places/index.vue'
import PlaceView from '@/pages/places/_id.vue'
import PhotoBankView from "@/pages/photobank/index.vue";
import PhotoBankCreateView from "@/pages/photobank/create.vue";
import OrganizationsView from "~/pages/organizations/index.vue";
import OrganizationView from '~/pages/organizations/_id.vue'
import NewsView from '~/pages/news/index.vue'
import NewView from '~/pages/news/_id.vue'
import MunicipalityView from '~/pages/municipality/_slug.vue'
import JournalsView from '~/pages/journals/index.vue'
import JournalView from '~/pages/journals/_id.vue'
import InteractiveMapView from "~/pages/interactive-map/index.vue";
import GuidesView from "~/pages/guides/index.vue";
import GuideView from "~/pages/guides/_id.vue";
import ExcursionsView from '~/pages/excursions/index.vue'
import ExcursionView from '~/pages/excursions/_id.vue'
import EventsView from '~/pages/events/index.vue'
import EventView from '~/pages/events/_id.vue'
import BookingRulesView from '~/pages/documents/booking-rules/index.vue'
import ContractOfferView from '~/pages/documents/contract-offer/index.vue'
import PrivacyPolicyView from '~/pages/documents/privacy-policy/index.vue'
import BookingTermsView from '~/pages/documents/terms/booking/_entity/_entity_id.vue'
import CancellationTermsView from '~/pages/documents/terms/cancellation/_entity/_entity_id.vue'
import UserAgreementView from '~/pages/documents/user-agreement/index.vue'
import CategoryCollectionsView from '~/pages/category-collections/_entity/_slug.vue'
import ArticlesView from '~/pages/articles/index.vue'
import AccountView from '~/pages/account/index.vue'
import AccountTravelsView from '~/pages/account/travels/index.vue'
import AccountTravelView from '~/pages/account/travels/_id.vue'
import AccountMyOrdersView from '~/pages/account/my/orders/index.vue'
import AccountMyPlacesView from '~/pages/account/my/places/index.vue'
import AccountMyRoutesView from '~/pages/account/my/routes/index.vue'
import AccountFavoritesEventsView from '~/pages/account/favorites/events/index.vue'
import AccountFavoritesExcursionsView from '~/pages/account/favorites/excursions/index.vue'
import AccountFavoritesJournalsView from '~/pages/account/favorites/journals/index.vue'
import AccountFavoritesPlacesView from '~/pages/account/favorites/places/index.vue'
import AccountFavoritesRoutesView from '~/pages/account/favorites/routes/index.vue'
import AccountAgenciesView from '~/pages/account/agencies/index.vue'
import AccountAgencyEventsView from '~/pages/account/agencies/_agency_id/events/index.vue'
import AccountAgencyEventView from '~/pages/account/agencies/_agency_id/events/_event_id.vue'
import AccountAgencyExcursionsView from '~/pages/account/agencies/_agency_id/excursions/index.vue'
import AccountAgencyExcursionView from '~/pages/account/agencies/_agency_id/excursions/_excursion_id.vue'
import AccountAgencyOrdersView from '~/pages/account/agencies/_agency_id/orders/index.vue'
import AccountAgencyProfileView from '~/pages/account/agencies/_agency_id/profile/index.vue';
import WidgetsView from '~/pages/account/agencies/_agency_id/widgets/index.vue'
import WidgetCreateView from '~/pages/account/agencies/_agency_id/widgets/create/index.vue'
import WidgetEditView from '~/pages/account/agencies/_agency_id/widgets/edit/_widget_id.vue'
import StreamView from '~/pages/streams/index.vue'
import StoryView from '~/pages/stories/_id.vue'

import {Route} from "~/types/config/route";

export default async () => {
  const config = await globalConfig()
  const {locales, defaultLocale} = config

  const routes: Route[] = [
    {
      path: '/streams',
      component: StreamView,
      name: 'StreamView',
      exceptions: []
    },
    {
      path: '/test',
      component: TestView,
      name: 'TestView',
      exceptions: []
    },
    {
      path: '/',
      component: HomeView,
      name: 'HomeView',
      exceptions: []
    },
    {
      path: '/travel-agencies',
      component: TravelAgenciesView,
      name: 'TravelAgenciesView',
      exceptions: []
    },
    {
      path: '/travel-agencies/:id',
      component: TravelAgencyView,
      name: 'TravelAgencyView',
      exceptions: []
    },
    {
      path: '/search',
      component: SearchView,
      name: 'SearchView',
      exceptions: locales.filter(item => item !== defaultLocale)
    },
    {
      path: '/routes',
      component: RoutesView,
      name: 'RoutesView',
      exceptions: []
    },
    {
      path: '/routes/:id',
      component: RouteView,
      name: 'RouteView',
      exceptions: []
    },
    {
      path: '/places',
      component: PlacesView,
      name: 'PlacesView',
      exceptions: []
    },
    {
      path: '/places/:id',
      component: PlaceView,
      name: 'PlaceView',
      exceptions: []
    },
    {
      path: '/photobank',
      component: PhotoBankView,
      name: 'PhotoBankView',
      exceptions: []
    },
    {
      path: '/photobank/create',
      component: PhotoBankCreateView,
      name: 'PhotoBankCreateView',
      exceptions: []
    },
    {
      path: '/organizations',
      component: OrganizationsView,
      name: 'OrganizationsView',
      exceptions: []
    },
    {
      path: '/organizations/:id',
      component: OrganizationView,
      name: 'OrganizationView',
      exceptions: []
    },
    {
      path: '/news',
      component: NewsView,
      name: 'NewsView',
      exceptions: []
    },
    {
      path: '/news/:id',
      component: NewView,
      name: 'NewView',
      exceptions: []
    },
    {
      path: '/municipality/:slug',
      component: MunicipalityView,
      name: 'MunicipalityView',
      exceptions: []
    },
    // {
    //   path: '/karta-gostja',
    //   component: KartaGostjaView,
    //   name: 'KartaGostjaView',
    //   exceptions: []
    // },
    {
      path: '/journals',
      component: JournalsView,
      name: 'JournalsView',
      exceptions: []
    },
    {
      path: '/journals/:id',
      component: JournalView,
      name: 'JournalView',
      exceptions: []
    },
    {
      path: '/interactive-map',
      component: InteractiveMapView,
      name: 'InteractiveMapView',
      exceptions: []
    },
    {
      path: '/guides',
      component: GuidesView,
      name: 'GuidesView',
      exceptions: []
    },
    {
      path: '/guides/:id',
      component: GuideView,
      name: 'GuideView',
      exceptions: []
    },
    {
      path: '/excursions',
      component: ExcursionsView,
      name: 'ExcursionsView',
      exceptions: []
    },
    {
      path: '/excursions/:id',
      component: ExcursionView,
      name: 'ExcursionView',
      exceptions: []
    },
    {
      path: '/events',
      component: EventsView,
      name: 'EventsView',
      exceptions: []
    },
    {
      path: '/events/:id',
      component: EventView,
      name: 'EventView',
      exceptions: []
    },
    {
      path: '/documents/booking-rules',
      component: BookingRulesView,
      name: 'BookingRulesView',
      exceptions: []
    },
    {
      path: '/documents/contract-offer',
      component: ContractOfferView,
      name: 'ContractOfferView',
      exceptions: []
    },
    {
      path: '/documents/privacy-policy',
      component: PrivacyPolicyView,
      name: 'PrivacyPolicyView',
      exceptions: []
    },
    {
      path: '/documents/terms/booking/:entity/:entity_id',
      component: BookingTermsView,
      name: 'BookingTermsView',
      exceptions: []
    },
    {
      path: '/documents/terms/cancellation/:entity/:entity_id',
      component: CancellationTermsView,
      name: 'CancellationTermsView',
      exceptions: []
    },
    {
      path: '/documents/user-agreement',
      component: UserAgreementView,
      name: 'UserAgreementView',
      exceptions: []
    },
    {
      path: '/category-collections/:entity/:slug',
      component: CategoryCollectionsView,
      name: 'CategoryCollectionsView',
      exceptions: []
    },
    {
      path: '/articles',
      component: ArticlesView,
      name: 'ArticlesView',
      exceptions: []
    },
    {
      path: '/account',
      component: AccountView,
      name: 'AccountView',
      exceptions: []
    },
    {
      path: '/account/travels',
      component: AccountTravelsView,
      name: 'AccountTravelsView',
      exceptions: []
    },
    {
      path: '/account/travels/:id',
      component: AccountTravelView,
      name: 'AccountTravelView',
      exceptions: []
    },
    {
      path: '/account/my/orders',
      component: AccountMyOrdersView,
      name: 'AccountMyOrdersView',
      exceptions: []
    },
    {
      path: '/account/my/places',
      component: AccountMyPlacesView,
      name: 'AccountMyPlacesView',
      exceptions: []
    },
    {
      path: '/account/my/routes',
      component: AccountMyRoutesView,
      name: 'AccountMyRoutesView',
      exceptions: []
    },
    {
      path: '/account/favorites/events',
      component: AccountFavoritesEventsView,
      name: 'AccountFavoritesEventsView',
      exceptions: []
    },
    {
      path: '/account/favorites/excursions',
      component: AccountFavoritesExcursionsView,
      name: 'AccountFavoritesExcursionsView',
      exceptions: []
    },
    {
      path: '/account/favorites/journals',
      component: AccountFavoritesJournalsView,
      name: 'AccountFavoritesJournalsView',
      exceptions: []
    },
    {
      path: '/account/favorites/places',
      component: AccountFavoritesPlacesView,
      name: 'AccountFavoritesPlacesView',
      exceptions: []
    },
    {
      path: '/account/favorites/routes',
      component: AccountFavoritesRoutesView,
      name: 'AccountFavoritesRoutesView',
      exceptions: []
    },
    {
      path: '/account/agencies',
      component: AccountAgenciesView,
      name: 'AccountAgenciesView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/events',
      component: AccountAgencyEventsView,
      name: 'AccountAgencyEventsView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/events/:event_id',
      component: AccountAgencyEventView,
      name: 'AccountAgencyEventView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/excursions',
      component: AccountAgencyExcursionsView,
      name: 'AccountAgencyExcursionsView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/excursions/:excursion_id',
      component: AccountAgencyExcursionView,
      name: 'AccountAgencyExcursionView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/orders',
      component: AccountAgencyOrdersView,
      name: 'AccountAgencyOrdersView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/profile',
      component: AccountAgencyProfileView,
      name: 'AccountAgencyProfileView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/widgets',
      component: WidgetsView,
      name: 'WidgetsView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/widgets/create',
      component: WidgetCreateView,
      name: 'WidgetCreateView',
      exceptions: []
    },
    {
      path: '/account/agencies/:agency_id/widgets/edit/:widget_id',
      component: WidgetEditView,
      name: 'WidgetEditView',
      exceptions: []
    },
    {
      path: '/stories/:story_id',
      component: StoryView,
      name: 'StoryView',
      exceptions: []
    },
  ]

  let array: Route[] = []

  locales.forEach(locale => {
    array = [...array, ...routes.map(route => ({
      ...route,
      path: locale !== defaultLocale && !route.exceptions.includes(locale) ?
        `/${locale}${route.path}` :
        route.path,
      name: `${route.name}___${locale}`,
      exceptions: route.exceptions,
    }))]
  })

  return array
}
