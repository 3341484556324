//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'ImageComponent',
  props: {
    src: {
      type: String,
      default: () => null
    },
    alt: {
      type: String,
      default: () => ''
    },
    lazyload: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapGetters({
      resolutions: 'theme/resolutions'
    })
  },
  created () {
    if (this.src) {
      if (/micro|small|medium|large|extra?/gi.test(this.src)) {
        this.src.replace(/micro|small|medium|large|extra?/gi,
          this?.lazyload?.resolutions?.min || 'micro')
      }
    }
  },
  methods: {
    load (element) {
      if (/micro|small|medium|large|extra?/gi.test(this.src)) {
        if (element.target.src.match(/micro|small|medium|large|extra?/gi)) {
          const max = this.resolutions.indexOf(this?.lazyload?.resolutions?.max || 'medium')
          const current = this.resolutions.indexOf(element.target.src.match(/micro|small|medium|large|extra?/gi)[0])
          if (current < max) {
            if (element.target.src.match(/micro/i)) {
              element.target.src = element.target.src.replace(/micro/i, 'small')
              return true
            }
            if (element.target.src.match(/small/i)) {
              element.target.src = element.target.src.replace(/small/i,
                this?.lazyload?.resolutions?.max || 'medium')
              return true
            }
          }
        }
      }
    },
    error (element) {
      element.target.src = require('@/assets/images/default-image.svg')
    }
  }
}
