import { parser } from '@/utils/parser'

export const state = () => ({
  places: [],
  meta: null
})

export const mutations = {
  setPlaces: (state, places) => state.places = [...state.places, ...places],
  setMeta: (state, meta) => state.meta = meta,
  clearPlaces: (state) => {
    state.places = []
    state.meta = null
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload.clear) {
      commit('clearPlaces')
    }
    const places = await this.$axios.$get(`/api/places?${parser(payload)}&user=current`)
    commit('setPlaces', places.data)
    commit('setMeta', places.meta)
  }
}

export const getters = {
  places: state => state.places,
  meta: state => state.meta
}
