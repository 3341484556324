// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (context, inject) => {
  // function image (image, host) {
  //   if (image) {
  //     return image.replace(/micro|small|medium|large|extra?/gi, 'medium') + '?v=2'
  //   } else {
  //     return host + '/meta-image-default-small.jpg?v=2'
  //   }
  // }

  function createSEOMeta (data) {
    let meta = []
    meta = [...meta, {
      hid: 'description',
      name: 'description',
      content: data?.og?.description || ''
    }]
    for (const graph in data) {
      for (const key in data[graph]) {
        if (data[graph][key]) {
          meta = [...meta, {
            hid: `${graph}:${key}`,
            property: `${graph}:${key}`,
            content: data[graph][key]
          }]
        }
      }
    }
    return meta
  }

  inject('createSEOMeta', createSEOMeta)
}
