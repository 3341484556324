//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import accountAgencyProfileValidation from '@/middleware/account-agency-profile-validation'

// import seo from '@/middleware/seo'

export default {
  name: 'AccountAgencyEventsView',
  components: {
    Icon24FillPlus: () => import('@/assets/icons/Icon24FillPlus.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    PendingButton: () => import('@/components/common/PendingButton'),
    AgencyEventsCard: () => import('@/components/common/AgencyEventsCard'),
    Section: () => import('@/components/common/Section/index')
  },
  layout: 'personal-area-agency',
  // middleware: [accountAgencyProfileValidation, seo],
  middleware: [accountAgencyProfileValidation],
  data () {
    return {
      titles: [
        this.$t('pages.account-agency-events-view.appellation'),
        this.$t('pages.account-agency-events-view.city'),
        this.$t('pages.account-agency-events-view.status')
      ],
      count: 7,
      page: 1
    }
  },
  head () {
    return {
      title: this.$t('pages.account-agency-events.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      events: 'account-agency-events/events',
      meta: 'account-agency-events/meta',
      pending: 'account-agency-events/pending',
      logged: 'user/logged',
      addEventNotEmpty: 'add-event/notEmpty'
    }),
    url () {
      return this.$store.state.host + this?.$route?.fullPath
    },
    showButton () {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    params () {
      return {
        page: this.page,
        count: this.count,
        lang: this.$i18n.locale,
        show: 'id,title,city,status'
      }
    }
  },
  created () {
    this.$store.commit('layout-personal-area-agency/setButtonBackLink', '/account/agencies')
    this.$store.commit('layout-personal-area-agency/setButtonBackTitle', this.$t('layouts.personal-area-layout.link-my-organizations'))
  },
  mounted () {
    this.$store.dispatch('account-agency-events/fetch', {
      clear: true,
      id: this.$route.params.agency_id,
      params: this.params
    })
  },
  methods: {
    openPopup () {
      if (this.logged) {
        if(this.addEventNotEmpty) {
          this.$store.commit('popup/open', {
            name: 'PopupPreEntity',
            params: {
              entity: 'add-event',
              popup: 'PopupAddEvent',
              style: {
                width: this?.$media() === 'sm' ? '100%' : '632px',
                maxHeight: '100%'
              }
            }
          })
        } else this.$store.commit('popup/open', {
          name: 'PopupAddEvent',
          params: {
            style: {
              width: '632px',
              maxHeight: '100vh'
            }
          }
        })
      } else {
        this.$login()
      }
    },
    next () {
      if (this.meta.current_page < this.meta.last_page) {
        this.page += 1
        this.$store.dispatch('account-agency-events/fetch', {
          id: this.$route.params.agency_id,
          params: this.params
        })
      }
    }
  }
}
