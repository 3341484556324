//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "MobileMenu",
  components: {
    Divider: () => import("@/components/common/Divider"),
    HeaderNavigation: () => import('@/components/common/HeaderNavigation'),
    MobileMenuDivider: () => import('@/components/common/MobileMenuDivider'),
    MobileMenuBottom: () => import('@/components/common/MobileMenuBottom'),
  },
  data() {
    return {
      layout: null,
    }
  },
  computed: {
    ...mapGetters({
      open: 'mobile-menu/opened',
      components: 'mobile-menu/components',
      media: 'viewport/media',
    }),
  },
  watch: {
    layout() {
      this.$store.commit('mobile-menu/setOpened', false)
    },
    $router() {
      this.$store.commit('mobile-menu/setOpened', false)
    },
    media() {
      this.$store.commit('mobile-menu/setOpened', false)
    },
    open(n) {
      if (!process.server) {
        const body = document.querySelector('body');
        const app = document.querySelector('.app');
        if (n) {
          body.classList.add('body--disable-scroll')
          app.classList.add('body--disable-scroll')
        } else {
          body.classList.remove('body--disable-scroll')
          app.classList.remove('body--disable-scroll')
        }
      }
    }
  },
  mounted() {
    this.layout = this.$root.layoutName
  },
}
