//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PartnerCard",
  components: {
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    link: {
      type: String,
      default: () => '',
    },
    image: {
      type: String,
      default: () => '',
    },
    background: {
      type: String,
      default: () => '',
    },
    grayscale: {
      type: Boolean,
      default: () => true,
    }
  },
  computed: {
    style() {
      return {
        background: this.background,
      }
    },
    classList() {
      return [
        {'partner-card__image--grayscale': this.grayscale}
      ]
    }
  }
}
