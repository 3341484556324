//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapGetters} from "vuex";

export default {
  name: "PromoCode",
  components: {
    InputComponent: () => import("@/components/common/InputComponent"),
    Button: () => import('@/components/common/Button'),
  },
  computed: {
    ...mapGetters({
      code: 'code/code',
      pending: 'code/pending',
      errors: 'code/errors',
    })
  },
  methods: {
    submit() {
      this.$store.dispatch('code/post')
        .then(() => {
          this.$store.dispatch('user/whoAmI')
        })
    }
  }
}
