//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentPhone",
  components: {
    Icon24FillPhone: () => import('@/assets/icons/Icon24FillPhone.svg?inline'),
    LinkPhone: () => import('@/components/common/LinkPhone')
  },
  props: {
    phone: {
      type: String,
      default: () => ''
    }
  }
}
