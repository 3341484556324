//
//
//
//
//
//
//
//
//
//

export default {
  name: "DesktopNavigationIconSearch",
  components: {
    Icon24FillSearch: () => import('@/assets/icons/Icon24FillSearch.svg?inline'),
  },
}
