import { errors } from '@/utils/errors'
import { eventAppend } from '@/utils/event-append'
import defaultStore from '@/utils/event-default-store-config'
import {parser} from "~/utils/parser";

export const state = () => ({
  config: { headers: { 'Content-Type': 'multipart/form-data' } },
  errors: null,
  pending: false,
  tourism_type_id: null,
  tourism_types: [],
  category_id: null,
  agency_id: null,
  city_id: null,
  place_id: null,
  place: null,
  places: [],
  title: null,
  annotation: null,
  description: null,
  started_at: null,
  stopped_at: null,
  youtube: null,
  rutube: null,
  label: null,
  images: [],
  accessible_environment: false,
  edit: false,
  id: null,
  additions: []
})

export const mutations = {
  deleteErrorsByKey: (state, key) => state.errors = errors(key, state.errors),
  setTourismTypes: (state, tourism_types) => state.tourism_types = tourism_types,
  setAdditions: (state, additions) => {
    state.additions = additions
    state.errors = errors('additions', state.errors)
  },
  setEdit: (state, {
    edit,
    id
  }) => {
    state.edit = !!(edit || id)
  },
  setErrors: (state, errors) => state.errors = { ...state.errors, ...errors },
  setId: (state, id) => {
    state.id = id
  },
  setPending: (state, pending) => {
    state.pending = pending
  },
  setPlaces: (state, places) => {
    state.places = places
    state.errors = errors('places', state.errors)
  },
  setTitle: (state, title) => {
    state.title = title
    state.errors = errors('title', state.errors)
  },
  setAnnotation: (state, annotation) => {
    state.annotation = annotation
    state.errors = errors('annotation', state.errors)
  },
  setDescription: (state, description) => {
    state.description = description
    state.errors = errors('description', state.errors)
  },
  setCategoryId: (state, category_id) => {
    state.category_id = category_id
    state.errors = errors('category_id', state.errors)
    // todo выпилить это, еогда уберем badge_id из выдачи
    state.errors = errors('badge_id', state.errors)
  },
  setTourismTypeId: (state, tourism_type_id) => {
    state.tourism_type_id = tourism_type_id
  },
  setCityId: (state, city_id) => {
    state.city_id = city_id
    state.errors = errors('city_id', state.errors)
  },
  setLabel: (state, label) => {
    state.label = label
    state.errors = errors('label', state.errors)
  },
  setPlaceId: (state, place_id) => {
    state.place_id = place_id
    state.errors = errors('place_id', state.errors)
  },
  setPlace: (state, place) => {
    state.place = place
  },
  setImages: (state, images) => {
    state.images = images
    state.errors = errors('images', state.errors)
  },
  setStartedAt: (state, started_at) => {
    state.started_at = started_at
    state.errors = errors('started_at', state.errors)
  },
  setStoppedAt: (state, stopped_at) => {
    state.stopped_at = stopped_at
    state.errors = errors('stopped_at', state.errors)
  },
  setYoutube: (state, youtube) => {
    state.youtube = youtube
    state.errors = errors('youtube', state.errors)
  },
  setRutube: (state, rutube) => {
    state.rutube = rutube
    state.errors = errors('rutube', state.errors)
  },
  setAccessibleEnvironment: (state, accessible_environment) => {
    state.accessible_environment = accessible_environment
  },
  setAgencyId: (state, agency_id) => {
    state.agency_id = agency_id
  },
  clear: (state) => {
    for (const key in defaultStore) {
      state[key] = defaultStore[key]
    }
  }
}

export const actions = {
  async tourism_types ({ commit }, payload) {
    const tourism_types = await this.$axios.$get(`/api/tourism_types?${parser(payload)}`)
    commit('setTourismTypes', tourism_types.data)
  },
  async places ({ commit }, payload) {
    const places = await this.$axios.$get(`/api/places?${parser(payload)}`)
    commit('setPlaces', places.data)
  },
  async post ({state, commit}) {
    commit('setPending', true)
    const data = eventAppend(state)
    await this.$axios.$post('/api/events', data, state.config)
      .catch(errors => commit('setErrors', errors.response.data))
    commit('setPending', false)
  },
  async put ({state, commit}, payload) {
    commit('setPending', true)
    const data = eventAppend(state)
    await this.$axios.$post(`/api/events/${payload.id}`, data, state.config)
      .catch(errors => commit('setErrors', errors.response.data))
    commit('setPending', false)
  }
}

export const getters = {
  additions: state => state.additions,
  edit: state => state.edit,
  id: state => state.id,
  errors: state => state.errors,
  pending: state => state.pending,
  tourism_type_id: state => state.tourism_type_id,
  tourism_types: state => state.tourism_types,
  category_id: state => state.category_id,
  agency_id: state => state.agency_id,
  city_id: state => state.city_id,
  place_id: state => state.place_id,
  place: state => state.place,
  places: state => state.places,
  title: state => state.title,
  annotation: state => state.annotation,
  description: state => state.description,
  started_at: state => state.started_at,
  stopped_at: state => state.stopped_at,
  youtube: state => state.youtube,
  rutube: state => state.rutube,
  label: state => state.label,
  images: state => state.images,
  accessible_environment: state => state.accessible_environment,
  notEmpty: state => {
    return (state.title !== null && state.title?.length > 0) ||
      (state.annotation !== null && state.annotation?.length > 0) ||
      (state.description !== null && state.description?.length > 0) ||
      (state.label !== null && state.label?.length > 0) ||
      (state.youtube !== null && state.youtube?.length > 0) ||
      (state.rutube !== null && state.rutube?.length > 0) ||
      state.category_id !== null ||
      state.tourism_type_id !== null ||
      state.place_id !== null ||
      state.images.length > 0 ||
      state.additions.length > 0 ||
      state.accessible_environment ||
      state.started_at !== null ||
      state.stopped_at !== null
  }
}
