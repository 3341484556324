//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {parser} from "@/utils/parser";
import {featureNormalizer} from "@/utils/feature-normalizer";

export default {
  name: "SectionAgencyBranches",
  components: {
    EmptyEntity: () => import("@/components/common/EmptyEntity"),
    AgencyBranchCard: () => import("@/components/common/AgencyBranchCard"),
    VueCustomScrollbar: () => import("vue-custom-scrollbar"),
    OnMap: () => import("@/components/common/OnMap/index"),
    Section: () => import("@/components/common/Section/index"),
  },
  props: {
    id: {
      type: [Number, String],
      default: () => 0
    },
    showTitle: {
      type: Boolean,
      default: () => true,
    },
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    },
    private: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      show: false,
      branches: [],
      features: [],
    };
  },
  computed: {
    payload() {
      return this.private ? {
        params: {
          lang: this.$i18n.locale,
          resolution: "micro",
          user: "current"
        }
      } : {
        params: {
          lang: this.$i18n.locale,
          resolution: "micro",
        }
      }
    }
  },
  mounted() {
    if (this.id) {
      this.$axios.$get(`/api/places?agency_id=${this.id}&${parser(this.payload)}`)
        .then((response) => {
          this.branches = response.data;
          this.features = response.data.map((item) => {
            return featureNormalizer(item)
          })
        })
        .finally(() => {
          this.branches?.length > 0 ? this.show = true : this.show = false;
        });
    }
  }
};
