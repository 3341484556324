export const state = () => ({
  opened: false,
  components: ['HeaderNavigation', 'MobileMenuDivider', 'MobileMenuBottom'],
})

export const actions = {}

export const mutations = {
  setOpened: (state, opened) => state.opened = opened,
  setComponents: (state, components) => state.components = components,
}

export const getters = {
  opened: state => state.opened,
  components: state => state.components,
}
