//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AgencyEventPlaces',
  components: {
    RoundButton: () => import('@/components/common/RoundButton'),
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    AgencyPlacesCard: () => import('@/components/common/AgencyPlacesCard')
  },
  layout: 'personal-area-agency',
  data () {
    return {
      titles: [
        this.$t('components.agency-event-places.title-image'),
        this.$t('components.agency-event-places.title-name'),
        this.$t('components.agency-event-places.title-address'),
        this.$t('components.agency-event-places.title-rating'),
        this.$t('components.agency-event-places.title-status')
      ]
    }
  },
  computed: {
    ...mapGetters({
      event: 'account-agency-event/event'
    }),
    places () {
      if (this?.event?.place) {
        return [this?.event?.place]
      }
      return []
    }
  },
  methods: {
    openPopup () {
      this.$store.commit('popup/open', {
        name: 'PopupAddEvent',
        params: {
          style: {
            width: '632px',
            maxHeight: '100vh'
          }
        },
        options: this?.event
      })
    }
  }
}
