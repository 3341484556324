//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentCity",
  components: {
    Icon24FillCity: () => import('@/assets/icons/Icon24FillCity.svg?inline')
  },
  props: {
    city: {
      type: String,
      default: () => ''
    }
  }
}
