//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PseudoSelectComponent",
  props: {
    label: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    border: {
      type: String,
      default: () => '1px solid var(--main-color)'
    }
  }
}
