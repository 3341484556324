//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Status',
  props: {
    status: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    condition () {
      switch (this.status) {
        case 'none': {
          return this.$t('components.status.none')
        }
        case 'new': {
          return this.$t('components.status.new')
        }
        case 'paid': {
          return this.$t('components.status.paid')
        }
        case 'confirmed': {
          return this.$t('components.status.confirmed')
        }
        case 'canceled': {
          return this.$t('components.status.canceled')
        }
        case 'provided': {
          return this.$t('components.status.provided')
        }
        case 'closed': {
          return this.$t('components.status.closed')
        }
        case 'moderated': {
          return this.$t('components.status.moderated')
        }
        case 'edited': {
          return this.$t('components.status.edited')
        }
        case 'active': {
          return this.$t('components.status.active')
        }
        case 'available': {
          return this.$t('components.status.available')
        }
        default: {
          return this.$t('components.status.nonactive')
        }
      }
    }
  }
}
