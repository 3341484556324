export default ({ redirect }, inject) => {
  const changeRoute = (params) => {
    const query = {}
    for (const item in params) {
      query[item] = params[item]
    }
    redirect({ query })
  }
  inject('changeRoute', changeRoute)
}
