//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionBannerAppMobile",
  components: {
    Button: () => import("@/components/common/Button"),
    Section: () => import("@/components/common/Section")
  }
}
