//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TextareaComponent',
  components: {
    LabelComponent: () => import('@/components/common/LabelComponent'),
    ErrorComponent: () => import('@/components/common/ErrorComponent')
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: () => ''
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: String,
      default: () => ''
    },
    errors: {
      type: [Array, String],
      default: () => ''
    }
  },
  computed: {
    processedValue () {
      return this?.value?.replace(/(<br\s\/>|<br\/>|\n|\r)/g, ' ')
    }
  },
  mounted() {
    this.paste();
  },
  methods: {
    input (e) {
      this.$emit('input', e.target.value)
    },
    paste() {
      const element = document.getElementById(this.id);
      element.addEventListener("paste", (e) => {
        e.stopPropagation();
        e.preventDefault();
        const clipboardText = e.clipboardData.getData("text/plain");
        const string = clipboardText.replace(/\r?\n/g, "")
        if (document.queryCommandSupported("insertText")) {
          document.execCommand("insertText", false, string);
        } else {
          document.execCommand("paste", false, string);
        }
      });
    },
  }
}
