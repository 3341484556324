export default (context, inject) => {
  const site = {
    mask: 'https://Z*.Z*',
    tokens: {
      'Z': {
        pattern: /([a-z0-9_./-])/,
      }
    }
  }
  inject('maskaPatternSite', site)
}
