//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'


export default {
  name: 'RouteCard',
  components: {
    Icon24StrokeFavorites: () => import('@/assets/icons/Icon24StrokeFavorites.svg?inline'),
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    Icon24FillTrash: () => import('@/assets/icons/Icon24FillTrash.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    LabelTag: () => import('@/components/common/LabelTag'),
    Status: () => import('@/components/common/Status'),
    RatingTag: () => import('@/components/common/RatingTag'),
    ImageComponent: () => import('@/components/common/ImageComponent')
  },
  props: {
    travel: {
      type: Boolean,
      default: () => false
    },
    full: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    favorited: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    images: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      default: () => null,
    },
    rating: {
      type: Number,
      default: () => 0
    },
    status: {
      type: String,
      default: () => 'active'
    },
    category: {
      type: Object,
      default: () => null
    },
    editable: {
      type: Boolean,
      default: () => false
    },
    showStatus: {
      type: Boolean,
      default: () => false
    },
    route: {
      type: Object,
      default: () => null
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      innerFavorited: false
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    }),
    checkImage() {
      if (this.image) {
        return this.image
      } else if (!this.image && this.images.length > 0) {
        return this.images[0]
      }
      return require('@/assets/images/default-image.svg')
    },
    roundButtonSize() {
      switch (this?.$media()) {
        case 'hd': {
          return '38px'
        }
        default: {
          return '36px'
        }
      }
    },
    is() {
      return this.status === 'active' ? 'nuxt-link' : 'div'
    },
    to() {
      return this.status === 'active' ? this.localePath(this.link, this.$i18n.locale) : ''
    },
    link() {
      return `/${this?.entity}/${this?.id}`
    },
    categoryTitle() {
      return this?.category?.title
    }
  },
  created() {
    this.innerFavorited = this.favorited
  },
  methods: {
    toggleFavorite() {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited
            this.$store.dispatch('favorites/fetch', {
              entity: 'routes',
              params: {
                count: 100500,
                lang: this.$i18n.locale
              }
            })
          })
      } else {
        this.$login()
      }
    },
    openPopup(name) {
      this.$store.commit('popup/open', {
        name,
        params: {
          style: {
            width: this?.$media() === 'sm' ? '100%' : '632px',
            maxHeight: '100vh'
          }
        },
        options: this?.route
      })
    },
    remove() {
      this.$axios.$post(`api/routes/${this.id}/travels/${this.$route.params.id}/remove`)
        .then(() => {
          this.$store.dispatch('travel/fetch', {
            id: this.$route.params.id,
            params: {
              lang: this.$i18n.locale
            }
          })
        })
    }
  }
}
