//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import seasons from '@/middleware/seasons'
import age from '@/middleware/age'
import seo from '@/middleware/seo'
import placesHasEvents from '@/middleware/places-has-events'
import tourismTypes from '@/middleware/tourism-types'

export default {
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    DatePickerComponent: () => import('@/components/common/DatePickerComponent'),
    SelectCheckBoxComponent: () => import('@/components/common/SelectCheckBoxComponent'),
    SelectSearchComponent: () => import('@/components/common/SelectSearchComponent'),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    Button: () => import('@/components/common/Button/index'),
    EntityFiltersWrapper: () => import('@/components/common/EntityFiltersWrapper/index'),
    EventCard: () => import('@/components/common/EventCard/index'),
    InputComponent: () => import('@/components/common/InputComponent/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    SectionEmptyEntity: () => import('~/components/common/SectionEmptyEntity'),
    PendingButton: () => import('@/components/common/PendingButton/index'),
    Section: () => import('@/components/common/Section/index')
  },
  middleware: [seasons, age, seo, placesHasEvents, tourismTypes],
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      cities: 'cities/cities',
      categories: 'categories/events',
      events: 'pages/events/events',
      meta: 'pages/events/meta',
      pending: 'pages/events/pending',
      city_id: 'pages/events/city_id',
      page: 'pages/events/page',
      count: 'pages/events/count',
      query: 'pages/events/query',
      categoriesForCheckbox: 'pages/events/categoriesForCheckbox',
      dates: 'pages/events/dates',
      place_id: 'pages/events/place_id',
      age_restriction_id: 'pages/events/age_restriction_id',
      season_id: 'pages/events/season_id',
    }),
    components() {
      return this.$config?.pages?.EventsView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showTopTitleComponent() {
      return this.components?.TopTitleComponent
    },
    showEntityFiltersWrapper() {
      return this.components?.EntityFiltersWrapper
    },
    showEvents() {
      return this.components?.Events
    },
    showButton() {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    citiesWithAll() {
      return [{
        id: null,
        title: this.$t('pages.events-view.select-city-default')
      }, ...this.cities]
    },
    places() {
      return [{
        id: null,
        title: this.$t('pages.events-view.select-places-default')
      }, ...this.$store.getters['places/has_events']]
    },
    seasons() {
      return [{
        id: null,
        title: this.$t('pages.events-view.select-seasons-default')
      }, ...this.$store.getters['seasons/seasons']]
    },
    age_restrictions() {
      return [{
        id: null,
        title: this.$t('pages.events-view.select-age-default')
      }, ...this.$store.getters['age-restrictions/age_restrictions']]
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  created() {
    if (process.browser) {
      const query = this.$route.query
      if (query.slug) {
        const cityId = this?.cities?.find(item => item.slug === this.$route.query.slug)?.id
        this.$store.commit('pages/events/setCityId', cityId)
      } else {
        if (this.$config.singleCity) {
          this.$store.commit('pages/events/setCityId', this.$defaultCityId())
        } else {
          this.$store.commit('pages/events/setCityId', query.city_id)
        }
      }
      this.$store.commit('pages/events/setCategories', this.categories)
      this.$store.commit('pages/events/setCategoryIdFromString', query.category_id)
      this.$store.commit('pages/events/setStartedAt', query.started_at || this.$moment().format('YYYY-MM-DD')
      )
      this.$store.commit('pages/events/setStoppedAt', query.stopped_at)
      this.$store.commit('pages/events/setSeasonId', query.season_id)
      this.$store.commit('pages/events/setAgeRestrictionId', query.age_restriction_id)
      this.$store.commit('pages/events/setLang', this.$i18n.locale)
    }
  },
  mounted() {
    this.$store.dispatch('pages/events/events', {clear: true})
  },
  methods: {
    scrollToFilter() {
      this.$handleScrollTo('#filter', 100)
    },
    submitSearch() {
      this.$store.commit('pages/events/setPage', 1)
      this.$store.dispatch('pages/events/search', {clear: true})
    },
    next() {
      if (this.page) {
        this.$store.commit('pages/events/setPage', this.page + 1)
        this.$store.dispatch('pages/events/events')
      } else {
        this.$store.commit('pages/events/setPage', 1)
        this.$store.dispatch('pages/events/events', {clear: true})
      }
    },
    reset() {
      this.$router.replace({query: {}}).catch(() => null)
      this.$store.commit('pages/events/reset')
      if (this.$config.singleCity) {
        this.$store.commit('pages/events/setCityId', this.$defaultCityId())
      }
    },
    submit() {
      this.$store.commit('pages/events/setPage', 1)
      this.$store.dispatch('pages/events/events', {clear: true})
    },
    resetAndDispatch() {
      this.reset()
      this.$store.commit('pages/events/setStartedAt', this.$moment().format('YYYY-MM-DD'))
      this.submit()
    },
    changePlaceId(selected) {
      this.$store.commit('pages/events/setPage', null)
      this.$store.commit('pages/events/setPlaceId', selected.id)
      this.$instantReplaceQuery({place_id: selected.id})
    },
    changeDate(dates) {
      this.$store.commit('pages/events/setPage', null)
      this.$store.commit('pages/events/setDate', dates)
      this.$instantReplaceQuery({
        schedule_started_at: dates[0],
        schedule_stopped_at: dates[1],
      })
    },
    changeAgeRestrictionId(selected) {
      this.$store.commit('pages/events/setPage', null)
      this.$store.commit('pages/events/setAgeRestrictionId', selected.id)
      this.$instantReplaceQuery({age_restriction_id: selected.id})
    },
    changeSeasonsId(selected) {
      this.$store.commit('pages/events/setPage', null)
      this.$store.commit('pages/events/setSeasonId', selected.id)
      this.$instantReplaceQuery({season_id: selected.id})
    },
    changeCityId(selected) {
      this.$store.commit('pages/events/setPage', null)
      this.$store.commit('pages/events/setCityId', selected.id)
      if (!this.$config.singleCity) {
        this.$instantReplaceQuery({city_id: selected.id})
      }
    },
    changeCategoriesForCheckbox(categories) {
      this.$store.commit('pages/events/setPage', null)
      this.$store.commit('pages/events/setCategoriesForCheckbox', categories)
      this.$instantReplaceQuery({category_id: categories.filter(item => item.checked).map(item => item.id).join(',')})
    },
  }
}
