//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import municipality from '~/middleware/pages/municipality'
import config from "@/configs/pages.config"
const components = {}
for (const component in config.MunicipalityView.components) {
  const folder = config.MunicipalityView.components[component].special ? 'special' : 'common'
  components[component] = () => ({
    component: import(`@/components/${folder}/${component}/index.vue`),
  })
}

export default {
  name: 'MunicipalityView',
  middleware: [municipality],
  head() {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.title,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.$seoImage(this.image) || this.SEOImage,
          url: this.SEOUrl,
          locale: this.SEOLocale,
          site_name: this.SEOSiteName
        },
        vk: {
          image: this.$seoImage(this.image) || this.SEOImage,
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      title: 'municipality/title',
      image: 'municipality/image'
    })
  }
}
