import { parser } from '@/utils/parser'

export const state = () => ({
  galleries: [],
  indexId: null
})

export const mutations = {
  setGalleries: (state, galleries) => {
    state.indexId = galleries.find(item => item.is_index)?.id
    state.galleries = galleries
  },
  setMeta: (state, meta) => state.meta = meta
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload?.clear) {
      commit('clear')
    }
    const galleries = await this.$axios.$get(`/api/galleries?${parser(payload)}`)
    commit('setGalleries', galleries.data)
  }
}

export const getters = {
  galleries: state => state.galleries,
  indexId: state => state.indexId
}
