//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "PhotoUploaderForm",
  components: {
    InputComponentSite: () => import("@/components/common/InputComponentSite"),
    Button: () => import("@/components/common/Button"),
    TextareaComponent: () => import("@/components/common/TextareaComponent"),
    InputComponent: () => import("@/components/common/InputComponent")
  },
  props: {
    photo: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      toasterOpen: "toasters/opened"
    }),
    id() {
      return this.photo?.id;
    },
    title() {
      return this.photo?.title;
    },
    description() {
      return this.photo?.description;
    },
    image() {
      return this.photo?.image;
    },
    errors() {
      return this.photo?.errors;
    },
    pending() {
      return this.photo?.pending;
    },
    sourceLink() {
      return this.photo?.sourceLink;
    },
    sourceTitle() {
      return this.photo?.sourceTitle;
    }
  },
  mounted() {
    if (this.user && this.id) {
      this.$store.commit("add-photos/setSourceTitle", {
        id: this.id,
        sourceTitle: this.user?.name || ""
      });
    }
  },
  methods: {
    submit() {

      if (!this.$isSite(this.sourceLink) && this.sourceLink !== "") {
        this.$store.commit("add-photos/setErrors", {
          id: this.id,
          errors: {
            sourceLink: [
              this.$t("components.photo-uploader-form.error-site")
            ]
          }
        });
      } else {
        this.$store.dispatch("add-photos/submit", {
          id: this.id,
          title: this.title,
          description: this.description,
          image: this.image,
          sourceLink: this.sourceLink,
          sourceTitle: this.sourceTitle,
        }).then(() => {
          if (!this.errors) {
            if (!this.toasterOpen) {
              // Первично задает данные для обертки тостера
              this.$store.commit("toasters/open", {
                params: {
                  position: {
                    vertical: "bottom",
                    horizontal: "right"
                  },
                  size: {
                    maxWidth: "400px",
                  }
                },
              });
            }
            // Добавление тостов в тостер
            this.$store.commit("toasters/setToasters", {
              id: this.id,
              componentInfo: {
                componentName: "ToasterText",
                props: {
                  text: this.$t("components.toaster-text.text")
                }
              }
            });
          }
        });
      }
    },
    remove() {
      this.$store.commit("add-photos/remove", this.id);
    }
  }
};
