//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TopEntityComponent',
  components: {
    TopEntityComponentPlaceTitle: () => import("@/components/common/TopEntityComponent/TopEntityComponentPlaceTitle"),
    TopEntityComponentCountRoutes: () => import("@/components/common/TopEntityComponent/TopEntityComponentCountRoutes"),
    TopEntityComponentAccessibleEnvironment: () => import("@/components/common/TopEntityComponent/TopEntityComponentAccessibleEnvironment"),
    TopEntityComponentCountExcursion: () => import("@/components/common/TopEntityComponent/TopEntityComponentCountExcursion"),
    TopEntityComponentCountPlaces: () => import("@/components/common/TopEntityComponent/TopEntityComponentCountPlaces"),
    TopEntityComponentWorkHours: () => import("@/components/common/TopEntityComponent/TopEntityComponentWorkHours"),
    TopEntityComponentDuration: () => import("@/components/common/TopEntityComponent/TopEntityComponentDuration"),
    TopEntityComponentDistance: () => import("@/components/common/TopEntityComponent/TopEntityComponentDistance"),
    TopEntityComponentSeason: () => import("@/components/common/TopEntityComponent/TopEntityComponentSeason"),
    TopEntityComponentAge: () => import("@/components/common/TopEntityComponent/TopEntityComponentAge"),
    TopEntityComponentAddress: () => import("@/components/common/TopEntityComponent/TopEntityComponentAddress"),
    TopEntityComponentMail: () => import("@/components/common/TopEntityComponent/TopEntityComponentMail"),
    TopEntityComponentPhone: () => import("@/components/common/TopEntityComponent/TopEntityComponentPhone"),
    TopEntityComponentSite: () => import("@/components/common/TopEntityComponent/TopEntityComponentSite"),
    TopEntityComponentDates: () => import("@/components/common/TopEntityComponent/TopEntityComponentDates"),
    TopEntityComponentCity: () => import("@/components/common/TopEntityComponent/TopEntityComponentCity"),
    TopEntityComponentLanguages: () => import("@/components/common/TopEntityComponent/TopEntityComponentLanguages"),
    TopEntityComponentCalories: () => import("@/components/common/TopEntityComponent/TopEntityComponentCalories"),
    TopEntityComponentTimes: () => import("@/components/common/TopEntityComponent/TopEntityComponentTimes"),
    TopEntityComponentLabel: () => import("@/components/common/TopEntityComponent/TopEntityComponentLabel"),
    TopEntityComponentSlider: () => import("@/components/common/TopEntityComponent/TopEntityComponentSlider"),
    SocialsSquaredComponent: () => import('@/components/common/SocialsSquaredComponent'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar')
  },
  props: {
    // Название места
    placeTitle: {
      type: String,
      default: () => ''
    },
    // Доступная среда
    accessibleEnvironment: {
      type: Boolean,
      default: () => false
    },
    // Категория
    calories: {
      type: [Number, String],
      default: () => ''
    },
    // Языки
    languages: {
      type: Array,
      default: () => []
    },
    // Сезонность
    season: {
      type: String,
      default: () => ''
    },
    // Сложность
    level: {
      type: String,
      default: () => ''
    },
    // Возрастное ограничение
    age: {
      type: Number,
      default: () => 0
    },
    // Фоновое изображение
    images: {
      type: Array,
      default: () => [require('@/assets/images/default-image.svg')]
    },
    // Адрес
    address: {
      type: String,
      default: () => ''
    },
    // Город
    city: {
      type: String,
      default: () => ''
    },
    // Часы работы
    workHours: {
      type: Object,
      default: () => null
    },
    // Электронная почта
    mail: {
      type: String,
      default: () => ''
    },
    // Сайт
    site: {
      type: String,
      default: () => ''
    },
    // Телефон
    phone: {
      type: String,
      default: () => ''
    },
    // Продолжительность
    duration: {
      type: Number,
      default: () => 0
    },
    // Дистанция
    distance: {
      type: Number,
      default: () => 0
    },
    // Социальные сети
    socials: {
      type: [Object, Array],
      default: () => []
    },
    // Кол-во маршрутов
    countRoutes: {
      type: Number,
      default: () => 0
    },
    // Кол-во мест
    countPlaces: {
      type: Number,
      default: () => 0
    },
    // Кол-во экскурсий
    countExcursions: {
      type: Number,
      default: () => 0
    },
    // Дата события
    dates: {
      type: String,
      default: () => ''
    },
    // Время события
    times: {
      type: String,
      default: () => ''
    },
    // Лейбл
    label: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    showRating() {
      return this.$slots['top-entity-rating']
    },
    showMeta() {
      return this.$slots['top-entity-meta']
    },
    showTicketButton() {
      return this.$slots['top-entity-ticket-button']
    },
    showSocials() {
      return !Array.isArray(this.socials)
    }
  }
}
