//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Hint',
  props: {
    position: {
      type: String,
      default: () => 'bottom'
    },
    tail: {
      type: String,
      default: () => 'middle'
    },
    color: {
      type: String,
      default: () => 'var(--black)'
    },
    background: {
      type: String,
      default: () => 'var(--white)'
    },
    // click, outside, hover
    mechanics: {
      type: String,
      default: () => ''
    },
    hide: {
      type: Boolean,
      default: () => false
    },
    nowrap: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      width: 'auto',
      wrapper: null
    }
  },
  computed: {
    style () {
      return {
        background: this?.background,
        color: this?.color
      }
    }
  },
  mounted () {
    if (this.$refs.content.clientWidth > 600) {
      this.width = '600px'
      this.$refs.content.style.whiteSpace = 'normal'
    } else {
      this.width = 'auto'
    }
    this.wrapper = this.$refs.wrapper.style
    if (this.mechanics === 'click' || this.mechanics === 'outside') {
      if (!this.hide) {
        this.wrapper.opacity = 1
        this.wrapper.cursor = 'pointer'
        this.wrapper.pointerEvents = 'auto'
      }
    }
  },
  methods: {
    closeOnClick () {
      if (this.mechanics === 'click' && !this.hide) {
        this.wrapper.opacity = 0
      }
    },
    closeOnOutside () {
      if (this.mechanics === 'outside' && !this.hide) {
        this.wrapper.opacity = 0
      }
    }
  }
}
