//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import tourismTypes from '@/middleware/tourism-types'
import pagePlaces from '@/middleware/page-places'
import seo from '@/middleware/seo'

export default {
  name: 'PlacesView',
  components: {
    SelectCheckBoxComponent: () => import('@/components/common/SelectCheckBoxComponent'),
    SelectSearchComponent: () => import('@/components/common/SelectSearchComponent'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline'),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    Button: () => import('@/components/common/Button/index'),
    EntityFiltersWrapper: () => import('@/components/common/EntityFiltersWrapper/index'),
    FilterToggle: () => import('@/components/common/FilterToggle/index'),
    InputComponent: () => import('@/components/common/InputComponent/index'),
    SectionBannerAppMobile: () => import('@/components/common/SectionBannerAppMobile/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    SectionEmptyEntity: () => import('~/components/common/SectionEmptyEntity'),
    PendingButton: () => import('@/components/common/PendingButton/index'),
    PlaceCard: () => import('@/components/common/PlaceCard/index'),
    RecommendationCard: () => import('@/components/common/RecommendationCard/index'),
    Scroller: () => import('@/components/common/Scroller/index'),
    Section: () => import('@/components/common/Section/index'),
    Toggle: () => import('@/components/common/Toggle/index')
  },
  middleware: [tourismTypes, seo, pagePlaces],
  data() {
    return {
      sortTypes: [
        {
          id: 'avg',
          text: this.$t('pages.places-view.toggle-sort-avg')
        },
        {
          id: 'created_at',
          text: this.$t('pages.places-view.toggle-sort-created-at')
        }
      ]
    }
  },
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      cities: 'cities/cities',
      city: 'cities/default',
      categories: 'categories/places',
      meta: 'pages/places/meta',
      places: 'pages/places/places',
      pending: 'pages/places/pending',
      guidebook: 'journals/guidebook',
      city_id: 'pages/places/city_id',
      page: 'pages/places/page',
      count: 'pages/places/count',
      query: 'pages/places/query',
      categoriesForCheckbox: 'pages/places/categoriesForCheckbox',
      sort: 'pages/places/sort',
      tourism_type_id: 'pages/places/tourism_type_id',
      promo: 'pages/places/promo',
      open_now: 'pages/places/open_now',
      accessible_environment: 'pages/places/accessible_environment',
    }),
    components() {
      return this.$config?.pages?.PlacesView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showTopTitleComponent() {
      return this.components?.TopTitleComponent
    },
    showEntityFiltersWrapper() {
      return this.components?.EntityFiltersWrapper
    },
    showPlaces() {
      return this.components?.Places
    },
    showRecommendationCard() {
      return this.components?.RecommendationCard
    },
    route() {
      return `/interactive-map?city_id=${this.city_id || this.city.id}&entity=places&lang=${this.$i18n.locale}`
    },
    showButton() {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    filteredArticles() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return this?.guidebook?.filter((item, index) => index < 4)
    },
    citiesWithAll() {
      return [{
        id: null,
        title: this.$t('pages.places-view.select-cities-default')
      }, ...this.cities]
    },
    tourism_types() {
      return [{
        id: null,
        title: this.$t('pages.places-view.select-tourism-default')
      }, ...this.$store.getters['tourism-types/tourism_types']]
    },
  },
  created() {
    if (process.browser) {
      const query = this.$route.query
      if (query.slug) {
        const cityId = this?.cities?.find(item => item.slug === this.$route.query.slug)?.id
        this.$store.commit('pages/places/setCityId', cityId)
      } else {
        if (this.$config.singleCity) {
          this.$store.commit('pages/places/setCityId', this.$defaultCityId())
        } else {
          this.$store.commit('pages/places/setCityId', query.city_id)
        }
      }
      this.$store.commit('pages/places/setTourismTypeId', query.tourism_type_id)
      this.$store.commit('pages/places/setCategories', this.categories)
      this.$store.commit('pages/places/setCategoryIdFromString', query.category_id)
      this.$store.commit('pages/places/setAccessibleEnvironment', query.accessible_environment)
      this.$store.commit('pages/places/setPromo', query.promo)
      this.$store.commit('pages/places/setSort', query.sort)
      this.$store.commit('pages/places/setLang', this.$i18n.locale)
    }
  },
  mounted() {
    this.$store.dispatch('pages/places/places', {clear: true})
  },
  methods: {
    scrollToFilter() {
      this.$handleScrollTo('#filter', 100)
    },
    submitSearch() {
      this.$store.commit('pages/places/setPage', 1)
      this.$store.dispatch('pages/places/search', {clear: true})
    },
    next() {
      if (this.page) {
        this.$store.commit('pages/places/setPage', this.page + 1)
        this.$store.dispatch('pages/places/places')
      } else {
        this.$store.commit('pages/places/setPage', 1)
        this.$store.dispatch('pages/places/places', {clear: true})
      }
    },
    changeOpenNow(open_now) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setOpenNow', open_now)
      this.$instantReplaceQuery({open_now})
    },
    changeCategoriesForCheckbox(categories) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setCategoriesForCheckbox', categories)
      this.$instantReplaceQuery({category_id: categories.filter(item => item.checked).map(item => item.id).join(',')})
    },
    changeCityId(selected) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setCityId', selected.id)
      if (!this.$config.singleCity) {
        this.$instantReplaceQuery({city_id: selected.id})
      }
    },
    changeAccessibleEnvironment(accessible_environment) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setAccessibleEnvironment', accessible_environment)
      this.$instantReplaceQuery({accessible_environment})
    },
    changeSelectedTypeTourism(selected) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setTourismTypeId', selected.id)
      this.$instantReplaceQuery({tourism_type_id: selected.id})
    },
    changePromo(promo) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setPromo', promo)
      this.$instantReplaceQuery({promo})
    },
    changeSelectedMunicipality(selected) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setCityId', selected.id)
      this.$instantReplaceQuery({city_id: selected.id})
    },
    changeSort(sort) {
      this.$store.commit('pages/places/setPage', null)
      this.$store.commit('pages/places/setSort', sort)
      this.$instantReplaceQuery({sort})
    },
    submit() {
      this.$store.commit('pages/places/setPage', 1)
      this.$store.dispatch('pages/places/places', {clear: true})
    },
    reset() {
      this.$router.replace({query: {}}).catch(() => null)
      this.$store.commit('pages/places/reset')
      if (this.$config.singleCity) {
        this.$store.commit('pages/places/setCityId', this.$defaultCityId())
      }
    },
    resetAndDispatch() {
      this.reset()
      this.submit()
    },
  }
}
