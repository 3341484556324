//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import seo from '@/middleware/seo'

export default {
  name: 'PhotoBankView',
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    Icon24FillPlus: () => import('@/assets/icons/Icon24FillPlus.svg?inline'),
    PendingButton: () => import('@/components/common/PendingButton/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    PhotoBankCard: () => import('@/components/common/PhotoBankCard/index'),
    Section: () => import('@/components/common/Section/index'),
    Button: () => import('@/components/common/Button/index')
  },
  middleware: [seo],
  data () {
    return {
      count: 9,
      page: 1,
      show: false
    }
  },
  head () {
    return {
      title: 'Туристический путеводитель Мурманской области',
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: 'Туристический путеводитель Мурманской области',
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      meta: 'photos/meta',
      photos: 'photos/photos',
      pending: 'photos/pending',
      logged: 'user/logged'
    }),
    params () {
      return {
        count: this.count,
        page: this.page,
        resolution: 'micro',
        lang: this.$i18n.locale
      }
    },
    showButton () {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    url () {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  mounted () {
    this.$store.dispatch('photos/photos', {
      clear: true,
      params: this.params
    })
  },
  methods: {
    viewSlider (id) {
      this.$store.commit('popup/open', {
        name: 'PopupFullSizeSlider',
        options: {
          list: this.photos,
          active: id
        }
      })
    },
    next () {
      this.page += 1
      this.$store.dispatch('photos/photos', {
        params: this.params
      })
    },
    link () {
      if (this.logged) {
        this.$router.push(this.$i18n.locale === 'ru' ? '/photobank/create' : `/${this.$i18n.locale}/photos/create`)
      } else {
        this.$login()
      }
    }
  }
}
