import { parser } from '@/utils/parser'

export const state = () => ({
  travel: null,
  routes: [],
  excursions: [],
  events: [],
  places: []
})

export const mutations = {
  setTravel: (state, travel) => {
    state.travel = travel
    state.trips = travel.trips
    state.routes = travel.routes
    state.excursions = travel.excursions
    state.events = travel.events
    state.places = travel.places
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    const travel = await this.$axios.$get(`/api/travels/${payload.id}?user${parser(payload)}`)
    commit('setTravel', travel.data)
  }
}

export const getters = {
  travel: state => state.travel,
  routes: state => state.routes,
  excursions: state => state.excursions,
  events: state => state.events,
  places: state => state.places
}
