//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentCountExcursion",
  components: {
    Icon24FillExcursion: () => import('@/assets/icons/Icon24FillExcursion.svg?inline')
  },
  props: {
    countExcursions: {
      type: Number,
      default: () => 0
    }
  }
}
