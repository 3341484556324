//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import accountAgencyWidget from '@/middleware/account-agency-widget'
import accountAgencyProfileValidation from "@/middleware/account-agency-profile-validation";
import InputComponentSite from "~/components/common/InputComponentSite";

export default {
  name: 'WidgetEditView',
  components: {
    InputComponentSite,
    CodeViewComponent: () => import("@/components/common/CodeViewComponent"),
    ColorPickerComponent: () => import("@/components/common/ColorPickerComponent"),
    Button: () => import("~/components/common/Button"),
    WidgetPreview: () => import("~/components/common/WidgetPreview"),
    SmallTabs: () => import("~/components/common/SmallTabs"),
    SelectCheckBoxComponent: () => import("~/components/common/SelectCheckBoxComponent"),
    InputComponent: () => import("~/components/common/InputComponent"),
    Section: () => import("@/components/common/Section")
  },
  layout: 'personal-area-agency',
  middleware: [accountAgencyProfileValidation, accountAgencyWidget],
  data() {
    return {
      pending: false,
      src: `${this.host}/widget-logo.svg`,
      tabs: [
        this.$t('pages.widget-edit-view.tab-preview'),
        this.$t('pages.widget-edit-view.tab-code'),
      ]
    }
  },
  computed: {
    ...mapGetters({
      host: 'host',
      title: 'add-widget/title',
      website: 'add-widget/website',
      background: 'add-widget/background',
      main: 'add-widget/main',
      textMainColor: 'add-widget/textMainColor',
      textButtonColor: 'add-widget/textButtonColor',
      array: 'add-widget/array',
      widget: 'widget/widget',
      agencies: 'user/agencies',
      errors: 'add-widget/errors',
    }),
    locale() {
      return this.$i18n.locale !== 'ru' ? `/${this.$i18n.locale}` : ''
    },
    code() {
      const id = this.$route.params.widget_id || this.id
      const host = this.host
      return `<div id="tourism-widget-wrapper" data-id="${id}"></div>
<script src="${host}/app.js"/>`
    }
  },
  created() {
    this.$store.commit('layout-personal-area-agency/setButtonBackLink', '/account/agencies')
    this.$store.commit('layout-personal-area-agency/setButtonBackTitle', this.$t('layouts.personal-area-layout.link-my-organizations'))
  },
  mounted() {
    this.$store.commit('add-widget/setTitle', this.widget.title || '')
    this.$store.commit('add-widget/setWebsite', this.widget.website || '')
    const agency_id = this?.widget?.widget_data?.agency_id
    const array = this.agencies
      .filter(item => item.status === 'active')
      .map(item => ({
        id: item.id,
        title: item.title,
        checked: agency_id.includes(item.id)
      }))
    if (agency_id?.length > 0) {
      for (let i = 0; i < agency_id?.length; i++) {
        for (let j = 0; j < array?.length; j++) {
          if (array[j].id === agency_id[i]) {
            array[j].checked = true
          }
        }
      }
    }
    this.$store.commit('add-widget/setArray', array)
    this.src = this.widget.widget_data.logotype.src || ''
    this.$store.commit('add-widget/setBackground', this?.widget?.widget_data?.style?.background)
    this.$store.commit('add-widget/setMain', this?.widget?.widget_data?.style?.main)
    this.$store.commit('add-widget/setTextButtonColor', this?.widget?.widget_data?.style?.textButtonColor)
    this.$store.commit('add-widget/setTextMainColor', this?.widget?.widget_data?.style?.textMainColor)
  },
  methods: {
    changeWebsite(website) {
      this.$store.commit('add-widget/setWebsite', website)
      if (website.trim().length > 0) {
        if(!this.$isSite(website)) {
          this.$store.commit('add-widget/setErrors', {
            website: [this.$t('pages.widget-create-view.error-website')]
          })
        }
      }
    },
    changeMain(main) {
      this.main = main
    },
    changeArray(array) {
      this.array = array
    },
    cancel() {
      this.$router.push(`${this.locale}/account/agencies/${this.$route.params.agency_id}/widgets`)
    },
    submit() {
      this.pending = true
      this.$store.dispatch('add-widget/put', {
        id: this.$route.params.widget_id,
      })
        .then(() => {
          if (!this.errors) {
            this.$router.push(`${this.locale}/account/agencies/${this.$route.params.agency_id}/widgets`)
          } else {
            this.pending = false
            this.$handleScrollTo('#title', 150)
          }
        })
    }
  },
}
