import {parser} from "~/utils/parser";

export const state = () => ({
  widget: null,
  pending: false,
})

export const mutations = {
  clear: (state) => state.widget = null,
  setWidget: (state, widget) => state.widget = widget,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async fetch({commit}, payload) {
    commit('setPending', true)
    if (payload.clear) {
      commit('clear')
    }
    await this.$axios.get(`/api/widgets/${payload.id}?${parser(payload)}`)
      .then(response => commit('setWidget', response?.data?.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}
export const getters = {
  widget: state => state.widget,
  logotype: state => state.widget?.widget_data?.logotype,
  style: state => state.widget?.widget_data?.style,
  pending: state => state.pending
}
