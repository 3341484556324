import { parser } from '@/utils/parser'

export const state = () => ({
  excursion: null,
  pending: false,
  schedules: [],
  schedulesPending: false
})

export const mutations = {
  setSchedules: (state, schedules) => state.schedules = schedules,
  setSchedulesPending: (state, pending) => state.schedulesPending = pending,
  clearSchedules: state => state.schedules = [],

  setExcursion: (state, excursion) => state.excursion = excursion,
  setExcursionPending: (state, pending) => state.schedulesPending = pending,
  clearExcursion: state => state.excursion = null
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setExcursionPending', true)
    if (payload.clear) {
      commit('clearExcursion')
    }
    const excursion = await this.$axios.$get(`/api/excursions/${payload.id}?${parser(payload)}&user=current`)
    commit('setExcursion', excursion.data)
    commit('setExcursionPending', false)
  },
  async schedules ({ commit }, payload) {
    commit('setSchedulesPending', true)
    if (payload.clear) {
      commit('clearSchedules')
    }
    const schedules = await this.$axios.$get(`/api/excursions/${payload.id}/schedules?${parser(payload)}&user=current`)
    commit('setSchedules', schedules.data)
    commit('setSchedulesPending', false)
  }
}

export const getters = {
  excursion: state => state.excursion,
  pending: state => state.pending,
  schedules: state => state.schedules,
  schedulesPending: state => state.schedulesPending
}
