export const scheduleAppend = (state) => {
  const data = new FormData()
  if (state.started_at) {
    data.append('started_at', state.started_at)
  }
  if (state.stopped_at) {
    data.append('stopped_at', state.stopped_at)
  }
  if (state.time) {
    data.append('time', state.time + ':00')
  }
  if (state.tariff) {
    data.append('tariff_title', state.tariff)
  }
  if (state.quota >= 0) {
    data.append('quota', state.quota)
  }
  if (state.price >= 0) {
    data.append('price', state.price)
  }
  if (state.days.length > 0) {
    data.append('days', JSON.stringify(state.days.map(day => day + 1)))
  }
  if (state.edit) {
    data.append('_method', 'PUT')
  }
  if (state.id) {
    data.append('agency_id', state.id)
  }
  data.append('max_bonus_points', state.max_bonus_points || 0)
  return data
}
