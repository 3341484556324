var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-item-from"},[_c('img',{staticClass:"photo-item-from__image",attrs:{"src":_vm.image,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"photo-item-from__inputs"},[_c('InputComponent',{staticClass:"mb-16",attrs:{"id":("title-" + _vm.id),"value":_vm.title,"label":_vm.$t('components.photo-uploader-form.input-title-label'),"placeholder":_vm.$t('components.photo-uploader-form.input-title-placeholder'),"disabled":_vm.pending,"errors":_vm.errors && _vm.errors.title},on:{"input":function (e) { return _vm.$store.commit('add-photos/setTitle', {
        id: _vm.id,
        title: e
      }); }}}),_vm._v(" "),_c('TextareaComponent',{staticClass:"mb-16",attrs:{"id":("description-" + _vm.id),"value":_vm.description,"label":_vm.$t('components.photo-uploader-form.input-description-label'),"placeholder":_vm.$t('components.photo-uploader-form.input-description-placeholder'),"disabled":_vm.pending,"errors":_vm.errors && _vm.errors.description},on:{"input":function (e) { return _vm.$store.commit('add-photos/setDescription', {
        id: _vm.id,
        description: e
      }); }}}),_vm._v(" "),_c('InputComponent',{staticClass:"mb-16",attrs:{"id":("sourceTitle-" + _vm.id),"value":_vm.sourceTitle,"label":_vm.$t('components.photo-uploader-form.input-name-label'),"placeholder":_vm.$t('components.photo-uploader-form.input-name-placeholder'),"disabled":_vm.pending,"errors":_vm.errors && _vm.errors.sourceTitle},on:{"input":function (e) { return _vm.$store.commit('add-photos/setSourceTitle', {
        id: _vm.id,
        sourceTitle: e
      }); }}}),_vm._v(" "),_c('InputComponentSite',{staticClass:"mb-16",attrs:{"id":("sourceLink-" + _vm.id),"value":_vm.sourceLink,"label":_vm.$t('components.photo-uploader-form.input-link-label'),"placeholder":_vm.$t('components.photo-uploader-form.input-link-placeholder'),"disabled":_vm.pending,"errors":_vm.errors && _vm.errors.sourceLink},on:{"input":function (e) { return _vm.$store.commit('add-photos/setSourceLink', {
        id: _vm.id,
        sourceLink: e
      }); }}}),_vm._v(" "),_c('div',{staticClass:"photo-item-from__buttons"},[_c('Button',{attrs:{"disabled":_vm.pending,"background":_vm.$colors.mainLightColor,"color":_vm.$colors.darkColor},nativeOn:{"click":function($event){return _vm.remove.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm.$t("components.photo-uploader-form.buttons-remove"))+"\n      ")]),_vm._v(" "),_c('Button',{attrs:{"disabled":_vm.pending,"background":_vm.$colors.darkColor},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm.$t("components.photo-uploader-form.buttons-submit"))+"\n      ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }