//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import seo from '@/middleware/seo'

export default {
  name: 'GuidesView',
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    GuideCard: () => import('@/components/common/GuideCard/index'),
    Section: () => import('@/components/common/Section/index'),
    PendingButton: () => import('@/components/common/PendingButton/index')
  },
  middleware: [seo],
  data () {
    return {
      count: 8,
      page: 1,
      pending: false
    }
  },
  head () {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      meta: 'guides/meta',
      guides: 'guides/guides',
      city: 'cities/default',
    }),
    components() {
      return this.$config?.pages?.GuidesView?.components
    },
    showSectionBannerAppMobile () {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showButton () {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    params () {
      return {
        city_id: this.$config.singleCity ? this.city.id : null,
        resolution: 'micro',
        count: this.count,
        page: this.page,
        lang: this.$i18n.locale
      }
    },
    url () {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  mounted () {
    this.$store.dispatch('guides/fetch', {
      clear: true,
      params: this.params
    })
  },
  methods: {
    async next () {
      if (this.meta.current_page < this.meta.last_page) {
        this.page += 1
        let string = '?'
        for (const key in this.params) {
          if (this.params[key]) {
            string += `&${key}=${this.params[key]}`
          }
        }
        this.pending = true
        // eslint-disable-next-line no-unused-vars
        await this.$axios.$get(`/api/guides${string}`)
          .then((resolve) => {
            this.$store.commit('guides/setGuides', resolve.data)
            this.$store.commit('guides/setMeta', resolve.meta)
            this.pending = false
          })
      }
    }
  }
}
