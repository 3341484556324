import {errors} from "~/utils/errors";

export const state = () => ({
  title: null,
  href: null,
  src: null,
  website: null,
  background: '#C2F0FA',
  main: '#5ED7F2',
  textButtonColor: '#133647',
  textMainColor: '#222222',
  array: [],
  pending: false,
  errors: {},
})

export const mutations = {
  clear: state => {
    state.title = null
    state.href = null
    state.src = null
    state.website = null
    state.background = '#C2F0FA'
    state.main = '#5ED7F2'
    state.textButtonColor = '#133647'
    state.textMainColor = '#222222'
    state.array = []
    state.pending = false
  },
  setTitle: (state, title) => {
    state.errors = errors('title', state.errors)
    state.title = title
  },
  setHref: (state, href) => {
    state.href = href
  },
  setSrc: (state, src) => {
    state.src = src
  },
  setWebsite: (state, website) => {
    state.errors = errors('website', state.errors)
    state.website = website
  },
  setBackground: (state, background) => {
    state.background = background
  },
  setMain: (state, main) => {
    state.main = main
  },
  setTextButtonColor: (state, textButtonColor) => {
    state.textButtonColor = textButtonColor
  },
  setTextMainColor: (state, textMainColor) => {
    state.textMainColor = textMainColor
  },
  setArray: (state, array) => {
    state.array = array
  },
  setPending: (state, pending) => {
    state.pending = pending
  },
  setErrors: (state, errors) => state.errors = { ...state.errors, ...errors },
}

export const actions = {
  async post({state, commit}) {
    commit('setPending', true)
    const agency_id = state.array.filter(item => item.checked === true).map(item => item.id)
    await this.$axios.post('/api/widgets', {
      title: state.title,
      website: state.website,
      widget_data: JSON.stringify({
        logotype: {
          src: state.src,
          title: state.title,
          href: state.href
        },
        style: {
          background: state.background,
          main: state.main,
          textButtonColor: state.textButtonColor,
          textMainColor: state.textMainColor
        },
        agency_id
      })
    })
      .then()
      .catch(errors => commit('setErrors', errors.response.data))
      .finally(() => commit('setPending', false))
  },
  async put({state, commit}, payload) {
    commit('setPending', true)
    const agency_id = state.array.filter(item => item.checked === true).map(item => item.id)
    await this.$axios.put(`/api/widgets/${payload.id}`, {
      title: state.title,
      website: state.website,
      widget_data: JSON.stringify({
        logotype: {
          src: state.src,
          title: state.title,
          href: state.href
        },
        style: {
          background: state.background,
          main: state.main,
          textButtonColor: state.textButtonColor,
          textMainColor: state.textMainColor
        },
        agency_id
      })
    })
      .then()
      .catch(errors => commit('setErrors', errors.response.data))
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  title: state => state.title,
  href: state => state.href,
  src: state => state.src,
  website: state => state.website,
  background: state => state.background,
  main: state => state.main,
  textButtonColor: state => state.textButtonColor,
  textMainColor: state => state.textMainColor,
  array: state => state.array,
  errors: state => state.errors,
  pending: state => state.pending,
}
