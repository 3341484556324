//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Toast",
  components: {
    Icon24FillClose: () => import('@/assets/icons/Icon24FillClose.svg?inline'),
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    time: {
      type: Number,
      default: () => 5 // Длительность таймера
    },
    status: {
      type: String,
      default: () => 'success' // Цвет картоки по статусам: 'success', 'danger', 'info', 'warning'
    },
    timerToggle: {
      type: Boolean,
      default: () => true // Вкл/Выкл работы таймера
    },
    componentInfo: {
      type: Object,
      default: () => ({
        componentName: '', // Имя встраиваемого компонента
        props: {} // Проперти компонента
      }),
    }
  },
  data() {
    return {
      show: false,
      timer: null,
      currentTime: 5,
      currentToast: 0,
      statuses: ['success', 'danger', 'info', 'warning']
    }
  },
  computed: {
    progress() {
      return {
        width: this.timerToggle ? `${100 / this.time * this.currentTime}%` : '100%'
      }
    },
    progressColor() {
      return this.statuses.includes(this.status) ? `toast__progress--${this.status}` : ''
    },
    backgroundColor() {
      return this.statuses.includes(this.status) ? `toast--${this.status}` : ''
    }
  },
  watch: {
    currentTime(n) {
      if (n === -1) {
        this.currentTime = this.time
        this.closeToaster()
      }
    }
  },
  mounted() {
    this.show = true
    this.currentToast = this.id
    if(this.timerToggle) {
      this.currentTime = this.time
      this.startTimer()
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    closeToaster() {
      if(this.timerToggle) {
        this.stopTimer()
      }
      this.show = false
      this.$store.commit('toasters/setDeleteToaster', this.id)
    }
  }
};
