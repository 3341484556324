//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorComponent',
  props: {
    errors: {
      type: [Array, String],
      default: () => ''
    },
    errorsEmpty: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    error () {
      return Array.isArray(this.errors) ? this.errors[0] : this.errors
    }
  }
}
