//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {parser} from "@/utils/parser";

export default {
  name: "AgenciesGrid",
  components: {
    AgencyCard: () => import("@/components/common/AgencyCard"),
    Button: () => import("@/components/common/Button"),
    Section: () => import("@/components/common/Section"),
  },
  props: {
    limiter: {
      type: Boolean,
      default: () => true,
    },
    params: {
      type: Object,
      default: () => ({
        city_id: null,
        count: 8,
        page: 1,
      })
    }
  },
  data() {
    return {
      city_id: null,
      pending: false,
      agencies: [],
      meta: null,
      count: 8,
      page: 1,
    }
  },
  computed: {
    show() {
      return this?.meta?.last_page !== this?.meta?.current_page || false
    },
  },
  created() {
    Object.assign(this.$data, this.params)
  },
  mounted() {
    this.request()
  },
  methods: {
    request() {
      const params = {
        ...this.params,
        city_id: this.city_id,
        page: this.page,
        count: this.count,
        lang: this.$i18n.locale,
        resolution: 'micro',
      }
      this.pending = true
      this.$axios.$get(`/api/travel-agencies?${parser({params})}`)
        .then(response => {
          this.agencies = [...this.agencies, ...response.data]
          this.meta = response.meta
        })
        .catch(() => {
          this.agencies = []
          this.meta = null
        })
        .finally(() => {
          this.pending = false
        })
    },
    click() {
      this.page += 1
      this.request()
    }
  }
}
