//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyDefaultText from "@/components/common/EmptyDefaultText";
import EmptyButton from "@/components/common/EmptyButton";

export default {
  name: "EmptyHeroSlider",
  components: {
    EmptyButton,
    EmptyDefaultText
  },
}
