//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionBillboard",
  components: {
    SectionBillboardButton: () => import("@/components/common/SectionBillboard/SectionBillboardButton"),
    SectionBillboardLink: () => import("@/components/common/SectionBillboard/SectionBillboardLink"),
    Section: () => import("@/components/common/Section")
  },
  props: {
    buttons: {
      type: Array,
      default: () => []
    },
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    columns() {
      switch (this?.buttons.length) {
        case 1: {
          return 'section-billboard__buttons--col-1'
        }
        case 2: {
          return 'section-billboard__buttons--col-2'
        }
        case 3: {
          return 'section-billboard__buttons--col-3'
        }
        default: {
          return 'section-billboard__buttons--col-4'
        }
      }
    }
  }
}
