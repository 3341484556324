//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorView',
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    Section: () => import('@/components/common/Section'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
  },
  props: {
    error: {
      type: Object,
      default: () => null
    }
  },
  head() {
    return {
      title: this.message,
    }
  },
  computed: {
    components() {
      return this.$config?.pages?.ErrorView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    status() {
      return this?.error?.statusCode || 404
    },
    message() {
      return this.$t(`pages.${this.status}.message`)
    },
  },
}
