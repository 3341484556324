export const state = () => ({
  counts: [10, 50, 100],
  count: 10,
  current: 0
})

export const mutations = {
  setCurrent: (state, current) => {
    state.current = current
    state.count = state.counts[current]
  }
}

export const actions = {}

export const getters = {
  current: state => state.current,
  counts: state => state.counts,
  count: state => state.count
}
