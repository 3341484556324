import {Plugin} from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$logout inside Vue components
  interface Vue {
    $logout(): void
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$logout inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $logout(): void
  }

  // nuxtContext.$logout
  interface Context {
    $logout(): void
  }
}

declare module 'vuex/types/index' {
  // this.$logout inside Vuex stores
  interface Store<S> {
    $logout(): void
  }
}

const pluginLogout: Plugin = ({app, store, isClient, route}, inject) => {
  inject('logout', (): void => {
    const api_token = app.$cookies.get('api_token')
    app.$cookies.remove('api_token')
    app.$axios.setHeader('Authorization', null)
    store.commit('user/logout')
    if (!process.server) {
      window.location.href = `/logout?api_token=${api_token}&latest_url=${route.fullPath}`
      localStorage.clear()
    }
  })
}

export default pluginLogout
