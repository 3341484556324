export const errors = (key, errors) => {
  let temp = null
  for (const error in errors) {
    if (key !== error) {
      if (temp === null) {
        temp = {}
      }
      temp[error] = errors[error]
    }
  }
  return temp
}
