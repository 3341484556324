//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex'


export default {
  name: 'Comment',
  components: {
    TextareaComponent: () => import('@/components/common/TextareaComponent'),
    Avatar: () => import('@/components/common/Avatar')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    entityId: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    parent_id: {
      type: Number,
      default: () => null
    },
    commenter: {
      type: Object,
      default: () => null
    },
    comment: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    updated_at: {
      type: Number,
      default: () => null
    },
    // eslint-disable-next-line vue/prop-name-casing
    created_at: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      showTextareaComponent: false,
      showOnModeration: false,
      message: '',
      error: '',
      formatDate: 'DD.MM.YYYY HH:mm'
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged',
      comments: 'comments/comments'
    }),
    disabled () {
      return !this.message.length > 0
    },
    filteredComments () {
      return this.comments.filter(item => item.parent_id === this.id)
    },
    name () {
      return this?.commenter?.name
    },
    avatar () {
      return this?.commenter?.avatar
    },
    date () {
      return this.$moment.unix(this.updated_at || this.created_at)
        .format(this.formatDate)
    }
  },
  methods: {
    input(value) {
      this.message = value
      if (this.error) {
        this.error = ''
      }
    },
    toggle () {
      if (this.logged) {
        this.showTextareaComponent = !this.showTextareaComponent
      } else {
        this.$login()
      }
    },
    submit () {
      if (this.message.length < 1000) {
        let string = '?'
        string += `parent_id=${this.id}`
        string += `&comment=${this.message}`
        this.$axios.$post(`/api/${this.entity}/${this.entityId}/comments${string}`)
          .then(() => {
            this.showTextareaComponent = false
            this.showOnModeration = true
          })
        this.message = ''
      } else {
        this.error = this.$t('components.comment.error')
      }
    }
  }
}
