import { parser } from '@/utils/parser'

export const state = () => ({
  age_restrictions: []
})

export const getters = {
  age_restrictions: state => state.age_restrictions
}

export const mutations = {
  setAgeRestrictions: (state, age_restrictions) => state.age_restrictions = age_restrictions
}

export const actions = {
  async fetch ({ commit }, payload) {
    const age_restrictions = await this.$axios.$get(`/api/lists/age-restrictions?${parser(payload)}`)
    commit('setAgeRestrictions', age_restrictions.data)
  }
}
