export default async function ({
  app,
  store,
  error,
  params,
  redirect
}) {
  function statusValidation (status) {
    switch (status) {
      case 'active':
        return true
      default:
        return false
    }
  }

  function idValidation () {
    const excursion = store.getters['account-agency-excursion/excursion']
    const agency = store.getters['account-agency-profile/agency']
    const user = store.getters['user/user']
    if (agency?.user_id === user?.id && agency?.id === excursion?.agency?.id) {
      if (statusValidation(agency?.status) === false || statusValidation(excursion?.status) === false) {
        error({status: 400, message: app.i18n.t('pages.400.message')})
      }
    } else {
      error({status: 400, message: app.i18n.t('pages.400.message')})
    }
  }

  async function validation () {
    if (store.getters['user/user']) {
      Promise.all([
        await store.dispatch('account-agency-excursion/fetch', {
          id: params.excursion_id,
          params: {
            lang: app.i18n.locale
          }
        })
          .catch(() => {
            error({status: 404, message: app.i18n.t('pages.404.message')})
          }),
        await store.dispatch('account-agency-profile/fetch', {
          id: params.agency_id,
          params: {
            lang: app.i18n.locale
          }
        })
          .catch(() => {
            error({status: 400, message: app.i18n.t('pages.400.message')})
          })
      ])
        .then(() => {
          idValidation()
        })
        .catch(() => {
          error({status: 400, message: app.i18n.t('pages.400.message')})
        })
    } else {
      redirect('/login')
    }
  }

  await validation()
}
