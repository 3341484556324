import {parser} from "~/utils/parser";

export const state = () => ({
  agency: null,
  pending: false,
})

export const mutations = {
  setAgency: (state, agency) => state.agency = agency,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async agency({commit}, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/travel-agencies/${payload.id}?${parser(payload)}`)
      .then(response => commit('setAgency', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  },
}

export const getters = {
  agency: state => state.agency,
  pending: state => state.pending,
}
