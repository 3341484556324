//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'AccountView',
  components: {
    PromoCode: () => import("@/components/common/PromoCode/index.vue"),
    BannerLoyaltyProgram: () => import('@/components/common/BannerLoyaltyProgram/index'),
    UserInfo: () => import('@/components/common/UserInfo/index'),
    EmptyEntity: () => import('@/components/common/EmptyEntity/index'),
    Button: () => import('@/components/common/Button/index'),
    TravelCard: () => import('@/components/common/TravelCard/index')
  },
  layout: 'personal-area',
  async fetch({
                app,
                store
              }) {
    await store.dispatch('travels/fetch', {
      params: {
        lang: app.i18n.locale
      }
    })
  },
  head() {
    return {
      title: this.$t('pages.account.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      travels: 'travels/travels',
      logged: 'user/logged'
    }),
    name() {
      return this?.user?.name
    },
    avatar() {
      return this?.user?.avatar
    },
    bonus_points_balance() {
      return this?.user?.bonus_points_balance
    },
    bonus_status() {
      return this?.user?.bonus_status
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  methods: {
    openPopup() {
      if (this.logged) {
        this.$store.commit('popup/open', {
          name: 'PopupAddTravel',
          params: {
            style: {
              width: '100%',
              maxWidth: '632px',
              maxHeight: '100vh',
              overflow: 'visible',
              position: 'relative',
              overflowY: 'visible',
              overflowX: 'visible'
            }
          }
        })
      } else {
        this.$login()
      }
    }
  }
}
