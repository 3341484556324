//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentWorkHours",
  components: {
    Icon24FillClock: () => import('@/assets/icons/Icon24FillClock.svg?inline'),
    WorkTime: () => import('@/components/common/WorkTime')
  },
  props: {
    workHours: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    visibleWorkNow() {
      return (this?.workHours === null) ? false : (Object.values(this?.workHours).some(item => item !== null))
    }
  }
}
