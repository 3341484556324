//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "TopEntityComponentSlider",
  components: {
    SliderBullets: () => import("@/components/common/SliderBullets/index.vue"),
    RoundButton: () => import("@/components/common/RoundButton/index.vue"),
    EmptyTopEntityComponentSlider: () => import("@/components/common/EmptyTopEntityComponentSlider/index.vue"),
    ImageComponent: () => import("@/components/common/ImageComponent/index.vue"),
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline'),
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      width: 0,
      pending: true,
      current: 0
    }
  },
  computed: {
    ...mapGetters({
      viewportWidth: 'viewport/width',
    }),
    showNext() {
      return -this.current < this.images.length - 1 && this.images.length > 1 && this.$mediaSwitch({
        hd: true,
        lg: true,
        defaultValue: false
      })
    },
    showPreview() {
      return this.current < 0 && this.images.length > 1 && this.$mediaSwitch({hd: true, lg: true, defaultValue: false})
    },
    style() {
      return {
        width: this.width,
        left: this.$el.clientWidth * this.current + 'px'
      }
    }
  },
  watch: {
    viewportWidth() {
      this.pending = true
      this.width = this.$el.clientWidth + 'px'
      setTimeout(() => this.pending = false, 500)
    }
  },
  mounted() {
    this.pending = false
    this.width = this.$el.clientWidth + 'px'
  },
  methods: {
    openPopup() {
      const current = this.current < 0 ? -this.current : 0
      this.$store.commit('popup/open', {
        name: 'PopupFullSizeSlider',
        options: {
          list: this.images.map((item, index) => ({
            description: "",
            id: index,
            image: item,
            title: "",
          })),
          active: this.images.indexOf(this.images[current])
        }
      })
    },
    changeCurrent(current) {
      this.current = -current
    },
    swipePreview() {
      if (this.current < 0) {
        this.preview()
      }
    },
    swipeNext() {
      if (-this.current < this.images.length - 1) {
        this.next()
      }
    },
    next() {
      this.current -= 1
    },
    preview() {
      this.current += 1
    }
  },
}
