import {Plugin} from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $randomString(): string
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $randomString(): string
  }

  // nuxtContext.$cardFromEntity
  interface Context {
    $randomString(): string
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $randomString(): string
  }
}

const pluginRandomString: Plugin = (context, inject) => {
  inject('randomString', (): string => Math.random()
    .toString(36)
    .substring(2, 15))
}

export default pluginRandomString
