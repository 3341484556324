//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "DesktopNavigationIconAvatar",
  components: {
    Avatar: () => import('@/components/common/Avatar'),
  },
  computed: {
    ...mapGetters({
      media: 'viewport/media',
      user: 'user/user',
      logged: "user/logged",
      host: 'host'
    }),
    avatar() {
      if (this.user) {
        return this.user.avatar || require('@/assets/images/default-user-avatar.svg')
      }
      return require('@/assets/images/default-user-avatar.svg')
    },
  },
  methods: {
    goToAccount() {
      if (this.logged) {
        this.$router.push(
          this.$currentLocale() === this.$defaultLocale() ?
            '/account' :
            `${this.$prefixLocale()}/account`
        )
      } else {
        this.$login(
          this.$currentLocale() === this.$defaultLocale() ?
            `${this.host}/account` :
            `${this.host}/${this.$i18n.locale}/account`
        )
      }
    },
  }
}
