//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionAgencyLegalInformationDate",
  props: {
    date: {
      type: String,
      default: () => ""
    }
  }
};
