//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'AgencyCard',
  components: {
    ImageComponent: () => import('@/components/common/ImageComponent')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    currentUserRating: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-user-avatar.svg')
    },
    title: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    },
  },
  computed: {
    typeText () {
      switch (this?.type) {
        case 'travel': {
          return this.$t('components.agency-card.travel-agency')
        }
        case 'guide': {
          return this.$t('components.agency-card.guide')
        }
        case 'org': {
          return this.$t('components.agency-card.organization')
        }
        default: {
          return ''
        }
      }
    },
    link () {
      return `/${this?.entity}/${this?.id}`
    }
  }
}
