//
//
//
//
//
//

export default {
  name: 'WorkTime',
  props: {
    workHours: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      workNow: '',
      startDay: '',
      endDay: ''
    }
  },
  mounted () {
    this.calcWorkTime()
  },
  methods: {
    calcWorkTime () {
      const weekDay = this.$moment()
        .weekday()
      const now = this.$moment()
        .format('HH:mm')
      if (this?.workHours) {
        this.getWorkTime(weekDay)
        if (weekDay === 1 && now < this.startDay) {
          this.workNow = `${this.$t('components.work-time.opens-from')} ${this.startDay}`
        } else if (now < this.startDay || this.startDay === null) {
          this.getWorkTime(weekDay - 1)
          if ((this.endDay > this.startDay) || this.startDay === null || this.endDay === null || (now > this.endDay)) {
            this.getWorkTime(weekDay)
          }
          this.compareWorkTime(now)
        } else {
          this.compareWorkTime(now)
        }
      }
      return ''
    },
    getWorkTime (weekDay) {
      const formatTime = 'HH:mm'
      this.startDay = this.workHours[`day_${weekDay}_open`]
        ? this.$moment(this.workHours[`day_${weekDay}_open`], formatTime)
          .format(formatTime)
        : null
      this.endDay = this.workHours[`day_${weekDay}_close`]
        ? this.$moment(this.workHours[`day_${weekDay}_close`], formatTime)
          .format(formatTime)
        : null
    },
    compareWorkTime (now) {
      if (this.startDay !== null && this.endDay !== null && this.endDay < this.startDay) {
        this.workNow = (now >= this.startDay || now < this.endDay)
          ? this.$t('components.work-time.open-now') // Сейчас открыто
          : this.$t('components.work-time.closed') // Закрыто
      } else if (this.startDay === null && this.endDay === null) {
        this.workNow = this.$t('components.work-time.day-off') // Выходной
      } else if (this.startDay === this.endDay) {
        this.workNow = this.$t('components.work-time.around-the-clock') // Круглосуточно
      } else if (this.startDay === null && this.endDay !== null) {
        this.workNow = now < this.endDay
          ? `${this.$t('components.work-time.open-until')} ${this.endDay}` // Открыто до (время)
          : this.$t('components.work-time.closed') // Закрыто
      } else if (this.startDay !== null && this.endDay === null) {
        this.workNow = now < this.startDay
          ? `${this.$t('components.work-time.opens-from')} ${this.startDay}` // Откроется с (время)
          : `${this.$t('components.work-time.open-from')} ${this.startDay}` // Открыто с (время)
      } else if (this.startDay !== null && this.endDay !== null && now < this.startDay) {
        this.workNow = `${this.$t('components.work-time.opens-from')} ${this.startDay}` // Откроется с (время)
      } else if (this.startDay !== null && this.endDay !== null && now >= this.startDay && now < this.endDay) {
        this.workNow = this.$t('components.work-time.open-now') // Сейчас открыто
      } else if (this.startDay !== null && this.endDay !== null && now > this.startDay && now > this.endDay) {
        this.workNow = this.$t('components.work-time.closed') // Закрыто
      }
    }
  }
}
