// Паттерн на проверку телефонв. Если значение соответсвует - true, иначе - false.

import {Plugin} from "@nuxt/types";

type Phone = any;

declare module "vue/types/vue" {
  interface Vue {
    $isPhoneOrEmpty(phone: Phone): boolean;
  }
}

declare module "@nuxt/types" {
  interface NuxtAppOptions {
    $isPhoneOrEmpty(phone: Phone): boolean;
  }

  interface Context {
    $isPhoneOrEmpty(phone: Phone): boolean;
  }
}

declare module "vuex/types/index" {
  interface Store<S> {
    $isPhoneOrEmpty(phone: Phone): boolean;
  }
}

const pluginIsPhoneOrEmpty: Plugin = ({app}, inject) => {
  inject("isPhoneOrEmpty", (phone: Phone) => {
    return phone?.length === 0 || app.$isPhone(phone);
  });
};

export default pluginIsPhoneOrEmpty;
