//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionEmptyEntity",
  components: {
    Section: () => import('@/components/common/Section'),
    Empty: () => import('@/components/common/Empty')
  },
  props: {
    pending: {
      type: Boolean,
      default: () => false
    },
    pendingMessage: {
      type: String,
      default: () => ''
    },
    emptyMessage: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    message () {
      return this.pending
        ? this.$t(this.pendingMessage)
        : this.$t(this.emptyMessage)
    }
  }
}
