export const state = () => ({
  languages: [],
  current: 'ru'
})

export const getters = {
  languages: state => state.languages,
  current: state => state.current
}

export const mutations = {
  setCurrentLanguage: (state, current) => {
    state.current = current
  },
  setLanguages: (state, languages) => {
    state.languages = languages
  }
}

export const actions = {
  async fetch ({ commit }) {
    const languages = await this.$axios.$get('/api/languages')
    commit('setLanguages', languages.data)
  }
}
