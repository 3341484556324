export default async function ({
                                 app,
                                 store,
                                 params,
                                 error
                               }) {
  await store.dispatch('place/fetch', {
    id: params.id,
    params: {
      lang: app.i18n.locale,
      resolution: 'micro'
    }
  })
    .then(async () => {
      const place = store.getters['place/place']
      const logged = store.getters['user/logged']
      if (!place) {
        error({statusCode: 404})
      }
      if (logged) {
        await store.dispatch('travels/fetch', {
          params: {
            lang: app.i18n.locale
          }
        })
      }
    })
}
