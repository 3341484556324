import Vue from 'vue'

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      // eslint-disable-next-line eqeqeq
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

Vue.directive('scroll', {
  inserted(el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.directive('defer', {
  inserted(el, binding) {
    const options = {
      display: binding?.value?.display || 'block',
      root: binding?.value?.root || null,
      rootMargin: binding?.value?.rootMargin || '300px',
      threshold: binding?.value?.threshold || 0
    }

    function loadElement() {
      el.classList.add(`defer-show-${options.display}`)
    }

    function callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadElement()
          observer.unobserve(el)
        }
      })
    }

    function createIntersectionObserver() {
      const observer = new IntersectionObserver(callback, options)
      observer.observe(el)
    }

    if (!window.IntersectionObserver) {
      loadElement()
    } else {
      createIntersectionObserver()
    }
  }
})

Vue.directive('lazyload', {
  inserted(el, binding) {
    const options = {
      root: binding?.value?.root || null,
      rootMargin: binding?.value?.rootMargin || '300px',
      threshold: binding?.value?.threshold || 0
    }

    function loadImage() {
      el.src = el.dataset.src
    }

    function callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage()
          observer.unobserve(el)
        }
      })
    }

    function createIntersectionObserver() {
      const observer = new IntersectionObserver(callback, options)
      observer.observe(el)
    }

    if (!window.IntersectionObserver) {
      loadImage()
    } else {
      createIntersectionObserver()
    }
  }
})
