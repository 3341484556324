function headers (name, req) {
  if (req) {
    const matches = req?.headers?.cookie?.match(new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ))
    return matches ? decodeURIComponent(matches[1]) : null
  }
  return false
}

function cookie (name) {
  if (process.browser) {
    const matches = document?.cookie?.match(new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ))
    return matches ? decodeURIComponent(matches[1]) : null
  }
  return false
}

export default function ({
  req,
  query,
  route,
  redirect
}) {
  const key = '0749576D08896112737CC22F7224F91A'
  const welcome = 'welcome'
  if (query?.welcome !== key &&
    headers(welcome, req) !== key &&
    cookie(welcome) !== key) {
    return redirect('/welcome')
  } else if (route.path === '/welcome') {
    redirect('/')
  }
}
