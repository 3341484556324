import { parser } from '@/utils/parser'

export const state = () => ({
  events: [],
  meta: null,
  pending: false
})

export const mutations = {
  setEvents: (state, events) => state.events = [...state.events, ...events],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending,
  clearEvents: (state) => {
    state.events = []
    state.meta = null
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload.clear) {
      commit('clearEvents')
    }
    const events = await this.$axios.$get(`/api/agencies/${payload.id}/events?${parser(payload)}&user=current`)
    commit('setEvents', events.data)
    commit('setMeta', events.meta)
    commit('setPending', false)
  }
}

export const getters = {
  events: state => state.events,
  meta: state => state.meta,
  pending: state => state.pending
}
