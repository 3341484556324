//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import seo from '@/middleware/seo'

export default {
  name: 'JournalsView',
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    Section: () => import('@/components/common/Section/index'),
    JournalCard: () => import('@/components/common/JournalCard/index')
  },
  middleware: [seo],
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      journals: 'journals/journals',
      city: 'cities/default',
    }),
    components() {
      return this.$config?.pages?.JournalsView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    params() {
      return {
        count: 100500,
        resolution: 'micro',
        lang: this.$i18n.locale,
        city_id: this.$config.singleCity ? this.city.id : null,
      }
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  mounted() {
    this.$store.dispatch('journals/fetch', {
      clear: true,
      params: this.params
    })
  }
}
