//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FooterGroup",
  props: {
    title: {
      type: String,
      default: () => ''
    },
    links: {
      type: Array,
      default: () => []
    }
  }
}
