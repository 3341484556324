export const state = () => ({
  comments: []
})

export const mutations = {
  setComments: (state, comments) => state.comments = comments
}

export const actions = {
  async getCommentsFromServerWithParams ({ commit }, payload) {
    const comments = await this.$axios.$get(`/api/${payload.entity}/${payload.id}/comments`)
    commit('setComments', comments.data)
  }
}

export const getters = {
  comments: state => state.comments
}
