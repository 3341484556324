//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentDates",
  components: {
    Icon24FillCalendar: () => import('@/assets/icons/Icon24FillCalendar.svg?inline')
  },
  props: {
    dates: {
      type: String,
      default: () => ''
    }
  }
}
