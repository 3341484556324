//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'MapNavigationPlaces',
  components: {
    HorizontalEntityCard: () => import('@/components/common/HorizontalEntityCard'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar'),
    Empty: () => import('@/components/common/Empty'),
  },
  props: {
    numbers: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      pending: 'interactive-map/placesPending',
      places: 'interactive-map/places',
      entity: 'interactive-map/entity',
      id: 'interactive-map/id'
    }),
    showPlaces() {
      return this.entity === 'places' && !this.id
    }
  },
  methods: {
    click(id) {
      this.$store.commit('interactive-map/setEntity', 'places')
      this.$store.commit('interactive-map/setId', id)
    }
  }
}
