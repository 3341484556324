//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import guide from '~/middleware/pages/guide'

export default {
  name: 'GuideView',
  components: {
    SectionAgencyBranches: () => import("@/components/common/SectionAgencyBranches"),
    EntityContent: () => import("@/components/common/EntityContent"),
    SectionComments: () => import("@/components/common/SectionComments"),
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    TopEntityAgencyProfile: () => import('@/components/common/TopEntityAgencyProfile'),
    ErrorComponent: () => import('@/components/common/ErrorComponent'),
    JournalCard: () => import('@/components/common/JournalCard/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    ExcursionCard: () => import('@/components/common/ExcursionCard/index'),
    RouteCard: () => import('@/components/common/RouteCard/index'),
    Section: () => import('@/components/common/Section/index')
  },
  middleware: [guide],
  head() {
    return {
      title: 'Туристический путеводитель Мурманской области',
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: 'Туристический путеводитель Мурманской области',
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      guide: 'guide/guide',
      branches: 'guide/branches'
    }),
    components() {
      return this.$config?.pages?.GuideView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showSectionComments() {
      return this.components?.SectionComments
    },
    showContextAdditions() {
      return this.components?.ContextAdditions
    },
    id() {
      return this?.guide?.id
    },
    entity() {
      return this?.guide?.entity
    },
    description() {
      return this?.guide?.description
    },
    additions() {
      return this?.guide?.additions
    },
    excursions() {
      return this?.guide?.excursions
    },
    routes() {
      return this?.guide?.trips.filter(item => item.type !== 'excursion')
    },
    journals() {
      return this?.guide?.journals
    },
    status() {
      return this?.guide?.status
    }
  }
}
