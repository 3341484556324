//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'SearchRoutes',
  components: {
    RouteCard: () => import('@/components/common/RouteCard'),
    Button: () => import('@/components/common/Button'),
    HorizontalEntityCard: () => import('@/components/common/HorizontalEntityCard')
  },
  data () {
    return {
      count: 3,
      page: 1
    }
  },
  computed: {
    ...mapGetters({
      routes: 'search/routes',
      routesMeta: 'search/routesMeta'
    }),
    show () {
      return this?.routes?.length > 0 && this?.page < this?.routesMeta?.last_page
    }
  },
  methods: {
    next () {
      this.page += 1
      this.$store.dispatch('search/routes', {
        params: {
          resolution: 'medium',
          query: this.$route.query.search,
          page: this.page,
          count: this.count
        }
      })
    }
  }
}
