//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "NoteCard",
  components: {
    Divider: () => import("@/components/common/Divider"),
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    image: {
      type: String,
      default: () => null
    },
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    }
  },
  methods: {
    openPopupArticleNote() {
      this.$store.commit('popup/open', {
        name: 'PopupArticleNote',
        params: {
          style: {
            maxWidth: '632px',
            maxHeight: '100vh'
          }
        },
        options: {
          image: this.image,
          title: this.title,
          description: this.description
        }
      })
    }
  }
};
