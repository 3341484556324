import { render, staticRenderFns } from "./index.vue?vue&type=template&id=98599460&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapNavigation: require('/srv/rir-platform/tourism/src/tourism-front/components/common/MapNavigation/MapNavigation.vue').default,OnMap: require('/srv/rir-platform/tourism/src/tourism-front/components/common/OnMap/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default,SectionBannerAppMobile: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionBillboard/index.vue').default})
