import {Plugin} from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$functionality inside Vue components
  interface Vue {
    $isEmptyObject(object: object): boolean
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$functionality inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $isEmptyObject(object: object): boolean
  }

  // nuxtContext.$functionality
  interface Context {
    $isEmptyObject(object: object): boolean
  }
}

declare module 'vuex/types/index' {
  // this.$functionality inside Vuex stores
  interface Store<S> {
    $isEmptyObject(object: object): boolean
  }
}

const pluginIsEmptyObject: Plugin = ({store}, inject) => {
  inject('isEmptyObject', (object: object) => {
    // eslint-disable-next-line
    for (const i in object) return false;
    return true;
  })
}

export default pluginIsEmptyObject
