//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "SectionAgencyLegalInformation",
  components: {
    SectionAgencyLegalInformationAddress: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationAddress"),
    SectionAgencyLegalInformationEmail: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationEmail"),
    SectionAgencyLegalInformationKpp: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationKpp"),
    SectionAgencyLegalInformationOkved: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationOkved"),
    SectionAgencyLegalInformationOgrn: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationOgrn"),
    SectionAgencyLegalInformationInn: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationInn"),
    SectionAgencyLegalInformationDate: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationDate"),
    SectionAgencyLegalInformationName: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationName"),
    SectionAgencyLegalInformationStatus: () => import("@/components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationStatus"),
    Empty: () => import("@/components/common/Empty"),
    Section: () => import("@/components/common/Section"),
    Icon24FillEdit: () => import("@/assets/icons/Icon24FillEdit.svg?inline"),
    Icon24FillRefresh: () => import("@/assets/icons/Icon24FillRefresh.svg?inline"),
    RoundButton: () => import("@/components/common/RoundButton"),
  },
  data() {
    return {
      isUpdatingUserInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      agency: "account-agency-profile/agency",
    }),
    legalInformation() {
      return this?.agency?.legal_information;
    },
    legal_status() {
      return this?.legalInformation?.legal_status || "";
    },
    fullName() {
      return this?.legalInformation?.fullName || "";
    },
    registrationDate() {
      return this?.legalInformation?.registrationDate || "";
    },
    inn() {
      return this?.legalInformation?.inn || "";
    },
    ogrn() {
      return this?.legalInformation?.ogrn || "";
    },
    main_okved() {
      return this?.legalInformation?.main_okved || "";
    },
    kpp() {
      return this?.legalInformation?.kpp || "";
    },
    address() {
      return this?.legalInformation?.address || "";
    },
    email() {
      return this?.legalInformation?.email || "";
    },
    user_account_page_url() {
      return this?.user?.user_account_page_url;
    },
    provider_id() {
      return this?.agency?.provider_id;
    },
  },
  methods: {
    editAgency() {
      window.open(`${this.user_account_page_url}/restxq/organization-edit?id=${this.provider_id}`);
      this.$store.commit("agency/setErrors", null);
    },
    async updateUserInfo() {
      this.isUpdatingUserInfo = true
      try {
        await this.$store.dispatch('user/refreshToken')
        await this.$store.dispatch('user/whoAmI')
        await this.$store.dispatch("user/agency", {
          id: this.$route.params.agency_id,
          params: {
            lang: this.$i18n.locale
          }
        });
        await this.$store.dispatch("account-agency-profile/fetch", {
          id: this.$route.params.agency_id,
          params: {
            lang: this.$i18n.locale
          }
        });
      } catch (e) {
        this.$login()
      }
      this.isUpdatingUserInfo = false
    },
  }
};
