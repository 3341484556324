//
//
//
//
//
//
//
//
//

export default {
  name: "FooterFeedback",
  methods: {
    feedback() {
      this.$store.commit('popup/open', {
        name: 'PopupFeedback',
        params: {
          style: {
            maxHeight: '100%'
          }
        }
      })
    }
  }
}
