//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LabelComponent",
  props: {
    id: {
      type: [String, Number],
      default: () => ""
    },
    label: {
      type: String,
      default: () => ""
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    obligatory: {
      type: Boolean,
      default: () => false
    }
  }
};
