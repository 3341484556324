// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({app, store}, inject) => {
  inject('openPopupAddPlace', logged => {
    const notEmptyPlace = store.getters['add-place/notEmpty']
    if (logged) {
      if (notEmptyPlace) {
        store.commit('popup/open', {
          name: 'PopupPreEntity',
          params: {
            entity: 'add-place',
            popup: 'PopupAddPlace',
            style: {
              width: store.getters['viewport/sm'] || false ? '100%' : '632px',
              maxHeight: '100%'
            }
          }
        })
      } else {
        store.commit('popup/open', {
          name: 'PopupAddPlace',
          params: {
            style: {
              width: store.getters['viewport/sm'] || false ? '100%' : '632px',
              maxHeight: '100%'
            }
          }
        })
      }
    } else {
      app.$login()
    }
  })
}
