import {parser} from "~/utils/parser";

export const state = () => ({
  widgets: [],
  meta: null,
  pending: false,
})

export const mutations = {
  clear: (state) => state.widgets = [],
  setWidgets: (state, widgets) => state.widgets = [...state.widgets, ...widgets],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async delete({commit}, payload) {
    commit('setPending', true)
    await this.$axios.delete(`/api/widgets/${payload.id}`)
      .catch(() => null)
      .finally(() => commit('setPending', false))
  },
  async fetch({commit}, payload) {
    commit('setPending', true)
    if (payload.clear) {
      commit('clear')
    }
    await this.$axios.get(`/api/widgets?${parser(payload)}`)
      .then(response => commit('setWidgets', response?.data?.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  widgets: state => state.widgets,
  meta: state => state.meta,
  pending: state => state.pending
}
