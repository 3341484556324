//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "SectionPartners",
  components: {
    TestSlider: () => import("@/components/common/TestSlider"),
    PartnerCard: () => import("@/components/common/PartnerCard"),
    Section: () => import("@/components/common/Section"),
  },
  props: {
    grayscale: {
      type: Boolean,
      default: () => true,
    },
    background: {
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      pending: true,
      partners: [],
    }
  },
  computed: {
    ...mapGetters({
      width: 'viewport/width',
      media: 'viewport/media',
    }),
    mediaSwitchBoolean() {
      return this.media === 'hd' || this.media === 'lg'
    }
  },
  watch: {
    width() {
      this.pending = true
      setTimeout(() => {
        this.pending = false
      }, 1000)
    }
  },
  mounted() {
    this.$axios.get('/api/partners')
      .then(response => this.partners = response?.data?.data)
      .catch(() => null)
      .finally(() => {
        this.pending = false
      })
  }
}
