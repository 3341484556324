//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import seasons from '@/middleware/seasons'
import levels from '@/middleware/levels'
import age from '@/middleware/age'
import tourismTypes from '@/middleware/tourism-types'
import seo from '@/middleware/seo'

export default {
  name: 'RoutesView',
  components: {
    SelectCheckBoxComponent: () => import("@/components/common/SelectCheckBoxComponent"),
    SelectSearchComponent: () => import('@/components/common/SelectSearchComponent'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline'),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    Button: () => import('@/components/common/Button/index'),
    EntityFiltersWrapper: () => import('@/components/common/EntityFiltersWrapper/index'),
    FilterToggle: () => import('@/components/common/FilterToggle/index'),
    InputComponent: () => import('@/components/common/InputComponent/index'),
    SectionBannerAppMobile: () => import('@/components/common/SectionBannerAppMobile/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    SectionEmptyEntity: () => import('~/components/common/SectionEmptyEntity'),
    PendingButton: () => import('@/components/common/PendingButton/index'),
    Section: () => import('@/components/common/Section/index'),
    Toggle: () => import('@/components/common/Toggle/index'),
    RouteCard: () => import('@/components/common/RouteCard/index')
  },
  middleware: [tourismTypes, seasons, levels, age, seo],
  data() {
    return {
      sortTypes: [
        {
          id: 'avg',
          text: this.$t('pages.routes-view.toggle-sort-avg')
        },
        {
          id: 'created_at',
          text: this.$t('pages.routes-view.toggle-sort-created-at')
        }
      ],
    }
  },
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      cities: 'cities/cities',
      city: 'cities/default',
      sort: 'pages/routes/sort',
      routes:'pages/routes/routes',
      meta: 'pages/routes/meta',
      pending: 'pages/routes/pending',
      city_id: 'pages/routes/city_id',
      page: 'pages/routes/page',
      count: 'pages/routes/count',
      query: 'pages/routes/query',
      promo: 'pages/routes/promo',
      tourism_type_id: 'pages/routes/tourism_type_id',
      accessible_environment: 'pages/routes/accessible_environment',
      age_restriction_id: 'pages/routes/age_restriction_id',
      level_id: 'pages/routes/level_id',
      season_id: 'pages/routes/season_id',
      category_id: 'pages/routes/category_id',
      categories: 'categories/routes',
      categoriesForCheckbox: 'pages/routes/categoriesForCheckbox',
    }),
    components() {
      return this.$config?.pages?.RoutesView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showTopTitleComponent() {
      return this.components?.TopTitleComponent
    },
    showEntityFiltersWrapper() {
      return this.components?.EntityFiltersWrapper
    },
    showRoutes() {
      return this.components?.Routes
    },
    showButton() {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    citiesWithAll() {
      return [{
        id: null,
        title: this.$t('pages.routes-view.select-city-default')
      }, ...this.cities]
    },
    seasons() {
      return [{
        id: null,
        title: this.$t('pages.routes-view.select-seasons-default')
      }, ...this.$store.getters['seasons/seasons']]
    },
    levels() {
      return [{
        id: null,
        title: this.$t('pages.routes-view.select-level-default')
      }, ...this.$store.getters['levels/levels']]
    },
    age_restrictions() {
      return [{
        id: null,
        title: this.$t('pages.routes-view.select-age-default')
      }, ...this.$store.getters['age-restrictions/age_restrictions']]
    },
    tourism_types() {
      return [{
        id: null,
        title: this.$t('pages.routes-view.select-tourism-default')
      }, ...this.$store.getters['tourism-types/tourism_types']]
    },
  },
  created() {
    if (process.browser) {
      const query = this.$route.query
      if (query.slug) {
        const cityId = this?.cities?.find(item => item.slug === this.$route.query.slug)?.id
        this.$store.commit('pages/routes/setCityId', cityId)
      } else {
        if (this.$config.singleCity) {
          this.$store.commit('pages/routes/setCityId', this.$defaultCityId())
        } else {
          this.$store.commit('pages/routes/setCityId', query.city_id)
        }
      }
      this.$store.commit('pages/routes/setTourismTypeId', query.tourism_type_id)
      this.$store.commit('pages/routes/setAccessibleEnvironment', query.accessible_environment)
      this.$store.commit('pages/routes/setPromo', query.promo)
      this.$store.commit('pages/routes/setSort', query.sort)
      this.$store.commit('pages/routes/setSeasonId', query.season_id)
      this.$store.commit('pages/routes/setLevelId', query.level_id)
      this.$store.commit('pages/routes/setAgeRestrictionId', query.age_restriction_id)
      this.$store.commit('pages/routes/setLang', this.$i18n.locale)
      this.$store.commit('pages/routes/setCategories', this.categories)
      this.$store.commit('pages/routes/setCategoryIdFromString', query.category_id)
    }
  },
  mounted() {
    this.$store.dispatch('pages/routes/routes', {clear: true})
  },
  methods: {
    scrollToFilter() {
      this.$handleScrollTo('#filter', 100)
    },
    changeAccessibleEnvironment(accessible_environment) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setAccessibleEnvironment', accessible_environment)
      this.$instantReplaceQuery({accessible_environment})
    },
    submitSearch() {
      this.$store.commit('pages/routes/setPage', 1)
      this.$store.dispatch('pages/routes/search', {clear: true})
    },
    next() {
      if (this.page) {
        this.$store.commit('pages/routes/setPage', this.page + 1)
        this.$store.dispatch('pages/routes/routes')
      } else {
        this.$store.commit('pages/routes/setPage', 1)
        this.$store.dispatch('pages/routes/routes', {clear: true})
      }
    },
    resetAndDispatch() {
      this.reset()
      this.submit()
    },
    reset() {
      this.$router.replace({query: {}}).catch(() => null)
      this.$store.commit('pages/routes/reset')
      if (this.$config.singleCity) {
        this.$store.commit('pages/routes/setCityId', this.$defaultCityId())
      }
    },
    submit() {
      this.$store.commit('pages/routes/setPage', 1)
      this.$store.dispatch('pages/routes/routes', {clear: true})
    },
    changePromo(promo) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setPromo', promo)
      this.$instantReplaceQuery({promo})
    },
    changeSelectedTypeTourism(selected) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setTourismTypeId', selected.id)
      this.$instantReplaceQuery({tourism_type_id: selected.id})
    },
    changeCityId(selected) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setCityId', selected.id)
      if (!this.$config.singleCity) {
        this.$instantReplaceQuery({city_id: selected.id})
      }
    },
    changeSeasonsId(selected) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setSeasonId', selected.id)
      this.$instantReplaceQuery({season_id: selected.id})
    },
    changeLevelId(selected) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setLevelId', selected.id)
      this.$instantReplaceQuery({level_id: selected.id})
    },
    changeAgeRestrictionId(selected) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setAgeRestrictionId', selected.id)
      this.$instantReplaceQuery({age_restriction_id: selected.id})
    },
    changeSort(sort) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setSort', sort)
      this.$instantReplaceQuery({sort})
    },
    changeCategoriesForCheckbox(categories) {
      this.$store.commit('pages/routes/setPage', null)
      this.$store.commit('pages/routes/setCategoriesForCheckbox', categories)
      this.$instantReplaceQuery({category_id: categories.filter(item => item.checked).map(item => item.id).join(',')})
    },
  }
}
