//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionAgencyLegalInformationName",
  props: {
    name: {
      type: String,
      default: () => ""
    }
  }
};
