import { parser } from '@/utils/parser'

export const state = () => ({
  news: null,
  pending: false
})

export const mutations = {
  clear: (state) => {
    state.news = null
    state.meta = null
  },
  setNews: (state, news) => state.news = news,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/news/${payload.id}?${parser(payload)}`)
      .then(response => commit('setNews', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  news: state => state.news,
  pending: state => state.pending,

  additions: state => state.news?.additions || [],
  annotation: state => state.news?.annotation || '',
  createdAt: state => state.news?.created_at || '',
  description: state => state.news?.description || '',
  id: state => state.news?.id || null,
  images: state => state.news?.images || [],
  rutube: state => state.news?.rutube || '',
  status: state => state.news?.status || '',
  title: state => state.news?.title || '',
  type: state => state.news?.type || '',
  updatedAt: state => state.news?.updated_at || '',
  youtube: state => state.news?.youtube || '',
}
