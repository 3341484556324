import {Plugin} from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$functionality inside Vue components
  interface Vue {
    $galleryIndent(): number
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$functionality inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $galleryIndent(): number
  }

  // nuxtContext.$functionality
  interface Context {
    $galleryIndent(): number
  }
}

declare module 'vuex/types/index' {
  // this.$functionality inside Vuex stores
  interface Store<S> {
    $galleryIndent(): number
  }
}

const pluginGalleryIndent: Plugin = ({store}, inject) => {
  inject('galleryIndent', () => {
    switch (store.getters["viewport/media"]) {
      case 'md': {
        return 24
      }
      case 'sm': {
        return 8
      }
      default: {
        return 32
      }
    }
  })
}

export default pluginGalleryIndent
