//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SharingComponent',
  components: {
    SocialsRoundedComponent: () => import('@/components/common/SocialsRoundedComponent')
  },
  props: {
    url: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    socialsSharingLinks() {
      if (this.url) {
        return {
          vk: `https://vk.com/share.php?url=${this.url}`,
          ok: `https://connect.ok.ru/offer?url=${this.url}`,
          // facebook: `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
        }
      } else return []
    }
  }
}
