//
//
//
//
//
//
//
//
//
//

export default {
  name: "MobileNavigation",
  components: {
    BurgerButton: () => import('@/components/common/BurgerButton')
  }
}
