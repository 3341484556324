import { parser } from '~/utils/parser'

export const state = () => ({
  show: true,
  groups: [],
  socials: {},
  credits: [],
  documents: []
})

export const mutations = {
  setShow: (state, show) => state.show = show,
  setGroups: (state, groups) => state.groups = groups,
  setSocials: (state, socials) => state.socials = socials,
  setCredits: (state, credits) => state.credits = credits,
  setDocuments: (state, documents) => state.documents = documents,
}

export const actions = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetch ({ commit }, payload) {
    await this.$axios.$get(`/api/footer?${parser(payload)}`)
      .then((response) => {
        commit('setGroups', response.data.groups || [])
        commit('setDocuments', response.data.documents || [])
        commit('setSocials', response.data.socials || {})
        commit('setCredits', response.data.credits || [])
      })
      .catch((error) => {
        console.error('Footer Error: ', error.message)
      })
  }
}

export const getters = {
  show: state => state.show,
  groups: state => state.groups,
  socials: state => state.socials,
  credits: state => state.credits,
  documents: state => state.documents,
}
