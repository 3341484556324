import { parser } from '@/utils/parser'

export const state = () => ({
  journal: null,
  pending: false,
})

export const mutations = {
  setJournal: (state, journal) => state.journal = journal,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/journals/${payload.id}?${parser(payload)}`)
      .then(response => commit('setJournal', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  journal: state => state.journal,
  pending: state => state.pending,
}
