//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: 'HeaderNavigationList',
  components: {
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline'),
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    HeaderNavigationLink: () => import('@/components/common/HeaderNavigation/HeaderNavigationLink.vue')
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: () => '',
    },
    array: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      opened: 'header-navigation/opened',
      viewport: 'viewport/media'
    }),
    is() {
      return this.index === this.opened &&
      typeof this.opened === 'number' ?
        'Icon24FillArrowTop' :
        'Icon24FillArrowBottom'
    },
    mobileDevices() {
      return this.viewport === 'sm' || this.viewport === 'md'
    }
  },
  methods: {
    toggleList() {
      this.$store.commit('header-navigation/setOpened',
        this.index === this.opened &&
        typeof this.opened === 'number' ?
          null :
          this.index
      )
    },
    closePopup() {
      this.$store.commit('header-navigation/setOpened', null)
    },
    props(item) {
      let props = {}
      if (item?.title) {
        props = {...props, title: item.title}
      }
      if (item?.link) {
        props = {...props, link: item.link}
      }
      if (item?.contents) {
        props = {...props, array: item.contents}
      }
      return props
    }
  }
}
