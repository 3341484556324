//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentCalories",
  components: {
    Icon24FillCalories: () => import('@/assets/icons/Icon24FillCalories.svg?inline')
  },
  props: {
    calories: {
      type: [Number, String],
      default: () => ''
    }
  }
}
