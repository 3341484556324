//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentAddress",
  components: {
    Icon24FillLocation: () => import('@/assets/icons/Icon24FillLocation.svg?inline')
  },
  props: {
    address: {
      type: String,
      default: () => ''
    }
  }
}
