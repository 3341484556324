//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FilterToggle',
  components: {
    Icon24FillFavorites: () => import('@/assets/icons/Icon24FillFavorites.svg?inline'),
    Icon24FillInvalid: () => import('@/assets/icons/Icon24FillInvalid.svg?inline'),
    Icon24FillClock: () => import('@/assets/icons/Icon24FillClock.svg?inline'),
    Icon24FillTickets: () => import('@/assets/icons/Icon24FillTickets.svg?inline')
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default () {
        return this.$t('components.filter-toggle.default')
      }
    },
    checked: {
      type: Boolean,
      required: true
    },
    icon: {
      type: String,
      default: () => 'Icon24FillFavorites'
    }
  },
  data () {
    return {
      random: Math.random()
    }
  },
  computed: {
    model: {
      get () {
        return this.checked
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  }
}
