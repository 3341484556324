export default async function ({
  app,
  store,
  redirect
}) {
  if (store.getters['user/user']) {
    await store.dispatch('user/orders', {
      params: {
        user: 'current',
        lang: app.i18n.locale,
        count: 100500
      }
    })
  } else {
    redirect('/login')
  }
}
