//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TabsLine',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    divider: {
      type: Boolean,
      default: () => false
    },
    current: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      currentId: 0
    }
  },
  mounted () {
    this.currentId = this.current
    this.$emit('change-tab', this.currentId)
  },
  methods: {
    changeTab (id) {
      this.currentId = id
      this.$emit('change-tab', id)
    }
  }
}
