import {Plugin} from '@nuxt/types'

type Locale = string

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $prefixLocale(divider?: boolean): Locale
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $prefixLocale(divider?: boolean): Locale
  }

  // nuxtContext.$cardFromEntity
  interface Context {
    $prefixLocale(divider?: boolean): Locale
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $prefixLocale(divider?: boolean): Locale
  }
}

const pluginPrefixLocale: Plugin = ({app,$config}, inject) => {
  inject('prefixLocale', (divider = true) => {
    if (app.i18n.locale !== $config.defaultLocale) {
      return `${divider ? '/' : ''}${app.i18n.locale}`
    }
    return `${divider ? '/' : ''}`
  })
}

export default pluginPrefixLocale
