//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {parser} from "@/utils/parser";

export default {
  name: "TravelAgencyExcursions",
  components: {
    Section: () => import("@/components/common/Section"),
    Button: () => import("@/components/common/Button"),
    ExcursionCard: () => import('@/components/common/ExcursionCard'),
  },
  data() {
    return {
      excursions: [],
      meta: null,
      pending: false,
      count: 6,
      page: 1
    }
  },
  computed: {
    agency_id() {
      return this.$route.params.id
    },
    showButton() {
      return this.meta.last_page > this.meta.current_page
    }
  },
  mounted() {
    this.request()
  },
  methods: {
    logo(agency) {
      if (agency?.image) {
        return agency.image
      }
      return require('@/assets/images/default-image.svg')
    },
    request() {
      const params = {
        agency_id: this.agency_id,
        count: this.count,
        page: this.page,
        lang: this.$i18n.locale,
        resolution: 'micro'
      }
      this.pending = true
      this.$axios.$get(`/api/excursions?${parser({
        params
      })}`)
        .then(response => {
          this.excursions = [...this.excursions, ...response.data]
          this.meta = response.meta
        })
        .catch(() => null)
        .finally(() => this.pending = false)
    },
    next() {
      this.page += 1
      this.request()
    }
  },
}
