//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: 'MapNavigationTabs',
  computed: {
    ...mapGetters({
      id: 'interactive-map/id',
      entity: 'interactive-map/entity',
      category_id: 'interactive-map/category_id',
    }),
    showTabs() {
      return !(this.entity === 'categories' && this.category_id) && !this.id
    },
    tabs() {
      return [
        {
          id: 'places',
          title: this.$t('components.map-navigation-tabs.places')
        },
        {
          id: 'categories',
          title: this.$t('components.map-navigation-tabs.types')
        },
        {
          id: 'routes',
          title: this.$t('components.map-navigation-tabs.routes')
        },
        {
          id: 'layers',
          title: this.$t('components.map-navigation-tabs.layers')
        }
      ]
    }
  },
  methods: {
    click(id) {
      this.$store.commit('interactive-map/setCategoryId', null)
      this.$store.commit('interactive-map/setEntity', id);
    }
  }
}
