//
//
//
//
//
//
//
//
//

export default {
  name: 'Tag',
  props: {
    gap: {
      type: String,
      default: () => '16px'
    },
    color: {
      type: String,
      default: () => 'var(--dark-color)'
    },
    background: {
      type: String,
      default: () => 'transparent'
    },
    borderColor: {
      type: String,
      default: () => 'var(--dark-color)'
    }
  },
  computed: {
    style () {
      return {
        margin: this.gap,
        color: this.color,
        background: this.background,
        borderColor: this.borderColor
      }
    }
  }
}
