//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import accountAgencyExcursionValidation from '@/middleware/account-agency-excursion-validation'
// import seo from '@/middleware/seo'

export default {
  name: 'AccountAgencyExcursionView',
  components: {
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    Status: () => import('@/components/common/Status'),
    Section: () => import('@/components/common/Section/index'),
    Gallery: () => import('@/components/common/Gallery/index'),
    AgencyExcursionSchedules: () => import('@/components/common/AgencyExcursionSchedules/index'),
    AgencyExcursionPlaces: () => import('@/components/common/AgencyExcursionPlaces/index')
  },
  layout: 'personal-area-agency',
  // middleware: [accountAgencyExcursionValidation, seo],
  middleware: [accountAgencyExcursionValidation],
  async fetch ({
    store,
    app
  }) {
    await store.dispatch('tourism-types/fetch', {
      params: {
        count: 100500,
        lang: app.i18n.locale
      }
    })
  },
  head () {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      categories: 'categories/excursions',
      excursion: 'account-agency-excursion/excursion'
    }),
    title () {
      return this?.excursion?.title || ''
    },
    description () {
      return this?.excursion?.description || ''
    },
    additions() {
      return this?.excursion?.additions
    },
    logotype () {
      return this?.excursion?.images[0] || require('~/assets/images/default-image.svg')
    },
    images () {
      return this?.excursion?.images?.map((item, index) => {
        return {
          id: index,
          image: item,
          title: '',
          description: ''
        }
      })
    },
    places () {
      return this?.excursion?.places || []
    },
    status () {
      return this?.excursion?.status
    },
    url () {
      return this.$store.state.host + this?.$route?.fullPath
    },
    category () {
      return this?.excursion?.category
    },
    tourism_type () {
      return this?.excursion?.tourism_type
    },
    level () {
      return this?.excursion?.level
    },
    information () {
      let array = []
      if (this?.excursion?.city?.title) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-city'),
          value: this?.excursion?.city?.title
        }]
      }
      if (this?.category?.title) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-subtype'),
          value: this?.category?.title
        }]
      }
      if (this?.tourism_type?.title) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-tourism-type'),
          value: this?.tourism_type?.title
        }]
      }
      if (this?.level?.title) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-level'),
          value: this?.level?.title
        }]
      }
      if (this?.excursion?.duration_int) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-duration'),
          value: this.$duration(this?.excursion?.duration_int)
        }]
      }
      if (this?.excursion?.distance_int) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-distance'),
          value: this.$distance(this?.excursion?.distance_int)
        }]
      }
      if (this?.excursion?.accessible_environment) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-accessible-environment-title'),
          value: this.$t('pages.account-agency-excursion-view.information-accessible-environment-yes')
        }]
      } else {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-accessible-environment-title'),
          value: this.$t('pages.account-agency-excursion-view.information-accessible-environment-no')
        }]
      }
      if (this?.excursion?.places?.length > 0) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-places'),
          value: this?.excursion?.places?.length
        }]
      }
      if (this?.excursion?.languages?.length > 0) {
        array = [...array, {
          name: this.$t('pages.account-agency-excursion-view.information-languages'),
          value: this?.excursion?.languages.join(', ')
        }]
      }
      return array
    }
  },
  created () {
    this.$store.commit('layout-personal-area-agency/setButtonBackLink', `/account/agencies/${this.$route.params.agency_id}/excursions`)
    this.$store.commit('layout-personal-area-agency/setButtonBackTitle', this.$t('pages.account-agency-excursion-view.link-my-excursions'))
  },
  methods: {
    openPopup () {
      this.$store.commit('popup/open', {
        name: 'PopupAddExcursion',
        params: {
          style: {
            width: '632px',
            maxHeight: '100vh'
          }
        },
        options: this?.excursion
      })
    }
  }
}
