//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AgencyPlacesCard',
  components: {
    Status: () => import('@/components/common/Status')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    title: {
      type: String,
      default: () => ''
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-image.svg')
    },
    address: {
      type: String,
      default: () => ''
    },
    rating: {
      type: Number,
      default: () => 0
    },
    status: {
      type: String,
      default: () => ''
    }
  }
}
