import { parser } from '@/utils/parser'

export const state = () => ({
  municipality: null,
  pending: false,
})

export const mutations = {
  setPending: (state, pending) => state.pending = pending,
  setMunicipality: (state, municipality) => state.municipality = municipality,
  clear: state => state.municipality = null
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload?.clear) {
      commit('clear')
    }
    commit('setPending', true)
    await this.$axios.$get(`/api/cities?${parser(payload)}`)
      .then(resolve => {
        commit('setMunicipality', resolve.data[0])
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  pending: state => state.pending,
  municipality: state => state.municipality,

  created_at: state => state.municipality?.created_at || null,
  deleted_at: state => state.municipality?.deleted_at || null,
  description: state => state.municipality?.description || '',
  excursionsCount: state => state.municipality?.excursionsCount || 0,
  id: state => state.municipality?.id || null,
  image: state => state.municipality?.image || '',
  latitude: state => state.municipality?.latitude || '',
  longitude: state => state.municipality?.longitude || '',
  phone: state => state.municipality?.phone || '',
  placesCount: state => state.municipality?.placesCount || 0,
  routesCount: state => state.municipality?.tripsCount || 0,
  slug: state => state.municipality?.slug || '',
  title: state => state.municipality?.title || '',
  updated_at: state => state.municipality?.updated_at || null,
  zoom: state => state.municipality?.zoom || 0,
}
