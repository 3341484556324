//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import seo from '@/middleware/seo'

export default {
  name: 'NewsView',
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    Button: () => import('@/components/common/Button/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    SectionEmptyEntity: () => import('~/components/common/SectionEmptyEntity'),
    NewsCard: () => import('@/components/common/NewsCard/index'),
    PendingButton: () => import('@/components/common/PendingButton/index'),
    Section: () => import('@/components/common/Section/index')
  },
  middleware: [seo],
  data () {
    return {
      count: 12,
      page: 1
    }
  },
  head () {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      news: 'news/news',
      meta: 'news/meta',
      pending: 'news/pending',
      city: 'cities/default',
    }),
    components() {
      return this.$config?.pages?.NewsView?.components
    },
    showSectionBannerAppMobile () {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showTopTitleComponent() {
      return this.components?.TopTitleComponent
    },
    showNews() {
      return this.components?.News
    },
    showButton () {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    params () {
      return {
        city_id: this.$config.singleCity ? this.city.id : null,
        page: this.page,
        count: this.count,
        resolution: 'micro',
        lang: this.$i18n.locale
      }
    },
    url () {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  created () {
    this.$store.dispatch('news/fetch', {
      clear: true,
      params: this.params
    })
  },
  methods: {
    next () {
      if (this.meta.current_page < this.meta.last_page) {
        this.page += 1
        this.$store.dispatch('news/fetch', {
          params: this.params
        })
      }
    }
  }
}
