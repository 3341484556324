export default [
  {
    rel: 'preload',
    as: 'font',
    href: require('~/assets/fonts/Golos/black/Golos-Text_Black.woff2'),
    type: 'font/woff2',
    crossorigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: require('~/assets/fonts/Golos/bold/Golos-Text_Bold.woff2'),
    type: 'font/woff2',
    crossorigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: require('~/assets/fonts/Golos/demi-bold/Golos-Text_DemiBold.woff2'),
    type: 'font/woff2',
    crossorigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: require('~/assets/fonts/Golos/medium/Golos-Text_Medium.woff2'),
    type: 'font/woff2',
    crossorigin: 'anonymous'
  },
  {
    rel: 'preload',
    as: 'font',
    href: require('~/assets/fonts/Golos/regular/Golos-Text_Regular.woff2'),
    type: 'font/woff2',
    crossorigin: 'anonymous'
  }
]
