import {parser} from "~/utils/parser";

export const state = () => ({
  menu: [],
  opened: null,
  pending: false
})

export const actions = {
  async fetch({commit}, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/menu?${parser(payload)}`)
      .then((response) => {
        commit('setMenu', response.data)
      })
      .catch(() => {
        commit('setMenu', [])
      })
      .finally(() => commit('setPending', false))
  }
}

export const mutations = {
  setOpened: (state, opened) => state.opened = opened,
  setMenu: (state, menu) => state.menu = menu,
  setPending: (state, pending) => state.pending = pending
}

export const getters = {
  menu: state => state.menu,
  pending: state => state.pending,
  opened: state => state.opened
}
