//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import accountAgencyProfileValidation from "@/middleware/account-agency-profile-validation";

export default {
  name: 'WidgetsView',
  components: {
    Section: () => import('@/components/common/Section'),
    RoundButton: () => import('@/components/common/RoundButton'),
    Icon24FillPlus: () => import('@/assets/icons/Icon24FillPlus.svg?inline'),
    AccordionWidgets: () => import('@/components/common/AccordionWidgets')
  },
  layout: 'personal-area-agency',
  // validate() {
  //   return process.env.NODE_ENV !== 'production'
  // },
  middleware: [accountAgencyProfileValidation],
  computed: {
    ...mapGetters({
      widgets: 'widgets/widgets',
      pending: 'widgets/pending',
    }),
    locale() {
      return this.$i18n.locale !== 'ru' ? `/${this.$i18n.locale}` : ''
    }
  },
  created () {
    this.$store.commit('layout-personal-area-agency/setButtonBackLink', '/account/agencies')
    this.$store.commit('layout-personal-area-agency/setButtonBackTitle', this.$t('layouts.personal-area-layout.link-my-organizations'))
  },
  mounted() {
    this.$store.dispatch('widgets/fetch', {
      clear: true,
      params: {
        user: 'current'
      }
    })
  }
}
