//
//
//
//
//
//
//
//
//

export default {
  name: 'LinkPhone',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    phone() {
      return this.value.match(/\d+/g).join()
    },
    href() {
      return `tel:+${this.phone}`
    }
  }
}
