//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "PopupStoryCard",
  components: {
    PopupStoryCardTopBar: () => import("~/components/common/PopupStory/PopupStoryCardTopBar.vue"),
    PopupStoryCardButton: () => import("~/components/common/PopupStory/PopupStoryCardButton.vue"),
    EmptyPopupStoryCard: () => import("@/components/common/PopupStory/EmptyPopupStoryCard.vue"),
  },
  computed: {
    ...mapGetters({
      pending: 'story/pending',
      story: 'story/story',
      parts: 'story/parts',
      image: 'story/image',
      title: 'story/title',
      description: 'story/description',
      partCurrentIndex: 'story/partCurrentIndex',
    }),
    imageMedium() {
      return this.image?.replace('micro', 'medium')
    },
  },
  mounted() {
    this.$store.commit('story/setPending', true)
  },
  destroyed() {
    this.$store.commit('story/clear')
  },
  methods: {
    nextStory() {

    },
    prevStory() {

    },
    nextPart() {
      if (this.partCurrentIndex < this.parts.length - 1) {
        this.$store.commit('story/setPending', true)
        this.$store.commit('story/setPartCurrentIndex', this.partCurrentIndex + 1)
      }
    },
    prevPart() {
      if (this.partCurrentIndex > 0) {
        this.$store.commit('story/setPending', true)
        this.$store.commit('story/setPartCurrentIndex', this.partCurrentIndex - 1)
      }
    },
    load() {
      this.$store.commit('story/setPending', false)
    },
  },
}
