import {Plugin} from '@nuxt/types'
import {Socials} from "~/types/socials";
import {SocialKey} from "~/types/social-key";

interface SocialsParams {
  socials: Socials,
  output: 'object' | 'array' | undefined
}

declare module 'vue/types/vue' {
  interface Vue {
    $socials(params: SocialsParams): Socials | object[]
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $socials(params: SocialsParams): Socials | object[]
  }

  interface Context {
    $socials(params: SocialsParams): Socials | object[]
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $socials(params: SocialsParams): Socials | object[]
  }
}

const pluginSocials: Plugin = ({store}, inject) => {
  inject('socials', ({socials, output = 'object'}: SocialsParams): Socials | object[] => {
    if (socials) {
      if (output === 'object') {
        const filtered: Socials = {};
        (Object.keys(socials) as Array<SocialKey>).forEach((key) => {
          if (socials[key]) {
            filtered[key] = socials[key];
          }
        });
        return filtered
      }
      if (output === 'array') {
        let filtered: object[] = [];
        (Object.keys(socials) as Array<SocialKey>).forEach((key) => {
          if (socials[key]) {
            // @ts-ignore
            filtered = [...filtered, socials[key]];
          }
        });
        return filtered
      }
    }
    return output === 'array' ? [] : {}
  })
}

export default pluginSocials
