//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {accountMenu} from "@/configs/account.config.ts";

const components = {}
accountMenu.forEach(item => {
  components[item.icon] = () => import(`@/assets/icons/${item.icon}.svg?inline`)
})

export default {
  name: 'PersonalAreaMenu',
  components: {
    Icon24FillDot: () => import('@/assets/icons/Icon24FillDot.svg?inline'),
    Icon24FillExit: () => import('@/assets/icons/Icon24FillExit.svg?inline'),
    ...components
  },
  data() {
    return {
      menu: accountMenu
    }
  },
  methods: {
    changeShowById(id) {
      this.menu = this.menu.map((item) => {
        if (item.id === id) {
          item.show = !item.show
        }
        return item
      })
    }
  }
}
