//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    margin: {
      type: Boolean,
      default: () => false
    },
    divider: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      currentIndex: 0
    }
  },
  methods: {
    changeTab (i) {
      this.currentIndex = i
    }
  }
}
