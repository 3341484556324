//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "PhotoBankCard",
  components: {
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    image: {
      type: String,
      default: () => require("@/assets/images/default-image.svg")
    },
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    },
    sourceTitle: {
      type: String,
      default: () => ""
    },
    sourceLink: {
      type: String,
      default: () => ""
    },
    id: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    ...mapGetters({
      photos: "photos/photos",
    }),
    linkName () {
      return this.sourceTitle || (new URL(this.sourceLink || '')).hostname || ''
    }
  },
  methods: {
    viewSlider() {
      this.$store.commit("popup/open", {
        name: "PopupFullSizeSlider",
        options: {
          list: this.photos,
          active: this.id
        }
      });
    },
  }
};
