import { parser } from '@/utils/parser'

export const state = () => ({
  routes: [],
  meta: null,
  pending: false
})

export const mutations = {
  clear: (state) => {
    state.routes = []
    state.meta = null
  },
  setRoutes: (state, routes) => {
    state.routes = [...state.routes, ...routes];
  },
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    const routes = await this.$axios.$get(`/api/routes?${parser(payload)}`)
    commit('setRoutes', routes.data)
    commit('setMeta', routes.meta)
    commit('setPending', false)
  }
}

export const getters = {
  routes: state => state.routes,
  meta: state => state.meta,
  pending: state => state.pending
}
