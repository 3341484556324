//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "MapNavigationLayer",
  components: {
    EmptyEntity: () => import("@/components/common/EmptyEntity"),
    LayerNote: () => import("@/components/common/LayerNote"),
    ImageComponent: () => import("@/components/common/ImageComponent"),
    MapNavigationBackButton: () => import("@/components/common/MapNavigation/MapNavigationBackButton.vue"),
    VueCustomScrollbar: () => import("vue-custom-scrollbar"),
    Icon24FillClose: () => import("@/assets/icons/Icon24FillClose.svg?inline"),
    InputComponent: () => import("@/components/common/InputComponent/index")
  },
  data() {
    return {
      showSearchResult: false,
      search: "",
      resultAreas: []
    };
  },
  computed: {
    ...mapGetters({
      entity: "interactive-map/entity",
      id: "interactive-map/id",
      layer: "interactive-map/layer",
      areas: "interactive-map/areas"
    }),
    showLayer() {
      return this.entity === "layers" && this.id;
    },
    title() {
      return this.layer?.title || "";
    },
    image() {
      return this.layer?.image || "";
    },
    description() {
      return this.layer?.description || "";
    },
  },
  watch: {
    id() {
      this.clearSearch();
    },
    search() {
      if (this.search.length >= 3) {
        this.searchAreas();
      }
      if (this.search.length === 0) {
        this.clearSearch();
      }
    }
  },
  methods: {
    clearSearch() {
      this.showSearchResult = false;
      this.search = "";
      this.resultAreas = [];
    },
    searchAreas() {
      this.showSearchResult = true;
      this.resultAreas = this.areas.filter(item =>
        item.title.toUpperCase().includes(this.search.toUpperCase()) ||
        item.description.toUpperCase().includes(this.search.toUpperCase()));
    }
  }
};
