//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AdditionMail',
  components: {
    LinkMail: () => import('@/components/common/LinkMail')
  },
  props: {
    title: {
      type: String,
      default: () => 'E-mail'
    },
    value: {
      type: String,
      default: () => ''
    },
    divider: {
      type: Boolean,
      default: () => false
    }
  }
}
