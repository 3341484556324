//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HorizontalLayerCard",
  components: {
    LabelTag: () => import("@/components/common/LabelTag"),
    RoundButton: () => import("@/components/common/RoundButton"),
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    id: {
      type: Number,
      default: () => null
    },
    number: {
      type: Number,
      default: () => null
    },
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    },
    image: {
      type: String,
      default: () => require("@/assets/images/default-image.svg")
    },
    withoutImage: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: () => ""
    },
    label: {
      type: String,
      default: () => ''
    }
  },
};
