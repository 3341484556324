//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AccountAgencyOrder',
  components: {
    Button: () => import('@/components/common/Button'),
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline'),
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Status: () => import('@/components/common/Status'),
    RoundButton: () => import('@/components/common/RoundButton')
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    customer_name: {
      type: String,
      default: () => ''
    },
    payable: {
      type: Boolean,
      required: true
    },
    status: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    customer_email: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    customer_phone: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    created_at: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    updated_at: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    order_items: {
      type: Array,
      required: true
    },
    // eslint-disable-next-line vue/prop-name-casing
    total_price: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/prop-name-casing
    paid_datetime: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    canceled_datetime: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    confirmed_datetime: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/prop-name-casing
    sm_registered: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    orderTotalPayment () {
      return this?.paid_datetime === 0 || !this?.paid_datetime
        ? '-'
        : this.$moment.unix(this?.paid_datetime)
          .format('DD.MM.YYYY HH:mm')
    },
    agency_id () {
      return this.$route.params.agency_id
    },
    number () {
      return `${this.id}`.padStart(10, '0')
        .padStart(12, '№ ')
    },
    showTogglerButtons () {
      return (this.status === 'paid' && this.payable === true) || (this.status === 'new' && this.payable === false)
    },
    showTogglerSendCheckButton () {
      return this.status === 'confirmed' && this.payable === false && this.total_price > 0 && this.sm_registered === true
    }
  },
  methods: {
    confirm () {
      this.$axios.$post(`/api/agencies/${this.agency_id}/orders/${this.id}/confirm`)
        .finally(() => this.$store.dispatch('account-agency-orders/search', {
          id: this.$route.params.agency_id,
          clear: true,
          params: {
            page: 1,
            count: 10,
            lang: this.$i18n.locale,
            payable: `${+this.payable}`
          }
        }))
    },
    cancel () {
      this.$axios.$post(`/api/agencies/${this.agency_id}/orders/${this.id}/cancel`)
        .finally(() => this.$store.dispatch('account-agency-orders/search', {
          id: this.$route.params.agency_id,
          clear: true,
          params: {
            page: 1,
            count: 10,
            lang: this.$i18n.locale,
            payable: `${+this.payable}`
          }
        }))
    },
    sendCheck () {
      this.$axios.$put(`/api/agencies/${this.agency_id}/orders/${this.id}?&payable=1`)
        .finally(() => this.$store.dispatch('account-agency-orders/search', {
          id: this.$route.params.agency_id,
          clear: true,
          params: {
            page: 1,
            count: 10,
            lang: this.$i18n.locale,
            payable: `${+this.payable}`
          }
        }))
    },
    click () {
      this.show = !this.show
    }
  }
}
