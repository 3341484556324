//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DocumentationLayout',
  components: {
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    Section: () => import('@/components/common/Section')
  }
}
