//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BannerLoyaltyProgram',
  props: {
    background: {
      type: String,
      default: () => require('@/assets/images/loyality-program-background.jpg')
    },
    points: {
      type: Number,
      default: () => 0
    },
    status: {
      type: String,
      default: () => ''
    }
  }
}
