//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import accountAgencyOrdersValidation from '@/middleware/account-agency-orders-validation'
// import seo from '@/middleware/seo'

export default {
  name: 'AccountAgencyOrdersView',
  components: {
    AccountAgencyOrdersViewComponent: () => import('@/components/common/AccountAgencyOrdersViewComponent'),
    TabsLine: () => import('@/components/common/TabsLine'),
    Section: () => import('@/components/common/Section')
  },
  layout: 'personal-area-agency',
  // middleware: [accountAgencyOrdersValidation, seo],
  middleware: [accountAgencyOrdersValidation],
  data () {
    return {
      tabs: [
        {
          id: 1,
          title: `${this.$t('pages.account-agency-orders-view.tab-order')}`
        },
        {
          id: 0,
          title: `${this.$t('pages.account-agency-orders-view.tab-request')}`
        }
      ],
      payable: 0
    }
  },
  head () {
    return {
      title: this.$t('pages.account-agency-orders.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  methods: {
    changeTab(id) {
      this.payable = id
      this.$store.dispatch('account-agency-orders/fetch', {
        id: this.$route.params.agency_id,
        clear: true,
        params: {
          page: 1,
          count: this.$store.getters['account-agency-orders-view/count'],
          lang: this.$i18n.locale,
          payable: `${this.payable}`
        }
      })
    }
  }
}
