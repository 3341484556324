//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EmptyEntity',
  props: {
    title: {
      type: String,
      default () {
        return this.$t('components.empty-entity.title')
      }
    },
    description: {
      type: String,
      default () {
        return this.$t('components.empty-entity.description')
      }
    }
  }
}
