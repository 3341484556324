function getMode () {
  if (process.browser) {
    if (window.matchMedia('(orientation: portrait)').matches) {
      return 'portrait'
    } else if (window.matchMedia('(orientation: landscape)').matches) {
      return 'landscape'
    }
    return null
  }
  return null
}

export const state = () => ({
  browser: null,
  width: '',
  height: '',
  media: null,
  os: null,
  sm: false,
  md: false,
  lg: false,
  hd: false,
  mode: null,
  scroll: 0,
  size: {
    sm: 320,
    md: 624,
    lg: 960,
    hd: 1168
  }
})

export const getters = {
  viewport: state => state,
  width: state => state.width,
  media: state => state.media,
  sm: state => state.sm,
  md: state => state.md,
  lg: state => state.lg,
  hd: state => state.hd,
  mode: state => state.mode,
  scroll: state => state.scroll,
  os: state => state.os,
  browser: state => state.browser
}

export const mutations = {
  setOS: (state, os) => {
    state.os = os
  },
  setBrowser: (state, browser) => {
    state.browser = browser
  },
  setType: (state, type) => {
    state.mode = getMode()
    if (type === 'sm') {
      state.sm = true
      state.md = false
      state.lg = false
      state.hd = false
    }
    if (type === 'md') {
      state.sm = false
      state.md = true
      state.lg = false
      state.hd = false
    }
    if (type === 'lg') {
      state.sm = false
      state.md = false
      state.lg = true
      state.hd = false
    }
    if (type === 'hd') {
      state.sm = false
      state.md = false
      state.lg = false
      state.hd = true
    }
    state.media = type
  },
  setScroll: (state, scroll) => {
    state.scroll = scroll
  },
  setViewport: (state, {
    width,
    height
  }) => {
    state.width = width
    state.height = height
    state.mode = getMode()
    if (width < state.size.md) {
      state.sm = true
      state.md = false
      state.lg = false
      state.hd = false
      state.media = 'sm'
    } else if (width >= state.size.md && width < state.size.lg) {
      state.sm = false
      state.md = true
      state.lg = false
      state.hd = false
      state.media = 'md'
    } else if (width >= state.size.lg && width < state.size.hd) {
      if (state.mode === 'portrait') {
        state.sm = false
        state.md = true
        state.lg = false
        state.hd = false
        state.media = 'md'
      } else {
        state.sm = false
        state.md = false
        state.lg = true
        state.hd = false
        state.media = 'lg'
      }
    } else if (width >= state.size.hd) {
      state.sm = false
      state.md = false
      state.lg = false
      state.hd = true
      state.media = 'hd'
    }
  }
}

export const actions = {}
