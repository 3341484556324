//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ArticleTop',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    annotation: {
      type: String,
      default: () => ''
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-image.svg')
    }
  }
}
