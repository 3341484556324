import { parser } from '@/utils/parser'

export const state = () => ({
  search: [],
  all: [],
  allMeta: null,

  events: [],
  eventsMeta: null,
  eventsPending: false,

  news: [],
  newsMeta: [],
  newsPending: false,

  places: [],
  placesMeta: null,
  placesPending: false,

  routes: [],
  routesMeta: null,
  routesPending: false,

  excursions: [],
  excursionsMeta: null,
  excursionsPending: null,

  others: [],
  othersMeta: null,
  history: []
})

export const mutations = {
  setSearch: (state, search) => state.search = search,

  setEvents: (state, events) => state.events = [...state.events, ...events],
  setEventsMeta: (state, meta) => state.eventsMeta = meta,
  setEventsPending: (state, pending) => state.eventsPending = pending,
  clearEvents: (state) => {
    state.events = []
    state.eventsMeta = null
  },

  setPlaces: (state, places) => state.places = [...state.places, ...places],
  setPlacesMeta: (state, meta) => state.placesMeta = meta,
  setPlacesPending: (state, pending) => state.placesPending = pending,
  clearPlaces: (state) => {
    state.places = []
    state.placesMeta = null
  },

  setNews: (state, news) => state.news = [...state.news, ...news],
  setNewsMeta: (state, meta) => state.newsMeta = meta,
  setNewsPending: (state, pending) => state.newsPending = pending,
  clearNews: (state) => {
    state.news = []
    state.newsMeta = null
  },

  setRoutes: (state, routes) => state.routes = [...state.routes, ...routes],
  setRoutesMeta: (state, meta) => state.routesMeta = meta,
  setRoutesPending: (state, pending) => state.routesPending = pending,
  clearRoutes: (state) => {
    state.routes = []
    state.routesMeta = null
  },

  setExcursions: (state, excursions) => state.excursions = [...state.excursions, ...excursions],
  setExcursionsMeta: (state, meta) => state.excursionsMeta = meta,
  setExcursionsPending: (state, pending) => state.excursionsPending = pending,
  clearExcursions: (state) => {
    state.excursions = []
    state.excursionsMeta = null
  },

  clearAll: (state) => {
    state.places = []
    state.events = []
    state.excursions = []
    state.routes = []
    state.news = []

    state.placesMeta = null
    state.eventsMeta = null
    state.excursionsMeta = null
    state.routesMeta = null
    state.newsMeta = null
  }
}

export const actions = {
  async excursions ({ commit }, payload) {
    commit('setExcursionsPending', true)
    if (payload?.clear) {
      commit('clearExcursions')
    }
    const excursions = await this.$axios.$get(`/api/search/excursions?${parser(payload)}`)
    commit('setExcursions', excursions.data)
    commit('setExcursionsMeta', excursions.meta)
    commit('setExcursionsPending', false)
  },

  async routes ({ commit }, payload) {
    commit('setRoutesPending', true)
    if (payload?.clear) {
      commit('clearRoutes')
    }
    const routes = await this.$axios.$get(`/api/search/routes?${parser(payload)}`)
    commit('setRoutes', routes.data)
    commit('setRoutesMeta', routes.meta)
    commit('setRoutesPending', false)
  },

  async events ({ commit }, payload) {
    commit('setEventsPending', true)
    if (payload?.clear) {
      commit('clearEvents')
    }
    const events = await this.$axios.$get(`/api/search/events?${parser(payload)}`)
    commit('setEvents', events.data)
    commit('setEventsMeta', events.meta)
    commit('setEventsPending', false)
  },

  async news ({ commit }, payload) {
    commit('setNewsPending', true)
    if (payload?.clear) {
      commit('clearNews')
    }
    const news = await this.$axios.$get(`/api/search/news?${parser(payload)}`)
    commit('setNews', news.data)
    commit('setNewsMeta', news.meta)
    commit('setNewsPending', false)
  },

  async places ({ commit }, payload) {
    commit('setPlacesPending', true)
    if (payload?.clear) {
      commit('clearPlaces')
    }
    const places = await this.$axios.$get(`/api/search/places?${parser(payload)}`)
    commit('setPlaces', places.data)
    commit('setPlacesMeta', places.meta)
    commit('setPlacesPending', false)
  },

  async fetch ({ commit }, payload) {
    const search = await this.$axios.$get(`/api/search?${parser(payload)}`)
    commit('setSearch', search.data)
  }
}

export const getters = {
  search: state => state.search,

  events: state => state.events,
  eventsMeta: state => state.eventsMeta,
  eventsPending: state => state.eventsPending,

  routes: state => state.routes,
  routesMeta: state => state.routesMeta,
  routesPending: state => state.routesPending,

  excursions: state => state.excursions,
  excursionsMeta: state => state.excursionsMeta,
  excursionsPending: state => state.excursionsPending,

  places: state => state.places,
  placesMeta: state => state.placesMeta,
  placesPending: state => state.placesPending,

  news: state => state.news,
  newsMeta: state => state.newsMeta,
  newsPending: state => state.newsPending
}
