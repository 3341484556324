//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionAgencyLegalInformationEmail",
  props: {
    email: {
      type: String,
      default: () => ""
    }
  }
};
