//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "InputComponentSite",
  components: {
    LabelComponent: () => import("@/components/common/LabelComponent"),
    ErrorComponent: () => import("@/components/common/ErrorComponent")
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: () => ""
    },
    value: {
      type: String,
      default: () => ""
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    label: {
      type: String,
      default: () => ""
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    obligatory: {
      type: Boolean,
      default: () => false
    },
    errors: {
      type: Array,
      default: () => []
    },
    autocomplete: {
      type: String,
      default: () => "off"
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    classList() {
      return {
        "input-component-site__input--error": this.errors && this.errors.length > 0,
        "input-component-site__input--disabled": this.disabled,
      };
    },
    val() {
      const httpRegExpPattern1 = '(http|https)'
      const httpRegExpPattern2 = '(http:|https:)'
      if(this.value) {
        const value = this.value.toLowerCase()
        const defaultValue = `http://${this.value}`.toLowerCase()
        if(/[а-яёА-ЯЁ]/g.test(value)) {
          // Если сиволы кирилицы, то они удаляются
          return value.replace(/[а-яёА-ЯЁ]/g,'')
        }
        if(/\s+/.test(value)) {
          // Если сиволы пробела, то они удаляются
          return value.replace(/ /g,'')
        }
        if(RegExp(httpRegExpPattern1, 'i').test(this.value)) {
          if(RegExp(httpRegExpPattern2, 'i').test(this.value)) {
            // Вернет значение без изменений
            return value
          } else {
            if (value.length > 4) {
              if(value.length > 5) {
                // Если нет http:/ или https:, то подставит http://
                return (value[5] !== '/' && value[5] !== ':') ? defaultValue : value
              } else {
                // Если нет http: или https, то подставит http://
                return (value[4] !== ':' && value[4] !== 's') ? defaultValue : value
              }
            } else {
              // Вернет значение без изменений
              return value
            }
          }
        } else if(value[0] === 'h') {
          if(value[1] === 't') {
            if(value[2] === 't') {
              if(value[3] === 'p') {
                // Если после http нет s или :, то подставит http://
                return (value[4] === 's' || value[4] === ':') ? value : defaultValue
              } else {
                // Если после htt нет p, то подставит http://
                return value.length > 3 ? defaultValue : value
              }
            } else {
              // Если после ht нет t, то подставит http://
              return value.length > 2 ? defaultValue : value
            }
          } else {
            // Если после h нет t, то подставит http://
            return value.length > 1 ? defaultValue : value
          }
        } else {
          // Вернет значение с подставленным в начало http://
          return defaultValue
        }
      } else {
        // Вернет пустую строку
        return this.value
      }
    }
  },
  methods: {
    input(e) {
      this.$emit("input", e.target.value, e.target.id);
    },
  }
};
