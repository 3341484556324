import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0f8cd81a&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/InputComponent/index.vue').default,InputComponentSite: require('/srv/rir-platform/tourism/src/tourism-front/components/common/InputComponentSite/index.vue').default,SelectCheckBoxComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SelectCheckBoxComponent/index.vue').default,ColorPickerComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ColorPickerComponent/index.vue').default,WidgetPreview: require('/srv/rir-platform/tourism/src/tourism-front/components/common/WidgetPreview/index.vue').default,SmallTabs: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SmallTabs/index.vue').default,Button: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Button/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default})
