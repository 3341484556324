//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentMail",
  components: {
    LinkMail: () => import('@/components/common/LinkMail'),
    Icon24FillEmail: () => import('@/assets/icons/Icon24FillEmail.svg?inline')
  },
  props: {
    mail: {
      type: String,
      default: () => ''
    }
  }
}
