import { render, staticRenderFns } from "./PopupStoryCard.vue?vue&type=template&id=3236b3c9&"
import script from "./PopupStoryCard.vue?vue&type=script&lang=js&"
export * from "./PopupStoryCard.vue?vue&type=script&lang=js&"
import style0 from "./PopupStoryCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupStoryCardTopBar: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PopupStory/PopupStoryCardTopBar.vue').default,PopupStoryCardButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PopupStory/PopupStoryCardButton.vue').default})
