//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AudioPlayer',
  components: {
    Icon24FillPlay: () => import('@/assets/icons/Icon24FillPlay.svg?inline'),
    Icon24FillPause: () => import('@/assets/icons/Icon24FillPause.svg?inline'),
    Icon24FillClose: () => import('@/assets/icons/Icon24FillClose.svg?inline'),
    Icon24Fill15Decrease: () => import('@/assets/icons/Icon24Fill15Decrease.svg?inline'),
    Icon24Fill15Increase: () => import('@/assets/icons/Icon24Fill15Increase.svg?inline')
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    audios: {
      type: Array,
      default: () => []
    },
    card: {
      type: Boolean,
      default: () => false
    },
    vertical: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      style: {},
      track: null,
      durationTime: null,
      currentTime: '00:00:00',
      plays: false,
      tracker: null,
      volume: null,
      loaded: false,
      index: 0,
      thisSong: null
    }
  },
  computed: {
    song () {
      return this.$store.getters['audio-player/song']
    },
    url () {
      return this.$store.getters['audio-player/url']
    },
    active () {
      return this.$store.getters['audio-player/active']
    },
    uid () {
      return this.$store.getters['audio-player/uid']
    }
  },
  watch: {
    uid (id) {
      if (id === this._uid && this.active) {
        this.initialization()
      }
    }
  },
  mounted () {
    if (!this.card) {
      this.initialization()
    }
    this.$nextTick(() => {
      this.track = this.$refs.range
    })
  },
  beforeDestroy () {
    this.pause()
  },
  methods: {
    initialization () {
      this.pause()
      this.$store.commit('audio-player/setUrl', this.audios[this.index])
      this.$store.commit('audio-player/setSong', this.url)
      this.thisSong = this.song
      this.thisSong.addEventListener('loadedmetadata', () => {
        this.loaded = true
        this.durationTimeFunction()
        if (this.card) {
          this.play()
        }
      })
      this.thisSong.addEventListener('timeupdate', this.currentTimeFunction)
      this.thisSong.addEventListener('ended', this.ended)
    },
    play () {
      if (this.loaded) {
        this.thisSong.play()
        this.plays = true
      }
    },
    pause () {
      this.song?.pause()
      this.plays = false
    },
    changeCurrentTime (e) {
      this.thisSong.currentTime = e.target.value
      if (this.track) {
        const value = (this.track.value - this.track.min) / (this.track.max - this.track.min) || 0
        const percent = value * 100
        this.style = {
          background:
            `linear-gradient(to right, var(--white-80) 0%, var(--white-80) ${percent}%, var(--white-40) ${percent}%, var(--white-40) 100%)`
        }
      }
    },
    currentTimeFunction () {
      this.track.value = this.thisSong.currentTime
      const h = parseInt(this.thisSong.currentTime / 3600, 10)
      const m = parseInt(this.thisSong.currentTime / 60, 10)
      const s = parseInt(this.thisSong.currentTime - (m * 60), 10)
      this.currentTime = `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}` || '00:00:00'
      if (this.track) {
        const value = (this.track.value - this.track.min) / (this.track.max - this.track.min) || 0
        const percent = value * 100
        this.style = {
          background:
              `linear-gradient(to right, var(--white-80) 0%, var(--white-80) ${percent}%, var(--white-40) ${percent}%, var(--white-40) 100%)`
        }
      }
    },
    durationTimeFunction () {
      this.track.max = this.thisSong.duration
      const h = parseInt(this.thisSong.duration / 3600, 10)
      const m = parseInt(this.thisSong.duration / 60, 10)
      const s = parseInt(this.thisSong.duration - (m * 60), 10)
      this.durationTime = `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}` || '00:00:00'
    },
    forwards15second () {
      if (this.loaded) {
        this.thisSong.currentTime += 15
      }
    },
    rearwards15second () {
      if (this.loaded) {
        this.thisSong.currentTime -= 15
      }
    },
    selectAudio (index) {
      this.thisSong.pause()
      this.index = index
      this.initialization()
      this.play()
    },
    ended () {
      this.index += 1
    },
    closePlayer () {
      this.song.pause()
      this.$refs.player.style.display = 'none'
      this.$store.commit('audio-player/setPlay', { active: false })
    }
  }
}
