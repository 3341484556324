//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationCityComponent: () => import('@/components/common/HeaderNavigation/HeaderNavigationCityComponent.vue'),
    HeaderNavigationList: () => import('@/components/common/HeaderNavigation/HeaderNavigationList.vue'),
    HeaderNavigationLink: () => import('@/components/common/HeaderNavigation/HeaderNavigationLink.vue'),
  },
  computed: {
    ...mapGetters({
      menu: 'header-navigation/menu',
      opened: 'header-navigation/opened',
      media: 'viewport/media'
    }),
    viewport() {
      switch (this.media) {
        case 'hd':
          return 'desktop'
        case 'lg':
          return 'laptop'
        case 'md':
          return 'tablet'
        case 'sm':
          return 'mobile'
        default:
          return ''
      }
    },
    items() {
      return this.menu.filter(item => item.viewport.includes(this.viewport))
    },
    mobileDevices() {
      return this.media === 'sm' || this.media === 'md'
    }
  },
  methods: {
    props({item, index}) {
      let props = {}
      if (item?.title) {
        props = {...props, title: item.title}
      }
      if (item?.link) {
        props = {...props, link: item.link}
      }
      if (item?.contents) {
        props = {...props, index, array: item.contents}
      }
      return props
    }
  },
}
