//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapGetters} from 'vuex'

export default {
  name: 'MapNavigationSearch',
  components: {
    Icon24FillSearch: () => import('@/assets/icons/Icon24FillSearch.svg?inline'),
    InputComponent: () => import('@/components/common/InputComponent'),
    SelectComponent: () => import('@/components/common/SelectComponent')
  },
  computed: {
    ...mapGetters({
      id: 'interactive-map/id',
      cities: 'cities/cities',
      city_id: 'interactive-map/city_id',
      search: 'interactive-map/search',
      query: 'interactive-map/query',
      entity: 'interactive-map/entity',
      category_id: 'interactive-map/category_id'
    }),
    showSearch() {
      if (this.id) {
        return false
      } else return !(this.entity === 'categories' && this.category_id || this.entity === 'layers');
      // return !(this.entity === 'categories' || this.entity === 'layers' && this.category_id) && !this.id || !(this.entity === 'layers')
    },
    locale() {
      return this.$i18n.locale
    },
    citiesWithAll() {
      return [{
        id: null,
        title: this.$t('components.map-navigation-search.select-city-default')
      }, ...this.cities]
    }
  },
  methods: {
    input(e) {
      this.$store.commit('interactive-map/setSearch', e)
    }
  }
  // created() {
  //   this.query = this.$route.query.query
  //   if (process.env.SINGLE_CITY) {
  //     this.$store.commit('interactive-map/setCityId', this.$defaultCityId())
  //   }
  // },
  // methods: {
  //   checkCurrentTab() {
  //     if (this.locale === 'ru') {
  //       if (this.$route.query.entity === 'places') {
  //         this.$store.dispatch('interactive-map/query', {
  //           entity: 'places',
  //           params: {
  //             query: this.query,
  //             count: 100500,
  //             lang: this.$i18n.locale,
  //             resolution: 'micro',
  //             show: 'id,title,address,work_hours,images,location,category_id,rating,city_id,favorited,category',
  //             city_id: this.city_id
  //           }
  //         })
  //       } else if (this.$route.query.entity === 'routes') {
  //         this.$store.dispatch('interactive-map/query', {
  //           entity: 'routes',
  //           params: {
  //             query: this.query,
  //             count: 100500,
  //             lang: this.$i18n.locale,
  //             resolution: 'micro',
  //             show: 'id,title,entity,address,direction,images,fovarited,rating,subtype',
  //             city_id: this.city_id
  //           }
  //         })
  //       }
  //     }
  //   },
  //   submit() {
  //     this.$store.commit('interactive-map/setHistory', this.$route.query)
  //     if (this?.query?.trim()?.length > 0) {
  //       this.$router.replace({
  //         query: {
  //           ...this.$route.query,
  //           query: this.query,
  //           city_id: this.$route.query.city_id
  //         }
  //       })
  //       this.checkCurrentTab()
  //     }
  //   },
  //   changeCityId(city) {
  //     this.$store.commit('interactive-map/setCityId', city.id)
  //     this.$store.commit('interactive-map/setHistory', this.$route.query)
  //     this.$router.replace({
  //       query: {
  //         ...this.$route.query,
  //         query: this.$route.query.query,
  //         city_id: city.id
  //       }
  //     })
  //     if (this.$route.query.entity === 'places') {
  //       this.$store.dispatch('interactive-map/places', {
  //         entity: 'places',
  //         params: {
  //           count: 100500,
  //           lang: this.$i18n.locale,
  //           resolution: 'micro',
  //           show: 'id,title,address,work_hours,images,location,category_id,rating,city_id,favorited,category',
  //           city_id: city.id
  //         }
  //       })
  //     } else if (this.$route.query.entity === 'routes') {
  //       this.$store.dispatch('interactive-map/routes', {
  //         entity: 'routes',
  //         params: {
  //           count: 100500,
  //           lang: this.$i18n.locale,
  //           resolution: 'micro',
  //           show: 'id,title,entity,images,favorited,distance_int,duration_int,subtype,rating',
  //           city_id: city.id
  //         }
  //       })
  //     }
  //   }
  // }
}
