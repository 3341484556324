//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import auth from '@/middleware/auth'
import seasons from '@/middleware/seasons'
import levels from '@/middleware/levels'
import age from '@/middleware/age'

export default {
  name: 'PersonalAreaLayout',
  components: {
    Toaster: () => import("@/components/common/Toaster"),
    MobileMenu: () => import('@/components/common/MobileMenu/index'),
    Disclaimer: () => import('@/components/common/Disclaimer/index'),
    PersonalAreaMenu: () => import('@/components/common/PersonalAreaMenu/index'),
    Footer: () => import('@/components/common/Footer/index'),
    Popup: () => import('@/components/common/Popup/index'),
    Button: () => import('@/components/common/Button/index'),
    Header: () => import('@/components/common/Header/index'),
    Section: () => import('@/components/common/Section/index'),
    ScrollToTopButton: () => import('@/components/common/ScrollToTopButton/index'),
    PersonalAreaMenuMobile: () => import('@/components/common/PersonalAreaMenuMobile/index')
  },
  middleware: [auth, seasons, age, levels],
  computed: {
    ...mapGetters({
      logged: 'user/logged',
      token: 'user/token',
      show: 'popup/popup',
      browser: 'viewport/browser',
      os: 'viewport/os',
      addRoutesNotEmpty: 'add-route/notEmpty',
      addPlaceNotEmpty: 'add-place/notEmpty'
    })
  },
  created() {
    if (this.token) {
      this.browser.name === 'Safari' || this.os.name === 'iOS'
        ? this.$axios.defaults.params = {api_token: this.token}
        : this.$axios.setToken(`Bearer ${this.token}`)
    }
    if (!this.logged) {
      this.$router.push('/login')
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    window.addEventListener('scroll', this.handleScroll)
    this.$store.commit('popup/close')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    createRoute() {
      if(this.addRoutesNotEmpty) {
        this.$store.commit('popup/open', {
          name: 'PopupPreEntity',
          params: {
            entity: 'add-route',
            popup: 'PopupAddRoute',
            style: {
              width: this?.$media() === 'sm' ? '100%' : '632px',
              maxHeight: '100%'
            }
          }
        })
      } else this.openPopup('PopupAddRoute')
    },
    createPlace() {
      if(this.addPlaceNotEmpty) {
        this.$store.commit('popup/open', {
          name: 'PopupPreEntity',
          params: {
            entity: 'add-place',
            popup: 'PopupAddPlace',
            style: {
              width: this?.$media() === 'sm' ? '100%' : '632px',
              maxHeight: '100%'
            }
          }
        })
      } else this.openPopup('PopupAddPlace')
    },
    openPopup(name) {
      this.$store.commit('popup/open', {
        name,
        params: {
          style: {
            width: this?.$media() === 'sm' ? '100%' : '632px',
            maxHeight: '100%'
          }
        }
      })
    },
    handleScroll() {
      this.$store.commit('viewport/setScroll', window.scrollY)
    },
    handleResize() {
      this.$store.commit('viewport/setViewport', {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      })
    }
  }
}
