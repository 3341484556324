//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Tags',
  props: {
    gap: {
      type: String,
      default: '16px'
    }
  },
  computed: {
    style () {
      return {
        margin: `-${this.gap}`
      }
    }
  }
}
