import { parser } from '@/utils/parser'

export const state = () => ({
  orderables: [],
  orders: [],
  meta: null,
  pending: false
})

export const mutations = {
  setOrders: (state, orders) => state.orders = [...state.orders, ...orders],
  setOrderables: (state, orderables) =>  state.orderables = orderables,
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending,
  clearOrders: (state) => {
    state.orders = []
    state.meta = null
  }
}

export const actions = {
  async orderables ({ commit }, payload) {
    await this.$axios.$get(`/api/agencies/${payload.id}/orderables?${parser(payload)}`)
      .then((resolve) => {
        commit('setOrderables', resolve.data)
      })
      .catch(() => null)
  },
  async search ({ commit }, payload) {
    commit('setPending', true)
    if (payload.clear) {
      commit('clearOrders')
    }
    const orders = await this.$axios.$get(`/api/agencies/${payload.id}/orders?${parser(payload)}`)
    commit('setOrders', orders.data)
    commit('setMeta', orders.meta)
    commit('setPending', false)
  },
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload.clear) {
      commit('clearOrders')
    }
    const orders = await this.$axios.$get(`/api/agencies/${payload.id}/orders?${parser(payload)}`)
    commit('setOrders', orders.data)
    commit('setMeta', orders.meta)
    commit('setPending', false)
  },
  export ({commit}, payload) {
    window.open(`/api/agencies/${payload.id}/orders/export?${parser(payload)}`)
  }
}

export const getters = {
  orders: state => state.orders,
  orderables: state => state.orderables,
  meta: state => state.meta,
  pending: state => state.pending
}
