export default async function ({
                                 $config,
                                 store,
                                 app
                               }) {
  const cityId = $config.singleCity ? store.getters['cities/default'].id : null
  await store.dispatch('articles/fetch', {
    params: {
      city_id: cityId,
      count: 100500,
      resolutions: 'medium',
      lang: app.i18n.locale
    }
  })
}
