export default async function ({
                                 params,
                                 store,
                                 app,
                                 error
                               }) {
  await store.dispatch('piece-news/fetch', {
    id: params.id,
    params: {
      lang: app.i18n.locale,
      resolution: 'micro',
    }
  })
    .then(() => {
      const news = store.getters['piece-news/news']
      if (!(news && news?.status === 'active')) {
        error({statusCode: 404})
      }
    })
}
