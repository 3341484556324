import { parser } from '@/utils/parser'

export const state = () => ({
  journals: [],
  meta: null,
  promo: [],
  guidebook: [],
  pending: false
})

export const mutations = {
  clear: state => state.journals = [],
  setMeta: (state, meta) => state.meta = meta,
  setJournals: (state, journals) => state.journals = [...state.journals, ...journals],
  setPromo: (state, promo) => state.promo = promo,
  setGuidebook: (state, guidebook) => state.guidebook = guidebook,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/journals?${parser(payload)}`)
      .then((response) => {
        commit('setJournals', response.data)
        commit('setMeta', response.meta)
        commit('setPending', false)
      })
      .catch(() => {
        commit('setJournals', [])
        commit('setMeta', null)
      })
      .finally(() => {
        commit('setPending', false)
      })
  },
  async getPromo ({ commit }, payload) {
    const promo = await this.$axios.$get(`/api/journals?promo${parser(payload)}`)
    commit('setPromo', promo.data)
  },
  async getGuidebook ({ commit }, payload) {
    const guidebook = await this.$axios.$get(`/api/journals?guidebook${parser(payload)}`)
    commit('setGuidebook', guidebook.data)
  }
}

export const getters = {
  journals: state => state.journals,
  meta: state => state.meta,
  promo: state => state.promo,
  guidebook: state => state.guidebook,
  pending: state => state.pending
}
