import {parser} from "~/utils/parser";

export const state = () => ({
  excursions: [],
  meta: null,
  pending: false,
  query: '',
  page: 1,
  count: 12,
  city_id: null,
  agencies: [],
  agenciesForCheckbox: [],
  categories: [],
  categoriesForCheckbox: [],
  agency_id: [],
  schedule_started_at: null,
  schedule_stopped_at: null,
  accessible_environment: false,
  sort: 'avg',
  promo: false,
  has_tickets: false,
  tourism_type_id: null,
  season_id: null,
  level_id: null,
  age_restriction_id: null,
  lang: null,
})

export const mutations = {
  reset: (state) => {
    state.excursions = []
    state.meta = null
    state.query = ''
    state.page = 1
    state.city_id = null
    state.agenciesForCheckbox = state.agenciesForCheckbox.map(item => ({...item, checked: false}))
    state.categoriesForCheckbox = state.categoriesForCheckbox.map(item => ({...item, checked: false}))
    state.schedule_started_at = null
    state.schedule_stopped_at = null
    state.accessible_environment = false
    state.sort = 'avg'
    state.promo = false
    state.has_tickets = false
    state.tourism_type_id = null
    state.season_id = null
    state.level_id = null
    state.age_restriction_id = null
  },
  clear: (state) => {
    state.excursions = []
    state.meta = null
  },
  setExcursions: (state, excursions) => state.excursions = [...state.excursions, ...excursions],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending,
  setDate: (state, dates) => {
    if (dates.length > 1) {
      state.schedule_started_at = dates[0]
      state.schedule_stopped_at = dates[1]
    }
  },
  setCityId: (state, city_id) => state.city_id = city_id ? Number(city_id) : null,
  setAgencies: (state, agencies) => {
    state.agencies = agencies
    if (state.agenciesForCheckbox.length === 0) {
      state.agenciesForCheckbox = agencies.map(item => ({
        id: item.id,
        title: item.title,
        checked: false
      }))
    }
  },
  setCategories: (state, categories) => {
    state.categories = categories
    if (state.categoriesForCheckbox.length === 0) {
      state.categoriesForCheckbox = categories.map(item => ({
        id: item.id,
        title: item.title,
        checked: false
      }))
    }
  },
  setAgencyId: (state, agency_id) => state.agency_id = agency_id,
  setAgenciesForCheckbox: (state, agenciesForCheckbox) => state.agenciesForCheckbox = agenciesForCheckbox,
  setCategoriesForCheckbox: (state, categoriesForCheckbox) => state.categoriesForCheckbox = categoriesForCheckbox,
  setAgencyIdFromString: (state, agencyString) => {
    if (agencyString) {
      const array = agencyString.split(',');
      array.forEach(id => {
        state.agenciesForCheckbox.forEach(item => {
          if (Number(item.id) === Number(id)) {
            item.checked = true
          }
        })
      })
    } else {
      state.agenciesForCheckbox.forEach(item => item.checked = false)
    }
  },
  setCategoryIdFromString: (state, categoryString) => {
    if (categoryString) {
      const array = categoryString.split(',');
      array.forEach(id => {
        state.categoriesForCheckbox.forEach(item => {
          if (Number(item.id) === Number(id)) {
            item.checked = true
          }
        })
      })
    } else {
      state.categoriesForCheckbox.forEach(item => item.checked = false)
    }
  },
  setScheduleStartedAt: (state, schedule_started_at) => state.schedule_started_at = schedule_started_at,
  setScheduleStoppedAt: (state, schedule_stopped_at) => state.schedule_stopped_at = schedule_stopped_at,
  setQuery: (state, query) => state.query = query,
  setPage: (state, page) => state.page = page,
  setCount: (state, count) => state.count = count,
  setAccessibleEnvironment: (state, accessible_environment) => {
    accessible_environment === 'true' || accessible_environment === true ?
      state.accessible_environment = true :
      state.accessible_environment = false
  },
  setSort: (state, sort) => {
    sort === 'avg' || sort === 'created_at' ?
      state.sort = sort :
      state.sort = 'avg'
  },
  setPromo: (state, promo) => {
    promo === 'true' || promo === true ?
      state.promo = true :
      state.promo = false
  },
  setHasTickets: (state, has_tickets) => {
    has_tickets === 'true' || has_tickets === true ?
      state.has_tickets = true :
      state.has_tickets = false
  },
  setTourismTypeId: (state, tourism_type_id) => state.tourism_type_id = tourism_type_id ? Number(tourism_type_id) : null,
  setSeasonId: (state, season_id) => state.season_id = season_id ? Number(season_id) : null,
  setLevelId: (state, level_id) => state.level_id = level_id ? Number(level_id) : null,
  setAgeRestrictionId: (state, age_restriction_id) => state.age_restriction_id = age_restriction_id ? Number(age_restriction_id) : null,
  setLang: (state, lang) => state.lang = lang,
}

export const actions = {
  async search({commit, state}, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/search/excursions?${parser({
      params: {
        city_id: state.city_id,
        query: state.query,
        count: state.count,
        page: state.page,
        resolution: 'micro',
        lang: state.lang,
      }
    })}`)
      .then((resolve) => {
        commit('setExcursions', resolve.data)
        commit('setMeta', resolve.meta)
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  },
  async agencies({commit}, payload) {
    await this.$axios.$get(`/api/agencies?${parser(payload)}`)
      .then(response => {
        commit('setAgencies', response.data)
      })
      .catch(() => null)
      .finally(() => null)
  },
  async excursions({state, commit}, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/excursions?${parser({
      params: {
        resolution: 'micro',
        city_id: state.city_id,
        season_id: state.season_id,
        level_id: state.level_id,
        age_restriction_id: state.age_restriction_id,
        tourism_type_id: state.tourism_type_id,
        agency_id: state.agenciesForCheckbox.filter(item => item.checked === true).map(item => item.id).join(','),
        category_id: state.categoriesForCheckbox.filter(item => item.checked === true).map(item => item.id).join(','),
        schedule_started_at: state.schedule_started_at,
        schedule_stopped_at: state.schedule_stopped_at,
        promo: state.promo,
        has_tickets: state.has_tickets,
        accessible_environment: state.accessible_environment,
        sort: state.sort,
        page: state.page,
        count: state.count,
        lang: state.lang,
      }
    })}`)
      .then(response => {
        commit('setExcursions', response.data)
        commit('setMeta', response.meta)
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  excursions: state => state.excursions,
  meta: state => state.meta,
  pending: state => state.pending,
  dates: state => [state.schedule_started_at, state.schedule_stopped_at],
  city_id: state => state.city_id,
  agencies: state => state.agencies,
  agenciesForCheckbox: state => state.agenciesForCheckbox,
  categoriesForCheckbox: state => state.categoriesForCheckbox,
  agency_id: state => state.agency_id,
  schedule_started_at: state => state.schedule_started_at,
  schedule_stopped_at: state => state.schedule_stopped_at,
  query: state => state.query,
  page: state => state.page,
  count: state => state.count,
  accessible_environment: state => state.accessible_environment,
  sort: state => state.sort,
  promo: state => state.promo,
  has_tickets: state => state.has_tickets,
  tourism_type_id: state => state.tourism_type_id,
  season_id: state => state.season_id,
  level_id: state => state.level_id,
  age_restriction_id: state => state.age_restriction_id,
  lang: state => state.lang,
}
