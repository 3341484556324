import { render, staticRenderFns } from "./_event_id.vue?vue&type=template&id=0088f9f1&"
import script from "./_event_id.vue?vue&type=script&lang=js&"
export * from "./_event_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Status: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Status/index.vue').default,Gallery: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Gallery/index.vue').default,ContextAdditions: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ContextAdditions/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default,AgencyEventPlaces: require('/srv/rir-platform/tourism/src/tourism-front/components/common/AgencyEventPlaces/index.vue').default,AgencyEventSchedules: require('/srv/rir-platform/tourism/src/tourism-front/components/common/AgencyEventSchedules/index.vue').default})
