export const state = () => ({
  code: null,
  pending: false,
  errors: [],
})

export const mutations = {
  setCode: (state, code) => {
    state.code = code
    state.errors = []
  },
  setErrors: (state, errors) => state.errors = errors,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async post({state, commit}) {
    commit('setPending', true)
    const data = new FormData()
    data.append('code', state.code)
    await this.$axios.$post(`/api/bonus/codes/apply`, data)
      .catch((errors) => {
        commit('setErrors', errors.response.data.code)
      })
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  code: state => state.code,
  pending: state => state.pending,
  errors: state => state.errors,
}
