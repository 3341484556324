//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ColorPickerComponent",
  props: {
    background: {
      type: String,
      default: () => '#000000'
    }
  }
}
