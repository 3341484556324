//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "Toaster",
  components: {
    Toast: () => import("@/components/common/Toast")
  },
  computed: {
    ...mapGetters({
      toasters: "toasters/toasters", // Список тостов
      opened: "toasters/opened", // Рендеринг обертки
      params: "toasters/params", // Параметры обертки, обязательные: position.horizontal, position.vertical, size
    }),
    // В какую сторону будет появление и исчезнование анимации
    animationPosition() {
      if (this.params) {
        const classes = [];
        if (this.params?.position) {
          if (this.params?.position?.horizontal) {
            classes.push(`${this.params?.position?.horizontal}`);
          }
        }
        return classes;
      } else {
        return "";
      }
    },
    // Позиционирование обертки в интерфейсе
    styleClasses() {
      if (this.params) {
        const classes = [];
        if (this.params?.position) {
          if (this.params?.position?.vertical) {
            classes.push(`toaster--position-${this.params?.position?.vertical}`);
          }
          if (this.params?.position?.horizontal) {
            classes.push(`toaster--position-${this.params?.position?.horizontal}`);
          }
        }
        return classes;
      } else {
        return "";
      }
    },
    // Ширина обертки
    styleSize() {
      return this?.params?.size;
    },
  },
  watch: {
    // При смене роута закрывается обертка
    $route() {
      this.$store.commit("toasters/close");
    }
  }
};
