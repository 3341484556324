import { parser } from '@/utils/parser'

export const state = () => ({
  agency: null,
  pending: false,
  excursions: [],
  excursionsMeta: null,
  excursionsPending: false,
  errors: null,
  errorCode: null,
})

export const mutations = {
  setErrors: (state, errors) => state.errors = errors,
  setErrorsCode: (state, code) => state.errorCode = code,
  setAgency: (state, agency) => state.agency = agency,
  setPending: (state, pending) => state.pending = pending,
  setExcursions: (state, excursions) => state.excursions = [...state.excursions, ...excursions],
  setExcursionsMeta: (state, meta) => state.excursionsMeta = meta,
  setExcursionsPending: (state, pending) => state.excursionsPending = pending,
  clearExcursions: (state) => {
    state.excursions = []
    state.excursionsMeta = null
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    const agency = await this.$axios.$get(`/api/agencies/${payload.id}?${parser(payload)}`)
    commit('setAgency', agency.data)
    commit('setPending', false)
  },
  async excursions ({ commit }, payload) {
    commit('setExcursionsPending', true)
    if (payload?.clear) {
      commit('clearExcursions')
    }
    const excursions = await this.$axios.$get(`/api/excursions?${parser(payload)}`)
    commit('setExcursions', excursions.data)
    commit('setExcursionsMeta', excursions.meta)
    commit('setExcursionsPending', false)
  },
  async smRegister ({
    commit,
    dispatch
  }, payload) {
    commit('setPending', true)
    await this.$axios.$post(`/api/agencies/${payload.id}/sm-register?${parser(payload)}`)
      .then(() => {
        dispatch('fetch', {
          id: payload.id,
          params: payload.params
        })
      })
      .catch((errors) => {
        commit('setErrors', errors.response.data)
        commit('setErrorsCode', errors.response.status)
      })
      .finally(() => {
        commit('setPending', false)
      })
  }
}

export const getters = {
  errors: state => state.errors,
  errorCode: state => state.errorCode,
  agency: state => state.agency,
  pending: state => state.pending,
  excursions: state => state.excursions,
  excursionsMeta: state => state.excursionsMeta,
  excursionsPending: state => state.excursionsPending
}
