//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "StreamsView",
  components: {
    Section: () => import("@/components/common/Section")
  },
  data() {
    return {
      streams: [],
      pending: true,
    }
  },
  mounted() {
    this.$axios.get('/api/stavropol/video-streams')
      .then(response => this.streams = response.data.data)
      .catch(() => null)
      .finally(() => this.pending = false)
  }
}
