// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (context, inject) => {
  const handleScrollTo = (selector, indent) => {
    setTimeout(() => {
      let top = document?.querySelector(selector)?.offsetTop || 0
      window.scrollTo({
        top: top -= indent || 0,
        behavior: 'smooth'
      })
    }, 1)
  }
  context.$handleScrollTo = handleScrollTo
  inject('handleScrollTo', handleScrollTo)
}
