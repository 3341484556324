//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import seo from "@/middleware/seo";
import pageSearch from "@/middleware/page-search";

export default {
  name: "SearchView",
  components: {
    EntityContent: () => import("@/components/common/EntityContent"),
    Icon24FillClose: () => import("@/assets/icons/Icon24FillClose.svg?inline"),
    Icon24FillSearch: () => import("@/assets/icons/Icon24FillSearch.svg?inline"),
    SearchNews: () => import("@/components/common/SearchNews/index"),
    SearchRoutes: () => import("@/components/common/SearchRoutes/index"),
    SearchEvents: () => import("@/components/common/SearchEvents/index"),
    SearchExcursions: () => import("@/components/common/SearchExcursions/index"),
    SearchPlaces: () => import("@/components/common/SearchPlaces/index"),
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile/index"),
    Tags: () => import("@/components/common/Tags/index"),
    Tag: () => import("@/components/common/Tag/index"),
    SectionBillboard: () => import("@/components/common/SectionBillboard/index"),
    Section: () => import("@/components/common/Section/index"),
    Button: () => import("@/components/common/Button/index"),
    InputComponent: () => import("@/components/common/InputComponent/index")
  },
  middleware: [seo, pageSearch],
  data() {
    return {
      cookies: [],
      search: "",
      placesPending: true,
      routesPending: true,
      excursionsPending: true,
      eventsPending: true,
      newsPending: true
    };
  },
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t("pages.index.seo.site-name"),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
        }
      })
    };
  },
  computed: {
    ...mapGetters({
      SEOType: "seo/type",
      SEOTitle: "seo/title",
      SEOKeywords: "seo/keywords",
      SEODescription: "seo/description",
      SEOImage: "seo/image",
      SEOUrl: "seo/url",
      SEOLocale: "seo/locale",
      SEOSiteName: "seo/siteName",
      places: "search/places",
      placesMeta: "search/placesMeta",
      excursions: "search/excursions",
      excursionsMeta: "search/excursionsMeta",
      routes: "search/routes",
      routesMeta: "search/routesMeta",
      events: "search/events",
      eventsMeta: "search/eventsMeta",
      news: "search/news",
      newsMeta: "search/newsMeta"
    }),
    components() {
      return this.$config?.pages?.SearchView?.components;
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile;
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard;
    },
    tagGridGap() {
      switch (this?.$media()) {
        case "sm": {
          return "4px";
        }
        default: {
          return "8px";
        }
      }
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath;
    },
    allEntitiesCount() {
      return this.placesMeta?.total + this.routesMeta?.total + this.excursionsMeta?.total +
        this.eventsMeta?.total + this.newsMeta?.total || 0;
    },
    pending() {
      return [this.placesPending, this.routesPending, this.excursionsPending,
        this.eventsPending, this.newsPending].every(item => item === true);
    }
  },
  mounted() {
    this.$store.commit("search/clearAll");
    this.getCookie();
    this.search = this.$route.query.search || "";
    if (this.search.length > 0) {
      this.submit();
    }
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.$router.replace({query: null});
    },
    unique(arr) {
      const result = [];
      for (const str of arr) {
        if (!result.includes(str)) {
          result.push(str);
        }
      }
      return result;
    },
    changeSearch(cookie) {
      this.search = cookie;
      this.$router.replace({query: {search: cookie}});
      this.submit();
      this.$handleScrollTo(".content", 100);
    },
    getCookie() {
      const cookies = document.cookie.split("; ")
        .reduce((prev, current) => {
          const [name, value] = current.split("=");
          prev[name] = value;
          return prev;
        }, {});
      this.cookies = cookies?.tourismSearchHistory?.split(",") || [];
    },
    submit() {
      if (this?.search?.trim().length > 0) {
        this.$store.commit("search/clearAll");
        this.cookies = [...this.cookies, this.search.trim()];
        document.cookie = `tourismSearchHistory=${this.cookies.toString()}`;
        this.$store.dispatch("search/places", {
          params: {
            count: 3,
            lang: this.$i18n.locale,
            query: this?.search?.trim(),
            resolution: "small"
          }
        })
          .finally(() => this.placesPending = false);
        this.$store.dispatch("search/excursions", {
          params: {
            count: 3,
            lang: this.$i18n.locale,
            query: this?.search?.trim(),
            resolution: "small"
          }
        })
          .finally(() => this.excursionsPending = false);
        this.$store.dispatch("search/routes", {
          params: {
            count: 3,
            lang: this.$i18n.locale,
            query: this?.search?.trim(),
            resolution: "small"
          }
        })
          .finally(() => this.routesPending = false);
        this.$store.dispatch("search/events", {
          params: {
            count: 3,
            lang: this.$i18n.locale,
            query: this?.search?.trim(),
            resolution: "small"
          }
        })
          .finally(() => this.eventsPending = false);
        this.$store.dispatch("search/news", {
          params: {
            count: 3,
            lang: this.$i18n.locale,
            query: this?.search?.trim(),
            resolution: "small"
          }
        })
          .finally(() => this.newsPending = false);
      }
    }
  }
};
