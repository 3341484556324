//
//
//
//
//
//
//
//

export default {
  name: 'RatingTag',
  props: {
    rating: {
      type: Number,
      default: () => 0
    }
  }
}
