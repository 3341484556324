export const travelAppend = (state) => {
  const formData = new FormData()
  if (state.started_at) {
    formData.append('started_at', state.started_at + ':00')
  }
  if (state.stopped_at) {
    formData.append('stopped_at', state.stopped_at + ':00')
  }
  if (state.title) {
    formData.append('title', state.title)
  }
  if (state.user_id) {
    formData.append('user_id', state.user_id)
  }
  return formData
}
