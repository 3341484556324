//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SliderBullets",
  props: {
    bullets: {
      type: Number,
      default: () => 0
    },
    active: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    style() {
      return {
        left: (8 * 3) + (16 * 3) - ((8 * this.active) + (16 * this.active)) + 'px'
      }
    }
  }
}
