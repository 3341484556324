//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
// import seo from '@/middleware/seo'

export default {
  name: 'AccountMyRoutesView',
  components: {
    Empty: () => import('@/components/common/Empty/index'),
    RouteCard: () => import('@/components/common/RouteCard/index'),
  },
  layout: 'personal-area',
  // middleware: [seo],
  head () {
    return {
      title: this.$t('pages.account-my-routes.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      routes: 'user/routes',
      pending: 'user/routesPending'
    }),
  },
  created () {
    this.$store.dispatch('user/routes', {
      params: {
        lang: this.$i18n.locale,
        resolution: 'micro'
      }
    })
  }
}
