export default async function ({
                                 store,
                                 params,
                                 app,
                                 error
                               }) {
  await store.dispatch('pages/travel-agency/agency', {
    id: params.id,
    params: {
      lang: app.i18n.locale,
      resolution: 'micro',
    }
  })
    .then(() => {
      const agency = store.getters['pages/travel-agency/agency']
      if (!(agency && agency?.status === 'active')) {
        error({statusCode: 404})
      }
    })
}
