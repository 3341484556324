import { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  // this.$functionality inside Vue components
  interface Vue {
    $defaultCityId(): number
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$functionality inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $defaultCityId(): number
  }
  // nuxtContext.$functionality
  interface Context {
    $defaultCityId(): number
  }
}

declare module 'vuex/types/index' {
  // this.$functionality inside Vuex stores
  interface Store<S> {
    $defaultCityId(): number
  }
}

const pluginDefaultCityId: Plugin = ({store}, inject) => {
  inject('defaultCityId', () => store.getters['cities/default']?.id)
}

export default pluginDefaultCityId
