export default async function ({
                                 store,
                                 params,
                                 error,
                                 app
                               }) {
  await store.dispatch('route/fetch', {
    id: params.id,
    params: {
      lang: app.i18n.locale,
      resolution: 'micro',
    }
  })
    .then(async () => {
      const route = store.getters['route/route']
      const logged = store.getters['user/logged']
      if (!(route && route?.status === 'active')) {
        error({statusCode: 404})
      }
      if (logged) {
        await store.dispatch('travels/fetch', {
          params: {
            lang: app.i18n.locale
          }
        })
      }
    })
}
