import { parser } from '@/utils/parser'

export const state = () => ({
  stories: [],
  pending: false
})

export const getters = {
  stories: state => state.stories,
  pending: state => state.pending
}

export const mutations = {
  setStories: (state, stories) => {
    state.stories = stories
  },
  setPending: (state, pending) => {
    state.pending = pending
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/stories?type=web${parser(payload)}`)
      .then((response) => {
        commit('setStories', response.data)
        commit('setPending', false)
      })
      .catch(() => {
        commit('setStories', [])
      })
      .finally(() => {
        commit('setPending', false)
      })
  }
}
