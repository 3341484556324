//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'MapNavigationBackButton',
  components: {
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline')
  }
}
