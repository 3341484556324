//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "InputComponent",
  components: {
    LabelComponent: () => import("~/components/common/LabelComponent"),
    ErrorComponent: () => import("~/components/common/ErrorComponent")
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: () => ""
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    textAlign: {
      type: String,
      default: () => ""
    },
    value: {
      type: [String, Number],
      default: () => ""
    },
    type: {
      type: String,
      default: () => "text"
    },
    errors: {
      type: Array,
      default: () => []
    },
    autocomplete: {
      type: String,
      default: () => "off"
    },
    mask: {
      type: [String, Object, Array],
      default: () => ""
    },
    backgroundColor: {
      type: String,
      default: () => "var(--white)"
    },
    borderColor: {
      type: String,
      default: () => "var(--main-color)"
    },
    obligatory: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    styleList() {
      return {
        background: this.backgroundColor,
        border: this.errors && this.errors.length > 0 ? "1px solid var(--system-red)" : `1px solid ${this.borderColor}`
      };
    },
    classList() {
      return {
        "input-component__disabled": this?.disabled
      };
    },
    styleText() {
      return this?.textAlign ? `input-component__text--${this?.textAlign}` : "";
    }
  },
  methods: {
    input(e) {
      this.$emit("input", e.target.value, e.target.id);
    },
    focus(e) {
      this.$emit("focus", e);
    },
    blur(e) {
      this.$emit("blur", e);
    }
  }
};
