//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FooterLicense",
  computed: {
    year () {
      return this.$moment(this.currentDate)
        .format('YYYY')
    }
  }
}
