//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'


export default {
  name: 'HorizontalEntityCard',
  components: {
    Icon24StrokeFavorites: () => import('@/assets/icons/Icon24StrokeFavorites.svg?inline'),
    Icon24FillPlay: () => import('@/assets/icons/Icon24FillPlay.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    RatingTag: () => import('@/components/common/RatingTag'),
    ImageComponent: () => import('@/components/common/ImageComponent'),
    AudioPlayer: () => import('@/components/common/AudioPlayer')
  },
  props: {
    mini: {
      type: Boolean,
      default: () => false
    },
    mode: {
      type: String,
      default: () => 'link'
    },
    id: {
      type: Number,
      default: () => null
    },
    title: {
      type: String,
      default: () => ''
    },
    favorited: {
      type: Boolean,
      default: () => false
    },
    rating: {
      type: Number,
      default: () => null
    },
    address: {
      type: String,
      default: () => ''
    },
    audios: {
      type: Array,
      default: () => []
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-image.svg')
    },
    category: {
      type: Object,
      default: () => null
    },
    entity: {
      type: String,
      default: () => ''
    },
    number: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      innerFavorited: false
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    }),
    categoryTitle () {
      return this?.category?.title || ''
    },
    roundButtonSize () {
      switch (this?.$media()) {
        case 'hd': {
          return '38px'
        }
        default: {
          return '36px'
        }
      }
    },
    link () {
      return `/${this?.entity}/${this?.id}`
    },
    audio () {
      return this.audios[0]
    }
  },
  created () {
    this.innerFavorited = this.favorited
  },
  methods: {
    toggleFavorite () {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites${this.$store.getters['user/apple']
          ? '?api_token=' + this.$store.getters['user/token']
          : ''}`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited
          })
      } else {
        this.$login()
      }
    },
    openPlayer () {
      const players = document.querySelectorAll('.audio-player--card')
      players.forEach(elem => elem.style.display = 'none')
      this.$refs.player.$el.style.display = 'block'
      this.$store.commit('audio-player/setPlay', {
        active: true,
        uid: this.$refs.player._uid
      })
    }
  }
}
