//
//
//
//
//
//

export default {
  name: "SectionSharing",
  components: {
    Section: () => import("@/components/common/Section/index"),
    SharingComponent: () => import("@/components/common/SharingComponent/index")
  },
  computed: {
    url() {
      return this.$store.state.host + this?.$route?.fullPath;
    }
  }
};
