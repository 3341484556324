//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "DesktopNavigationIconLanguage",
  components: {
    Icon24FillWeb: () => import('@/assets/icons/Icon24FillWeb.svg?inline'),
  },
  computed: {
    ...mapGetters({
      languages: 'languages/languages',
    }),
    language() {
      return this.languages.find(item => item.locale === this.$i18n.locale)
    },
    image() {
      if (this.language) {
        return this.language.image || require('@/assets/images/default-image.svg')
      }
      return null
    },
    show() {
      return this.languages?.length > 1
    }
  },
  methods: {
    changeLanguage() {
      this.$store.commit('popup/open', {
        name: 'PopupLanguage'
      })
    },
  }
}
