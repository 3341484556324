//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StatusDropDownMenu',
  components: {
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Icon24FillArrowTop: () => import('@/assets/icons/Icon24FillArrowTop.svg?inline')
  },
  props: {
    status: {
      type: String,
      default: () => ''
    },
    statuses: {
      type: Array,
      default: () => null
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    classList () {
      return [
        { 'status-drop-down-menu__status--none': this.status === 'none' },
        { 'status-drop-down-menu__status--new': this.status === 'new' },
        { 'status-drop-down-menu__status--pending': this.status === 'pending' },
        { 'status-drop-down-menu__status--paid': this.status === 'paid' },
        { 'status-drop-down-menu__status--moderated': status === 'moderated' },
        { 'status-drop-down-menu__status--edited': status === 'edited' },
        { 'status-drop-down-menu__status--canceled': this.status === 'canceled' },
        { 'status-drop-down-menu__status--processed': this.status === 'processed' },
        { 'status-drop-down-menu__status--closed': this.status === 'closed' },
        { 'status-drop-down-menu__status--active': this.status === 'active' },
        { 'status-drop-down-menu__status--available': this.status === 'available' },
        { 'status-drop-down-menu__status--nonactive': this.status === 'nonactive' }
      ]
    }
  },
  methods: {
    condition (status) {
      switch (status) {
        case 'none': {
          return this.$t('components.status.none')
        }
        case 'new': {
          return this.$t('components.status.new')
        }
        case 'pending': {
          return this.$t('components.status.pending')
        }
        case 'paid': {
          return this.$t('components.status.paid')
        }
        case 'canceled': {
          return this.$t('components.status.canceled')
        }
        case 'processed': {
          return this.$t('components.status.processed')
        }
        case 'closed': {
          return this.$t('components.status.closed')
        }
        case 'moderated': {
          return this.$t('components.status.edited')
        }
        case 'edited': {
          return this.$t('components.status.edited')
        }
        case 'active': {
          return this.$t('components.status.active')
        }
        case 'available': {
          return this.$t('components.status.available')
        }
        default: {
          return this.$t('components.status.nonactive')
        }
      }
    },
    iconColor(status) {
      if (status === ('active' || 'confirmed' || 'closed' || 'nonactive')) {
        return this.$colors.black
      } else {
        return this.$colors.white
      }
    },
    showHide () {
      this.show = !this.show
    },
    close () {
      this.show = false
    },
    selectStatus (status) {
      this.$emit('change-status', status)
    }
  }
}
