//
//
//
//
//
//
//
//
//

export default {
  name: 'LinkMail',
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
