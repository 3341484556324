//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'


export default {
  name: 'TopEntityRating',
  components: {
    Icon36StrokeStar: () => import('@/assets/icons/Icon36StrokeStar.svg?inline')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => null
    },
    rating: {
      type: Number,
      default: () => 0
    },
    currentUserRating: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      innerRating: this.rating || 0,
      innerUserRating: this.currentUserRating || 0,
      overStar: 0,
      selectorIsOpen: false
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    }),
    iconSize () {
      switch (this?.$media()) {
        case 'sm': {
          return '28px'
        }
        default: {
          return '36px'
        }
      }
    }
  },
  watch: {
    currentUserRating (value) {
      this.innerUserRating = value
    }
  },
  methods: {
    setRating (value) {
      if (this.logged) {
        let string = '?'
        string += `&value=${value}`
        this.innerUserRating = value
        this.$axios.$post(`/api/${this.entity}/${this.id}/ratings${string}`)
          .then((resolve) => {
            this.selectorIsOpen = false
            this.innerRating = resolve.data.rating
          })
      } else {
        this.$login()
      }
    }
  }
}
