//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'MapNavigationRoutes',
  components: {
    HorizontalEntityCard: () => import('@/components/common/HorizontalEntityCard'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar'),
    Empty: () => import('@/components/common/Empty'),
  },
  computed: {
    ...mapGetters({
      pending: 'interactive-map/routesPending',
      routes: 'interactive-map/routes',
      entity: 'interactive-map/entity',
      id: 'interactive-map/id'
    }),
    showRoutes() {
      return this.entity === 'routes' && !this.id
    }
  },
  // methods: {
  //   click(id) {
  //     this.$store.commit('interactive-map/setHistory', this.$route.query)
  //     this.$router.replace({
  //       query: {
  //         ...this.$route.query,
  //         id,
  //         entity: 'route',
  //         query: null
  //       }
  //     })
  //   }
  // }
}
