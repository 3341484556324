//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Scroller',
  props: {
    freeze: {
      type: Boolean,
      default: () => false
    }
  }
}
