import UAParser from 'ua-parser-js'

export default function ({
  req,
  store
}) {
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
  const parser = new UAParser(userAgent)
  store.commit('viewport/setOS', parser.getOS())
  store.commit('viewport/setBrowser', parser.getBrowser())
  if (!store.getters['viewport/media']) {
    if (parser.getDevice()?.type === 'mobile') {
      store.commit('viewport/setType', 'sm')
    }
    if (parser.getDevice()?.type === 'tablet') {
      store.commit('viewport/setType', 'md')
    }
    if (parser.getDevice()?.type !== 'mobile' && parser.getDevice()?.type !== 'tablet') {
      store.commit('viewport/setType', 'hd')
    }
  }
}
