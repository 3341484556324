import { parser } from '@/utils/parser'

export const state = () => ({
  levels: []
})

export const getters = {
  levels: state => state.levels
}

export const mutations = {
  setLevels: (state, levels) => {
    state.levels = levels
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    const levels = await this.$axios.$get(`/api/lists/levels?${parser(payload)}`)
    commit('setLevels', levels.data)
  }
}
