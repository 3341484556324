export default async function ({
  store,
  app
}) {
  await store.dispatch('seasons/fetch', {
    params: {
      count: 100500,
      lang: app.i18n.locale
    }
  })
}
