//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import {parser} from "@/utils/parser";
import accountAgencyPlacesValidation from "@/middleware/account-agency-places-validation";
import accountAgencyProfileValidation from "@/middleware/account-agency-profile-validation";

export default {
  name: "AccountAgencyProfileView",
  components: {
    SectionAgencyBranches: () => import("@/components/common/SectionAgencyBranches"),
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    SectionAgencyLegalInformation: () => import("@/components/common/SectionAgencyLegalInformation"),
    TradingPlatformMemberBecome: () => import("@/components/common/TradingPlatformMemberBecome"),
    Icon24FillPlus: () => import('@/assets/icons/Icon24FillPlus.svg?inline'),
    Section: () => import("@/components/common/Section"),
    RoundButton: () => import("@/components/common/RoundButton"),
    Empty: () => import("@/components/common/Empty"),
    PlaceCard: () => import("@/components/common/PlaceCard"),
    Tabs: () => import("@/components/common/Tabs/index"),
    Button: () => import("@/components/common/Button/index"),
    PendingButton: () => import("@/components/common/PendingButton/index"),
    TopEntityAgencyProfile: () => import("@/components/common/TopEntityAgencyProfile/index")
  },
  layout: "personal-area-agency",
  middleware: [accountAgencyProfileValidation, accountAgencyPlacesValidation],
  data() {
    return {
      isUpdatingUserInfo: false,
      loading: false,
      pending: false,
      count: 9,
      page: 1
    };
  },
  head() {
    return {
      title: this.$t("pages.account-agency-profile.seo.title"),
      meta: this.$createSEOMeta({
        og: {
          type: "website",
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t("pages.index.seo.site-name"),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
        }
      })
    };
  },
  computed: {
    ...mapGetters({
      agency: "account-agency-profile/agency",
      places: "account-agency-places/places",
      meta: "account-agency-places/meta",
      city: 'cities/default',
      sm_registered: "account-agency-profile/sm_registered",
      addPlaceNotEmpty: 'add-place/notEmpty'
    }),
    agency_id() {
      return Number(this?.$route?.params?.agency_id);
    },
    status() {
      return this?.agency?.status;
    },
    description() {
      return this?.agency?.description;
    },
    additions() {
      return this?.agency?.additions;
    },
    showButton() {
      return this?.meta?.last_page !== this?.meta?.current_page;
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath;
    },
    params() {
      return {
        resolution: "micro",
        page: this.page,
        count: this.count,
        lang: this.$i18n.locale,
        city_id: this.$config.singleCity ? this.city.id : null,
      };
    }
  },
  created() {
    this.$store.commit("layout-personal-area-agency/setButtonBackLink", "/account/agencies");
    this.$store.commit("layout-personal-area-agency/setButtonBackTitle", this.$t("layouts.personal-area-layout.link-my-organizations"));
  },
  methods: {
    link(id) {
      return `/account/agencies/${this.$route.params.agency_id}/profile/places/${id}`;
    },
    openPopupAddPlace() {
      if(this.addPlaceNotEmpty) {
        this.$store.commit('popup/open', {
          name: 'PopupPreEntity',
          params: {
            entity: 'add-place',
            popup: 'PopupAddPlace',
            style: {
              width: this?.$media() === 'sm' ? '100%' : '632px',
              maxHeight: '100%'
            }
          }
        })
      } else this.$store.commit("popup/open", {
        name: "PopupAddPlace",
        params: {
          style: {
            width: "632px",
            maxHeight: "100vh"
          }
        }
      });
    },
    async next() {
      if (this.meta.current_page < this.meta.last_page) {
        this.page += 1;
        this.pending = true;
        await this.$axios.$get(`/api/places?user${parser({params: this.params})}`)
          .then((resolve) => {
            this.$store.commit("account-agency-places/setPlaces", resolve.data);
            this.$store.commit("account-agency-places/setMeta", resolve.meta);
            this.pending = false;
          });
      }
    }
  }
};
