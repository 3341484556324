import {Plugin} from '@nuxt/types'
import {Entity} from "~/types/entity";
import {EntitySingle} from "~/types/entity-single";

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $entitySingle(entity: Entity): EntitySingle | ''
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $entitySingle(entity: Entity): EntitySingle | ''
  }

  // nuxtContext.$cardFromEntity
  interface Context {
    $entitySingle(entity: Entity): EntitySingle | ''
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $entitySingle(entity: Entity): EntitySingle | ''
  }
}

const pluginEntitySingle: Plugin = ({store}, inject) => {
  inject('entitySingle', (entity: Entity) => {
    if (!entity) {
      throw new Error('Entity must by define')
    }
    switch (entity) {
      case "places":
        return "place"
      case "routes":
        return "route"
      case "excursions":
        return "excursion"
      case "events":
        return "event"
      case "news":
        return "piece-news"
      default :
        return ""
    }
  })
}

export default pluginEntitySingle
