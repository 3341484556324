//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import seasons from '@/middleware/seasons'
import levels from '@/middleware/levels'
import age from '@/middleware/age'
import tourismTypes from '@/middleware/tourism-types'
import seo from '@/middleware/seo'
import excursions from '@/middleware/pages/excursions.js'

export default {
  name: 'ExcursionsView',
  components: {
    AgenciesGrid: () => import("@/components/common/AgenciesGrid"),
    DatePickerComponent: () => import("@/components/common/DatePickerComponent"),
    SelectCheckBoxComponent: () => import("@/components/common/SelectCheckBoxComponent"),
    SelectSearchComponent: () => import('@/components/common/SelectSearchComponent'),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    Button: () => import('@/components/common/Button/index'),
    EntityFiltersWrapper: () => import('@/components/common/EntityFiltersWrapper/index'),
    ExcursionCard: () => import('@/components/common/ExcursionCard/index'),
    FilterToggle: () => import('@/components/common/FilterToggle/index'),
    InputComponent: () => import('@/components/common/InputComponent/index'),
    SectionBannerAppMobile: () => import('@/components/common/SectionBannerAppMobile/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    SectionEmptyEntity: () => import('~/components/common/SectionEmptyEntity'),
    Section: () => import('@/components/common/Section/index'),
    Toggle: () => import('@/components/common/Toggle/index')
  },
  middleware: [seasons, levels, age, tourismTypes, seo, excursions],
  data() {
    return {
      sortTypes: [
        {
          id: 'avg',
          text: this.$t('pages.excursions-view.toggle-sort-avg')
        },
        {
          id: 'created_at',
          text: this.$t('pages.excursions-view.toggle-sort-created-at')
        }
      ],
    }
  },
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      cities: 'cities/cities',
      excursions: 'pages/excursions/excursions',
      meta: 'pages/excursions/meta',
      pending: 'pages/excursions/pending',
      dates: 'pages/excursions/dates',
      filterAgencies: 'agencies/filterAgencies',
      agency_id: 'pages/excursions/agency_id',
      agenciesForCheckbox: 'pages/excursions/agenciesForCheckbox',
      categories: 'categories/excursions',
      categoriesForCheckbox: 'pages/excursions/categoriesForCheckbox',
      schedule_started_at: 'pages/excursions/schedule_started_at',
      schedule_stopped_at: 'pages/excursions/schedule_stopped_at',
      city_id: 'pages/excursions/city_id',
      page: 'pages/excursions/page',
      count: 'pages/excursions/count',
      query: 'pages/excursions/query',
      accessible_environment: 'pages/excursions/accessible_environment',
      sort: 'pages/excursions/sort',
      promo: 'pages/excursions/promo',
      has_tickets: 'pages/excursions/has_tickets',
      tourism_type_id: 'pages/excursions/tourism_type_id',
      season_id: 'pages/excursions/season_id',
      level_id: 'pages/excursions/level_id',
      age_restriction_id: 'pages/excursions/age_restriction_id',
      city: 'cities/default',
    }),
    components() {
      return this.$config?.pages?.ExcursionsView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showTopTitleComponent() {
      return this.components?.TopTitleComponent
    },
    showEntityFiltersWrapper() {
      return this.components?.EntityFiltersWrapper
    },
    showExcursions() {
      return this.components?.Excursions
    },
    showAgenciesGrid() {
      return this.components?.AgenciesGrid
    },
    showBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    agenciesGridParams() {
      return {
        city_id: this.$config.singleCity ? this.city.id : null,
        count: 8,
        page: 1,
      }
    },
    showButton() {
      return this?.meta?.last_page !== this?.meta?.current_page
    },
    citiesWithAll() {
      return [{
        id: null,
        title: this.$t('pages.excursions-view.select-city-default')
      }, ...this.cities]
    },
    tourism_types() {
      return [{
        id: null,
        title: this.$t('pages.excursions-view.select-tourism-default')
      }, ...this.$store.getters['tourism-types/tourism_types']]
    },
    seasons() {
      return [{
        id: null,
        title: this.$t('pages.excursions-view.select-seasons-default')
      }, ...this.$store.getters['seasons/seasons']]
    },
    levels() {
      return [{
        id: null,
        title: this.$t('pages.excursions-view.select-level-default')
      }, ...this.$store.getters['levels/levels']]
    },
    age_restrictions() {
      return [{
        id: null,
        title: this.$t('pages.excursions-view.select-age-default')
      }, ...this.$store.getters['age-restrictions/age_restrictions']]
    },
  },
  created() {
    if (process.browser) {
      const query = this.$route.query
      if (query.slug) {
        const cityId = this?.cities?.find(item => item.slug === this.$route.query.slug)?.id
        this.$store.commit('pages/excursions/setCityId', cityId)
      } else {
        if (this.$config.singleCity) {
          this.$store.commit('pages/excursions/setCityId', this.$defaultCityId())
        } else {
          this.$store.commit('pages/excursions/setCityId', query.city_id)
        }
      }
      this.$store.commit('pages/excursions/setTourismTypeId', query.tourism_type_id)
      this.$store.commit('pages/excursions/setCategories', this.categories)
      this.$store.commit('pages/excursions/setAgencyIdFromString', query.agency_id)
      this.$store.commit('pages/excursions/setCategoryIdFromString', query.category_id)
      this.$store.commit('pages/excursions/setScheduleStartedAt', query.schedule_started_at)
      this.$store.commit('pages/excursions/setScheduleStoppedAt', query.schedule_stopped_at)
      this.$store.commit('pages/excursions/setAccessibleEnvironment', query.accessible_environment)
      this.$store.commit('pages/excursions/setPromo', query.promo)
      this.$store.commit('pages/excursions/setHasTickets', query.has_tickets)
      this.$store.commit('pages/excursions/setSort', query.sort)
      this.$store.commit('pages/excursions/setSeasonId', query.season_id)
      this.$store.commit('pages/excursions/setLevelId', query.level_id)
      this.$store.commit('pages/excursions/setAgeRestrictionId', query.age_restriction_id)
      this.$store.commit('pages/excursions/setLang', this.$i18n.locale)
    }
  },
  mounted() {
    this.$store.dispatch('pages/excursions/excursions', {clear: true})
  },
  methods: {
    scrollToFilter() {
      this.$handleScrollTo('#filter', 100)
    },
    submitSearch() {
      this.$store.commit('excursions/clear')
      this.$store.commit('pages/excursions/setPage', 1)
      this.$store.dispatch('pages/excursions/search', {clear: true})
    },
    avatar(item) {
      return item?.guide?.image || item?.agency?.image || ''
    },
    submit() {
      this.$store.commit('pages/excursions/setPage', 1)
      this.$store.dispatch('pages/excursions/excursions', {clear: true})
    },
    next() {
      if (this.page) {
        this.$store.commit('pages/excursions/setPage', this.page + 1)
        this.$store.dispatch('pages/excursions/excursions')
      } else {
        this.$store.commit('pages/excursions/setPage', 1)
        this.$store.dispatch('pages/excursions/excursions', {clear: true})
      }
    },
    resetAndDispatch() {
      this.reset()
      this.submit()
    },
    reset() {
      this.$router.replace({query: {}}).catch(() => null)
      this.$store.commit('pages/excursions/reset')
      if (this.$config.singleCity) {
        this.$store.commit('pages/excursions/setCityId', this.$defaultCityId())
      }
    },
    changeAgenciesForCheckbox(agencies) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setAgenciesForCheckbox', agencies)
      this.$instantReplaceQuery({agency_id: agencies.filter(item => item.checked).map(item => item.id).join(',')})
    },
    changeCategoriesForCheckbox(categories) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setCategoriesForCheckbox', categories)
      this.$instantReplaceQuery({category_id: categories.filter(item => item.checked).map(item => item.id).join(',')})
    },
    changeDates(dates) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setDate', dates)
      this.$instantReplaceQuery({
        schedule_started_at: dates[0],
        schedule_stopped_at: dates[1],
      })
    },
    changeCityId(selected) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setCityId', selected.id)
      if (!this.$config.singleCity) {
        this.$instantReplaceQuery({city_id: selected.id})
      }
    },
    changeAccessibleEnvironment(accessible_environment) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setAccessibleEnvironment', accessible_environment)
      this.$instantReplaceQuery({accessible_environment})
    },
    changePromo(promo) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setPromo', promo)
      this.$instantReplaceQuery({promo})
    },
    changeTickets(has_tickets) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setHasTickets', has_tickets)
      this.$instantReplaceQuery({has_tickets})
    },
    changeSelectedTypeTourism(selected) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setTourismTypeId', selected.id)
      this.$instantReplaceQuery({tourism_type_id: selected.id})
    },
    changeSelectedMunicipality(selected) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setCityId', selected.id)
      this.$instantReplaceQuery({city_id: selected.id})
    },
    changeSeasonsId(selected) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setSeasonId', selected.id)
      this.$instantReplaceQuery({season_id: selected.id})
    },
    changeLevelId(selected) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setLevelId', selected.id)
      this.$instantReplaceQuery({level_id: selected.id})
    },
    changeAgeRestrictionId(selected) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setAgeRestrictionId', selected.id)
      this.$instantReplaceQuery({age_restriction_id: selected.id})
    },
    changeSort(sort) {
      this.$store.commit('pages/excursions/setPage', null)
      this.$store.commit('pages/excursions/setSort', sort)
      this.$instantReplaceQuery({sort})
    },
  }
}
