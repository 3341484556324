//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "FooterGroups",
  components: {
    FooterSocials: () => import("@/components/common/Footer/FooterSocials.vue"),
    FooterGroup: () => import("@/components/common/Footer/FooterGroup.vue"),
  },
  computed: {
    ...mapGetters({
      groups: 'footer/groups',
      socials: 'footer/socials'
    }),
    show() {
      return this.groups.length > 0 || Object.keys(this.socials).length > 0
    }
  }
}
