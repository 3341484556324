//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SocialsSquaredComponent',
  components: {
    Icon24FillVk: () => import('@/assets/icons/Icon24FillVk.svg?inline'),
    Icon24FillOk: () => import('@/assets/icons/Icon24FillOk.svg?inline'),
    Icon24FillYoutube: () => import('@/assets/icons/Icon24FillYoutube.svg?inline'),
    Icon24FillInstagram: () => import('@/assets/icons/Icon24FillInstagram.svg?inline'),
    Icon24FillFacebook: () => import('@/assets/icons/Icon24FillFacebook.svg?inline'),
    Icon24FillTiktok: () => import('@/assets/icons/Icon24FillTiktok.svg?inline'),
    Icon24FillTelegram: () => import('@/assets/icons/Icon24FillTelegram.svg?inline')
  },
  props: {
    fillIcon: {
      type: String,
      default: () => 'var(--dark-color)'
    },
    background: {
      type: String,
      default: () => 'var(--main-light-color)'
    },
    socials: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    socialsIcons (name) {
      switch (name) {
        case 'facebook': {
          return 'Icon24FillFacebook'
        }
        case 'instagram': {
          return 'Icon24FillInstagram'
        }
        case 'youtube': {
          return 'Icon24FillYoutube'
        }
        case 'ok': {
          return 'Icon24FillOk'
        }
        case 'vk': {
          return 'Icon24FillVk'
        }
        case 'telegram': {
          return 'Icon24FillTelegram'
        }
        case 'tiktok': {
          return 'Icon24FillTiktok'
        }
      }
    }
  }
}
