import {parser} from "~/utils/parser";

export const state = () => ({
  agencies: [],
  meta: null,
  pending: false,
  page: 1,
  count: 8,
  city_id: null,
  resolution: 'micro',
  lang: null,
})

export const mutations = {
  setAgencies: (state, agencies) => state.agencies = [...state.agencies, ...agencies],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending,
  setPage: (state, page) => state.page = page,
  setCount: (state, count) => state.count = count,
  setCityId: (state, city_id) => state.city_id = city_id,
  setResolution: (state, resolution) => state.resolution = resolution,
  setLang: (state, lang) => state.lang = lang,
  clear: state => {
    state.agencies = []
    state.meta = null
  }
}

export const actions = {
  async agencies({commit, state}) {
    commit('setPending', true)
    await this.$axios.$get(`/api/travel-agencies?${parser({
      params: {
        resolution: state.resolution,
        city_id: state.city_id,
        lang: state.lang,
        count: state.count,
        page: state.page,
      }
    })}`)
      .then(response => {
        commit('setAgencies', response.data)
        commit('setMeta', response.meta)
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  agencies: state => state.agencies,
  meta: state => state.meta,
  pending: state => state.pending,
  page: state => state.page,
  count: state => state.count,
  city_id: state => state.city_id,
  resolution: state => state.resolution,
  lang: state => state.lang,
  showPendingButton: state => state.meta?.last_page !== state?.meta?.current_page
}
