export const EmptyJournalsCollectionCard = () => import('../../components/common/EmptyJournalsCollectionCard.vue' /* webpackChunkName: "components/empty-journals-collection-card" */).then(c => wrapFunctional(c.default || c))
export const AccountAgencyOrder = () => import('../../components/common/AccountAgencyOrder/index.vue' /* webpackChunkName: "components/account-agency-order" */).then(c => wrapFunctional(c.default || c))
export const AddFile = () => import('../../components/common/AddFile/index.vue' /* webpackChunkName: "components/add-file" */).then(c => wrapFunctional(c.default || c))
export const AccordionWidgetsWidgetAccordionTab = () => import('../../components/common/AccordionWidgets/WidgetAccordionTab.vue' /* webpackChunkName: "components/accordion-widgets-widget-accordion-tab" */).then(c => wrapFunctional(c.default || c))
export const AccordionWidgets = () => import('../../components/common/AccordionWidgets/index.vue' /* webpackChunkName: "components/accordion-widgets" */).then(c => wrapFunctional(c.default || c))
export const AddingImagesComponent = () => import('../../components/common/AddingImagesComponent/index.vue' /* webpackChunkName: "components/adding-images-component" */).then(c => wrapFunctional(c.default || c))
export const AdditionMail = () => import('../../components/common/AdditionMail/index.vue' /* webpackChunkName: "components/addition-mail" */).then(c => wrapFunctional(c.default || c))
export const AdditionPhone = () => import('../../components/common/AdditionPhone/index.vue' /* webpackChunkName: "components/addition-phone" */).then(c => wrapFunctional(c.default || c))
export const AdditionSite = () => import('../../components/common/AdditionSite/index.vue' /* webpackChunkName: "components/addition-site" */).then(c => wrapFunctional(c.default || c))
export const Additions = () => import('../../components/common/Additions/index.vue' /* webpackChunkName: "components/additions" */).then(c => wrapFunctional(c.default || c))
export const AgenciesCard = () => import('../../components/common/AgenciesCard/index.vue' /* webpackChunkName: "components/agencies-card" */).then(c => wrapFunctional(c.default || c))
export const AgenciesGrid = () => import('../../components/common/AgenciesGrid/index.vue' /* webpackChunkName: "components/agencies-grid" */).then(c => wrapFunctional(c.default || c))
export const AccountAgencyOrdersViewComponent = () => import('../../components/common/AccountAgencyOrdersViewComponent/index.vue' /* webpackChunkName: "components/account-agency-orders-view-component" */).then(c => wrapFunctional(c.default || c))
export const AgencyCard = () => import('../../components/common/AgencyCard/index.vue' /* webpackChunkName: "components/agency-card" */).then(c => wrapFunctional(c.default || c))
export const AgencyEventPlaces = () => import('../../components/common/AgencyEventPlaces/index.vue' /* webpackChunkName: "components/agency-event-places" */).then(c => wrapFunctional(c.default || c))
export const AgencyEventSchedules = () => import('../../components/common/AgencyEventSchedules/index.vue' /* webpackChunkName: "components/agency-event-schedules" */).then(c => wrapFunctional(c.default || c))
export const AgencyEventsCard = () => import('../../components/common/AgencyEventsCard/index.vue' /* webpackChunkName: "components/agency-events-card" */).then(c => wrapFunctional(c.default || c))
export const AgencyExcursionCard = () => import('../../components/common/AgencyExcursionCard/index.vue' /* webpackChunkName: "components/agency-excursion-card" */).then(c => wrapFunctional(c.default || c))
export const AgencyExcursionPlaces = () => import('../../components/common/AgencyExcursionPlaces/index.vue' /* webpackChunkName: "components/agency-excursion-places" */).then(c => wrapFunctional(c.default || c))
export const AgencyExcursionSchedules = () => import('../../components/common/AgencyExcursionSchedules/index.vue' /* webpackChunkName: "components/agency-excursion-schedules" */).then(c => wrapFunctional(c.default || c))
export const AccountMyOrder = () => import('../../components/common/AccountMyOrder/index.vue' /* webpackChunkName: "components/account-my-order" */).then(c => wrapFunctional(c.default || c))
export const AgencyBranchCard = () => import('../../components/common/AgencyBranchCard/index.vue' /* webpackChunkName: "components/agency-branch-card" */).then(c => wrapFunctional(c.default || c))
export const ArticleBlock = () => import('../../components/common/ArticleBlock/index.vue' /* webpackChunkName: "components/article-block" */).then(c => wrapFunctional(c.default || c))
export const AddingAdditionsComponentBlock = () => import('../../components/common/AddingAdditionsComponent/AddingAdditionsComponentBlock.vue' /* webpackChunkName: "components/adding-additions-component-block" */).then(c => wrapFunctional(c.default || c))
export const AddingAdditionsComponentBlockMail = () => import('../../components/common/AddingAdditionsComponent/AddingAdditionsComponentBlockMail.vue' /* webpackChunkName: "components/adding-additions-component-block-mail" */).then(c => wrapFunctional(c.default || c))
export const AddingAdditionsComponentBlockPhone = () => import('../../components/common/AddingAdditionsComponent/AddingAdditionsComponentBlockPhone.vue' /* webpackChunkName: "components/adding-additions-component-block-phone" */).then(c => wrapFunctional(c.default || c))
export const AddingAdditionsComponentBlockSite = () => import('../../components/common/AddingAdditionsComponent/AddingAdditionsComponentBlockSite.vue' /* webpackChunkName: "components/adding-additions-component-block-site" */).then(c => wrapFunctional(c.default || c))
export const AddingAdditionsComponentBlockText = () => import('../../components/common/AddingAdditionsComponent/AddingAdditionsComponentBlockText.vue' /* webpackChunkName: "components/adding-additions-component-block-text" */).then(c => wrapFunctional(c.default || c))
export const AddingAdditionsComponentVariationList = () => import('../../components/common/AddingAdditionsComponent/AddingAdditionsComponentVariationList.vue' /* webpackChunkName: "components/adding-additions-component-variation-list" */).then(c => wrapFunctional(c.default || c))
export const AddingAdditionsComponent = () => import('../../components/common/AddingAdditionsComponent/index.vue' /* webpackChunkName: "components/adding-additions-component" */).then(c => wrapFunctional(c.default || c))
export const AgencyPlacesCard = () => import('../../components/common/AgencyPlacesCard/index.vue' /* webpackChunkName: "components/agency-places-card" */).then(c => wrapFunctional(c.default || c))
export const ArticleNotes = () => import('../../components/common/ArticleNotes/index.vue' /* webpackChunkName: "components/article-notes" */).then(c => wrapFunctional(c.default || c))
export const ArticleSection = () => import('../../components/common/ArticleSection/index.vue' /* webpackChunkName: "components/article-section" */).then(c => wrapFunctional(c.default || c))
export const ArticleTop = () => import('../../components/common/ArticleTop/index.vue' /* webpackChunkName: "components/article-top" */).then(c => wrapFunctional(c.default || c))
export const Attention = () => import('../../components/common/Attention/index.vue' /* webpackChunkName: "components/attention" */).then(c => wrapFunctional(c.default || c))
export const AudioPlayer = () => import('../../components/common/AudioPlayer/index.vue' /* webpackChunkName: "components/audio-player" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/common/Avatar/index.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const AviaKassaWidgetComponent = () => import('../../components/common/AviaKassaWidgetComponent/index.vue' /* webpackChunkName: "components/avia-kassa-widget-component" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/common/Badge/index.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const BannerLoyaltyProgram = () => import('../../components/common/BannerLoyaltyProgram/index.vue' /* webpackChunkName: "components/banner-loyalty-program" */).then(c => wrapFunctional(c.default || c))
export const Brochure = () => import('../../components/common/Brochure/index.vue' /* webpackChunkName: "components/brochure" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/common/Banner/index.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/common/Button/index.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/common/Calendar/index.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const CalendarInfo = () => import('../../components/common/CalendarInfo/index.vue' /* webpackChunkName: "components/calendar-info" */).then(c => wrapFunctional(c.default || c))
export const CategoriesComponent = () => import('../../components/common/CategoriesComponent/index.vue' /* webpackChunkName: "components/categories-component" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionSquareEventCard = () => import('../../components/common/CategoryCollectionSquareEventCard/index.vue' /* webpackChunkName: "components/category-collection-square-event-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionSquareExcursionCard = () => import('../../components/common/CategoryCollectionSquareExcursionCard/index.vue' /* webpackChunkName: "components/category-collection-square-excursion-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionSquarePlaceCard = () => import('../../components/common/CategoryCollectionSquarePlaceCard/index.vue' /* webpackChunkName: "components/category-collection-square-place-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionSquareRouteCard = () => import('../../components/common/CategoryCollectionSquareRouteCard/index.vue' /* webpackChunkName: "components/category-collection-square-route-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionsFilter = () => import('../../components/common/CategoryCollectionsFilter/index.vue' /* webpackChunkName: "components/category-collections-filter" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionsFilterFilters = () => import('../../components/common/CategoryCollectionsFilterFilters/index.vue' /* webpackChunkName: "components/category-collections-filter-filters" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionsFilterSearch = () => import('../../components/common/CategoryCollectionsFilterSearch/index.vue' /* webpackChunkName: "components/category-collections-filter-search" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionsGrid = () => import('../../components/common/CategoryCollectionsGrid/index.vue' /* webpackChunkName: "components/category-collections-grid" */).then(c => wrapFunctional(c.default || c))
export const CategoryCollectionsTitle = () => import('../../components/common/CategoryCollectionsTitle/index.vue' /* webpackChunkName: "components/category-collections-title" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/common/Checkbox/index.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const AgencySchedulesCard = () => import('../../components/common/AgencySchedulesCard/index.vue' /* webpackChunkName: "components/agency-schedules-card" */).then(c => wrapFunctional(c.default || c))
export const CodeViewComponent = () => import('../../components/common/CodeViewComponent/index.vue' /* webpackChunkName: "components/code-view-component" */).then(c => wrapFunctional(c.default || c))
export const BurgerButton = () => import('../../components/common/BurgerButton/index.vue' /* webpackChunkName: "components/burger-button" */).then(c => wrapFunctional(c.default || c))
export const ColorPickerComponent = () => import('../../components/common/ColorPickerComponent/index.vue' /* webpackChunkName: "components/color-picker-component" */).then(c => wrapFunctional(c.default || c))
export const ContextAdditions = () => import('../../components/common/ContextAdditions/index.vue' /* webpackChunkName: "components/context-additions" */).then(c => wrapFunctional(c.default || c))
export const Comments = () => import('../../components/common/Comments/index.vue' /* webpackChunkName: "components/comments" */).then(c => wrapFunctional(c.default || c))
export const DatePickerComponent = () => import('../../components/common/DatePickerComponent/index.vue' /* webpackChunkName: "components/date-picker-component" */).then(c => wrapFunctional(c.default || c))
export const DescriptionFoldingComponent = () => import('../../components/common/DescriptionFoldingComponent/index.vue' /* webpackChunkName: "components/description-folding-component" */).then(c => wrapFunctional(c.default || c))
export const ContextSchedule = () => import('../../components/common/ContextSchedule/index.vue' /* webpackChunkName: "components/context-schedule" */).then(c => wrapFunctional(c.default || c))
export const DesktopNavigation = () => import('../../components/common/DesktopNavigation/index.vue' /* webpackChunkName: "components/desktop-navigation" */).then(c => wrapFunctional(c.default || c))
export const DesktopNavigationIconAvatar = () => import('../../components/common/DesktopNavigationIconAvatar/index.vue' /* webpackChunkName: "components/desktop-navigation-icon-avatar" */).then(c => wrapFunctional(c.default || c))
export const DesktopNavigationIconLanguage = () => import('../../components/common/DesktopNavigationIconLanguage/index.vue' /* webpackChunkName: "components/desktop-navigation-icon-language" */).then(c => wrapFunctional(c.default || c))
export const DesktopNavigationIconSearch = () => import('../../components/common/DesktopNavigationIconSearch/index.vue' /* webpackChunkName: "components/desktop-navigation-icon-search" */).then(c => wrapFunctional(c.default || c))
export const Disclaimer = () => import('../../components/common/Disclaimer/index.vue' /* webpackChunkName: "components/disclaimer" */).then(c => wrapFunctional(c.default || c))
export const Distance = () => import('../../components/common/Distance/index.vue' /* webpackChunkName: "components/distance" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/common/Divider/index.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/common/Empty/index.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const ArticleEntityCardsBlock = () => import('../../components/common/ArticleEntityCardsBlock/index.vue' /* webpackChunkName: "components/article-entity-cards-block" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/common/Comment/index.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const EmptyButton = () => import('../../components/common/EmptyButton/index.vue' /* webpackChunkName: "components/empty-button" */).then(c => wrapFunctional(c.default || c))
export const EmptyCard = () => import('../../components/common/EmptyCard/index.vue' /* webpackChunkName: "components/empty-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyCategoryCollectionCard = () => import('../../components/common/EmptyCategoryCollectionCard/index.vue' /* webpackChunkName: "components/empty-category-collection-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyAgenciesSlider = () => import('../../components/common/EmptyAgenciesSlider/index.vue' /* webpackChunkName: "components/empty-agencies-slider" */).then(c => wrapFunctional(c.default || c))
export const EmptyAgency = () => import('../../components/common/EmptyAgency/index.vue' /* webpackChunkName: "components/empty-agency" */).then(c => wrapFunctional(c.default || c))
export const EmptyDefaultCard = () => import('../../components/common/EmptyDefaultCard/index.vue' /* webpackChunkName: "components/empty-default-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyDefaultSlider = () => import('../../components/common/EmptyDefaultSlider/index.vue' /* webpackChunkName: "components/empty-default-slider" */).then(c => wrapFunctional(c.default || c))
export const EmptyEntity = () => import('../../components/common/EmptyEntity/index.vue' /* webpackChunkName: "components/empty-entity" */).then(c => wrapFunctional(c.default || c))
export const EmptyDefaultText = () => import('../../components/common/EmptyDefaultText/index.vue' /* webpackChunkName: "components/empty-default-text" */).then(c => wrapFunctional(c.default || c))
export const EmptyEntityCard = () => import('../../components/common/EmptyEntityCard/index.vue' /* webpackChunkName: "components/empty-entity-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyEntityCardsSlider = () => import('../../components/common/EmptyEntityCardsSlider/index.vue' /* webpackChunkName: "components/empty-entity-cards-slider" */).then(c => wrapFunctional(c.default || c))
export const EmptyJournalsCollectionGrid = () => import('../../components/common/EmptyJournalsCollectionGrid/index.vue' /* webpackChunkName: "components/empty-journals-collection-grid" */).then(c => wrapFunctional(c.default || c))
export const EmptyNoteCard = () => import('../../components/common/EmptyNoteCard/index.vue' /* webpackChunkName: "components/empty-note-card" */).then(c => wrapFunctional(c.default || c))
export const Duration = () => import('../../components/common/Duration/index.vue' /* webpackChunkName: "components/duration" */).then(c => wrapFunctional(c.default || c))
export const EmptyPartnersSlider = () => import('../../components/common/EmptyPartnersSlider/index.vue' /* webpackChunkName: "components/empty-partners-slider" */).then(c => wrapFunctional(c.default || c))
export const EmptyScheduleCalendar = () => import('../../components/common/EmptyScheduleCalendar/index.vue' /* webpackChunkName: "components/empty-schedule-calendar" */).then(c => wrapFunctional(c.default || c))
export const EmptyTopEntityComponentSlider = () => import('../../components/common/EmptyTopEntityComponentSlider/index.vue' /* webpackChunkName: "components/empty-top-entity-component-slider" */).then(c => wrapFunctional(c.default || c))
export const EntityContent = () => import('../../components/common/EntityContent/index.vue' /* webpackChunkName: "components/entity-content" */).then(c => wrapFunctional(c.default || c))
export const EntityFiltersWrapper = () => import('../../components/common/EntityFiltersWrapper/index.vue' /* webpackChunkName: "components/entity-filters-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ErrorComponent = () => import('../../components/common/ErrorComponent/index.vue' /* webpackChunkName: "components/error-component" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/common/EventCard/index.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const ExcursionCard = () => import('../../components/common/ExcursionCard/index.vue' /* webpackChunkName: "components/excursion-card" */).then(c => wrapFunctional(c.default || c))
export const Favorite = () => import('../../components/common/Favorite/index.vue' /* webpackChunkName: "components/favorite" */).then(c => wrapFunctional(c.default || c))
export const Favoriting = () => import('../../components/common/Favoriting/index.vue' /* webpackChunkName: "components/favoriting" */).then(c => wrapFunctional(c.default || c))
export const FilterToggle = () => import('../../components/common/FilterToggle/index.vue' /* webpackChunkName: "components/filter-toggle" */).then(c => wrapFunctional(c.default || c))
export const FooterCredit = () => import('../../components/common/Footer/FooterCredit.vue' /* webpackChunkName: "components/footer-credit" */).then(c => wrapFunctional(c.default || c))
export const FooterCreditLink = () => import('../../components/common/Footer/FooterCreditLink.vue' /* webpackChunkName: "components/footer-credit-link" */).then(c => wrapFunctional(c.default || c))
export const FooterCredits = () => import('../../components/common/Footer/FooterCredits.vue' /* webpackChunkName: "components/footer-credits" */).then(c => wrapFunctional(c.default || c))
export const FooterDocument = () => import('../../components/common/Footer/FooterDocument.vue' /* webpackChunkName: "components/footer-document" */).then(c => wrapFunctional(c.default || c))
export const FooterDocuments = () => import('../../components/common/Footer/FooterDocuments.vue' /* webpackChunkName: "components/footer-documents" */).then(c => wrapFunctional(c.default || c))
export const FooterFeedback = () => import('../../components/common/Footer/FooterFeedback.vue' /* webpackChunkName: "components/footer-feedback" */).then(c => wrapFunctional(c.default || c))
export const FooterGroup = () => import('../../components/common/Footer/FooterGroup.vue' /* webpackChunkName: "components/footer-group" */).then(c => wrapFunctional(c.default || c))
export const FooterGroups = () => import('../../components/common/Footer/FooterGroups.vue' /* webpackChunkName: "components/footer-groups" */).then(c => wrapFunctional(c.default || c))
export const FooterLicense = () => import('../../components/common/Footer/FooterLicense.vue' /* webpackChunkName: "components/footer-license" */).then(c => wrapFunctional(c.default || c))
export const FooterSocials = () => import('../../components/common/Footer/FooterSocials.vue' /* webpackChunkName: "components/footer-socials" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/common/Footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const GalleryFrame = () => import('../../components/common/Gallery/GalleryFrame.vue' /* webpackChunkName: "components/gallery-frame" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/common/Gallery/index.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const GuideCard = () => import('../../components/common/GuideCard/index.vue' /* webpackChunkName: "components/guide-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyCategoryCollectionSlider = () => import('../../components/common/EmptyCategoryCollectionSlider/index.vue' /* webpackChunkName: "components/empty-category-collection-slider" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/common/Header/index.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationBurger = () => import('../../components/common/HeaderNavigation/HeaderNavigationBurger.vue' /* webpackChunkName: "components/header-navigation-burger" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationCityComponent = () => import('../../components/common/HeaderNavigation/HeaderNavigationCityComponent.vue' /* webpackChunkName: "components/header-navigation-city-component" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationLink = () => import('../../components/common/HeaderNavigation/HeaderNavigationLink.vue' /* webpackChunkName: "components/header-navigation-link" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationList = () => import('../../components/common/HeaderNavigation/HeaderNavigationList.vue' /* webpackChunkName: "components/header-navigation-list" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationTag = () => import('../../components/common/HeaderNavigation/HeaderNavigationTag.vue' /* webpackChunkName: "components/header-navigation-tag" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/common/HeaderNavigation/index.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeroSliderButton = () => import('../../components/common/HeroSlider/HeroSliderButton.vue' /* webpackChunkName: "components/hero-slider-button" */).then(c => wrapFunctional(c.default || c))
export const HeroSliderSlide = () => import('../../components/common/HeroSlider/HeroSliderSlide.vue' /* webpackChunkName: "components/hero-slider-slide" */).then(c => wrapFunctional(c.default || c))
export const HeroSlider = () => import('../../components/common/HeroSlider/index.vue' /* webpackChunkName: "components/hero-slider" */).then(c => wrapFunctional(c.default || c))
export const Hint = () => import('../../components/common/Hint/index.vue' /* webpackChunkName: "components/hint" */).then(c => wrapFunctional(c.default || c))
export const HorizontalEntityCard = () => import('../../components/common/HorizontalEntityCard/index.vue' /* webpackChunkName: "components/horizontal-entity-card" */).then(c => wrapFunctional(c.default || c))
export const HorizontalLayerCard = () => import('../../components/common/HorizontalLayerCard/index.vue' /* webpackChunkName: "components/horizontal-layer-card" */).then(c => wrapFunctional(c.default || c))
export const EmptyPartnerCard = () => import('../../components/common/EmptyPartnerCard/index.vue' /* webpackChunkName: "components/empty-partner-card" */).then(c => wrapFunctional(c.default || c))
export const InputComponent = () => import('../../components/common/InputComponent/index.vue' /* webpackChunkName: "components/input-component" */).then(c => wrapFunctional(c.default || c))
export const InputComponentDistance = () => import('../../components/common/InputComponentDistance/index.vue' /* webpackChunkName: "components/input-component-distance" */).then(c => wrapFunctional(c.default || c))
export const InputComponentSite = () => import('../../components/common/InputComponentSite/index.vue' /* webpackChunkName: "components/input-component-site" */).then(c => wrapFunctional(c.default || c))
export const EmptyHeroSlider = () => import('../../components/common/EmptyHeroSlider/index.vue' /* webpackChunkName: "components/empty-hero-slider" */).then(c => wrapFunctional(c.default || c))
export const JournalCard = () => import('../../components/common/JournalCard/index.vue' /* webpackChunkName: "components/journal-card" */).then(c => wrapFunctional(c.default || c))
export const LabelComponent = () => import('../../components/common/LabelComponent/index.vue' /* webpackChunkName: "components/label-component" */).then(c => wrapFunctional(c.default || c))
export const GuidesGrid = () => import('../../components/common/GuidesGrid/index.vue' /* webpackChunkName: "components/guides-grid" */).then(c => wrapFunctional(c.default || c))
export const ImageComponent = () => import('../../components/common/ImageComponent/index.vue' /* webpackChunkName: "components/image-component" */).then(c => wrapFunctional(c.default || c))
export const IntersectionComponent = () => import('../../components/common/IntersectionComponent/IntersectionComponent.vue' /* webpackChunkName: "components/intersection-component" */).then(c => wrapFunctional(c.default || c))
export const LinkPhone = () => import('../../components/common/LinkPhone/index.vue' /* webpackChunkName: "components/link-phone" */).then(c => wrapFunctional(c.default || c))
export const LinkSite = () => import('../../components/common/LinkSite/index.vue' /* webpackChunkName: "components/link-site" */).then(c => wrapFunctional(c.default || c))
export const LabelTag = () => import('../../components/common/LabelTag/index.vue' /* webpackChunkName: "components/label-tag" */).then(c => wrapFunctional(c.default || c))
export const MobileMenu = () => import('../../components/common/MobileMenu/index.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuBottom = () => import('../../components/common/MobileMenuBottom/index.vue' /* webpackChunkName: "components/mobile-menu-bottom" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuDivider = () => import('../../components/common/MobileMenuDivider/index.vue' /* webpackChunkName: "components/mobile-menu-divider" */).then(c => wrapFunctional(c.default || c))
export const MobileNavigation = () => import('../../components/common/MobileNavigation/index.vue' /* webpackChunkName: "components/mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const LinkMail = () => import('../../components/common/LinkMail/index.vue' /* webpackChunkName: "components/link-mail" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/common/NewsCard/index.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const OnMap = () => import('../../components/common/OnMap/index.vue' /* webpackChunkName: "components/on-map" */).then(c => wrapFunctional(c.default || c))
export const OstrovokWidgetComponent = () => import('../../components/common/OstrovokWidgetComponent/index.vue' /* webpackChunkName: "components/ostrovok-widget-component" */).then(c => wrapFunctional(c.default || c))
export const PartnerCard = () => import('../../components/common/PartnerCard/index.vue' /* webpackChunkName: "components/partner-card" */).then(c => wrapFunctional(c.default || c))
export const PendingButton = () => import('../../components/common/PendingButton/index.vue' /* webpackChunkName: "components/pending-button" */).then(c => wrapFunctional(c.default || c))
export const PersonalAreaMenu = () => import('../../components/common/PersonalAreaMenu/index.vue' /* webpackChunkName: "components/personal-area-menu" */).then(c => wrapFunctional(c.default || c))
export const MapNavigation = () => import('../../components/common/MapNavigation/MapNavigation.vue' /* webpackChunkName: "components/map-navigation" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationBackButton = () => import('../../components/common/MapNavigation/MapNavigationBackButton.vue' /* webpackChunkName: "components/map-navigation-back-button" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationLayer = () => import('../../components/common/MapNavigation/MapNavigationLayer.vue' /* webpackChunkName: "components/map-navigation-layer" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationLayers = () => import('../../components/common/MapNavigation/MapNavigationLayers.vue' /* webpackChunkName: "components/map-navigation-layers" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationPlace = () => import('../../components/common/MapNavigation/MapNavigationPlace.vue' /* webpackChunkName: "components/map-navigation-place" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationPlaceCard = () => import('../../components/common/MapNavigation/MapNavigationPlaceCard.vue' /* webpackChunkName: "components/map-navigation-place-card" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationPlaces = () => import('../../components/common/MapNavigation/MapNavigationPlaces.vue' /* webpackChunkName: "components/map-navigation-places" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationPlacesType = () => import('../../components/common/MapNavigation/MapNavigationPlacesType.vue' /* webpackChunkName: "components/map-navigation-places-type" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationRoute = () => import('../../components/common/MapNavigation/MapNavigationRoute.vue' /* webpackChunkName: "components/map-navigation-route" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationRouteCard = () => import('../../components/common/MapNavigation/MapNavigationRouteCard.vue' /* webpackChunkName: "components/map-navigation-route-card" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationRoutes = () => import('../../components/common/MapNavigation/MapNavigationRoutes.vue' /* webpackChunkName: "components/map-navigation-routes" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationSearch = () => import('../../components/common/MapNavigation/MapNavigationSearch.vue' /* webpackChunkName: "components/map-navigation-search" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationTabs = () => import('../../components/common/MapNavigation/MapNavigationTabs.vue' /* webpackChunkName: "components/map-navigation-tabs" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationType = () => import('../../components/common/MapNavigation/MapNavigationType.vue' /* webpackChunkName: "components/map-navigation-type" */).then(c => wrapFunctional(c.default || c))
export const MapNavigationTypes = () => import('../../components/common/MapNavigation/MapNavigationTypes.vue' /* webpackChunkName: "components/map-navigation-types" */).then(c => wrapFunctional(c.default || c))
export const PhotoBankCard = () => import('../../components/common/PhotoBankCard/index.vue' /* webpackChunkName: "components/photo-bank-card" */).then(c => wrapFunctional(c.default || c))
export const PhotoUploaderForm = () => import('../../components/common/PhotoUploader/PhotoUploaderForm.vue' /* webpackChunkName: "components/photo-uploader-form" */).then(c => wrapFunctional(c.default || c))
export const PhotoUploader = () => import('../../components/common/PhotoUploader/index.vue' /* webpackChunkName: "components/photo-uploader" */).then(c => wrapFunctional(c.default || c))
export const PlaceCard = () => import('../../components/common/PlaceCard/index.vue' /* webpackChunkName: "components/place-card" */).then(c => wrapFunctional(c.default || c))
export const PersonalAreaMenuMobile = () => import('../../components/common/PersonalAreaMenuMobile/index.vue' /* webpackChunkName: "components/personal-area-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const Popup = () => import('../../components/common/Popup/index.vue' /* webpackChunkName: "components/popup" */).then(c => wrapFunctional(c.default || c))
export const PopupAddExcursionMap = () => import('../../components/common/PopupAddExcursion/PopupAddExcursionMap.vue' /* webpackChunkName: "components/popup-add-excursion-map" */).then(c => wrapFunctional(c.default || c))
export const PopupAddExcursion = () => import('../../components/common/PopupAddExcursion/index.vue' /* webpackChunkName: "components/popup-add-excursion" */).then(c => wrapFunctional(c.default || c))
export const PopupAddPlaceMap = () => import('../../components/common/PopupAddPlace/PopupAddPlaceMap.vue' /* webpackChunkName: "components/popup-add-place-map" */).then(c => wrapFunctional(c.default || c))
export const PopupAddPlace = () => import('../../components/common/PopupAddPlace/index.vue' /* webpackChunkName: "components/popup-add-place" */).then(c => wrapFunctional(c.default || c))
export const PopupAddEvent = () => import('../../components/common/PopupAddEvent/index.vue' /* webpackChunkName: "components/popup-add-event" */).then(c => wrapFunctional(c.default || c))
export const PopupAddPlaceSuccess = () => import('../../components/common/PopupAddPlaceSuccess/index.vue' /* webpackChunkName: "components/popup-add-place-success" */).then(c => wrapFunctional(c.default || c))
export const PopupAddRouteMap = () => import('../../components/common/PopupAddRoute/PopupAddRouteMap.vue' /* webpackChunkName: "components/popup-add-route-map" */).then(c => wrapFunctional(c.default || c))
export const PopupAddRoute = () => import('../../components/common/PopupAddRoute/index.vue' /* webpackChunkName: "components/popup-add-route" */).then(c => wrapFunctional(c.default || c))
export const PopupAddSchedule = () => import('../../components/common/PopupAddSchedule/index.vue' /* webpackChunkName: "components/popup-add-schedule" */).then(c => wrapFunctional(c.default || c))
export const PopupAgencyRegSuccess = () => import('../../components/common/PopupAgencyRegSuccess/index.vue' /* webpackChunkName: "components/popup-agency-reg-success" */).then(c => wrapFunctional(c.default || c))
export const LayerNote = () => import('../../components/common/LayerNote/index.vue' /* webpackChunkName: "components/layer-note" */).then(c => wrapFunctional(c.default || c))
export const PopupEditAgency = () => import('../../components/common/PopupEditAgency/index.vue' /* webpackChunkName: "components/popup-edit-agency" */).then(c => wrapFunctional(c.default || c))
export const PopupEditSuccess = () => import('../../components/common/PopupEditSuccess/index.vue' /* webpackChunkName: "components/popup-edit-success" */).then(c => wrapFunctional(c.default || c))
export const NoteCard = () => import('../../components/common/NoteCard/index.vue' /* webpackChunkName: "components/note-card" */).then(c => wrapFunctional(c.default || c))
export const PopupFullSizeSlider = () => import('../../components/common/PopupFullSizeSlider/index.vue' /* webpackChunkName: "components/popup-full-size-slider" */).then(c => wrapFunctional(c.default || c))
export const PopupLanguage = () => import('../../components/common/PopupLanguage/index.vue' /* webpackChunkName: "components/popup-language" */).then(c => wrapFunctional(c.default || c))
export const PopupMunicipality = () => import('../../components/common/PopupMunicipality/index.vue' /* webpackChunkName: "components/popup-municipality" */).then(c => wrapFunctional(c.default || c))
export const PopupOrderBonusField = () => import('../../components/common/PopupOrder/PopupOrderBonusField.vue' /* webpackChunkName: "components/popup-order-bonus-field" */).then(c => wrapFunctional(c.default || c))
export const PopupOrderButtons = () => import('../../components/common/PopupOrder/PopupOrderButtons.vue' /* webpackChunkName: "components/popup-order-buttons" */).then(c => wrapFunctional(c.default || c))
export const PopupOrderForm = () => import('../../components/common/PopupOrder/PopupOrderForm.vue' /* webpackChunkName: "components/popup-order-form" */).then(c => wrapFunctional(c.default || c))
export const PopupOrderTariff = () => import('../../components/common/PopupOrder/PopupOrderTariff.vue' /* webpackChunkName: "components/popup-order-tariff" */).then(c => wrapFunctional(c.default || c))
export const PopupOrderTariffList = () => import('../../components/common/PopupOrder/PopupOrderTariffList.vue' /* webpackChunkName: "components/popup-order-tariff-list" */).then(c => wrapFunctional(c.default || c))
export const PopupOrderTotalAmount = () => import('../../components/common/PopupOrder/PopupOrderTotalAmount.vue' /* webpackChunkName: "components/popup-order-total-amount" */).then(c => wrapFunctional(c.default || c))
export const PopupOrder = () => import('../../components/common/PopupOrder/index.vue' /* webpackChunkName: "components/popup-order" */).then(c => wrapFunctional(c.default || c))
export const PopupPreEntity = () => import('../../components/common/PopupPreEntity/index.vue' /* webpackChunkName: "components/popup-pre-entity" */).then(c => wrapFunctional(c.default || c))
export const PopupRemoveSchedule = () => import('../../components/common/PopupRemoveSchedule/index.vue' /* webpackChunkName: "components/popup-remove-schedule" */).then(c => wrapFunctional(c.default || c))
export const PopupScheduleQuotaEdit = () => import('../../components/common/PopupScheduleQuotaEdit/index.vue' /* webpackChunkName: "components/popup-schedule-quota-edit" */).then(c => wrapFunctional(c.default || c))
export const PopupStoryEmptyPopupStoryCard = () => import('../../components/common/PopupStory/EmptyPopupStoryCard.vue' /* webpackChunkName: "components/popup-story-empty-popup-story-card" */).then(c => wrapFunctional(c.default || c))
export const PopupStoryCard = () => import('../../components/common/PopupStory/PopupStoryCard.vue' /* webpackChunkName: "components/popup-story-card" */).then(c => wrapFunctional(c.default || c))
export const PopupStoryCardButton = () => import('../../components/common/PopupStory/PopupStoryCardButton.vue' /* webpackChunkName: "components/popup-story-card-button" */).then(c => wrapFunctional(c.default || c))
export const PopupStoryCardTopBar = () => import('../../components/common/PopupStory/PopupStoryCardTopBar.vue' /* webpackChunkName: "components/popup-story-card-top-bar" */).then(c => wrapFunctional(c.default || c))
export const PopupStory = () => import('../../components/common/PopupStory/index.vue' /* webpackChunkName: "components/popup-story" */).then(c => wrapFunctional(c.default || c))
export const PopupFeedback = () => import('../../components/common/PopupFeedback/index.vue' /* webpackChunkName: "components/popup-feedback" */).then(c => wrapFunctional(c.default || c))
export const PopupArticleNote = () => import('../../components/common/PopupArticleNote/index.vue' /* webpackChunkName: "components/popup-article-note" */).then(c => wrapFunctional(c.default || c))
export const PopupAddTravel = () => import('../../components/common/PopupAddTravel/index.vue' /* webpackChunkName: "components/popup-add-travel" */).then(c => wrapFunctional(c.default || c))
export const PopupSuccess = () => import('../../components/common/PopupSuccess/index.vue' /* webpackChunkName: "components/popup-success" */).then(c => wrapFunctional(c.default || c))
export const PopupTradingPlatformMemberRequestSuccess = () => import('../../components/common/PopupTradingPlatformMemberRequestSuccess/index.vue' /* webpackChunkName: "components/popup-trading-platform-member-request-success" */).then(c => wrapFunctional(c.default || c))
export const PopupWidgetError = () => import('../../components/common/PopupWidgetError/index.vue' /* webpackChunkName: "components/popup-widget-error" */).then(c => wrapFunctional(c.default || c))
export const PopupWidgetRemove = () => import('../../components/common/PopupWidgetRemove/index.vue' /* webpackChunkName: "components/popup-widget-remove" */).then(c => wrapFunctional(c.default || c))
export const PopupTradingPlatformMemberRequest = () => import('../../components/common/PopupTradingPlatformMemberRequest/index.vue' /* webpackChunkName: "components/popup-trading-platform-member-request" */).then(c => wrapFunctional(c.default || c))
export const PopupWidgetSuccess = () => import('../../components/common/PopupWidgetSuccess/index.vue' /* webpackChunkName: "components/popup-widget-success" */).then(c => wrapFunctional(c.default || c))
export const PromoCode = () => import('../../components/common/PromoCode/index.vue' /* webpackChunkName: "components/promo-code" */).then(c => wrapFunctional(c.default || c))
export const RatingTag = () => import('../../components/common/RatingTag/index.vue' /* webpackChunkName: "components/rating-tag" */).then(c => wrapFunctional(c.default || c))
export const RecommendationCard = () => import('../../components/common/RecommendationCard/index.vue' /* webpackChunkName: "components/recommendation-card" */).then(c => wrapFunctional(c.default || c))
export const RoundButton = () => import('../../components/common/RoundButton/index.vue' /* webpackChunkName: "components/round-button" */).then(c => wrapFunctional(c.default || c))
export const RouteCard = () => import('../../components/common/RouteCard/index.vue' /* webpackChunkName: "components/route-card" */).then(c => wrapFunctional(c.default || c))
export const PopupTariffStatusChange = () => import('../../components/common/PopupTariffStatusChange/index.vue' /* webpackChunkName: "components/popup-tariff-status-change" */).then(c => wrapFunctional(c.default || c))
export const ScheduleWeekdays = () => import('../../components/common/ScheduleWeekdays/index.vue' /* webpackChunkName: "components/schedule-weekdays" */).then(c => wrapFunctional(c.default || c))
export const ScrollToTopButton = () => import('../../components/common/ScrollToTopButton/index.vue' /* webpackChunkName: "components/scroll-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const Scroller = () => import('../../components/common/Scroller/index.vue' /* webpackChunkName: "components/scroller" */).then(c => wrapFunctional(c.default || c))
export const SearchEvents = () => import('../../components/common/SearchEvents/index.vue' /* webpackChunkName: "components/search-events" */).then(c => wrapFunctional(c.default || c))
export const PopupTradingPlatformMemberRequestReject = () => import('../../components/common/PopupTradingPlatformMemberRequestReject/index.vue' /* webpackChunkName: "components/popup-trading-platform-member-request-reject" */).then(c => wrapFunctional(c.default || c))
export const SearchNews = () => import('../../components/common/SearchNews/index.vue' /* webpackChunkName: "components/search-news" */).then(c => wrapFunctional(c.default || c))
export const SearchExcursions = () => import('../../components/common/SearchExcursions/index.vue' /* webpackChunkName: "components/search-excursions" */).then(c => wrapFunctional(c.default || c))
export const SearchPlaces = () => import('../../components/common/SearchPlaces/index.vue' /* webpackChunkName: "components/search-places" */).then(c => wrapFunctional(c.default || c))
export const SearchRoutes = () => import('../../components/common/SearchRoutes/index.vue' /* webpackChunkName: "components/search-routes" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/common/Section/index.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyBranches = () => import('../../components/common/SectionAgencyBranches/index.vue' /* webpackChunkName: "components/section-agency-branches" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationAddress = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationAddress.vue' /* webpackChunkName: "components/section-agency-legal-information-address" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationDate = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationDate.vue' /* webpackChunkName: "components/section-agency-legal-information-date" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationEmail = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationEmail.vue' /* webpackChunkName: "components/section-agency-legal-information-email" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationInn = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationInn.vue' /* webpackChunkName: "components/section-agency-legal-information-inn" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationKpp = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationKpp.vue' /* webpackChunkName: "components/section-agency-legal-information-kpp" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationName = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationName.vue' /* webpackChunkName: "components/section-agency-legal-information-name" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationOgrn = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationOgrn.vue' /* webpackChunkName: "components/section-agency-legal-information-ogrn" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationOkved = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationOkved.vue' /* webpackChunkName: "components/section-agency-legal-information-okved" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformationStatus = () => import('../../components/common/SectionAgencyLegalInformation/SectionAgencyLegalInformationStatus.vue' /* webpackChunkName: "components/section-agency-legal-information-status" */).then(c => wrapFunctional(c.default || c))
export const SectionAgencyLegalInformation = () => import('../../components/common/SectionAgencyLegalInformation/index.vue' /* webpackChunkName: "components/section-agency-legal-information" */).then(c => wrapFunctional(c.default || c))
export const SectionAviaKassaWidgetComponent = () => import('../../components/common/SectionAviaKassaWidgetComponent/index.vue' /* webpackChunkName: "components/section-avia-kassa-widget-component" */).then(c => wrapFunctional(c.default || c))
export const SectionBannerAppMobile = () => import('../../components/common/SectionBannerAppMobile/index.vue' /* webpackChunkName: "components/section-banner-app-mobile" */).then(c => wrapFunctional(c.default || c))
export const SectionBannerOnMap = () => import('../../components/common/SectionBannerOnMap/index.vue' /* webpackChunkName: "components/section-banner-on-map" */).then(c => wrapFunctional(c.default || c))
export const SectionBillboardButton = () => import('../../components/common/SectionBillboard/SectionBillboardButton.vue' /* webpackChunkName: "components/section-billboard-button" */).then(c => wrapFunctional(c.default || c))
export const SectionBillboardLink = () => import('../../components/common/SectionBillboard/SectionBillboardLink.vue' /* webpackChunkName: "components/section-billboard-link" */).then(c => wrapFunctional(c.default || c))
export const SectionBillboard = () => import('../../components/common/SectionBillboard/index.vue' /* webpackChunkName: "components/section-billboard" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendarFilters = () => import('../../components/common/ScheduleCalendar/ScheduleCalendarFilters.vue' /* webpackChunkName: "components/schedule-calendar-filters" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendarMobileContext = () => import('../../components/common/ScheduleCalendar/ScheduleCalendarMobileContext.vue' /* webpackChunkName: "components/schedule-calendar-mobile-context" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendarMobileItem = () => import('../../components/common/ScheduleCalendar/ScheduleCalendarMobileItem.vue' /* webpackChunkName: "components/schedule-calendar-mobile-item" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendarScroll = () => import('../../components/common/ScheduleCalendar/ScheduleCalendarScroll.vue' /* webpackChunkName: "components/schedule-calendar-scroll" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalenderContext = () => import('../../components/common/ScheduleCalendar/ScheduleCalenderContext.vue' /* webpackChunkName: "components/schedule-calender-context" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalenderItem = () => import('../../components/common/ScheduleCalendar/ScheduleCalenderItem.vue' /* webpackChunkName: "components/schedule-calender-item" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalenderSort = () => import('../../components/common/ScheduleCalendar/ScheduleCalenderSort.vue' /* webpackChunkName: "components/schedule-calender-sort" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendar = () => import('../../components/common/ScheduleCalendar/index.vue' /* webpackChunkName: "components/schedule-calendar" */).then(c => wrapFunctional(c.default || c))
export const RutubeVideo = () => import('../../components/common/RutubeVideo/index.vue' /* webpackChunkName: "components/rutube-video" */).then(c => wrapFunctional(c.default || c))
export const SectionCategoriesSliders = () => import('../../components/common/SectionCategoriesSliders/index.vue' /* webpackChunkName: "components/section-categories-sliders" */).then(c => wrapFunctional(c.default || c))
export const SectionCategoryCollectionsExcursions = () => import('../../components/common/SectionCategoryCollectionsExcursions/index.vue' /* webpackChunkName: "components/section-category-collections-excursions" */).then(c => wrapFunctional(c.default || c))
export const SectionCategoryCollectionsPlaces = () => import('../../components/common/SectionCategoryCollectionsPlaces/index.vue' /* webpackChunkName: "components/section-category-collections-places" */).then(c => wrapFunctional(c.default || c))
export const SectionCategoryCollectionsRoutes = () => import('../../components/common/SectionCategoryCollectionsRoutes/index.vue' /* webpackChunkName: "components/section-category-collections-routes" */).then(c => wrapFunctional(c.default || c))
export const SectionComments = () => import('../../components/common/SectionComments/index.vue' /* webpackChunkName: "components/section-comments" */).then(c => wrapFunctional(c.default || c))
export const SectionCustomSliderFirst = () => import('../../components/common/SectionCustomSliderFirst/index.vue' /* webpackChunkName: "components/section-custom-slider-first" */).then(c => wrapFunctional(c.default || c))
export const SearchPlaceDropDownMenu = () => import('../../components/common/SearchPlaceDropDownMenu/index.vue' /* webpackChunkName: "components/search-place-drop-down-menu" */).then(c => wrapFunctional(c.default || c))
export const SectionCustomSlider = () => import('../../components/common/SectionCustomSlider/index.vue' /* webpackChunkName: "components/section-custom-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionCustomSliderSecond = () => import('../../components/common/SectionCustomSliderSecond/index.vue' /* webpackChunkName: "components/section-custom-slider-second" */).then(c => wrapFunctional(c.default || c))
export const SectionEntityGrid = () => import('../../components/common/SectionEntityGrid/index.vue' /* webpackChunkName: "components/section-entity-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionCustomSliderLanding = () => import('../../components/common/SectionCustomSliderLanding/index.vue' /* webpackChunkName: "components/section-custom-slider-landing" */).then(c => wrapFunctional(c.default || c))
export const SectionCategoryCollectionsEvents = () => import('../../components/common/SectionCategoryCollectionsEvents/index.vue' /* webpackChunkName: "components/section-category-collections-events" */).then(c => wrapFunctional(c.default || c))
export const SectionEventEntityContent = () => import('../../components/common/SectionEventEntityContent/index.vue' /* webpackChunkName: "components/section-event-entity-content" */).then(c => wrapFunctional(c.default || c))
export const SectionEventAnotherEventsSlider = () => import('../../components/common/SectionEventAnotherEventsSlider/index.vue' /* webpackChunkName: "components/section-event-another-events-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionEventPlaceEventsSlider = () => import('../../components/common/SectionEventPlaceEventsSlider/index.vue' /* webpackChunkName: "components/section-event-place-events-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionEmptyEntity = () => import('../../components/common/SectionEmptyEntity/index.vue' /* webpackChunkName: "components/section-empty-entity" */).then(c => wrapFunctional(c.default || c))
export const SectionEventTopTitle = () => import('../../components/common/SectionEventTopTitle/index.vue' /* webpackChunkName: "components/section-event-top-title" */).then(c => wrapFunctional(c.default || c))
export const SectionExcursionComments = () => import('../../components/common/SectionExcursionComments/index.vue' /* webpackChunkName: "components/section-excursion-comments" */).then(c => wrapFunctional(c.default || c))
export const SectionExcursionEntityContent = () => import('../../components/common/SectionExcursionEntityContent/index.vue' /* webpackChunkName: "components/section-excursion-entity-content" */).then(c => wrapFunctional(c.default || c))
export const SectionExcursionGallery = () => import('../../components/common/SectionExcursionGallery/index.vue' /* webpackChunkName: "components/section-excursion-gallery" */).then(c => wrapFunctional(c.default || c))
export const SectionEventTopEntity = () => import('../../components/common/SectionEventTopEntity/index.vue' /* webpackChunkName: "components/section-event-top-entity" */).then(c => wrapFunctional(c.default || c))
export const SectionExcursionTopEntity = () => import('../../components/common/SectionExcursionTopEntity/index.vue' /* webpackChunkName: "components/section-excursion-top-entity" */).then(c => wrapFunctional(c.default || c))
export const SectionEventGallery = () => import('../../components/common/SectionEventGallery/index.vue' /* webpackChunkName: "components/section-event-gallery" */).then(c => wrapFunctional(c.default || c))
export const SectionHeroSlider = () => import('../../components/common/SectionHeroSlider/index.vue' /* webpackChunkName: "components/section-hero-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionExcursionTopTitle = () => import('../../components/common/SectionExcursionTopTitle/index.vue' /* webpackChunkName: "components/section-excursion-top-title" */).then(c => wrapFunctional(c.default || c))
export const SectionJournalTop = () => import('../../components/common/SectionJournalTop/index.vue' /* webpackChunkName: "components/section-journal-top" */).then(c => wrapFunctional(c.default || c))
export const SectionJournalsCollectionGrid = () => import('../../components/common/SectionJournalsCollectionGrid/index.vue' /* webpackChunkName: "components/section-journals-collection-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionJournalsShortGrid = () => import('../../components/common/SectionJournalsShortGrid/index.vue' /* webpackChunkName: "components/section-journals-short-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalityAgenciesGrid = () => import('../../components/common/SectionMunicipalityAgenciesGrid/index.vue' /* webpackChunkName: "components/section-municipality-agencies-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionEventComments = () => import('../../components/common/SectionEventComments/index.vue' /* webpackChunkName: "components/section-event-comments" */).then(c => wrapFunctional(c.default || c))
export const SectionExcursionOnMap = () => import('../../components/common/SectionExcursionOnMap/index.vue' /* webpackChunkName: "components/section-excursion-on-map" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalityEntityContent = () => import('../../components/common/SectionMunicipalityEntityContent/index.vue' /* webpackChunkName: "components/section-municipality-entity-content" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalityGuidesGrid = () => import('../../components/common/SectionMunicipalityGuidesGrid/index.vue' /* webpackChunkName: "components/section-municipality-guides-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionJournalTopWithImage = () => import('../../components/common/SectionJournalTopWithImage/index.vue' /* webpackChunkName: "components/section-journal-top-with-image" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalityJournals = () => import('../../components/common/SectionMunicipalityJournals/index.vue' /* webpackChunkName: "components/section-municipality-journals" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalityTopEntity = () => import('../../components/common/SectionMunicipalityTopEntity/index.vue' /* webpackChunkName: "components/section-municipality-top-entity" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalityTopTitle = () => import('../../components/common/SectionMunicipalityTopTitle/index.vue' /* webpackChunkName: "components/section-municipality-top-title" */).then(c => wrapFunctional(c.default || c))
export const SectionNews = () => import('../../components/common/SectionNews/index.vue' /* webpackChunkName: "components/section-news" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalitySliderExcursions = () => import('../../components/common/SectionMunicipalitySliderExcursions/index.vue' /* webpackChunkName: "components/section-municipality-slider-excursions" */).then(c => wrapFunctional(c.default || c))
export const SectionPhotobank = () => import('../../components/common/SectionPhotobank/index.vue' /* webpackChunkName: "components/section-photobank" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalityEventGrid = () => import('../../components/common/SectionMunicipalityEventGrid/index.vue' /* webpackChunkName: "components/section-municipality-event-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionRecommendations = () => import('../../components/common/SectionRecommendations/index.vue' /* webpackChunkName: "components/section-recommendations" */).then(c => wrapFunctional(c.default || c))
export const SectionStories = () => import('../../components/common/SectionStories/index.vue' /* webpackChunkName: "components/section-stories" */).then(c => wrapFunctional(c.default || c))
export const SectionSharing = () => import('../../components/common/SectionSharing/index.vue' /* webpackChunkName: "components/section-sharing" */).then(c => wrapFunctional(c.default || c))
export const SectionTags = () => import('../../components/common/SectionTags/index.vue' /* webpackChunkName: "components/section-tags" */).then(c => wrapFunctional(c.default || c))
export const SelectCheckBoxComponent = () => import('../../components/common/SelectCheckBoxComponent/index.vue' /* webpackChunkName: "components/select-check-box-component" */).then(c => wrapFunctional(c.default || c))
export const SelectComponent = () => import('../../components/common/SelectComponent/index.vue' /* webpackChunkName: "components/select-component" */).then(c => wrapFunctional(c.default || c))
export const SectionMunicipalitySliderRoutes = () => import('../../components/common/SectionMunicipalitySliderRoutes/index.vue' /* webpackChunkName: "components/section-municipality-slider-routes" */).then(c => wrapFunctional(c.default || c))
export const SectionWidgets = () => import('../../components/common/SectionWidgets/index.vue' /* webpackChunkName: "components/section-widgets" */).then(c => wrapFunctional(c.default || c))
export const SharingComponent = () => import('../../components/common/SharingComponent/index.vue' /* webpackChunkName: "components/sharing-component" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/common/Slider/index.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SectionPartners = () => import('../../components/common/SectionPartners/index.vue' /* webpackChunkName: "components/section-partners" */).then(c => wrapFunctional(c.default || c))
export const SmallTabs = () => import('../../components/common/SmallTabs/index.vue' /* webpackChunkName: "components/small-tabs" */).then(c => wrapFunctional(c.default || c))
export const SocialVideoFrameComponent = () => import('../../components/common/SocialVideoFrameComponent/index.vue' /* webpackChunkName: "components/social-video-frame-component" */).then(c => wrapFunctional(c.default || c))
export const SocialsRoundedComponent = () => import('../../components/common/SocialsRoundedComponent/index.vue' /* webpackChunkName: "components/socials-rounded-component" */).then(c => wrapFunctional(c.default || c))
export const SocialsSquaredComponent = () => import('../../components/common/SocialsSquaredComponent/index.vue' /* webpackChunkName: "components/socials-squared-component" */).then(c => wrapFunctional(c.default || c))
export const Status = () => import('../../components/common/Status/index.vue' /* webpackChunkName: "components/status" */).then(c => wrapFunctional(c.default || c))
export const StatusDropDownMenu = () => import('../../components/common/StatusDropDownMenu/index.vue' /* webpackChunkName: "components/status-drop-down-menu" */).then(c => wrapFunctional(c.default || c))
export const SliderBullets = () => import('../../components/common/SliderBullets/index.vue' /* webpackChunkName: "components/slider-bullets" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/common/Tabs/index.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TabsLine = () => import('../../components/common/TabsLine/index.vue' /* webpackChunkName: "components/tabs-line" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/common/Tag/index.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const Tags = () => import('../../components/common/Tags/index.vue' /* webpackChunkName: "components/tags" */).then(c => wrapFunctional(c.default || c))
export const TestSliderSlots = () => import('../../components/common/TestSlider/TestSliderSlots.vue' /* webpackChunkName: "components/test-slider-slots" */).then(c => wrapFunctional(c.default || c))
export const TestSlider = () => import('../../components/common/TestSlider/index.vue' /* webpackChunkName: "components/test-slider" */).then(c => wrapFunctional(c.default || c))
export const SelectSearchComponent = () => import('../../components/common/SelectSearchComponent/index.vue' /* webpackChunkName: "components/select-search-component" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/common/Toast/index.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const SliderWithBullets = () => import('../../components/common/SliderWithBullets/index.vue' /* webpackChunkName: "components/slider-with-bullets" */).then(c => wrapFunctional(c.default || c))
export const ToasterText = () => import('../../components/common/ToasterText/index.vue' /* webpackChunkName: "components/toaster-text" */).then(c => wrapFunctional(c.default || c))
export const Toaster = () => import('../../components/common/Toaster/index.vue' /* webpackChunkName: "components/toaster" */).then(c => wrapFunctional(c.default || c))
export const TextareaComponent = () => import('../../components/common/TextareaComponent/index.vue' /* webpackChunkName: "components/textarea-component" */).then(c => wrapFunctional(c.default || c))
export const StoryCard = () => import('../../components/common/StoryCard/index.vue' /* webpackChunkName: "components/story-card" */).then(c => wrapFunctional(c.default || c))
export const TopEntityRating = () => import('../../components/common/TopEntityRating/index.vue' /* webpackChunkName: "components/top-entity-rating" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../components/common/Toggle/index.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const TopTitleComponent = () => import('../../components/common/TopTitleComponent/index.vue' /* webpackChunkName: "components/top-title-component" */).then(c => wrapFunctional(c.default || c))
export const TopEntityAgencyProfile = () => import('../../components/common/TopEntityAgencyProfile/index.vue' /* webpackChunkName: "components/top-entity-agency-profile" */).then(c => wrapFunctional(c.default || c))
export const TravelAgencyExcursions = () => import('../../components/common/TravelAgencyExcursions/index.vue' /* webpackChunkName: "components/travel-agency-excursions" */).then(c => wrapFunctional(c.default || c))
export const UserInfo = () => import('../../components/common/UserInfo/index.vue' /* webpackChunkName: "components/user-info" */).then(c => wrapFunctional(c.default || c))
export const VueEditorComponent = () => import('../../components/common/VueEditorComponent/index.vue' /* webpackChunkName: "components/vue-editor-component" */).then(c => wrapFunctional(c.default || c))
export const WeekDaysComponent = () => import('../../components/common/WeekDaysComponent/index.vue' /* webpackChunkName: "components/week-days-component" */).then(c => wrapFunctional(c.default || c))
export const WidgetPreviewPseudoInputComponent = () => import('../../components/common/WidgetPreview/PseudoInputComponent.vue' /* webpackChunkName: "components/widget-preview-pseudo-input-component" */).then(c => wrapFunctional(c.default || c))
export const WidgetPreviewPseudoSelectComponent = () => import('../../components/common/WidgetPreview/PseudoSelectComponent.vue' /* webpackChunkName: "components/widget-preview-pseudo-select-component" */).then(c => wrapFunctional(c.default || c))
export const WidgetPreview = () => import('../../components/common/WidgetPreview/index.vue' /* webpackChunkName: "components/widget-preview" */).then(c => wrapFunctional(c.default || c))
export const WorkHours = () => import('../../components/common/WorkHours/index.vue' /* webpackChunkName: "components/work-hours" */).then(c => wrapFunctional(c.default || c))
export const WorkTime = () => import('../../components/common/WorkTime/index.vue' /* webpackChunkName: "components/work-time" */).then(c => wrapFunctional(c.default || c))
export const YandexTicketBueButton = () => import('../../components/common/YandexTicketBueButton/index.vue' /* webpackChunkName: "components/yandex-ticket-bue-button" */).then(c => wrapFunctional(c.default || c))
export const YoutubeVideo = () => import('../../components/common/YoutubeVideo/index.vue' /* webpackChunkName: "components/youtube-video" */).then(c => wrapFunctional(c.default || c))
export const TradingPlatformMemberBecome = () => import('../../components/common/TradingPlatformMemberBecome/index.vue' /* webpackChunkName: "components/trading-platform-member-become" */).then(c => wrapFunctional(c.default || c))
export const TravelCard = () => import('../../components/common/TravelCard/index.vue' /* webpackChunkName: "components/travel-card" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentAccessibleEnvironment = () => import('../../components/common/TopEntityComponent/TopEntityComponentAccessibleEnvironment.vue' /* webpackChunkName: "components/top-entity-component-accessible-environment" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentAddress = () => import('../../components/common/TopEntityComponent/TopEntityComponentAddress.vue' /* webpackChunkName: "components/top-entity-component-address" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentAge = () => import('../../components/common/TopEntityComponent/TopEntityComponentAge.vue' /* webpackChunkName: "components/top-entity-component-age" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentCalories = () => import('../../components/common/TopEntityComponent/TopEntityComponentCalories.vue' /* webpackChunkName: "components/top-entity-component-calories" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentCity = () => import('../../components/common/TopEntityComponent/TopEntityComponentCity.vue' /* webpackChunkName: "components/top-entity-component-city" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentCountExcursion = () => import('../../components/common/TopEntityComponent/TopEntityComponentCountExcursion.vue' /* webpackChunkName: "components/top-entity-component-count-excursion" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentCountPlaces = () => import('../../components/common/TopEntityComponent/TopEntityComponentCountPlaces.vue' /* webpackChunkName: "components/top-entity-component-count-places" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentCountRoutes = () => import('../../components/common/TopEntityComponent/TopEntityComponentCountRoutes.vue' /* webpackChunkName: "components/top-entity-component-count-routes" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentDates = () => import('../../components/common/TopEntityComponent/TopEntityComponentDates.vue' /* webpackChunkName: "components/top-entity-component-dates" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentDistance = () => import('../../components/common/TopEntityComponent/TopEntityComponentDistance.vue' /* webpackChunkName: "components/top-entity-component-distance" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentDuration = () => import('../../components/common/TopEntityComponent/TopEntityComponentDuration.vue' /* webpackChunkName: "components/top-entity-component-duration" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentLabel = () => import('../../components/common/TopEntityComponent/TopEntityComponentLabel.vue' /* webpackChunkName: "components/top-entity-component-label" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentLanguages = () => import('../../components/common/TopEntityComponent/TopEntityComponentLanguages.vue' /* webpackChunkName: "components/top-entity-component-languages" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentLevel = () => import('../../components/common/TopEntityComponent/TopEntityComponentLevel.vue' /* webpackChunkName: "components/top-entity-component-level" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentMail = () => import('../../components/common/TopEntityComponent/TopEntityComponentMail.vue' /* webpackChunkName: "components/top-entity-component-mail" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentPhone = () => import('../../components/common/TopEntityComponent/TopEntityComponentPhone.vue' /* webpackChunkName: "components/top-entity-component-phone" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentPlaceTitle = () => import('../../components/common/TopEntityComponent/TopEntityComponentPlaceTitle.vue' /* webpackChunkName: "components/top-entity-component-place-title" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentSeason = () => import('../../components/common/TopEntityComponent/TopEntityComponentSeason.vue' /* webpackChunkName: "components/top-entity-component-season" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentSite = () => import('../../components/common/TopEntityComponent/TopEntityComponentSite.vue' /* webpackChunkName: "components/top-entity-component-site" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentSlider = () => import('../../components/common/TopEntityComponent/TopEntityComponentSlider.vue' /* webpackChunkName: "components/top-entity-component-slider" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentTimes = () => import('../../components/common/TopEntityComponent/TopEntityComponentTimes.vue' /* webpackChunkName: "components/top-entity-component-times" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponentWorkHours = () => import('../../components/common/TopEntityComponent/TopEntityComponentWorkHours.vue' /* webpackChunkName: "components/top-entity-component-work-hours" */).then(c => wrapFunctional(c.default || c))
export const TopEntityComponent = () => import('../../components/common/TopEntityComponent/index.vue' /* webpackChunkName: "components/top-entity-component" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
