//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SmallTabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    openedTab: {
      type: Number,
      default: () => 0,
    },
    theme: {
      type: String,
      default: () => 'black'
    }
  },
  data () {
    return {
      tabIndex: 0,
      showMenu: false,
      showContent: false
    }
  },
  created() {
    this.tabIndex = this.openedTab
  },
  mounted () {
    this.showMenu = true
    this.showContent = true
  },
  methods: {
    changeIndex (index) {
      this.tabIndex = index
      this.$emit('change', index)
    }
  }
}
