import { render, staticRenderFns } from "./_excursion_id.vue?vue&type=template&id=0417014a&"
import script from "./_excursion_id.vue?vue&type=script&lang=js&"
export * from "./_excursion_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Status: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Status/index.vue').default,RoundButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/RoundButton/index.vue').default,Gallery: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Gallery/index.vue').default,ContextAdditions: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ContextAdditions/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default,AgencyExcursionPlaces: require('/srv/rir-platform/tourism/src/tourism-front/components/common/AgencyExcursionPlaces/index.vue').default,AgencyExcursionSchedules: require('/srv/rir-platform/tourism/src/tourism-front/components/common/AgencyExcursionSchedules/index.vue').default})
