//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TopTitleComponent',
  props: {
    // Текст над заголовком (тип и т.д)
    suptitle: {
      type: String,
      default: () => ''
    },
    // Заголовок
    title: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    showDefaultSlot () {
      return this?.$slots?.default
    }
  }
}
