import {Plugin} from '@nuxt/types'

type Locale = string

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $defaultLocale(): Locale
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $defaultLocale(): Locale
  }

  // nuxtContext.$cardFromEntity
  interface Context {
    $defaultLocale(): Locale
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $defaultLocale(): Locale
  }
}

const pluginDefaultLocale: Plugin = ({$config}, inject) => {
  inject('defaultLocale', () => $config.defaultLocale)
}

export default pluginDefaultLocale
