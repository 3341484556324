export default async function ({
  store,
  app
}) {
  // const levels = store.getters['levels/levels']
  // if (levels.length === 0) {
  await store.dispatch('levels/fetch', {
    params: {
      count: 100500,
      lang: app.i18n.locale
    }
  })
  // }
}
