// @ts-ignore
import {accountMenuItem, accountMenuList} from "~/types/account/account-menu-item";

export const accountMenu: Array<accountMenuList | accountMenuItem> = [
  {
    id: 0,
    link: '/account',
    text: 'components.personal-area-menu.profile',
    icon: 'Icon24FillUser',
    exact: true,
  },
  {
    id: 1,
    link: '/account/agencies',
    text: 'components.personal-area-menu.my-agencies',
    icon: 'Icon24FillBonuses',
    exact: true,
  },
  {
    id: 2,
    text: 'components.personal-area-menu.favorite',
    icon: 'Icon24FillFavorites',
    show: false,
    list: [
      {
        link: '/account/favorites/places',
        text: 'components.personal-area-menu.favorite-places',
      },
      {
        link: '/account/favorites/routes',
        text: 'components.personal-area-menu.favorite-routes',
      },
      {
        link: '/account/favorites/excursions',
        text: 'components.personal-area-menu.favorite-excursions',
      },
      {
        link: '/account/favorites/events',
        text: 'components.personal-area-menu.favorite-events',
      },
      {
        link: '/account/favorites/journals',
        text: 'components.personal-area-menu.favorite-journals',
      }
    ]
  },
  {
    id: 3,
    link: '/account/my/orders',
    text: 'components.personal-area-menu.my-orders',
    icon: 'Icon24FillShopping',
    show: false,
    list: []
  },
  {
    id: 4,
    link: '/account/travels',
    text: 'components.personal-area-menu.my-travels',
    icon: 'Icon24FillTravel',
    exact: false,
  },
  {
    id: 5,
    text: 'components.personal-area-menu.my-activity',
    show: false,
    icon: 'Icon24FillStar',
    list: [
      {
        link: '/account/my/routes',
        text: 'components.personal-area-menu.my-activity-routes',
      },
      {
        link: '/account/my/places',
        text: 'components.personal-area-menu.my-activity-places',
      }
    ]
  }
]
