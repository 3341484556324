export default (context, inject) => {
  function duration (value, card) {
    if (value > 2880) {
      return `${Math.round(value / 60 / 24)} ${this.$t('common.unit.day')}`
    } else {
      const obj = this.$moment.utc(this.$moment.duration(value * 60, 'seconds').asMilliseconds()).toObject()
      const day = obj.date - 1
      const hours = obj.hours
      const minutes = obj.minutes
      if (day) {
        return hours
          ? `${day} ${this.$t('common.unit.day')} ${hours} ${this.$t('common.unit.hour')}`
          : `${day} ${this.$t('common.unit.day')}`
      } else if (hours && hours < 12 && minutes && card === 'card') {
        const hour = Math.round(value / 60 * 10) / 10
        const floor = Math.floor(hour)
        const residue = hour % floor
        return residue < 0.25
          ? `${floor} ${this.$t('common.unit.hour')}`
          : (residue < 0.75
              ? `${floor + 0.5} ${this.$t('common.unit.hour')}`
              : `${floor + 1} ${this.$t('common.unit.hour')}`)
      } else if (hours && hours < 12 && minutes) {
        return `${hours} ${this.$t('common.unit.hour')} ${minutes} ${this.$t('common.unit.minute')}`
      } else if (hours) {
        return `${Math.round(value / 60)} ${this.$t('common.unit.hour')}`
      } else {
        return `${minutes} ${this.$t('common.unit.minute')}`
      }
    }
  }
  inject('duration', duration)
}
