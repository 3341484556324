//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DatePickerComponent',
  components: {
    Icon24FillCalendar: () => import('@/assets/icons/Icon24FillCalendar.svg?inline'),
    Icon24FillClose: () => import('@/assets/icons/Icon24FillClose.svg?inline'),
    ErrorComponent: () => import('@/components/common/ErrorComponent'),
    LabelComponent: () => import('@/components/common/LabelComponent')
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    type: {
      type: String,
      default: () => 'date'
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: () => ''
    },
    labelEmpty: {
      type: Boolean,
      default: () => false
    },
    errors: {
      type: Array,
      default: () => []
    },
    errorsEmpty: {
      type: Boolean,
      default: () => false
    },
    format: {
      type: String,
      default: () => 'DD.MM.YYYY'
    },
    valueType: {
      type: String,
      default: () => 'YYYY-MM-DD'
    },
    confirm: {
      type: Boolean,
      default: () => false
    },
    confirmText: {
      type: String,
      default: () => ''
    },
    range: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    value: {
      type: [Array, String],
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    popupStyle: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: () => false
    },
    obligatory: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    valueCurrent: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      }
    },
    dataPickerLocale() {
      return this.$store.getters.dateLocale
    },
    popupStyleDefault() {
      return {
        position: 'absolute',
        top: '0',
        left: '0'
      }
    },
    errorStyle() {
      return this.errors && this.errors.length > 0 ? 'error' : ''
    }
  }
}
