//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import seo from '@/middleware/seo'
import pageArticles from '@/middleware/pages/articles.js'

export default {
  name: 'ArticlesView',
  components: {
    SectionBannerAppMobile: () => import('@/components/common/SectionBannerAppMobile/index'),
    ArticleBlock: () => import('@/components/common/ArticleBlock/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    Section: () => import('@/components/common/Section/index'),
    ArticleTop: () => import('@/components/common/ArticleTop/index')
  },
  middleware: [seo, pageArticles],
  head() {
    return {
      title: 'Туристический путеводитель Мурманской области',
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: 'Туристический путеводитель Мурманской области',
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      articles: 'articles/articles'
    }),
    components() {
      return this.$config?.pages?.ArticlesView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.$handleScrollTo(`${this.$route.hash}`)
    }
  }
}
