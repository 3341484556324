export const state = () => ({
  locales: [],
  messages: {},
  host: null
})

export const mutations = {
  setMessages: (state, messages) => state.messages = messages,
  setLocales: (state, locales) => state.locales = locales,
  setHost: (state, host) => state.host = host
}

export const actions = {
  async nuxtServerInit({
                         state,
                         dispatch,
                         commit
                       }, {
                         $config
                       }) {
    await dispatch('languages/fetch')
    await this.$axios.$get('/api/languages')
      .then((response) => {
        const locales = response.data.map(item => item.locale)
        commit('setLocales', locales)
      })
      .then(async () => {
        const messages = {}
        await Promise.all(state.locales.map(async (item) => {
          const message = await this.$axios.$get(`${$config.host}/storage/translations/${item}.json`)
          messages[item] = message
        }))
          .then(() => {
            commit('setMessages', messages)
          })
      })
  }
}

export const getters = {
  locales: state => state.locales,
  messages: state => state.messages,
  host: state => state.host,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dateLocaleByLang: (state, getters) => (lang) => {
    const unit = getters.messages[lang]?.common?.unit
    return {
      formatLocale: {
        months: [
          unit?.months?.january?.full || 'January',
          unit?.months?.february?.full || 'February',
          unit?.months?.march?.full || 'March',
          unit?.months?.april?.full || 'April',
          unit?.months?.may?.full || 'May',
          unit?.months?.june?.full || 'June',
          unit?.months?.july?.full || 'July',
          unit?.months?.august?.full || 'August',
          unit?.months?.september?.full || 'September',
          unit?.months?.october?.full || 'October',
          unit?.months?.november?.full || 'November',
          unit?.months?.december?.full || 'December'
        ],
        monthsShort: [
          unit?.months?.january?.short || 'Jan',
          unit?.months?.february?.short || 'Feb',
          unit?.months?.march?.short || 'Mar',
          unit?.months?.april?.short || 'Apr',
          unit?.months?.may?.short || 'May',
          unit?.months?.june?.short || 'Jun',
          unit?.months?.july?.short || 'Jul',
          unit?.months?.august?.short || 'Aug',
          unit?.months?.september?.short || 'Sep',
          unit?.months?.october?.short || 'Oct',
          unit?.months?.november?.short || 'Nov',
          unit?.months?.december?.short || 'Dec'
        ],
        weekdays: [
          unit?.week?.sunday?.full || 'Sunday',
          unit?.week?.monday?.full || 'Monday',
          unit?.week?.tuesday?.full || 'Tuesday',
          unit?.week?.wednesday?.full || 'Wednesday',
          unit?.week?.thursday?.full || 'Thursday',
          unit?.week?.friday?.full || 'Friday',
          unit?.week?.saturday?.full || 'Saturday'
        ],
        weekdaysShort: [
          unit?.week?.sunday?.short || 'Sun',
          unit?.week?.monday?.short || 'Mon',
          unit?.week?.tuesday?.short || 'Tue',
          unit?.week?.wednesday?.short || 'Wed',
          unit?.week?.thursday?.short || 'Thu',
          unit?.week?.friday?.short || 'Fri',
          unit?.week?.saturday?.short || 'Sat'
        ],
        weekdaysMin: [
          unit?.week?.sunday?.min || 'Su',
          unit?.week?.monday?.min || 'Mo',
          unit?.week?.tuesday?.min || 'Tu',
          unit?.week?.wednesday?.min || 'We',
          unit?.week?.thursday?.min || 'Th',
          unit?.week?.friday?.min || 'Fr',
          unit?.week?.saturday?.min || 'Sa'
        ],
        firstDayOfWeek: +unit?.firstdayofweek || 0,
        firstWeekContainsDate: +unit?.firstweekcontainsdate || 1
      },
      yearFormat: unit?.yearformat || 'YYYY',
      monthFormat: unit?.monthformat || 'MMMM',
      monthBeforeYear: !!+unit?.monthbeforeyear || true
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dateLocale: (state, getters) => {
    return getters.dateLocaleByLang(getters['languages/current'])
  }
}
