//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import travelAgency from "@/middleware/pages/travel-agency";

export default {
  name: 'TravelAgencyView',
  components: {
    SectionAgencyBranches: () => import("@/components/common/SectionAgencyBranches"),
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    EntityContent: () => import("@/components/common/EntityContent"),
    TravelAgencyExcursions: () => import("@/components/common/TravelAgencyExcursions"),
    SectionBannerAppMobile: () => import('@/components/common/SectionBannerAppMobile/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    Section: () => import('@/components/common/Section/index'),
    TopEntityAgencyProfile: () => import('@/components/common/TopEntityAgencyProfile/index')
  },
  middleware: [travelAgency],
  head() {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.title,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.$seoImage(this.images) || this.SEOImage,
          url: this.SEOUrl,
          locale: this.SEOLocale,
          site_name: this.SEOSiteName
        },
        vk: {
          image: this.$seoImage(this.images) || this.SEOImage,
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      agency: 'pages/travel-agency/agency',
    }),
    components() {
      return this.$config?.pages?.TravelAgencyView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showContextAdditions() {
      return this.components?.ContextAdditions
    },
    description() {
      return this?.agency?.description
    },
    title() {
      return this?.agency?.title
    },
    id() {
      return this?.agency?.id
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath
    },
    additions() {
      return this?.agency?.additions
    },
  },
}
