export default async function ({
  $config,
  store,
  app
}) {
  store.commit('cities/setSingleCity', $config.singleCity)
  await store.dispatch('cities/fetch', {
    params: {
      count: 100500,
      lang: app.i18n.locale,
      resolution: 'micro'
    }
  })
}
