
export default {
  name: "AviaKassaWidgetComponent",
  props: {
    channelToken: {
      type: String,
      default: () => ''
    },
    id: {
      type: Number,
      default: () => null
    },
    src: {
      type: String,
      default: () => ''
    },
    showAvia: {
      type: Boolean,
      default: () => true
    },
    showRail: {
      type: Boolean,
      default: () => false
    },
    showHotel: {
      type: Boolean,
      default: () => false
    },
    showAviaTitle: {
      type: Boolean,
      default: () => false
    },
    showRailTitle: {
      type: Boolean,
      default: () => false
    },
    showHotelTitle: {
      type: Boolean,
      default: () => false
    },
    aviaTitle: {
      type: String,
      default: () => ''
    },
    railTitle: {
      type: String,
      default: () => ''
    },
    hotelTitle: {
      type: String,
      default: () => ''
    },
    showAviakassaLogo: {
      type: Boolean,
      default: () => false
    },
    showLocaleSelect: {
      type: Boolean,
      default: () => false
    },
    aviaShowComplexRoute: {
      type: Boolean,
      default: () => true
    },
    showAviaAirlinesPrefilter: {
      type: Boolean,
      default: () => true
    },
    paddingLeft: {
      type: Number,
      default: () => 0
    },
    paddingRight: {
      type: Number,
      default: () => 0
    }
  },
  render(createElement) {
    return createElement(
      'div',
      {
        class: {
          'avia-kassa': true
        }
      },
      [
        createElement(
          'div',
          {
            attrs: {
              id: `ak-app-${this.id}`
            }
          }
        ),
        createElement(
          'script',
          {
            attrs: {
              id: `ak-app-script-${this.id}`,
              charset: 'utf-8',
              type: 'text/javascript',
              defer: true,
              src: this.src
            }
          }
        ),
        createElement(
          'script',
          {
            attrs: {
              charset: 'utf-8',
              type: 'text/javascript'
            },
            domProps: {
              innerHTML: `
              document.getElementById('ak-app-script-${this.id}')
              .addEventListener('load', function () {
              new Aviakassa.Partner('ak-app-${this.id}', {
              'showAvia': ${this.showAvia},
              'showRail': ${this.showRail},
              'showHotel': ${this.showHotel},
              'showAviaTitle': ${this.showAviaTitle},
              'showRailTitle': ${this.showRailTitle},
              'showHotelTitle': ${this.showHotelTitle},
              'aviaTitle': '${this.aviaTitle}',
              'railTitle': '${this.railTitle}',
              'hotelTitle': '${this.hotelTitle}',
              'showAviakassaLogo': ${this.showAviakassaLogo},
              'showLocaleSelect': ${this.showLocaleSelect},
              'aviaShowComplexRoute': ${this.aviaShowComplexRoute},
              'paddingLeft': ${this.paddingLeft},
              'paddingRight': ${this.paddingRight},
              'showAviaAirlinesPrefilter': ${this.showAviaAirlinesPrefilter},
              'channelToken': '${this.channelToken}',
              'id': ${this.id}
              })
              })
              `
            }
          }
        ),
        createElement(
          'style',
          {
            domProps: {
              innerHTML: `
              @media screen and (min-width: 960px) {
                .RouteModal {
                  padding-top: 40px;
                }
              }
              `
            }
          }
        )
      ]
    )
  }
}
