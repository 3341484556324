//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ContextSchedule",
  props: {
    schedules: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    Day1() {
      const open = this.Day(this?.schedules?.day_1_open)
      const close = this.Day(this?.schedules?.day_1_close)
      return this.resultFormation(open, close, this.$t('common.unit.week.monday.full'))
    },
    Day2() {
      const open = this.Day(this?.schedules?.day_2_open)
      const close = this.Day(this?.schedules?.day_2_close)
      return this.resultFormation(open, close, this.$t('common.unit.week.tuesday.full'))
    },
    Day3() {
      const open = this.Day(this?.schedules?.day_3_open)
      const close = this.Day(this?.schedules?.day_3_close)
      return this.resultFormation(open, close, this.$t('common.unit.week.wednesday.full'))
    },
    Day4() {
      const open = this.Day(this?.schedules?.day_4_open)
      const close = this.Day(this?.schedules?.day_4_close)
      return this.resultFormation(open, close, this.$t('common.unit.week.thursday.full'))
    },
    Day5() {
      const open = this.Day(this?.schedules?.day_5_open)
      const close = this.Day(this?.schedules?.day_5_close)
      return this.resultFormation(open, close, this.$t('common.unit.week.friday.full'))
    },
    Day6() {
      const open = this.Day(this?.schedules?.day_6_open)
      const close = this.Day(this?.schedules?.day_6_close)
      return this.resultFormation(open, close, this.$t('common.unit.week.saturday.full'))
    },
    Day7() {
      const open = this.Day(this?.schedules?.day_7_open)
      const close = this.Day(this?.schedules?.day_7_close)
      return this.resultFormation(open, close, this.$t('common.unit.week.sunday.full'))
    },
    week() {
      return [
        this.Day1,
        this.Day2,
        this.Day3,
        this.Day4,
        this.Day5,
        this.Day6,
        this.Day7
      ]
    }
  },
  created() {
    this.show = Object?.values(this.schedules)?.some(item => item !== null)
  },
  methods: {
    Day(val) {
      if (val) {
        return this.$moment(val, 'HHmmss').format('HH:mm')
      }
      return null
    },
    resultFormation(open, close, name) {
      if (open === null && close === null) {
        return {
          day: `${name}`,
          openTime: this.$t('components.context-schedule.day-off'),
          closeTime: ''
        }
      } else if (open === '00:00' && close === '00:00') {
        return {
          day: `${name}`,
          openTime: this.$t('components.context-schedule.around-the-clock'),
          closeTime: ''
        }
      } else if (open === null && close !== null) {
        return {
          day: `${name}`,
          openTime: '-',
          closeTime: `${this.$t('components.context-schedule.to')} ${close}`
        }
      } else if (open !== null && close === null) {
        return {
          day: `${name}`,
          openTime: `${this.$t('components.context-schedule.from')} ${open}`,
          closeTime: '-'
        }
      } else {
        return {
          day: `${name}`,
          openTime: `${this.$t('components.context-schedule.from')} ${open}`,
          closeTime: `${this.$t('components.context-schedule.to')} ${close}`
        }
      }
    }
  }
}
