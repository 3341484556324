import { parser } from '@/utils/parser'

export const state = () => ({
  gallery: null,
  photos: [],
  title: ''
})

export const mutations = {
  setGallery: (state, gallery) => state.gallery = gallery,
  setPhotos: (state, photos) => state.photos = photos,
  setTitle: (state, title) => state.title = title,
  setMeta: (state, meta) => state.meta = meta,
  clear: (state) => {
    state.gallery = []
    state.photos = []
    state.title = ''
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload?.clear) {
      commit('clear')
    }
    const gallery = await this.$axios.$get(`/api/galleries/${payload.id}?lang=${payload.params.lang}`)
    commit('setGallery', gallery.data)
    const photos = await this.$axios.$get(`/api/galleries/${payload.id}/photos?${parser(payload)}`)
    commit('setPhotos', photos.data)
    commit('setTitle', photos.gallery.title)
  }
}

export const getters = {
  gallery: state => state.gallery,
  photos: state => state.photos
}
