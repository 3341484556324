export default async function ({
  app,
  store
}) {
  // const has_events = store.getters['places/has_events']
  // if (has_events.length === 0) {
  await store.dispatch('places/hasEvents', {
    params: {
      count: 100500,
      lang: app.i18n.locale
    }
  })
  // }
}
