//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LinkSite',
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    checkLink () {
      // eslint-disable-next-line prefer-regex-literals
      if (RegExp('^(http|https)://', 'i')
        .test(this.value)) {
        return this.value
      } else {
        return `https://${this.value}`
      }
    },
    hostName () {
      return this.name || (new URL(this.checkLink || '')).hostname || ''
    }
  }
}
