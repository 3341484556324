//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'MapNavigationRoute',
  components: {
    MapNavigationBackButton: () => import("@/components/common/MapNavigation/MapNavigationBackButton"),
    HorizontalEntityCard: () => import('@/components/common/HorizontalEntityCard'),
    Duration: () => import('@/components/common/Duration'),
    Distance: () => import('@/components/common/Distance'),
    Button: () => import('@/components/common/Button'),
    Divider: () => import('@/components/common/Divider'),
    TopEntityRating: () => import('@/components/common/TopEntityRating'),
    Favorite: () => import('@/components/common/Favorite'),
    ImageComponent: () => import('@/components/common/ImageComponent'),
    VueCustomScrollbar: () => import('vue-custom-scrollbar')
  },
  computed: {
    ...mapGetters({
      route: 'interactive-map/route',
      pending: 'interactive-map/routePending'
    }),
    showRoute() {
      return this.entity === 'routes' && this.id && !this.routePending
    },
    id() {
      return this?.route?.id
    },
    entity() {
      return this?.route?.entity
    },
    title() {
      return this?.route?.title
    },
    city() {
      return this?.route?.city?.title
    },
    address() {
      return this?.route?.address
    },
    places() {
      return this?.route?.places || []
    },
    subtype() {
      return this?.route?.subtype?.title
    },
    duration_int() {
      return this?.route?.duration_int
    },
    distance_int() {
      return this?.route?.distance_int
    },
    favorited() {
      return this?.route?.favorited
    },
    image() {
      return this?.route?.images[0] || require('@/assets/images/default-image.svg')
    },
    rating() {
      return this?.route?.rating
    },
    currentUserRating() {
      return this?.route?.currentUserRating
    }
  },
  methods: {
    back() {
      // this.$store.dispatch('interactive-map/routes', {clear: true})
      this.$store.commit('interactive-map/setId', null)
    },
    click(id) {
      this.$store.commit('interactive-map/setEntity', 'places')
      this.$store.commit('interactive-map/setId', id)
    }
  },
  // methods: {
  //   click (id) {
  //     this.$store.commit('interactive-map/setHistory', this.$route.query)
  //     this.$router.replace({
  //       query: {
  //         ...this.$route.query,
  //         id,
  //         entity: 'place'
  //       }
  //     })
  //   },
  //   visibleWorkNow (work_hours) {
  //     if (work_hours) {
  //       return !!Object.values(work_hours)
  //         .find(item => item)
  //     }
  //     return false
  //   },
  //   back () {
  //     this.$store.commit('interactive-map/setHistory', this.$route.query)
  //     this.$router.replace({
  //       query: {
  //         id: null,
  //         entity: 'routes'
  //       }
  //     })
  //   },
  //   changeInnerFavorited () {
  //     this.$store.dispatch('interactive-map/route', {
  //       id: this.$route.query.id,
  //       params: {
  //         resolution: 'micro',
  //         lang: this.$i18n.locale
  //       }
  //     })
  //   }
  // }
}
