export default async function ({
  store,
  app
}) {
  const entities = [
    'excursions',
    'events',
    'places',
    'routes'
  ]
  let promises = []
  entities.forEach(entity => {
    // const array = store.getters[`categories/${entity}`]
    // if (array.length === 0) {
    promises = [...promises, store.dispatch(`categories/${entity}`, {
      params: {
        count: 100500,
        lang: app.i18n.locale
      }
    })]
    // }
  })
  await Promise.all(promises)
}
