import {errors} from '~/utils/errors'

export const state = () => ({
  errors: null,
  pending: false,
  tickets: [], // Выбранные билеты
  tariffs: [], // Тарифы,
  time: null, // Время тарифа
  customerName: '', // Имя покупателя
  customerEmail: '', // Email покупателя
  customerPhone: null, // Телефон покупателя
  checkBookingAndCancellationTerms: false, // Чекбокс на согласие условий бронирования и отмены
  checkPersonal: false, // Чекбокс на согласие обработки персональных данных
  checkContractOffer: false, // Чекбокс на согласие договора оферты
  useBonus: false, // Чекбокс на использование бонусов пользователя при оплате
  id: null, // id события/экскурсии
  bookingTerms: null,
  cancellationTerms: null,
})

export const mutations = {
  reset: state => {
    state.errors = null
    state.pending = false
    state.tickets = [] // Выбранные билеты
    state.tariffs = [] // Тарифы
    state.time = null // Время тарифа
    state.customerName = '' // Имя покупателя
    state.customerEmail = '' // Email покупателя
    state.customerPhone = null // Телефон покупателя
    state.checkBookingAndCancellationTerms = false // Чекбокс на согласие условий бронирования и отмены
    state.checkPersonal = false // Чекбокс на согласие обработки персональных данных
    state.checkContractOffer = false // Чекбокс на согласие договора оферты
    state.useBonus = false // Чекбокс на использование бонусов пользователя при оплате
    state.id = null // id события/экскурсии
    state.bookingTerms = null
    state.cancellationTerms = null
  },
  setBookingTerms: (state, bookingTerms) => state.bookingTerms = bookingTerms,
  setCancellationTerms: (state, cancellationTerms) => state.cancellationTerms = cancellationTerms,
  setTariffs: (state, tariffs) => state.tariffs = tariffs,
  setTime: (state, time) => state.time = time,
  addTicketInBasket: (state, ticket) => state.tickets = [...state.tickets, ticket],
  removeTicketFromBasket: (state, ticket) => {
    const index = state.tickets.findIndex(item => item.schedule_id === ticket.schedule_id)
    if (!(index < 0)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.tickets = state.tickets.filter((item, i) => i !== index)
    }
  },
  setErrors: (state, errors) => state.errors = errors,
  setPending: (state, pending) => state.pending = pending,
  setCustomerName: (state, name) => {
    state.customerName = name
    state.errors = errors('customer_name', state.errors)
  },
  setCustomerEmail: (state, email) => {
    state.customerEmail = email
    state.errors = errors('customer_email', state.errors)
  },
  setCustomerPhone: (state, phone) => {
    state.customerPhone = phone
    state.errors = errors('customer_phone', state.errors)
  },
  setCheckBookingAndCancellationTerms: (state, check) => state.checkBookingAndCancellationTerms = check,
  setCheckPersonal: (state, check) => state.checkPersonal = check,
  setCheckContactOffer: (state, check) => state.checkContractOffer = check,
  setUseBonus: (state, useBonus) => {
    state.useBonus = useBonus
    state.errors = errors('bonus_points', state.errors)
  },
  setId: (state, id) => state.id = id,
}

export const actions = {
  async create({commit}, payload) {
    commit('setPending', true)
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    await this.$axios.$post('/api/orders', JSON.stringify(payload), config)
      .then(response => window.location = response?.redirect_url)
      .catch((errors) => {
        commit('setErrors', errors.response.data)
        commit('setPending', false)
      })
  },
  // нужен для оплаты в лк
  async put({commit}, payload) {
    commit('setPending', true)
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    payload.params = {
      ...payload.params,
      _method: 'PUT'
    }
    await this.$axios.$post(`/api/orders/${payload.id}`, JSON.stringify(payload.params), config)
      .then(response => window.location = response?.redirect_url)
      .catch((errors) => {
        commit('setErrors', errors.response.data)
        commit('setPending', false)
      })
  }
}

export const getters = {
  tickets: state => state.tickets,
  tariffs: state => state.tariffs,
  ticketsBonusPoints: state => {
    if (state.tickets.length > 0) {
      return state.tickets.reduce((accumulate, item) => accumulate + item.max_bonus_points, 0)
    }
    return 0
  },
  time: state => state.time,
  errors: state => state.errors,
  pending: state => state.pending,
  customerName: state => state.customerName,
  customerEmail: state => state.customerEmail,
  customerPhone: state => state.customerPhone,
  checkBookingAndCancellationTerms: state => state.checkBookingAndCancellationTerms,
  checkPersonal: state => state.checkPersonal,
  checkContractOffer: state => state.checkContractOffer,
  useBonus: state => state.useBonus,
  id: state => state.id,
  bookingTerms: state => state.bookingTerms,
  cancellationTerms: state => state.cancellationTerms,
}
