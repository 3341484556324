import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a240b052&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopTitleComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/TopTitleComponent/index.vue').default,Section: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Section/index.vue').default,RecommendationCard: require('/srv/rir-platform/tourism/src/tourism-front/components/common/RecommendationCard/index.vue').default,Scroller: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Scroller/index.vue').default,InputComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/InputComponent/index.vue').default,Button: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Button/index.vue').default,Toggle: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Toggle/index.vue').default,FilterToggle: require('/srv/rir-platform/tourism/src/tourism-front/components/common/FilterToggle/index.vue').default,SelectCheckBoxComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SelectCheckBoxComponent/index.vue').default,SelectSearchComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SelectSearchComponent/index.vue').default,EntityFiltersWrapper: require('/srv/rir-platform/tourism/src/tourism-front/components/common/EntityFiltersWrapper/index.vue').default,PlaceCard: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PlaceCard/index.vue').default,PendingButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/PendingButton/index.vue').default,SectionEmptyEntity: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionEmptyEntity/index.vue').default,SectionBannerAppMobile: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SectionBillboard/index.vue').default})
