//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentTimes",
  components: {
    Icon24FillClock: () => import('@/assets/icons/Icon24FillClock.svg?inline')
  },
  props: {
    times: {
      type: String,
      default: () => ''
    }
  }
}
