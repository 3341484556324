//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TradingPlatformMemberBecome",
  components: {
    Button: () => import("@/components/common/Button")
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  methods: {
    openPopup() {
      this.$store.commit("popup/open", {
        name: "PopupTradingPlatformMemberRequest",
        options: {
          id: this.id
        }
      });
    }
  }
};
