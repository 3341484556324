//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'Avatar',
  props: {
    size: {
      type: Number,
      default: () => 36
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-user-avatar.svg')
    },
    lazyload: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapGetters({
      resolutions: 'theme/resolutions'
    }),
    style() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      }
    }
  },
  created() {
    if (this.src) {
      this.src.replace(/micro|small|medium|large|extra?/gi,
        this?.lazyload?.resolution?.min || 'micro')
    }
  },
  methods: {
    load(element) {
      if (element.target.src.match(/micro|small|medium|large|extra?/gi)) {
        const max = this.resolutions.indexOf(this?.lazyload?.resolutions?.max || 'medium')
        const current = this.resolutions.indexOf(element.target.src.match(/micro|small|medium|large|extra?/gi)[0])
        if (current < max) {
          if (element.target.src.match(/micro/i)) {
            element.target.src = element.target.src.replace(/micro/i, 'small')
            return true
          }
        }
      }
    },
    error(element) {
      element.target.src = require('@/assets/images/default-image.svg')
    }
  }
}
