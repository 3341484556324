import {parser} from '@/utils/parser'

export const state = () => ({
  slides: [],
  pending: true,
})

export const getters = {
  slides: state => state.slides,
  pending: state => state.pending,
}

export const mutations = {
  setHeroSlider: (state, slides) => state.slides = slides,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async fetch({commit}, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/hero-slider?${parser(payload)}`)
      .then(response => commit('setHeroSlider', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}
