//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RecommendationCard',
  components: { ImageComponent: () => import('@/components/common/ImageComponent') },
  props: {
    image: {
      type: String,
      default: () => require('@/assets/images/default-image.svg')
    },
    title: {
      type: String,
      default: () => ''
    },
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    link () {
      return `/journals/${this.id}`
    }
  }
}
