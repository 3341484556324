//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionJournalTopWithImage",
  components: {
    EntityContent: () => import("@/components/common/EntityContent"),
    ImageComponent: () => import("@/components/common/ImageComponent"),
    Section: () => import("@/components/common/Section/index"),
  },
  props: {
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    },
    image: {
      type: String,
      default: () => ""
    }
  }
};
