//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AgencyExcursionCard',
  components: {
    Status: () => import('@/components/common/Status')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    title: {
      type: String,
      default: () => ''
    },
    city: {
      type: String,
      default: () => ''
    },
    status: {
      type: String,
      default: () => ''
    }
  }
}
