import {parser} from '@/utils/parser'

export const state = () => ({
  filterAgencies: [],
  agencies: [],
  pending: false,
  meta: null,
})

export const mutations = {
  clear: state => state.agencies = [],
  setPending: (state, pending) => state.pending = pending,
  setAgencies: (state, agencies) => state.agencies = [...state.agencies, ...agencies],
  setMeta: (state, meta) => state.meta = meta,
  setFilterAgencies: (state, filterAgencies) => state.filterAgencies = filterAgencies
}

export const actions = {
  async fetch({commit}, payload) {
    if (payload?.clear) {
      commit('clear')
    }
    commit('setPending', true)
    await this.$axios.$get(`/api/agencies?${parser(payload)}`)
      .then(response => {
        commit('setAgencies', response.data)
        commit('setMeta', response.meta)
      })
      .catch(() => {
        commit('setAgencies', [])
        commit('setMeta', null)
      })
      .finally(() => {
        commit('setPending', false)
      })
  },
  async hasExcursions({commit}, payload) {
    const hasExcursions = await this.$axios.$get(`/api/agencies?has_excursions${parser(payload)}`)
    commit('setFilterAgencies', hasExcursions.data)
  }
}

export const getters = {
  agencies: state => state.agencies,
  meta: state => state.meta,
  pending: state => state.pending,
  filterAgencies: state => state.filterAgencies
}
