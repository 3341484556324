export default (context, inject) => {
  const email = {
    mask: 'Z*@Z*.S*',
    tokens: {
      'Z': {
        pattern: /([a-z0-9_.-])/,
      }
    }
  }
  inject('maskaPatternEmail', email)
}
