//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "PopupStoryCardTopBar",
  components: {
    Icon24FillClose: () => import("@/assets/icons/Icon24FillClose.svg?inline"),
  },
  data() {
    return {
      timer: null,
      counter: 100,
    }
  },
  computed: {
    ...mapGetters({
      parts: 'story/parts',
      partCurrentIndex: 'story/partCurrentIndex',
      pending: 'story/pending',
    }),
    styleProgressBar() {
      return {
        gridTemplateColumns: `repeat(${this.parts.length}, 1fr)`
      }
    },
    progress() {
      const percent = this.counter / 100 * 100
      return {
        background:
          `linear-gradient(
            to left,
            var(--white-60) 0%,
            var(--white-60) ${percent}%,
            var(--white) ${percent}%,
            var(--white) 100%
          )`
      };
    },
  },
  watch: {
    pending(n) {
      if (n === false) {
        this.resetTimer()
      } else {
        this.stopTimer()
      }
    },
    partCurrentIndex() {
      this.resetTimer()
    },
    counter(n) {
      if (n <= -1) {
        this.stopTimer()
        if (this.partCurrentIndex < this.parts.length - 1) {
          this.$store.commit('story/setPartCurrentIndex', this.partCurrentIndex + 1)
        } else if (this.partCurrentIndex === this.parts.length - 1) {
          this.$store.commit('story/setPartCurrentIndex', 0)
        }
        this.counter = 100
        this.startTimer()
      }
    }
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    resetTimer() {
      this.stopTimer()
      this.counter = 100
      this.startTimer()
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.counter--
      }, 50)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    styleProgressBarPart(index) {
      if (this.partCurrentIndex === index) {
        return this.progress
      } else if (index < this.partCurrentIndex) {
        return {background: 'var(--white)'}
      }
    },
  }
}
