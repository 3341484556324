//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionComments",
  components: {
    Section: () => import('@/components/common/Section'),
    Comments: () => import('@/components/common/Comments')
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    }
  }
}
