//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentAccessibleEnvironment",
  components: {
    Icon24FillInvalid: () => import('@/assets/icons/Icon24FillInvalid.svg?inline')
  },
  props: {
    accessibleEnvironment: {
      type: Boolean,
      default: () => false
    }
  }
}
