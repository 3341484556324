//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import accountAgencyWidget from '@/middleware/account-agency-widget'
import accountAgencyProfileValidation from "@/middleware/account-agency-profile-validation";

export default {
  name: 'WidgetCreateView',
  components: {
    InputComponentSite: () => import("@/components/common/InputComponentSite"),
    // CodeViewComponent: () => import("@/components/CodeViewComponent"),
    ColorPickerComponent: () => import("@/components/common/ColorPickerComponent"),
    Button: () => import("@/components/common/Button"),
    WidgetPreview: () => import("~/components/common/WidgetPreview"),
    SmallTabs: () => import("@/components/common/SmallTabs"),
    SelectCheckBoxComponent: () => import("@/components/common/SelectCheckBoxComponent"),
    InputComponent: () => import("@/components/common/InputComponent"),
    Section: () => import("@/components/common/Section")
  },
  layout: 'personal-area-agency',
  middleware: [accountAgencyProfileValidation, accountAgencyWidget],
  data() {
    return {
      pending: false,
      tabs: [
        this.$t('pages.widget-create-view.tab-preview'),
        // this.$t('pages.widget-create-view.tab-code'),
      ]
    }
  },
  computed: {
    ...mapGetters({
      title: 'add-widget/title',
      href: 'add-widget/href',
      src: 'add-widget/src',
      website: 'add-widget/website',
      background: 'add-widget/background',
      main: 'add-widget/main',
      textButtonColor: 'add-widget/textButtonColor',
      textMainColor: 'add-widget/textMainColor',
      array: 'add-widget/array',
      agencies: 'user/agencies',
      errors: 'add-widget/errors',
    }),
    locale() {
      return this.$i18n.locale !== 'ru' ? `/${this.$i18n.locale}` : ''
    },
    code() {
      const id = this.$route.params.widget_id || this.id
      const host = this.host
      return `<div id="tourism-widget-wrapper" data-id="${id}"></div>
<script src="${host}/app.js"/>`
    }
  },
  created() {
    this.$store.commit('layout-personal-area-agency/setButtonBackLink', '/account/agencies')
    this.$store.commit('layout-personal-area-agency/setButtonBackTitle', this.$t('layouts.personal-area-layout.link-my-organizations'))
    this.$store.commit('add-widget/clear')
    this.$store.commit('add-widget/setSrc', `${this.host}/widget-logo.svg`)
    this.$store.commit('add-widget/setArray', this.agencies
      .filter(item => item.status === 'active')
      .map(item => ({
        id: item.id,
        title: item.title,
        checked: false,
      })))
  },
  methods: {
    changeWebsite(website) {
      this.$store.commit('add-widget/setWebsite', website)
      if (website.trim().length > 0) {
        if(!this.$isSite(website)) {
          this.$store.commit('add-widget/setErrors', {
            website: [this.$t('pages.widget-create-view.error-website')]
          })
        }
      }
    },
    cancel() {
      this.$router.push(`${this.locale}/account/agencies/${this.$route.params.agency_id}/widgets`)
    },
    submit() {
      this.pending = true
      this.$store.dispatch('add-widget/post')
        .then(() => {
          if (!this.errors) {
            this.$router.push(`${this.locale}/account/agencies/${this.$route.params.agency_id}/widgets`)
          } else {
            this.pending = false
            this.$handleScrollTo('#title', 150)
          }
        })
    }
  }
}
