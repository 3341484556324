export default function (context, inject) {
  function getAbsoluteUrl(to) {
    const path = context.app.router.resolve(to).href
    return window.location.origin + path
  }

  function urlQuery() {
    const search = new URLSearchParams(window.location.search)
    let query = {}
    for (const key of search) {
      query = {...query, [key[0]]: key[1]}
    }
    return query
  }

  const instantReplaceQuery = (query) => {
    const url = getAbsoluteUrl({
      query: {
        ...context.query,
        ...urlQuery(),
        ...query
      }
    })
    history.replaceState({}, null, url)
  }

  context.$instantReplaceQuery = instantReplaceQuery
  inject('instantReplaceQuery', instantReplaceQuery)
}
