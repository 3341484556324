//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionAgencyLegalInformationOgrn",
  props: {
    ogrn: {
      type: String,
      default: () => ""
    }
  }
};
