//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "JournalCard",
  components: {
    Icon24StrokeFavorites: () => import("@/assets/icons/Icon24StrokeFavorites.svg?inline"),
    RoundButton: () => import("@/components/common/RoundButton"),
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ""
    },
    columns: {
      type: Number,
      default: () => 1
    },
    favorited: {
      type: Boolean,
      default: () => false
    },
    image: {
      type: String,
      default: () => require("~/assets/images/default-image.svg")
    },
    title: {
      type: String,
      default: () => ""
    },
    articleShow: {
      type: Boolean,
      default: () => true
    },
    article: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      innerFavorited: false
    };
  },
  computed: {
    ...mapGetters({
      logged: "user/logged"
    }),
    roundButtonSize() {
      switch (this?.$media()) {
        case "hd": {
          return "38px";
        }
        default: {
          return "36px";
        }
      }
    },
    link() {
      return `/${this.entity}/${this.id}`;
    }
  },
  created() {
    this.innerFavorited = this.favorited;
  },
  methods: {
    resolution(column) {
      switch (column) {
        case 1:
          return "small";
        case 3:
          return "large";
        default:
          return "medium";
      }
    },
    toggleFavorite() {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites${this.$store.getters["user/apple"]
          ? "?api_token=" + this.$store.getters["user/token"]
          : ""}`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited;
            this.$store.dispatch("favorites/fetch", {
              entity: "journals",
              params: {
                count: 100500,
                lang: this.$i18n.locale
              }
            });
          });
      } else {
        this.$login();
      }
    }
  }
};
