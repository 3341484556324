import {Plugin} from '@nuxt/types'

type Link = string | '';

declare module 'vue/types/vue' {
  // this.$functionality inside Vue components
  interface Vue {
    $isExternalLink(link: Link): boolean
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$functionality inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $isExternalLink(link: Link): boolean
  }

  // nuxtContext.$functionality
  interface Context {
    $isExternalLink(link: Link): boolean
  }
}

declare module 'vuex/types/index' {
  // this.$functionality inside Vuex stores
  interface Store<S> {
    $isExternalLink(link: Link): boolean
  }
}

const pluginIsExternalLink: Plugin = ({store}, inject) => {
  inject('isExternalLink', (link: Link) => {
    const result = link.match(/^(?:http(?:s)?:\/\/)/g);
    return result !== null
  })
}

export default pluginIsExternalLink
