//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AdditionPhone',
  components: {
    LinkPhone: () => import('@/components/common/LinkPhone')
  },
  props: {
    title: {
      type: String,
      default: () => 'Телефон'
    },
    value: {
      type: String,
      default: () => ''
    },
    divider: {
      type: Boolean,
      default: () => false
    }
  }
}
