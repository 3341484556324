// Паттерн на проверку видео с Рутуб. Если значение соответсвует - true, иначе - false.

import {Plugin} from '@nuxt/types'

type RutubeVideo = string | '';

declare module 'vue/types/vue' {
  interface Vue {
    $isRutubeVideo(video: RutubeVideo): boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isRutubeVideo(video: RutubeVideo): boolean
  }

  interface Context {
    $isRutubeVideo(video: RutubeVideo): boolean
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $isRutubeVideo(video: RutubeVideo): boolean
  }
}

const pluginIsRutubeVideo: Plugin = (context, inject) => {
  inject('isRutubeVideo', (video: RutubeVideo) => {
    const regexpRutubeVideo = /^(?:https:\/\/rutube\.ru\/video\/([\w\-_]{32}(?:\/)?)?$)/
    return regexpRutubeVideo.test(video)
  })
}

export default pluginIsRutubeVideo
