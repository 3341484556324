import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3df0b5b0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DescriptionFoldingComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/DescriptionFoldingComponent/index.vue').default,ArticleNotes: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ArticleNotes/index.vue').default,SocialVideoFrameComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/SocialVideoFrameComponent/index.vue').default,ArticleEntityCardsBlock: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ArticleEntityCardsBlock/index.vue').default,Gallery: require('/srv/rir-platform/tourism/src/tourism-front/components/common/Gallery/index.vue').default,EntityContent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/EntityContent/index.vue').default})
